import React from "react";
//import NarrowSConnector from "./NarrowSConnector";
//import LineConnector from "./LineConnector";
import ArcConnector from "./ArcConnector";
import SConnector from "./SConnector";

export type ShapeDirection =
  | "r2l"
  | "l2r"
  | "l2l"
  | "r2r"
  | "b2t"
  | "b2b"
  | "t2t"
  | "t2b";

export interface SvgConnectorProps extends React.SVGProps<SVGPathElement> {
  el1: HTMLDivElement;
  el2: HTMLDivElement;
  elParent?: HTMLDivElement | null;
  shape: "s" | "line" | "narrow-s" | "arc";
  direction?: ShapeDirection;
  grids?: number;
  stem?: number;
  roundCorner?: boolean;
  stroke?: string;
  strokeWidth?: number;
  minStep?: number;
  startArrow?: boolean;
  endArrow?: boolean;
  arrowSize?: number;
  zIndex?: number;
  isHover?:boolean;
  isSelected?:boolean;
  hoverColor?:string;
  selectedColor?:string;
}

export interface Point {
  x: number;
  y: number;
}

export interface ShapeConnectorProps extends React.SVGProps<SVGPathElement> {
  startPoint: Point;
  endPoint: Point;
  width: number,
  height: number,
  stroke?: string;
  strokeWidth?: number;
  startArrow?: boolean;
  endArrow?: boolean;
  arrowSize?: number;
  zIndex: number | 1;
}

/**
 * Connect elements with svg paths
 *
 * Forked from https://github.com/tudatn/react-svg-connector
 *
 * @param el1 first element (HTML or React component)
 * @param el2 second element (HTML or React component)
 * @param elParent parent element (HTML or React component)
 * @param shape s | line | narrow-s
 * @param direction (right, left, top, bottom) --> (right, left, top, bottom) if shape is narrow-s
 * @param grid number of columns in X/Y axis from the start point to the end point
 * @param stem min distance from the start point to the first transition
 * @param minStep radius of the transition curve, default is min of (deltaX/grid, deltaY/grid)
 * @param roundCorner true to have a curve transition
 * @param stroke color of the svg path
 * @param strokeWidth width of the svg path
 * @param startArrow true to have an arrow at the start point (not applicable for s shape)
 * @param endArrow true to have an arrow at the end point (not applicable for s shape)
 * @param arrowSize size of arrows
 * @param zIndex
 * @param isHover  if hover state is activated
 * @param isSelected  if selected state is activated
 * @param hoverColor  line stroke color on hover
 * @param selectedColor  line stroke color on select
 */

export default function SvgConnector({
  el1,
  el2,
  elParent,
  shape="s",
  direction,
  grids,
  stem,
  minStep,
  roundCorner=true,
  stroke="#666",
  strokeWidth=1,
  startArrow=false,
  endArrow=false,
  arrowSize=1,
  zIndex=1,
  isHover,
  isSelected,
  hoverColor="orange",
  selectedColor="steelblue"
}: SvgConnectorProps) {
  //const wrapperRef = useRef<HTMLDivElement | null>(null);

  function getCoords(el: HTMLElement, parent: any) {
    const box = el.getBoundingClientRect();

    //Parent position (use immediate parent if parent ref not provided)
    const parentPos = parent ? parent.getBoundingClientRect() : el.offsetParent?.getBoundingClientRect()


    const relativePos = {top:0, right:0, bottom:0, left:0, width:0, height:0};

    relativePos.top    = box.top - (parentPos?.top || 0);
      relativePos.right  = box.right - (parentPos?.right || 0);
      relativePos.bottom = box.bottom - (parentPos?.bottom || 0);
      relativePos.left   = box.left - (parentPos?.left || 0);
      relativePos.width = box.width;
      relativePos.height = box.height;
    return relativePos;
   }

  // Get start and end coords of connector line/curve (relative to parent container)
  function getLineCoordinates() {
    const el1Coords = getCoords(el1, elParent);
    const el2Coords = getCoords(el2, elParent);

    const el1Dimension = {
      width: el1Coords.width,
      height: el1Coords.height,
    };

    const el2Dimension = {
      width: el2Coords.width,
      height: el2Coords.height,
    };

    let start = {
      x: el1Coords.left + el1Coords.width, //el1Coords.right,
      y: el1Coords.top + el1Dimension.height / 2,
    };

    let end = {
      x: el2Coords.left,
      y: el2Coords.top + el2Dimension.height / 2,
    };

    switch (direction) {
      case "l2l":
        start.x = el1Coords.left;
        break;
      case "l2r":
        start.x = el1Coords.left + el1Coords.width;
        end.x =  el2Coords.left//el2Coords.right;
        break;
      case "r2r":
        start.x = el1Coords.right;
        end.x = el2Coords.right;
        break;
      case "b2t":
        start = {
          x: el1Coords.left + el1Dimension.width / 2,

          y: el1Coords.bottom,
        };
        end = {
          x: el2Coords.left + el2Dimension.width / 2,
          y: el2Coords.top,
        };
        break;
      case "b2b":
        start = {
          x: el1Coords.left + el1Dimension.width / 2,
          y: el1Coords.bottom,
        };
        end = {
          x: el2Coords.left + el2Dimension.width / 2,
          y: el2Coords.bottom,
        };
        break;
      case "t2t":
        start = {
          x: el1Coords.left + el1Dimension.width / 2,
          y: el1Coords.top,
        };
        end = {
          x: el2Coords.left + el2Dimension.width / 2,
          y: el2Coords.top,
        };
        break;
      case "t2b":
        start = {
          x: el1Coords.left + el1Dimension.width / 2,
          y: el1Coords.top,
        };
        end = {
          x: el2Coords.left + el2Dimension.width / 2,
          y: el2Coords.bottom,
        };
        break;
      default:
        break;
    }

    return { start, end };
  }

  if (!el1 || !el2) {
    return null
  }

  const coordinates = getLineCoordinates();

  const width = Math.abs(coordinates.end.x - coordinates.start.x);
  const height = Math.max(Math.abs(coordinates.end.y - coordinates.start.y), 5);

  const strokeColor = isSelected ? selectedColor
                    : isHover ? hoverColor
                    : stroke

  // increase z-index for select and hover, since lines may overlay
  const modZ = zIndex + (isSelected ? 2
                        : isHover ? 1
                        : 0)

  /*
   * @param startPoint
 * @param endPoint
 * @param width
 * @param height
 * @param stroke
 * @param strokeWidth
 * @param startArrow
 * @param endArrow
 * @param arrowSize
 * @param zIndex
   */
  return (
    <>
      {shape === "s" && (
        <SConnector
          stroke={ strokeColor }
          strokeWidth={strokeWidth}
          startPoint={coordinates.start}
          endPoint={coordinates.end}
          width={width}
          height={height}
          startArrow={startArrow}
          endArrow={endArrow}
          arrowSize={arrowSize}
          zIndex={modZ}
        />
      )}
      {shape === "arc" && (
        <ArcConnector
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          startPoint={coordinates.start}
          endPoint={coordinates.end}
          width={width}
          height={height}
          startArrow={startArrow}
          endArrow={endArrow}
          arrowSize={arrowSize}
          zIndex={modZ}
        />
      )}
    </>

  )
//   return (
//     <div
//       ref={wrapperRef}
//       style={{
//         position: "absolute",
//         top: 0,
//         width: wrapperRef.current?.offsetParent?.scrollWidth || "100%",
//         height: wrapperRef.current?.offsetParent?.scrollHeight || "100%",
//         zIndex: zIndex ?? 100,
//       }}
//     >
// {/*      {shape === "line" && (
//         <LineConnector
//           {...props}
//           startPoint={coordinates.start}
//           endPoint={coordinates.end}
//           startArrow={startArrow}
//           endArrow={endArrow}
//           arrowSize={arrowSize}
//         />
//       )}*/}
//       {shape === "s" && (
//         <SConnector
//           {...props}
//           stroke={(startId && startId === hoverId || endId && endId === hoverId ) ? "orange" : stroke }
//           startPoint={coordinates.start}
//           endPoint={coordinates.end}
//           startArrow={startArrow}
//           endArrow={endArrow}
//           arrowSize={arrowSize}
//         />
//       )}
// {/*      {shape === "narrow-s" && (
//         <NarrowSConnector
//           {...props}
//           startPoint={coordinates.start}
//           endPoint={coordinates.end}
//           stem={stem}
//           grids={grids}
//           roundCorner={roundCorner}
//           direction={direction}
//           minStep={minStep}
//           startArrow={startArrow}
//           endArrow={endArrow}
//           arrowSize={arrowSize}
//         />
//       )}*/}
//     </div>
//   );
}
