import React from 'react';
import {
  DropdownMenu, DropdownToggle, Button, UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import Select from '../../Components/Form/SimpleSelect';

const CallColumnSelect = (props) => {
  const findColIndex = (col) => props.choices.findIndex((c) => c.value === col);

  const handleSelectColumn = (cols) => {
    // The updated colums will NOT necessarily be ordered correctly
    // so we have to re-order it against the choices array
    // (Because API returns columns in the order request)
    const ordered = Array(cols.length);
    cols.map((col) => {
      const idx = findColIndex(col);
      if (idx >= 0) ordered[idx] = col;
    });

    // filter is for re-indexing
    props.onUpdate(ordered.filter(Boolean));
  };

  // TODO special permission to view source
  return (
    <UncontrolledDropdown className="me-2" size="sm">
      <DropdownToggle color="link" caret className="ms-0 pl-0" disabled={props.disabled}>
        <FaIcon icon="columns" size="sm" className="fa-icons me-1" />
        <span className="title">
          Columns
          {props.selectedCols && props.selectedCols.length > 0 && (
          <span>
            {' '}
            (
            {props.selectedCols.length}
            {' '}
            selected)
          </span>
          )}
        </span>
      </DropdownToggle>
      <DropdownMenu id="view-columns-dropdown" className="select-filters-dropdown dropDown">

        <div className="modal-body">
          <div>
            <Select
              autoFocus={false}
              placeholder="Select columns to view..."
              classNamePrefix="select-filter"
              isClearable
              onChange={handleSelectColumn}
              options={props.choices}
              value={props.selectedCols}
              tabSelectsValue
              backspaceRemovesValue
              isSearchable
              isMulti
              menuIsOpen
              controlShouldRenderValue
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              maxMenuHeight={200}
            />
          </div>

          <div className="d-flex justify-content-end">
            <Button className="ms-2" onClick={props.onApply} size="sm">Apply</Button>
          </div>

        </div>
      </DropdownMenu>
    </UncontrolledDropdown>

  );
};

CallColumnSelect.propTypes = {
  choices: PropTypes.array.isRequired,
  selectedCols: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
CallColumnSelect.defaultProps = {
  selectedCols: [],
};

export default CallColumnSelect;
