import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {faMale} from '@fortawesome/free-solid-svg-icons';
import RulesContext from '../../../Providers/RulesContext';
import { TreeContext } from '../../../Components/Rules/TreeProvider';
import BidDisplay from '../../../Components/Rules/BidDisplay';
import BidEdit from './BidEdit';
import RuleIcons from '../../../Components/Rules/RuleIcons';
import ValueDeltaDisplay from '../../../Components/ValueDeltaDisplay';
import { AdvertiserIconSet } from '../../../Components/Rules/constants';
import {UncontrolledTooltip} from "reactstrap";

/**
 * Component for one rule in a rules tree branch
 */
const TreeRule = ({
  rule, level, expanded, parentDisabled,
}) => {
  const rulesContext = useContext(RulesContext);
  const treeContext = useContext(TreeContext);
  const [bidEditMode, setBidEditMode] = useState(false);
  const [bidDelta, setBidDelta] = useState(null);
  const [bidWarning, setBidWarning] = useState(null);

  const handleBidClick = (e) => {
    e.stopPropagation();
    if (rulesContext.allowEdit) {
      treeContext.setCurrentBidEdit(rule.id);
      setBidEditMode(true);
    }

  };

  // If context tells us that rule focus has changed, set the bid edit mode if we are on this rule
  //  (allows tabbing from rule to rule while in edit mode)
  useEffect(() => {
    if (rulesContext.allowEdit && treeContext.currentBidEdit === rule.id) {
      setBidEditMode(true);
    }
  }, [treeContext.currentBidEdit]);

  const isSelected = rulesContext.selectedRule && rule.id === rulesContext.selectedRule.id;
  const isDisabled = rule.disabled || parentDisabled;

  return (
    <div className={classnames(['tree-leaf', { root: level === 0 }])} id={`rule-${rule.id}`}>

      <div
        className={classnames('rule', {
          'is-match': rule.isMatch,
          'is-not-match': treeContext.isFiltered && !rule.isMatch,
          'is-selected': isSelected,
          'is-folder': rule.rules.length,
          disabled: isDisabled,
          'is-standalone': rule.is_standalone,
        })}
        onClick={() => rulesContext.api.setSelected(rule)}
      >
        {rule.is_standalone && (
          <>
            <FaIcon icon={faMale} size="sm" className="icon-standalone" id={`tip-standalone-rule-${rule.id}`}/>
            <UncontrolledTooltip target={`tip-standalone-rule-${rule.id}`}>
              Standalone Rule: does not affect sibling rules
            </UncontrolledTooltip>
          </>

        )}

        {/* First bid row, shows label, attributes, and bid (or in edit mode, shows label and bid entry) */}
        {/* Use of flex-fill and flex-wrap to handle long labels */}
        <div className="d-flex flex-wrap">
          <div className="d-flex icon-and-label flex-fill">


            {isDisabled && <FaIcon size="sm" icon="ban" className="icon-disabled me-1" title="Rule disabled" />}

            <div className="rule-tree-label flex-fill" id={`tip-standalone-rule-${rule.id}`}>
              {rule.label}
              {!expanded && rule.rules.length > 0 && (
                <span className="child-count ms-1">
                  ({rule.rules.length})
                </span>
              )}
            </div>



          </div>

          <div className="d-flex ms-auto attributes-and-bid">
            {bidEditMode === false && <RuleIcons size="sm" rule={rule} product={treeContext.product} iconSet={AdvertiserIconSet} />}

            {bidEditMode === false && rule.bidding
                  && <BidDisplay ruleId={rule.id} {...rule.bidding} exclusivity={rule.exclusivity} clickHandler={handleBidClick} />}

            {bidEditMode === true && (
            <BidEdit
              rule={rule}
              {...rule.bidding}
              exclusivity={rule.exclusivity}
              setBidEditMode={setBidEditMode}
              onBidDelta={setBidDelta}
              onBidWarning={setBidWarning}
            />
            )}
          </div>
        </div>

        {/* Second row only for bid editing, shows warnings or delta */}
        {bidEditMode === true && (
        <>

          <ExclusiveStarMessage exclusivity={rule.exclusivity} />

          <div className="d-flex justify-content-between mt-2">

            <div className="bid-edit-help flex-fill">
              <small>ENTER/Tab=submit, ESC=cancel</small>
            </div>

            <div className="bid-warning">
              {bidWarning ? (
                <span>{bidWarning}</span>
              ) : (
                <>
                  {bidDelta && (
                  <span>
                    Bid change:
                    <ValueDeltaDisplay {...bidDelta} threshold={1000} />
                  </span>
                  )}
                </>
              )}
            </div>
          </div>
        </>

        )}


      </div>

    </div>
  );
};

const ExclusiveStarMessage = React.memo(({ exclusivity }) => {
  if (!exclusivity) return null;
  return (
    <div className="exclusive-warning">
      <FaIcon icon="star" size="sm" className={classnames(['exclusive-star', 'me-1', { optional: exclusivity === 'optional' }])} />
      {exclusivity === 'optional' && <b>Optionally exclusive bidding (bid must be modifier)</b>}
      {exclusivity === 'strict' && <b>Exclusive bidding only</b>}
    </div>
  );
});

TreeRule.propTypes = {
  rule: PropTypes.object,
  level: PropTypes.number,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  parentDisabled: PropTypes.bool,
};

TreeRule.defaultProps = {
  expanded: true,
};

export default TreeRule;
