import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/*
 * Checkbox formgroup with an icon instead of standard checkbox input
 */
const IconCheckbox = ({
  name, value, className, onChange, disabled = false, children,
}) => {
  const handleClick = () => {
    if (!disabled) {
      onChange(name, !value);
    }
  };

  return (
    <FormGroup className={classnames(['icon-checkbox', className, { checked: value, disabled }])} check>
      <Label check onClick={handleClick}>
        <FaIcon
          icon={['far', (value ? 'check-square' : 'square')]}
          size="lg"
          className="cbx-icon"
        />
        {children}
      </Label>
    </FormGroup>
  );
};

IconCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default IconCheckbox;
