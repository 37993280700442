import React from 'react';
import {
  Button
} from 'reactstrap';

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { TimezoneSelect, TimezoneDisplay } from '../Form/TimezoneSelect';
import DateRangeDropdown from '../Form/DateRangeDropdown';

/*
 * A filter bar section with a date picker, refresh/execute button, and clear filter button
 * Meant to be aligned on right side of filter bar
 *
 * If an update function is not provided for each item it will not be shown
 *
 * onExecute and onRefresh technically would do the same thing, but the former shows
 * a labelled button and the latter has a refresh icon. We use 'execute' for reports
 * because the report does not auto-update when you change the filters; you have to click
 * 'view report'. So it makes more sense to have such a button rather than a refresh symbol
 */
const RightDateAndControls = ({
  startDate,
  endDate,
  onChangeDate,
  onRefresh,
  onReset,
  onExecute,
  onSelectTimezone,
  timezone,
  defaultTimezone,
  allowFuture = false,
  isLoading,
  executeLabel = 'Get Data',
  className = '',
}) => (
  <div className={`right-date-controls ${className}`}>
    <div className="d-flex flex-grow-2 justify-content-end align-items-center">

      {onChangeDate && (
      <div className="d-flex flex-column align-items-end me-3">

        <DateRangeDropdown
          startDate={startDate}
          endDate={endDate}
          onSelect={onChangeDate}
          allowFuture={allowFuture}
        />

        { (timezone || defaultTimezone)
              && (
              <>
                {typeof onSelectTimezone === 'function' ? (
                  <TimezoneSelect
                    defaultTimezone={defaultTimezone}
                    selectedTimezone={timezone}
                    onSelectTimezone={onSelectTimezone}
                  />
                ) : (
                  <TimezoneDisplay timezone={timezone || defaultTimezone} />
                )}
              </>
        )}

      </div>
      )}

      <div className="my-1 d-flex flex-wrap">

        {onExecute && (
          <Button color="primary" size="sm" className="px-1" onClick={onExecute} disabled={isLoading}>
            {executeLabel}
          </Button>
        )}

        {onRefresh && (
          <Button color="link" title="Refresh data" onClick={onRefresh} disabled={isLoading} className="px-1 py-0">
            <FaIcon icon="sync" />
          </Button>
        )}

        {onReset && (
          <Button color="link" title="Clear filters" onClick={onReset} disabled={isLoading} className="px-1 py-0">
            <span className="fa-layers fa-fw">
              <FaIcon icon="filter" size="lg" />
              <FaIcon icon="circle" inverse size="sm" transform="right-7 up-2" />
              <FaIcon icon="ban" size="sm" color="red" transform="right-7 up-2" />
            </span>
          </Button>
        )}
      </div>
    </div>
  </div>
);

RightDateAndControls.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  allowFuture: PropTypes.bool,
  onChangeDate: PropTypes.func,
  onRefresh: PropTypes.func,
  onReset: PropTypes.func,
  onExecute: PropTypes.func,
  onSelectTimezone: PropTypes.func,
  timezone: PropTypes.string,
  defaultTimezone: PropTypes.string,
  isLoading: PropTypes.bool,
  executeLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default RightDateAndControls;
