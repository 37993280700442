import React, { FC,  useState, useEffect } from "react";
import AccountsTable from "./AccountsTable";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import useApi from "../../../Hooks/useApi";
import useFavoriteOrder from "../../../Hooks/useFavoriteOrder";



/*
 * All Accounts
 * Shows paged table listing all accounts
 */
const AllAccounts: FC<any> = ({filters, controls, updateQuery, onStatusChange, openOrderModal, favoriteOrderIds, onFavorite}) => {

  const { api } = useApi();
  const [list, setList] = useState<any[]>([]);
  const {addOrder, removeOrder} = useFavoriteOrder();
  const [pageCount, setPageCount] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(1);

  // load orders on mount or when filters/controls change
  useEffect(() => {
    load();
  }, [filters, controls.sort_by, controls.sort_dir, controls.page, controls.limit]);

  const filterParamsToApi = () => {
    const apiFilters = {...filters};

    // 'search' filters accounts, 'order_search' looks for orders
    if (apiFilters.search) {
      if (apiFilters.search_type === 'orders') {
        apiFilters.order_search = apiFilters.search;
        delete apiFilters.search;
      }
    }

    delete apiFilters.search_type;

    return apiFilters;
  }


  const load = async (refresh = false) => {
    // Get list of accounts, each with orders collections

    const apiFilters = filterParamsToApi();
    if (!refresh) {
      setLoading(true);
    }

    updateQuery();
    const {
      page, sort_by, sort_dir, limit,
    } = controls;
    const result = await api.endpoints.accounts.list({
      ...apiFilters,
      page,
      sort_by,
      sort_dir,
      limit
    });

    if (result) {
      const { limit, total, data } = result; // row:0, page:1, limit:20, data:[]
      setPageCount(total > limit ? Math.ceil(total / limit) : 1);
      setTotalRows(total);
      //set subrows (order list) expanded for all
      setList(data.map((a:any) => ({ ...a, expanded: true })));
      setRefreshKey(refreshKey + 1);
    } else {
      notify(`Unable to load orders: ${api.error.name}`, 'error');
    }


    if (!refresh) {
      setLoading(false);
    }
  };

  const doFavorite = async (id:number, setFavorite:boolean) => {
    let result;

    if (setFavorite){
      result = await addOrder(id);
    } else {
      result = await removeOrder(id);
    }

    if (result) {
      onFavorite(id);
    }


  }

  return (

      <AccountsTable
        accounts={list}
        onStatusChange={onStatusChange}
        openOrderModal={openOrderModal}
        onFavorite={doFavorite}
        favoriteOrderIds={favoriteOrderIds}
        loading={loading}
        pageCount={pageCount}
        totalRows={totalRows}
        {...controls}
        key={refreshKey}
      />

  )
}

export default AllAccounts;
