import React, {FC} from 'react';
import {ChartIntervalOptions} from "../Table/FilterChoices";
import {Input, Spinner} from "reactstrap";
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";

type AggSelectProps = {
  value:string;
  onChange:(interval:string) => void;
  className?:string;
  loading?:boolean;
  scaledTo?:string;
}
export const AggSelect:FC<AggSelectProps> = (
  {
    value,
    onChange,
    loading,
    scaledTo,
    className=""
  }
) => {

  const handleIntervalChange = (e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  return (
    <div
      className={`d-flex align-items-center ${className}`}
    >

      <div>Aggregate by </div>
      <Input
      type="select"
      value={value}
      bsSize="sm"
      name="interval-unit"
      style={{ width: '140px' }}
      className="ms-2"
      onChange={handleIntervalChange}
      disabled={loading}
    >
      {ChartIntervalOptions.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ))}
    </Input>
      {scaledTo && (
        <span className="ms-2">
                <HelpPopper
                  name="chart-scale-help"
                  iconClass="me-1"
                >
                  The selected time interval with the current date range
                  {' '}produces too many data points to display in the chart.
                  {' '}It has been scaled back to {scaledTo}
                </HelpPopper>
                scaled to {scaledTo}
              </span>)}
      {loading && <Spinner size="sm" className="ms-1" />}

    </div>
  )
}
