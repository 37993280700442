import { useMemo, useCallback } from 'react';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { TimezoneOptions } from '../Components/Form/FormCommon';

/*
 *Given America/Los_Angeles, return "Pacific Standard Time"
 */
const useTimezoneDisplay = (timezone) => {
  const formatToTimezone = useCallback((strDate, format) => formatTz(utcToZonedTime(new Date(strDate), timezone), format, { timeZone: timezone }), [timezone]);

  // Given America/Los_Angeles, return "Pacific Standard Time"
  const timezoneDisplay = useMemo(
    () => (timezone ? TimezoneOptions.find((opt) => opt.value === timezone).name : null),
    [timezone],
  );

  return {
    formatToTimezone,
    timezoneDisplay,
  };
};

export default useTimezoneDisplay;
