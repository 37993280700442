import React, { useState } from 'react';
import {RelativeTime} from "@thedmsgroup/mastodon-ui-components";
import {RequestLog, ResponseLog} from "@thedmsgroup/mastodon-ui-components/lib/components/HttpLog/HttpLog";
import {Alert, Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const HttpLog = ({log}) => {
  return <div className="delivery flex-grow-1" key={log.request_id}>
    <div className="data-row">
      <label>Request ID</label>
      <div>{log.request_id}</div>
    </div>

    <div className="data-row">
      <label>Sent At</label>
      <div><RelativeTime date={log.sent_at} /></div>
    </div>

    <div className="data-row">
      <label>Duration</label>
      <div>{log.response_time} ms</div>
    </div>

    <div className="data-row">
      <label>Status Code</label>
      <div>{log.status_code}</div>
    </div>

    <div className="data-row">
      <label>Request</label>
      <div>
        <ToggleMe>
          <><HelpText>This is the actual request sent to the client. It is safe to share with the client.</HelpText>
            {log.request && <RequestLog requestLog={log.request} />}</>
        </ToggleMe>
      </div>
    </div>

    <div className="data-row">
      <label>Response</label>
      <div>
        <ToggleMe>
          <><HelpText>This is the actual response received from the client. It is safe to share with the client.</HelpText>
            {log.response && <ResponseLog responseLog={log.response} bodyHeight={300} />}</>
        </ToggleMe>
      </div>
    </div>
  </div>
}

// todo extract to utilities
const HelpText = ({children}) => <Alert color="secondary">{children}</Alert>

// todo extract to utilities
const ToggleMe = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="toggle-me">
      <Button color="link" size="sm" className="inline d-flex align-items-center" onClick={handleToggle}>
        <FaIcon icon="eye" className="me-1" />
        <div>{show ? 'hide' : 'show'}</div>
      </Button>
      <div className={classnames(['data', { show }])}>
        {children}
      </div>
    </div>
  );
};

export default HttpLog
