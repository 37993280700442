import * as React from 'react';

const Discover = (props) => (
  <svg width={props.width} {...props}>
    <g fill="none">
      <path
        d="M52.877 0C23.68 0 0 23.155 0 51.71v367.58C0 447.85 23.672 471 52.877 471h644.246C726.32 471 750 447.845 750 419.29V51.71C750 23.15 726.328 0 697.123 0H52.877z"
        fill="#4D4D4D"
      />
      <path
        d="M314.57 152.198c8.496 0 15.623 1.733 24.295 5.912v22.087c-8.215-7.633-15.341-10.828-24.765-10.828-18.523 0-33.09 14.576-33.09 33.055 0 19.488 14.116 33.196 34.008 33.196 8.955 0 15.95-3.029 23.847-10.54v22.098c-8.981 4.02-16.26 5.607-24.765 5.607-30.075 0-53.444-21.935-53.444-50.224 0-27.984 23.991-50.363 53.914-50.363zm-93.379.609c11.102 0 21.26 3.612 29.754 10.673l-10.335 12.86c-5.145-5.48-10.01-7.792-15.927-7.792-8.513 0-14.713 4.606-14.713 10.667 0 5.198 3.48 7.95 15.332 12.118 22.467 7.808 29.124 14.732 29.124 30.021 0 18.633-14.399 31.602-34.923 31.602-15.029 0-25.955-5.626-35.055-18.32l12.758-11.68c4.549 8.36 12.136 12.837 21.557 12.837 8.811 0 15.334-5.779 15.334-13.576 0-4.042-1.976-7.507-5.921-9.958-1.987-1.16-5.921-2.89-13.653-5.481-18.55-6.347-24.914-13.132-24.914-26.391 0-15.75 13.667-27.58 31.582-27.58zm225.695 1.678h21.575l27.004 64.645 27.35-64.645h21.412l-43.744 98.713h-10.628l-42.969-98.713zm-382.065.148h28.991c32.03 0 54.359 19.786 54.359 48.19 0 14.163-6.831 27.856-18.382 36.944-9.72 7.67-20.795 11.11-36.13 11.11H64.822v-96.244zm92.437 0h19.751v96.245h-19.75v-96.245zm395.899 0h56.011v16.308h-36.275v21.362h34.938v16.3h-34.938v25.98h36.275v16.295h-56.011v-96.245zm69.094 0h29.283c22.78 0 35.833 10.397 35.833 28.416 0 14.735-8.187 24.406-23.064 27.284l31.873 40.545h-24.288l-27.336-38.664h-2.575v38.664h-19.726v-96.245zm19.726 15.159v29.148h5.771c12.612 0 19.297-5.206 19.297-14.88 0-9.367-6.686-14.268-18.985-14.268h-6.083zm-557.42 1.149v63.642h5.3c12.763 0 20.823-2.324 27.028-7.648 6.83-5.782 10.94-15.015 10.94-24.25 0-9.222-4.11-18.18-10.94-23.961-6.523-5.61-14.265-7.783-27.028-7.783h-5.3z"
        fill="#FFF"
      />
      <path
        d="M399.164 151.56c29.75 0 53.867 22.167 53.867 49.552v.031c0 27.385-24.117 49.584-53.867 49.584-29.75 0-53.867-22.199-53.867-49.584v-.03c0-27.386 24.117-49.554 53.867-49.554zm350.819 119.534c-25.048 17.233-212.574 140.397-537.264 199.891h484.387c29.197 0 52.877-23.154 52.877-51.71V271.094z"
        fill="#F47216"
      />
    </g>
  </svg>
);

Discover.defaultProps = {
  viewBox: '0 0 750 471',
  width: 100,
};

export default Discover;
