import React, {  useState, useEffect } from 'react';
import classnames from 'classnames';
import {Spinner} from "reactstrap";
import {Filter} from "../../../Components/Table/Filter";
//import { ProductChoices } from '../../Components/Table/FilterChoices';
import useApi from "../../../Hooks/useApi";


const TargetChoices = [
  { value: 'source', label: 'Source' },
  { value: 'channel', label: 'Channel' },
  { value: 'order', label: 'Order' },
];

const Filters = ({filters, loading, onChange}) => {

  const { api } = useApi();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [choices, setChoices] = useState({});
  const targetFromFilters = () => {
    if (filters.account) {
      return "order"
    } else if (filters.channel) {
      return "channel"
    }
    return "source";
  }
  const [targetObject, setTargetObject] = useState(targetFromFilters());


  // onLoad, get choices
  useEffect(() => {

      const getChoices = async()=>{

        const requests = [
          {endpoint:'verticals',action:'list'},
          {endpoint:'channels',action:'list', params:{options:true} },
          {endpoint:'accounts',action:'options'},
          {endpoint:'sources',action:'list', params:{options:true}}
        ];
        if (filters.account) {
          requests.push({endpoint:'orders',action:'list', params:{account_id:filters.account}})
        }
        const batches =  await api.batchGet(requests)
        if(batches && batches.length){
          const[verticals, channels, accounts, sources, orders] = batches;
          setChoices({
            target: TargetChoices,
            source: sources.map( item => { return {value:item.id.toString(), label:item.name}}),
            channel: channels.map( item => { return {value:item.id.toString(), label:item.name}}),
            vertical: verticals.map( item => { return {value:item.id.toString(), label:item.display_name}}),
            account: accounts.map( item => { return {value:item.id.toString(), label:item.name}}),
            order: orders ? orders.map( item => { return {value:item.id.toString(), label:item.name}}) : []
          })
        }
      };

      if(Object.keys(choices).length === 0) getChoices();

  }, []);

  // Adjust the target if parent changes the filters
  useEffect(() => {
    const target = targetFromFilters();
    if (target !== targetObject) {
      if (filters.account) {
        getAccountOrders(filters.account);
      }
      setTargetObject(target);
    }
  }, [filters])


  const handleChangeFilter = (param, value) => {
    let newFilters; //const newFilters = {}; //{...filters};
    if (value) {
      if (param === "order") {

        // order goes with account
        newFilters = { ...filters, [param]: value }
      } else {
        newFilters = { [param]: value }
      }
      onChange(newFilters);
      if (param === 'account') {
        getAccountOrders(value);
      }
    } else {
      newFilters = { ...filters }
      delete newFilters[param];
      onChange(newFilters);
    }
  }

  // target choice is not part of URL params
  const handleChangeTarget = (param, target) => {
    setTargetObject(target);
  }

  //TODO wire up
  const handleClearFilters = () => {
    onChange({});
  }

  const getAccountOrders = async(account_id) => {
    if (account_id) {
      setLoadingOrders(true);
      const orders = await api.endpoints.orders.list({account_id});
      if (orders) {
        setChoices(prev => {
          return {...prev, order: orders.map((o)=> {
              return {value:o.id.toString(), label:o.name}
            })}
        })

        if (orders.length === 1 && !filters.order) {
          handleChangeFilter('order', orders[0].id)
        }
      }
      setLoadingOrders(false);
    }

  }

  return (
    <div className="d-flex position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />
      <div className="d-flex">


        <div style={{width:'150px'}}>
          <Filter
            label="Route from"
            placeholder="Target"
            param="target"
            options={choices.target ?? []}
            value={targetObject}
            onChange={handleChangeTarget}
            isMulti={false}
            isClearable={false}
          />
        </div>

        {targetObject === "source" && (
          <div style={{width:'400px'}} className="min-250">
            <Filter
              label="Source"
              placeholder="Source"
              param="source"
              options={choices.source ?? []}
              value={filters?.source || ""}
              onChange={handleChangeFilter}
              //getOptionValue={(item)=>item.id.toString()}
              isMulti={false}
              isClearable={false}
            />
          </div>
        )}
        {targetObject === "channel" && (
          <div style={{width:'400px'}}  className="min-250">
            <Filter
              label="Channel"
              placeholder="Channel"
              param="channel"
              options={choices.channel ?? []}
              value={filters?.channel || []}
              onChange={handleChangeFilter}
              isMulti={false}
              isClearable={false}
            />
          </div>
        )}

        {targetObject === "order" && (
          <>
            <div style={{width:'300px'}} className="min-250">
              <Filter
                label="Account"
                placeholder="Account"
                param="account"
                options={choices.account ?? []}
                value={filters?.account || []}
                onChange={handleChangeFilter}
                isMulti={false}
                isClearable={false}
              />
            </div>


              <div style={{width:'300px'}} className="min-250">
                {loadingOrders ? (
                  <div><Spinner size="sm" /> Getting account orders...</div>
                ) : (
                <Filter
                  label="Order"
                  placeholder={filters.account ?  (choices.order?.length > 0 ? "Select an order" : "No orders found") : "Select an account"}
                  param="order"
                  options={choices.order ?? []}
                  value={filters?.order || []}
                  onChange={handleChangeFilter}
                  isMulti={false}
                  isClearable={false}
                />
                )}
              </div>


          </>

        )}


{/*
        <div className="min-250">
          <Filter
            placeholder="Vertical"
            param="vertical.name"
            options={choices.vertical ?? []}
            value={filters['vertical.name'] || []}
            onChange={handleChangeFilter}
            isMulti={false}
          />
        </div>

        <div className="min-250">
          <Filter
            placeholder="Product"
            param="product"
            options={choices.product ?? []}
            value={filters['product'] || []}
            onChange={handleChangeFilter}
            isMulti={false}
          />
        </div>*/}

      </div>

    </div>
  );
};

export default Filters;
