import React from 'react';
import {Outlet} from 'react-router-dom';
import Footer from '../Layout/Footer';
import logoImage from '../images/DMS_logo_mastodon_white.svg';

/*
 * Template wrapper for non-authenticated pages like Login, reset password.
 */
const EntryLayout = () => (
  <>
    <div id="entry-layout">

      <div className="blue-top"></div>

      <div id="entry-form-container">

        <div className="text-center mb-3"><img src={logoImage} width="240" alt="Mastodon: powered by DMS" /></div>

        <Outlet />

      </div>

      <div className="push-footer" />

    </div>
    <Footer />
  </>

);


export default EntryLayout;
