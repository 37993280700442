import React, { useState, useContext } from 'react';
import {
  Button, FormGroup, ButtonGroup, UncontrolledDropdown, DropdownItem, FormText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import RulesContext from '../../Providers/RulesContext';

const SelectionTree = ({ rule, onSelectTarget }) => (
  <UncontrolledDropdown>
    <DropdownItem onClick={() => onSelectTarget(rule)}>{rule.label}</DropdownItem>
    {rule.rules.length > 0 && (

    <div className="rule-branch">
      <div className="rule-branch-line">
        { rule.rules.map((childRule, i) => <SelectionTree key={childRule.id} rule={childRule} onSelectTarget={onSelectTarget} />)}
      </div>
    </div>
    )}
  </UncontrolledDropdown>
);

/*
 * I like to move it, move it
 */
const MoveWidget = ({ rule, close }) => {
  const [placement, setPlacement] = useState('below');
  const rulesContext = useContext(RulesContext);

  const selectTargetHandler = (targetRule) => {
    // For root rule as target, placement must be 'child'
    // const plc = rulesContext.api.orderRule().id === targetRule.id ? 'child' : placement;
    const isRoot = targetRule.id === rulesContext.tree.id;
    if (isRoot && placement === 'above') {
      return;
    }
    const plc = isRoot ? 'child' : placement;
    if (rule.id !== targetRule.id) {
      rulesContext.api.moveRule(rule, targetRule, plc);
    }
    close();
  };

  return (
    <div className="">

      <div id="move-rule-widget">
        <FormGroup id="move-rule-placement">
          <FormText>Select position and destination</FormText>
          <ButtonGroup vertical className="d-flex">
            <Button
              value="below"
              active={placement === 'below'}
              onClick={() => setPlacement('below')}
              block
              outline
            >
              Move below:
            </Button>
            <Button
              value="above"
              active={placement === 'above'}
              onClick={() => setPlacement('above')}
              block
              outline
            >
              Move above:
            </Button>
            <Button
              value="child"
              active={placement === 'child'}
              block
              outline
              onClick={() => setPlacement('child')}
            >
              Move as child of:
            </Button>
          </ButtonGroup>
        </FormGroup>
        <div id="rule-choice-container">
          <SelectionTree rule={rulesContext.tree} onSelectTarget={selectTargetHandler} />
        </div>
      </div>
    </div>
  );
};

MoveWidget.propTypes = {
  rule: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default MoveWidget;
