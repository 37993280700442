import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import PixelForm from '../../../Components/Form/PixelForm';
import useUniqueKeyArray from '../../../Hooks/useUniqueKeyArray';
import useDidMount from '../../../Hooks/useDidMount';

/*
 * Tracking: a page for entering tracking pixels associated with a rule.
 */
const Tracking = ({ clickTags, onChange, errors }) => {
  const didMount = useDidMount();

  const {
    items,
    addItem,
    removeItem,
    updateItem,
  } = useUniqueKeyArray(clickTags);

  // Update rule on state change
  useEffect(() => {
    if (didMount) {
      onChange('click_tags', items.map((item) => item.value));
    }
  }, [items]);

  return (
    <div className="form-section">
      <div className="form-section-header">Click Pixels</div>
      <p>Enter URLs or tags for tracking clicks.</p>

      {items.length === 0
        && (
        <StandardAlert color="light">
          <span>
            No click pixels are configured.
            <Button color="link" className="inline ms-1" onClick={addItem}>
              <FaIcon icon="plus" size="sm" className="me-1" />
              <span>Add a pixel</span>
            </Button>
&nbsp;
          </span>
        </StandardAlert>
        )}

      <div>

        {items.map((tag, i) => (
          <PixelForm
            pixel={tag.value}
            className="click-pixel"
            handleRemove={() => removeItem(i)}
            handleChange={(val) => updateItem(i, val)}
            key={tag.key}
          />
        ))}

        {items.length > 0 && (
          <div className="text-right">
            <Button color="link" onClick={addItem}>
              <FaIcon icon="plus" />
              {' '}
              <span>Add Another</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Tracking.propTypes = {
  clickTags: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default Tracking;
