import React, {useState, useEffect, useRef} from "react";
import useDateRangeValue from "@thedmsgroup/mastodon-ui-components/lib/components/DateRangeSelect/useDateRangeValue";
import {
  Button,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {faCommentsDollar, faCompress, faDollarSign, faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import usePermission from '../../../Hooks/usePermission';
import useApi from '../../../Hooks/useApi';
import PageSection from "../../../Layout/PageSection";
import DeliveryRejectStat from "../../Deliveries/DeliveryRejectStat";
import {aggregateToMultiDataset} from "../../../Components/Chart/chart";
import {ReadServiceBucket} from "../../../types";
import {BlockUI} from 'ns-react-block-ui';
import {DateRangeSelect, DefaultDateRangeSelectOptions, Tile} from "@thedmsgroup/mastodon-ui-components";
import ApplyOrRefreshButton from "../../../Components/Table/ApplyOrRefreshButton";
import useIntegrationNameLookup from "../../../Hooks/useIntegrationNameLookup";
import NumberFormat from "react-number-format";
import {AggSelect} from "../../../Components/Chart/AggSelect";
import {useChartInterval} from "../../../Hooks/useChartInterval";

//import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";

type OrderDashboardProps = {
  orderId:number,
  userTimezone:string;
}

/*
 * Section of Order/Rules Manager that shows order stats
 */
const OrderDashboard = ({ orderId, userTimezone }:OrderDashboardProps) => {

  const allowViewAuctions = usePermission('auctions.view');
  if (!allowViewAuctions) return ;
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryData, setDeliveryData] = useState<any>({});
  const [auctionData, setAuctionData] = useState<any>({});
  const {isLoaded: isIntegrationsLoaded, getIntegrationName} = useIntegrationNameLookup();
  const {valueToISO} = useDateRangeValue();
  const [filters, setFilters] = useState<Record<string, any>>({
      start: 'now/d',
      end: 'now/d',
      agg_interval: 'auto',
      agg_reject_category:'delivery_timeout',
      agg_timezone: userTimezone
    });
  const [isLoadingDeliveries, setIsLoadingDeliveries] = useState(true);
  const [chartMinMaxTime, setChartMinMaxTime] = useState({min:"", max:""})
  const {intervalToApi, getIntervalLabel} = useChartInterval();
  const [intervalLabel, setIntervalLabel] = useState('')
  const realIntervalRef =  useRef<string>('auto');
  const [isScaledInterval, setIsScaledInterval] = useState(false);

  const apiDeliveries = useApi('debug', 'deliveriesList');
  const apiAuctions = useApi('debug', 'auctionsList');


  const MAX_CHART_BUCKETS = 120;
  const NUM_CHART_BUCKETS = 15;


  //Use query string?
  // const [deliveryFilters, setDeliveryFilters] = useState(() => {
  //   return {
  //     start: searchParams.delivery_start ?? 'now/d',
  //     end: searchParams.delivery_end ?? 'now/d',
  //     agg_interval: '1m',
  //     tz: "America/Los_Angeles"
  //   }
  // })


  useEffect(() => {

     if (isIntegrationsLoaded) {
      loadDeliveries();
      loadAuctionData();
     }
  }, [filters, isIntegrationsLoaded]);

  const paramsToReadService = () => {
    const apiParams = {} as Record<string,any>;

    if (filters.start) {
      apiParams.after = valueToISO('start', filters.start, userTimezone)
    }

    if (filters.end) {
      apiParams.before = valueToISO('end', filters.end, userTimezone)
    }
    apiParams.tz = "America/Los_Angeles"; //deliveryFilters.delivery_tz
    apiParams.agg_interval = filters.agg_interval ?? 'auto';
    apiParams.agg_buckets = NUM_CHART_BUCKETS;
    if (filters.agg_reject_category) {
      apiParams.agg_reject_category = filters.agg_reject_category;
    }
    return apiParams;
  }

  const loadDeliveries = async () => {
    setIsLoading(true);
    setIsLoadingDeliveries(true);
    const params = paramsToReadService();

    // Interval adjustment
    // The interval value in the filters is what the user selects
    // This may be adjusted in order to scale the data to the chart
    // In that case, the adjusted ("real") value is sent to the API, but the filter value remains what
    // was selected, and a message about the scaling is shown.
    const {interval, scaledInterval} = intervalToApi(params.agg_interval, filters.start, filters.end, MAX_CHART_BUCKETS)
    params.agg_interval = scaledInterval || interval
    realIntervalRef.current = params.agg_interval;

    const result = await apiDeliveries.fetch({
      ...params,
      order_id: orderId,
      page: 1,
      per_page: 1, //don't need list, just aggregation detail (but setting to zero will result in default per_page in read service)
    });

    if (result && result.deliveries) {
      const { aggregations } = result.deliveries;

      const agg= {} as Record<string, any>;

      if (aggregations.reject_categories) {
        agg.rejectionCategoryTotals = aggregations.reject_categories.buckets;
      }

      if (aggregations.deliveries_over_time) {

        agg.rejectionsOverTimeByIntegration = aggregateToMultiDataset(
          aggregations.deliveries_over_time.buckets,
          'key_as_string',
          'key',
          'doc_count',
          'rejected.integrations.buckets',
          getIntegrationName
        );
      }

      if (aggregations.delivery_rejects_over_time) {
        agg.rejectionsOverTimeByCategory = aggregateToMultiDataset(
          aggregations.delivery_rejects_over_time.buckets,
          'key_as_string',
          'key',
          'doc_count',
          'reject_category.buckets'
        );
      }

      if (aggregations.integrations_rejected) {
        // buckets by rejection, each with integration counts
        const rejections = {} as Record<string, any>;
        aggregations.integrations_rejected.buckets.forEach((bkt:ReadServiceBucket) => {
          rejections[bkt.key] = bkt.integrations.buckets.map((b:ReadServiceBucket) => {
            return {integration:getIntegrationName(b.key.toString()), count:b.doc_count}
          });

        })
        agg.integrationRejectionTotals = rejections;
      }

      //This label is used to show actual requested interval, below the graph
      const label = getIntervalLabel(params.agg_interval);
      setIntervalLabel(label);
      setIsScaledInterval(Boolean(scaledInterval));

      setChartMinMaxTime({min:params.after, max:params.before})

      setDeliveryData(agg);

    } else {
      //@ts-ignore
      //notify(`Unable to load delivery data: ${apiDeliveries.error?.name}`, 'error');
    }

    setIsLoadingDeliveries(false);
    setIsLoading(false);
  };

  const loadAuctionData = async () => {
    setIsLoading(true);

    const params = paramsToReadService();
    delete params.agg_interval; //not charting
    delete params.agg_buckets;

    const result = await apiAuctions.fetch({
      ...params,
      order_id: orderId,
      version: 2, //the one with aggregations
      page: 1,
      per_page: 1, //don't need list, just aggregation detail (but setting to zero will result in default per_page in read service)
    });

    if (result && result.auctions) {
      const {  num_hits, aggregations } = result.auctions;

      const agg = {
        auction_count: num_hits,
        auctions_over_time:null,
        matches: null,
        num_leads_posted: aggregations?.num_leads_posted ?? 0
      }

      if (aggregations.matches?.filtered) {
        agg.matches = {...aggregations.matches.filtered}
      }

      setChartMinMaxTime({min:params.after, max:params.before})

      setAuctionData(agg);

    } else {
      //@ts-ignore
     // notify(`Unable to load auction data: ${apiDeliveries.error?.name}`, 'error');
    }

    setIsLoading(false);
  };

  const handleChangeRejectCategory = (category:string) => {
    setFilters({...filters, agg_reject_category:category});
  }

  const handleChangeInterval = (interval:string) => {
    setFilters({...filters, agg_interval:interval});
  }


  return (
    <PageSection
      title="Order Stats"
      expanded={true}
      expandable={true}
      tools={
        <DeliveryDetailFilters
          onChange={setFilters}
          onRefresh={loadDeliveries}
          isLoading={isLoading}
          filters={filters}
        />
      }
      storageKey="order_manager_dashboard_expanded"
      className="dashboard-section order-dashboard mb-3"
    >



      <BlockUI
        blocking={isLoading || isLoadingDeliveries}
        mode="stretch"
        loader={<Spinner color="success" />}
      >

        {auctionData.matches && (
          <div className="tile-grid mb-4">

            <Tile
              content={<NumberFormat value={auctionData.auction_count} displayType="text" thousandSeparator />}
              contentType="number"
              icon={<FaIcon icon={faCommentsDollar} size="5x"/>}
               horizontal
               horizontalColor="info"
              title="Total Auctions"
            />

            {auctionData.matches && (
              <>
                <Tile
                  content={<NumberFormat value={auctionData.matches.doc_count} displayType="text" thousandSeparator />}
                  contentType="number"
                  horizontal
                  horizontalColor="danger"
                  icon={<FaIcon icon={faCompress} size="5x"/>}
                  title="Match Attempts"
                />

                <Tile
                  content={<NumberFormat value={auctionData.matches.stats?.buckets.num_bids.doc_count} displayType="text" thousandSeparator />}
                  contentType="number"
                  horizontal
                  horizontalColor="primary"
                  icon={<FaIcon icon="comment-dollar" size="5x"/>}
                  title="Bid Attempts"
                />
              </>
            )}


            {auctionData.matches && (
              <>
                <Tile
                  content={<NumberFormat value={auctionData.num_leads_posted.doc_count} displayType="text" thousandSeparator />}
                  contentType="number"
                  horizontal
                  horizontalColor="warning"
                  icon={<FaIcon icon="id-card" size="5x"/>}
                  title="Leads Posted"
                />

                <Tile
                  content={<NumberFormat value={auctionData.matches.stats.buckets.num_sales.doc_count} displayType="text" thousandSeparator />}
                  contentType="number"
                  horizontal
                  horizontalColor="info"
                  icon={<FaIcon icon={faFileInvoiceDollar} size="5x"/>}
                  title="Sale Count"
                />
              </>
            )}

            {auctionData.matches.sales?.sum_account_bid && (
              <Tile
                content={<NumberFormat value={auctionData.matches.sales.sum_account_bid?.value} displayType="text" prefix="$" thousandSeparator decimalScale={0} fixedDecimalScale />}
                contentType="number"
                horizontal
                horizontalColor="success"
                icon={<FaIcon icon={faDollarSign} size="5x"/>}
                title="Revenue (Account Bid)"
              />
            )}

          </div>
        )}

        <div className="form-section p-0 m-0">
          <div className="d-flex justify-content-between">
            <div className="form-section-header">Delivery Rejections</div>
            <AggSelect
              value={filters.agg_interval}
              onChange={handleChangeInterval}
              scaledTo={isScaledInterval ? intervalLabel : undefined}
              loading={isLoading}
            />
          </div>

          <DeliveryRejectStat
            rejectCategory={filters.agg_reject_category ?? 'delivery_timeout'}
            onChangeRejectCategory={handleChangeRejectCategory}
            data={deliveryData}
            isLoading={isLoading || isLoadingDeliveries}
            className="mb-2"
            aggInterval={realIntervalRef.current}
            intervalLabel={intervalLabel}
            chartMinTime={chartMinMaxTime.min}
            chartMaxTime={chartMinMaxTime.max}
          />
        </div>

      </BlockUI>

    </PageSection>
  );
};


export default OrderDashboard;


type DeliveryDetailFiltersProps = {
  filters:Record<string,any>;
  defaultTimezone?:string;
  onChange:any;
  onRefresh:any;
  //reset:any;
  isLoading: boolean;
}

const DeliveryDetailFilters = ({
                                 filters,
                                 defaultTimezone="",
                                 onChange,
                                 onRefresh,
                                 isLoading,
                                 /* reset*/
                               }: DeliveryDetailFiltersProps) => {
  const [pendingFilters, setPendingFilters] = useState(filters)
  const [isPendingApply, setIsPendingApply] = useState(false)


  const handleApply = () => {
    setIsPendingApply(false);
    onChange(pendingFilters)
  }

/*  const handleIntervalChange = (e:ChangeEvent<HTMLInputElement>) => {

    const newFilters = { ...pendingFilters, agg_interval:e.target.value}

    setIsPendingApply(true);
    setPendingFilters(newFilters)
  };*/

  const handleDateRangeChange = (start:Date, end:Date, isQuickPick=false) => {
    const newFilters = {...pendingFilters, start, end}
    if (isQuickPick) {
      onChange(newFilters)
      setIsPendingApply(false);
    } else {
      setIsPendingApply(true);
      setPendingFilters(newFilters)
    }
  };

  const reset = () => {
    setIsPendingApply(false);
    onChange(null);
  };

  return (
    <div className="d-flex justify-content-end flex-grow-1 align-items-center">


      <div style={{minWidth:'500px'}}>
        <DateRangeSelect
          start={filters.start}
          end={filters.end}
          defaultTimezone={defaultTimezone}
          options={DefaultDateRangeSelectOptions}
          className="flex-fill me-2"
          onChangeRange={handleDateRangeChange}
          disabled={isLoading}
          /*onChangeTimezone={handleTimezoneChange}*/
          /* autoRefresh={autoRefresh}*/
          /*onChangeAutoRefresh={onChangeAutoRefresh}*/
        />
      </div>

      <div className="d-flex flex-wrap flex-shrink-0">

        <ApplyOrRefreshButton
          isLoading={isLoading}
          pendingApply={isPendingApply}
          onApply={handleApply}
          onRefresh={onRefresh}
        />

        <Button color="link" className="px-1 py-0" title="Clear filters" onClick={reset} disabled={isLoading}>
          <span className="fa-layers fa-fw">
            <FaIcon icon="filter" size="lg" />
            <FaIcon icon="circle" inverse size="sm" transform="right-7 up-2" />
            <FaIcon icon="ban" size="sm" color="red" transform="right-7 up-2" />
          </span>
        </Button>

      </div>
    </div>
  )
}
