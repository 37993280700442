import React, {useMemo} from "react";
import { ShapeConnectorProps } from "./SvgConnector";

interface ArcConnectorProps extends ShapeConnectorProps {}

const DefaultArcRadius = 12;
//const ArcRadius = 12;
const MinArcRadius = 4;

/**
 * Arc shape svg connector
 * Connector combines horizontal and vertical lines with rounded corners
 *
 * @param startPoint
 * @param endPoint
 * @param width
 * @param height
 * @param stroke
 * @param strokeWidth
 * @param startArrow
 * @param endArrow
 * @param arrowSize
 * @param zIndex
 */
export default function ArcConnector(props: ArcConnectorProps) {
  const {
    direction,
    stroke="orange",
    strokeWidth,
    startArrow,
    endArrow,
    startPoint,
    endPoint,
    arrowSize,
    width,
    height,
    ...rest
  } = props;

  // startPoint and endPoint coords are where the lines join relative to parent origin.
  // This SVG (in which we draw the connector) will be a box positioned in between these two points

  // We are drawing left to right, assume we start drawing the line at 0,0.
  // calculate relative endpoint.
  const lineStart = {
    x:0,
    y:props.endPoint.y > startPoint.y ? 0 : startPoint.y - endPoint.y
  };
  const lineEnd = {
    x: Math.abs(props.endPoint.x - props.startPoint.x),
    y: endPoint.y < startPoint.y ? 0 : endPoint.y - startPoint.y
  }
  // Top of svg connector is Y of highest element connection point
  // so one corner of the svg "box" is at the start point, and the kitty corner is at the
  // end point.
  const svgTop = startPoint.y > endPoint.y ? endPoint.y : startPoint.y;
  const curveDown = endPoint.y > startPoint.y;

  //Max horizontal and vertical distance
  const maxX = Math.abs(endPoint.x - startPoint.x);
  const maxY = Math.abs(endPoint.y - startPoint.y);
  //console.log('ArcConnector.tsx:', props.startPoint, props.endPoint,distanceX);

  const ArcRadius =  useMemo(()=>{
    if (maxY < (DefaultArcRadius * 2) ){
      //shrink arc radius to fit
      return Math.max(Math.floor(maxY/2), MinArcRadius);

    }

    return DefaultArcRadius;
  }, [])

  const vLineHeight = maxY - (ArcRadius * 2);
  const hLineWidth = (maxX - (ArcRadius * 2)) / 2;

  // Vertical line connected to arcs will be in the middle
  const midX = hLineWidth + ArcRadius;

  //overflow:visible to account for width of stroke going above or below start/end Y
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position:"absolute",
        overflow:"visible",
        top:`${svgTop}px`,
        left:`${startPoint.x}px`,
        width:`${width}px`,
        height:`${height}px`,
        zIndex: props.zIndex,
        // borderColor:"pink",
        // borderWidth: "1px",
        // borderStyle: "solid",
      }}
    >
      {/*<circle cx={startPoint.x} cy={startPoint.y} r="2" fill="red" />
      <circle cx={endPoint.x} cy={endPoint.y} r="2" fill="red" />*/}

      {/*

      { maxY < (ArcRadius * 2) ? (  //original condition for straight line join
       <path
          className="connector"
          d={`M0 0 L${maxX} 0`}
          stroke={props.stroke || "orange"}
          strokeWidth={props.strokeWidth || 3}
          fill="transparent"
        />

        bezier (single bend) d={`M0 0 q${maxX/2} ${maxY/2} ${maxX} ${maxY}`}

       */}



      { lineStart.y === lineEnd.y ? (
        <path
          className="connector"
          d={`M${lineStart.x} ${lineStart.y} L${lineEnd.x} ${lineEnd.y}`}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill="transparent"
        />
      ) : (
        <>
          {curveDown ? (
            <>
              {/* From left (top) to right (bottom) */}
              <path
                className="connector h-line ltr-down"
                d={`M0 0 L${hLineWidth} 0`}
                stroke={props.stroke || "orange"}
                strokeWidth={props.strokeWidth || 3}
                fill="transparent"
              />
              <ArcPath
                startX={hLineWidth}
                startY={0}
                endX={midX}
                endY={ArcRadius}
                radius={ArcRadius}
                isTop={true}
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
              />

                <path
                  className="connector vertical-line"
                  d={`M${midX} ${ArcRadius} L${midX} ${maxY - ArcRadius}`}
                  stroke={props.stroke || "orange"}
                  strokeWidth={props.strokeWidth || 3}
                  fill="transparent"
                />
                <ArcPath
                  startX={midX}
                  startY={maxY - ArcRadius}
                  endX={midX + ArcRadius}
                  endY={maxY}
                  radius={ArcRadius}
                  isTop={false}
                  stroke={props.stroke}
                  strokeWidth={props.strokeWidth}
                />
                <path
                  className="connector h-line"
                  d={`M${midX + ArcRadius} ${maxY} L${maxX} ${maxY}`}
                  stroke={props.stroke || "orange"}
                  strokeWidth={props.strokeWidth || 3}
                  fill="transparent"
                />


            </>
          ) : (
            <>
              {/* From left (bottom)  to right (top) */}
              <path
                className="connector h-line ltr-up"
                d={`M0 ${maxY} L${hLineWidth} ${maxY}`}
                stroke={props.stroke || "orange"}
                strokeWidth={props.strokeWidth || 3}
                fill="transparent"
              />
              <ArcPath
                startX={hLineWidth}
                startY={maxY}
                endX={midX}
                endY={maxY - ArcRadius}
                radius={ArcRadius}
                isTop={false}
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
              />

              <path
                className="connector vertical-line ltr-up"
                d={`M${midX} ${maxY - ArcRadius} L${midX} ${ArcRadius}`}
                stroke={props.stroke || "orange"}
                strokeWidth={props.strokeWidth || 3}
                fill="transparent"
              />
              <ArcPath
                startX={midX}
                startY={ArcRadius}
                endX={midX + ArcRadius}
                endY={0}
                radius={ArcRadius}
                isTop={true}
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
              />
              <path
                className="connector h-line ltr-up"
                d={`M${midX + ArcRadius} ${0} L${maxX} ${0}`}
                stroke={props.stroke || "orange"}
                strokeWidth={props.strokeWidth || 3}
                fill="transparent"
              />


            </>
          )}
        </>

      )}

    </svg>
  );
}

const ArcPath = ({startX, startY, endX, endY, radius, isTop, stroke, strokeWidth}: any) => {

  return (
    <path
      className={"connector start-up-hook"}
      d={`
        M${startX} ${startY}
        A${radius} ${radius} 0 0 ${isTop ? 1 : 0}
        ${endX} ${endY}
        `}
      stroke={stroke || "orange"}
      strokeWidth={strokeWidth || 3}
      fill="transparent"
    />
  )
}
