import React, { useContext, useState } from 'react';
import {
  Button, Nav, TabContent, TabPane, ModalFooter, ModalBody,
} from 'reactstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useFormState from '../../../Hooks/useFormState';
import {notify, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import { FormTabFlat } from '../../../Components/Form/FormCommon';
import LoadingBar from '../../../Layout/LoadingBar';
import Details from './Details';
import { AppContext } from '../../../Providers/AppProvider';

/*
 * Component for editing or creating a vendor
 */
const Vendor = ({ vendor, closeModal, openModal, confirmation, names }) => {
  const isNew = vendor.id === 0;
  const app = useContext(AppContext);
  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .lowercase()
      .required('Please enter a vendor name')
      .notOneOf(names.map(v => v.toLowerCase()), "Name already exists"),
    //token: Yup.string().trim().transform(value => (!value ? null : value)).nullable().min(36, "Invalid format"),
  });

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      ...vendor,
    },
    ValidationSchema,
    null,
    null,
   { isNew }, // validationContext allows conditional validation in case we need it later
  );

  const [activeTab, setActiveTab] = useState('details');

  const handleSelectTab = (activeTab) => setActiveTab(activeTab);
  const newVendor = {};

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating vendor...' : 'Saving vendor...');
    let result;
    const data = { ...formValues };

    data.name = data.name.trim();
    if (isNew) {
      data.create_auth_token = true;
      result = await app.api.endpoints.vendors.create(data);
      if (result) {
        confirmation=true;
        newVendor.id = result.id;
        newVendor.name = result.name;
        newVendor.crypto_key = result.crypto_key;
        if (result.auth_tokens !== undefined) {
          newVendor.token = result.auth_tokens[0].token;
        }
      }
    } else {
      result = await app.api.endpoints.vendors.update(data);
    }

    app.showLoader(false);

    if (result) {
      closeModal(true);
      if (confirmation) openModal(newVendor);
    } else {
      // Could get 'name already exists' error
      if (app.api.error.form && app.api.error.form['token.token']) {
        // TODO: error handling for tabs
        notify(`Unable to save vendor: ${app.api.error.form['token.token']}`, 'error');
      } else {
        notify(`Unable to save vendor: ${app.api.error.name}`, 'error');
      }
    }
  };

  return (
    <>
      <ModalBody>
        <LoadingBar name="modal" />

        {!formIsValid
        && (
          <StandardAlert color="warning">
            Please correct the errors below before continuing
          </StandardAlert>
        )}

        <Nav className="mastodon-tabs">
          <FormTabFlat
            id="details"
            label="Details"
            activeTab={activeTab}
            setActive={handleSelectTab}
          />

        </Nav>

        <div className="vendor-form">

          <TabContent
            activeTab={activeTab}
            className="rules-transition"
          >
            <TabPane tabId="details">
              <Details
                vendor={formValues}
                errors={formErrors}
                onChange={formApi.handleOnChange}
                isNew={isNew}
              />
            </TabPane>

          </TabContent>

        </div>
      </ModalBody>

      { confirmation === false &&
      (<ModalFooter>
        <Button
          onClick={closeModal}
          color="link"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          size="sm"
        >
          Save
        </Button>

      </ModalFooter>
      )}

    </>
  );
};

Vendor.propTypes = {
  vendor: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  confirmation: PropTypes.bool,
  names: PropTypes.array,
};

export default Vendor;
