import React, { useState, useEffect } from 'react';
import {
  Label, Input, Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import PropTypes from 'prop-types';

/*
 * PaginationWidget component
 * //Todo: pageCount instead of limit
 * Usage
 */

const PaginationWidget = (props) => {
  const [visiblePages, setVisiblePages] = useState(getVisiblePages(null, props.pages));

  useEffect(() => {
    setVisiblePages(getVisiblePages(props.page, props.pages));
  }, [props.pages, props.page]);

  const handleKeyDownJump = (e) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      let jump = parseInt(e.target.value);
      if (!jump) {
        jump = 1;
      } else if (jump > props.pages) {
        jump = props.pages;
      }
      props.onPageChange(jump);
    }
  };

  const changePage = (page) => {
    if (page === props.page) {
      return;
    }

    props.onPageChange(page);
  };

  const handleClickPrev = (e) => {
    if (activePage === 1) return;
    changePage(activePage - 1);
  };

  const handleClickNext = (e) => {
    if (activePage + 1 === props.pages) return;
    changePage(activePage + 1);
  };

  let activePage = props.page;

  // if(props.data.length === 0 ) return (null);
  if (!props.pages) return (null);

  return (
    <div className="pagination-container">
      {/* <div className="page-summary"> Props.page:{props.page}, props.pages:{props.pages},  activePage:{activePage}, totalRows{props.totalRows}</div> */}

      <div className="d-flex align-items-center">

        {props.totalRows > 20 && props.showPageSize === true && (
          <div className="page-show me-3">
            <Label>Display: </Label>
            <Input
              type="select"
              bsSize="sm"
              value={props.pageSize || props.defaultPageSize}
              onChange={(e) => props.onPageSizeChange(e.target.value)}
            >
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </div>
        )}

        <div className="page-summary me-3">
          <PageSummary page={props.page} totalRows={props.totalRows} pageSize={props.pageSize || props.defaultPageSize} />
        </div>

        {props.pages > 1 && (
          <div className="page-jump">
            <Label>Jump: </Label>
            <Input bsSize="sm" defaultValue="" onKeyDown={handleKeyDownJump} />
          </div>
        )}

      </div>


      <Pagination className="pagination-default">

        {/* <PaginationItem disabled={activePage === 1}>
              <PaginationLink first title="First Page" onClick={(e)=>changePage(1)}/>
            </PaginationItem> */}

        <PaginationItem disabled={activePage === 1}>
          <PaginationLink
            title="Previous Page"
            previous
            className="Table__pageButton"
            onClick={handleClickPrev}
          />
        </PaginationItem>

        {visiblePages.map((page, index, array) => (
          <React.Fragment key={index}>
            {/*  <div>page:{page},index:{index}, arry:{array.join(',')}</div> */}
            {array[index - 1] + 2 < page && <div className="ellipsis">...</div>}
            <PaginationItem active={activePage === page}>
              <PaginationLink
                tag="div"
                onClick={() => changePage(page)}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          </React.Fragment>
        ))}

        <PaginationItem disabled={activePage === props.pages}>
          <PaginationLink
            next
            title="Next Page"
            onClick={handleClickNext}

          />
        </PaginationItem>

        {/* <PaginationItem  disabled={activePage === props.pages}>
              <PaginationLink last title="Last Page"  onClick={(e)=>changePage(props.pages-1)}/>
            </PaginationItem> */}

      </Pagination>

    </div>

  );
};

const PageSummary = React.memo(({ page, pageSize, totalRows }) => {
  const rowStart = ((page - 1) * pageSize) + 1;
  const rowEnd = Math.min((rowStart + pageSize) - 1, totalRows);
  // console.log(`ReactTablePagination.js:PageSummary page: ${page}, pageSize:${pageSize}, totalRows:${totalRows}`);
  return (
    <span>
      {rowStart}
      {' '}
      to
      {' '}
      {rowEnd}
      {' '}
      of
      {' '}
      {totalRows}
      {' '}
      results
    </span>
  );
});

const filterPages = (visiblePages, totalPages) => visiblePages.filter((page) => page <= totalPages);

//  1 ... 4 5 6 ... 99
// 1 2 3 4 5 ... 99
// 1 ... 96 97 98 99
const getVisiblePages = (page, total) => {
  // console.log('ReactTablePagination.js:getVisiblePages, page', page);
  if (total < 7) {
    return filterPages([1, 2, 3, 4, 5, 6], total);
  }
  if (page % 5 >= 0 && page > 4 && page + 2 < total) {
    return [1, page - 1, page, page + 1, total];
  } if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
    return [1, total - 3, total - 2, total - 1, total];
  }
  return [1, 2, 3, 4, 5, total];
};

PaginationWidget.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  defaultPageSize: PropTypes.number,
  totalRows: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  showPageSize: PropTypes.bool,
};

PaginationWidget.defaultProps = {
  page: 1,
  showPageSize: true,
};
export default PaginationWidget;
