import React, { useRef } from 'react';
import {
  Button, FormGroup, Input, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';

/*
 * Warning Prompt for long query times in Aggregate Reports
 */
const LoadTimeWarning = ({ onDismiss }) => {
  const chkRef = useRef();

  const handleCancel = () => {
    onDismiss(false, chkRef.current.checked);
  };

  const handleLoad = () => {
    onDismiss(true, chkRef.current.checked);
  };

  return (
    <div id="load-warning-container">

      <div className="load-warning text-center">
        <div>Longer date ranges will take a while to load. Continue?</div>
        <div className="mt-2 mb-1">
          <Button color="primary" onClick={handleLoad} className="me-1">Yes, load it</Button>
          <Button onClick={handleCancel} outline>Cancel</Button>
        </div>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              defaultValue={false}
              innerRef={chkRef}
            />
            Do not warn me again
          </Label>
        </FormGroup>
      </div>

    </div>
  );
};

/*
Existing loading element
<div class="-loading active -active"> //absolute overlay
  <div>
    <div role="status" class="spinner-border text-secondary">
      <span class="sr-only">Loading...</span>
    </div>
   </div>

 </div>
 */

LoadTimeWarning.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};
export default LoadTimeWarning;
