import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormText, Spinner } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedCallback } from 'use-debounce';
import JSONPretty from 'react-json-prettify';
import { idea } from 'react-json-prettify/dist/themes';
import KeyValueRow from './KeyValueRow';
import TargetSelect from '../TargetingManager/TargetSelect';
import TargetValueForm from '../TargetingManager/TargetValueForm';
import useApi from '../../Hooks/useApi';
import '../TargetingManager/styles.scss';
// import {isObject} from "../../utils/types";
import './SeedData.scss';

/* Row with target attribute picker and form */
const SeedDataTargetRow = ({
  alias, value, attributes, attributeGroups, onChangeAttribute, onChangeValue,
}) => {
  const attr = attributes ? attributes[alias] : null;

  return (
    <div className="target-form seed-data-form flex-fill d-flex justify-content-between">
      <div className="seed-data-key flex-grow-1">
        <TargetSelect
          value={alias}
          onChange={ onChangeAttribute}
          attributeGroups={attributeGroups}
        />

      </div>
      <div className="seed-data-equals ms-2 me-2">{attr ? <b>=</b> : <span>&nbsp;</span>}</div>

      <div className="seed-data-value d-flex flex-column ">
        {/* Show form if attribute object (an attribute was selected) */}
        {attr && (
          <>
            <TargetValueForm
              value={value}
              onChangeValue={(e) => onChangeValue((e.target || e), attr.input_type)}
              attribute={attr}
            />

            {/* Label on the target is a note about the input: "Unit:month", "within 3 years", etc */}
            {attr.options.label && (
              <div><FormText>{attr.options.label}</FormText></div>
            )}
          </>
        )}

      </div>

    </div>
  );
};

/*
 * Widget for building a seed data object, consisting of key/value pairs.
 * A pair may be a custom key and value (free text input),
 * or a key/value derived from targeting attributes (uses form widgets from
 * the TargetingManager component).
 *
 * Custom key/values pairs are grouped under the 'custom' property
 * {
 *    preexisting_condition:false,
 *    custom: {
 *        campaign_id:33  //can also be an object
 *    }
 * }
 *
 * We handle these as separate state items
 */
const SeedDataBuilder = ({
  seedData, verticalId, product, onUpdate,view='form'
}) => {
  const { api } = useApi();
  const [jsonViewMode, setJsonViewMode] = useState(view === 'json');
  const [systemSeedData, setSystemSeedData] = useState(() => {
    let arr = [];
    // transform system seedData from object to array of objects with key/value props
    if (seedData && !Array.isArray(seedData)) {
      arr = Object.entries(seedData).reduce((acc, [key, value]) => {
        if (key !== 'custom') {
          acc.push({ key, value });
        }
        return acc;
      }, []);
    }

    return arr;
  });

  const [customSeedData, setCustomSeedData] = useState(() => {
    let arr = [];
    // transform  custom seedData from object to array of objects with key/value props
    if (seedData && !Array.isArray(seedData) && seedData.custom) {
      arr = Object.entries(seedData.custom).reduce((acc, [key, value]) => {
        if (key !== 'custom') {
          acc.push({ key, value });
        }
        return acc;
      }, []);
    }

    return arr;
  });

  const [attributes, setAttributes] = useState(null);
  const [attributeGroups, setAttributeGroups] = useState(null);
  // const hasSeedData = Array.isArray(seedData) && seedData.length;

  // Load attributes on mount
  useEffect(() => {
    if (!attributes) {
      // loadScripts(sourceVerticalId);
      load();
    }
  }, []);

  // Load attributes on change of vertical
  useEffect(() => {
    if (attributes) {
      load();
    }
  }, [verticalId, product]);

  const load = async () => {
    const result = await api.endpoints.attributes.list({
      options: true,
      verticalId,
      product,
    });
    if (result) {
      setAttributeGroups(result);

      // Ungroup the attributeGroup data and store in attributes
      const attrList = Object.entries(result).reduce((acc, [groupName, group]) => {
        group.map((attr) => {
          acc[attr.alias] = { ...attr, groupName };
        });
        return acc;
      }, {});

      setAttributes(attrList);
    }
  };

  // call debounced function to update parent on change of state
  useEffect(() => {
    handleUpdate();
  }, [systemSeedData, customSeedData]);

  // const isEmptyValue = (val) => {
  //   if(Array.isArray(val)) {
  //     //empty array
  //     return val.length === 0;
  //   } else if(typeof val === 'boolean'){
  //     //never empty
  //     return false;
  //   //} else if (typeof val === 'object' && (!val.from && !val.to)){      //empty integer range
  //   } else if (isObject(val) && Object.keys.length > 0){
  //     return false;
  //   }
  //
  //   return !val;
  // }

  // Clean up before passing to parent
  const handleUpdate = useDebouncedCallback(() => {
    const obj = {};
    systemSeedData.map((data) => {
      if (data.key) { // && !isEmptyValue(data.value)) {
        obj[data.key] = data.value;
      }
    });
    if (customSeedData.length) {
      obj.custom = {};
      customSeedData.map((data) => {
        if (data.key) { // } && !isEmptyValue(data.value)) {
          obj.custom[data.key] = data.value;
        }
      });
      if (!Object.keys(obj.custom).length) {
        delete obj.custom;
      }
    }
    onUpdate(obj);
  }, 700);

  const onChangeSystemKey = (key, i) => {
    setSystemSeedData((prev) => {
      prev[i].key = key;
      // set with proper "empty" value based on type
      prev[i].value = getTargetValueFromEl(null, attributes[key].input_type, null);

      return [...prev];
    });

  };
  const onChangeCustomKey = (key, i) => {
    setCustomSeedData((state) => {
      state[i].key = key;
      return [...state];
    });
  };

  const getTargetValueFromEl = (el, inputType, currentValue) => {
    let value = currentValue;
    const newValue = el && !Array.isArray(el) ? el.value : null;
    const fieldName = el && !Array.isArray(el) ? el.name : null;

    switch (inputType) {
      case 'integer':
      case 'zipcode_list':
      case 'county_list':
      case 'expression':
      case 'text':
        // TBD: for zipcode, how to format/normalize?
        value = newValue;
        break;
      case 'integer_range':
        // Handle cases where value comes from API as an array, which in js can behave like an object
        // ( value:{} in the stored json will be sent from PHP as value:[] )
        if (!value) {
          value = { from: null, to: null };
        } else if (Array.isArray(value)) {
          value = { from: value.from || null, to: value.to || null };
        }
        value[fieldName] = newValue; // from or to
        break;
      case 'boolean':
        value = typeof newValue === 'string' ? (newValue === 'true') : !!newValue;
        break;
      case 'choice':
        if (el?.checked) {
          value = newValue;
        }
        break;
      case 'multiple_choice':
      case 'dynamic_multiple_choice':
      case 'metro_choice':
      case 'state_choice':
        if (Array.isArray(el)) {
          // ReactSelect, we get an array of values rather than an event
          value = el;
        } else {
          // checkbox was checked/unchecked
          if (!Array.isArray(value)) value = [];
          // if false remove, if true add
          if (el?.checked) {
            value.push(el?.value);
          } else value = value.filter((val) => val != el?.value);
        }

        break;
    }

    return value;
  };

  const onChangeSystemValue = (el, inputType, i) => {
    setSystemSeedData((prev) => {
      prev[i].value = getTargetValueFromEl(el, inputType, prev[i].value);
      return [...prev];
    });
  };

  const onChangeCustomValue = (val, i) => {
    setCustomSeedData((prev) => {
      prev[i].value = val;
      return [...prev];
    });
  };

  const addSystemAttribute = () => setSystemSeedData([...systemSeedData, { key: '', value: '' }]);
  const addCustomAttribute = () => setCustomSeedData([...customSeedData, { key: '', value: '' }]);

  const removeSystemAttribute = (index) => {
    setSystemSeedData((state) => {
      if (typeof state[index] !== 'undefined') {
        return state.filter((pair, i) => i !== index);
      }
      return [...state];
    });
  };

  const removeCustomAttribute = (index) => {
    setCustomSeedData((state) => {
      if (typeof state[index] !== 'undefined') {
        return state.filter((pair, i) => i !== index);
      }
      return [...state];
    });
  };

  if (!attributes) return (<div><Spinner size="sm" color="secondary" /> <i>Loading attributes</i></div>);

  return (
    <div className="seed-data-builder">
      <div >
        <Button size="sm" color="link" onClick={() => setJsonViewMode(!jsonViewMode)}>
          {jsonViewMode ? 'Edit' : 'JSON'}
        </Button>
      </div>

      {jsonViewMode ? (
        <div className="form-section pretty-seed"><JSONPretty json={seedData} theme={idea} /></div>
      ) : (
        <>
          <div className="form-section">
            <div className="form-section-header">System Attributes</div>
            <p>Use system-defined targeting attributes</p>
            {systemSeedData.length > 0 ? (
              <div>
                {systemSeedData.map((item, i) => (
                  <div className="seed-data-item d-flex mb-2" key={i}>
                    <SeedDataTargetRow
                      alias={item.key}
                      value={item.value}
                      attributes={attributes}
                      attributeGroups={attributeGroups}
                      onChangeAttribute={(key) => onChangeSystemKey(key, i)}
                      onChangeValue={(el, inputType) => onChangeSystemValue(el, inputType, i)}
                    />

                    <div>
                      <Button color="link" className="inline ms-2 mt-1" onClick={addSystemAttribute} size="sm">
                        <FaIcon icon="plus" size="sm" />
                      </Button>
                      <Button color="link" className="inline ms-2 mt-1" onClick={() => removeSystemAttribute(i)}>
                        <FaIcon icon="times" color="darkred" size="sm" />
                      </Button>

                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Button color="link" className="me-2" onClick={addSystemAttribute} size="sm">
                <FaIcon icon="plus" className="me-1" size="sm" />
                Add System Attribute
              </Button>
            )}
          </div>

          <div className="form-section mt-3">
            <div className="form-section-header">Custom attributes</div>
            <p>Add custom key/value pairs</p>
            {customSeedData.length > 0 ? (
              <>
                {customSeedData.map((item, i) => (
                  <div className="seed-data-item d-flex align-items-center mb-2" key={i}>
                    <KeyValueRow
                      attribute={item.key}
                      value={item.value}
                      onChangeAttribute={(key) => { onChangeCustomKey(key, i); }}
                      onChangeValue={(val) => onChangeCustomValue(val, i)}
                      allowObjects
                    />

                    <div>
                      <Button color="link" className="inline ms-2" onClick={addCustomAttribute} size="sm">
                        <FaIcon icon="plus" size="sm" />
                      </Button>
                      <Button color="link" className="inline ms-2" onClick={() => removeCustomAttribute(i)}>
                        <FaIcon icon="times" color="darkred" size="sm" />
                      </Button>

                    </div>
                  </div>
                ))}
              </>
            ) : (
              <Button color="link" className="" onClick={addCustomAttribute} size="sm">
                <FaIcon icon="plus" className="me-1" size="sm" />
                Add Custom Attribute
              </Button>
            )}
          </div>
        </>
      )}

    </div>
  );
};

SeedDataBuilder.propTypes = {
  seedData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  verticalId: PropTypes.number,
  product: PropTypes.string,
  onUpdate: PropTypes.func,
};

SeedDataBuilder.defaultProps = {
  seedData: [],
  verticalId: '',
  product: '',
};

export default SeedDataBuilder;
