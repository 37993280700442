import React, { useState, useEffect, useContext } from 'react';
import { InputGroup, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import useApi from '../../Hooks/useApi';
import { AppContext } from '../../Providers/AppProvider';

/*
 * Select element for that fetches choices from the API.
 * Verticals are cached in AppProvider.
 * Shows spinner while loading.
 * Have a nice day.
 */
const DynamicSingleSelect = ({
  name,
  size = '',
  endpoint,
  action = 'list',
  params={},
  defaultValue = '',
  noSelectionLabel = 'Select...',
  onChange,
  labelProperty,
  invalid,
}) => {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState(app.getCache(endpoint));
  // const[items, setItems] = useGlobal(endpoint);
  const api = useApi(endpoint, action);

  // fetch on load
  useEffect(() => {
    if (!items || !items.length) {
      api.fetch(params);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (api.result) {
      setItems(api.result);
      app.setCache(endpoint, api.result);
      setIsLoading(false);
    } else if (api.error) {
      console.log('Unable to load items for select ', name, api.error);
    }
  }, [api.result, api.error]);

  return (
    <>
      { isLoading ? (
        <div className="inline-loading">
          <Spinner size="sm" />
          {' '}
          Loading...
        </div>
      ) : (
        <InputGroup>
          <Input
            type="select"
            name={name}
            defaultValue={defaultValue}
            invalid={invalid}
            onChange={onChange}
            bsSize={size}
          >
            <option value="">{noSelectionLabel}</option>
            {items.map((item, i) => (<option value={item.id} key={i}>{item[labelProperty]}</option>))}
          </Input>
        </InputGroup>
      )}
    </>

  );
};

DynamicSingleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noSelectionLabel: PropTypes.string,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  size: PropTypes.string, // bootstrap size: sm, lg, or ""
  endpoint: PropTypes.string.isRequired,
  action: PropTypes.string,
  labelProperty: PropTypes.string,
  params: PropTypes.object,
};
DynamicSingleSelect.defaultProps = {
  onChange: () => {},
  invalid: false,
  labelProperty: 'name',
};

export default DynamicSingleSelect;
