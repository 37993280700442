import React, {useContext, useEffect, useRef, useState, useCallback} from "react";
import {
  Modal, ModalBody, ModalHeader, Button
} from 'reactstrap';
import {AppContext} from "../Providers/AppProvider";
import useLocalStorage from "../Hooks/useLocalStorage";
import Mastodon from '../Components/Icons/DMS/Mastodon';

const CHECK_INTERVAL_SEC = 30;
const DELAY_SEC = 60*30; //wait this long when user chooses to delay update

/*
 * VersionChecker component
 *
 * Polls the server for a version file at regular intervals and presents a dialog
 * when the current site version is outdated. The user may choose to refresh or keep working.
 *
 * This component should be placed in a high level layout component.
 *
 * Current version data is inserted into the src/index.js file during the CI build, and becomes
 * a property of the app context during runtime. This component compares that value
 * to a version file in the dist directory, to see if the app is outdated.
 *
 * To make this work in Dev, we need to reconfigure the dev server
 * 1) in config/webpack.dev.js, in the dev server config, set hot:false, and
 *    devMiddleware to 'writeToDisk:true'. This will serve files from disk instead of memory,
 *    and disable hot reloading so the webpack server is not making the site refresh itself
 * 2) In src/index.js, set AppHash to any string value. Comment out the insertion of the environmental variable.
 * 3) In this component, set the interval and delay constants to something fairly quick, say 30 sec with 90 sec delay
 * 4) Restart the container, let the build complete
 * 5) In the ./dist directory, create version.json with contents like: {"version": 1, "hash": "foo123"},
 *    setting the hash value the same as in step 2
 * 6) Refresh the browser, and you will see the site regularly checking version.json (the hashes will be current
 *    so it will not ask to update)
 * 7) Change the hash in version.json, and set it the same in index.js. The site will recompile, and the version
 *    checker will see the new version.json file, causing the update dialog to appear.
 */
const VersionChecker = ({}) => {
  const app = useContext(AppContext);

  const[isOpen, setIsOpen] = useState(false);
  const[versionDelayStart, setVersionDelayStart] = useLocalStorage('version_delay_start')

  const toggleModal = () => setIsOpen(!isOpen);

  const versionCheckInterval = useRef<NodeJS.Timeout|null>();
  const delayUpdateTimer = useRef<NodeJS.Timeout|null>();

  useEffect(() => {

    if (app.buildHash) {
      console.log('Version Check: local:', `version:${app.version}, hash:${app.buildHash}`);
      if (!versionCheckInterval.current && !delayUpdateTimer.current) {
        versionCheckInterval.current = setInterval(() => checkVersion(), CHECK_INTERVAL_SEC * 1000)
      }
    } else {
      console.log('Version Check: App has no build hash, version check disabled');
    }

    // Unmount: clean up timers
    return () => {
      if (versionCheckInterval.current) {
        clearInterval(versionCheckInterval.current)
      }
      if (delayUpdateTimer.current) {
        clearTimeout(delayUpdateTimer.current)
      }
      versionCheckInterval.current = null;
      delayUpdateTimer.current = null;
    }

  }, [])

  // useCallback so we can keep state in scope when called back from timer function
  const isDelayed = useCallback(() => {
    if (versionDelayStart ) {
      console.log('Version update delayed', (Date.now() - versionDelayStart) * 1000);
    }

    return delayUpdateTimer.current || versionDelayStart && ((Date.now() - versionDelayStart) * 1000) < DELAY_SEC
  }, [versionDelayStart])

  const checkVersion = async () => {
    if (isDelayed()) {
        return;
    }

    const remote = await fetch('/version.json?time=' + Date.now(), {
      cache: "no-store",
      headers: {Pragma: 'no-cache', Expires:'-1'}
    }).then((response)=>{

      if (response.ok) {
        return response.json();
      }

      // Otherwise, throw an error
      throw response.status;
    }).then((data) => {
      return data;
    }).catch((err)=>{
      console.log('Version Check: error getting version:', err);
    })

    if (remote && remote.hash !== app.buildHash) {
      console.log('Version Check:outdated build:', `current:${app.buildHash}, new:${remote.hash}`);
      setIsOpen(true);
    }

    if (delayUpdateTimer.current) {
      clearTimeout(delayUpdateTimer.current);
      delayUpdateTimer.current = null;
    }

  }

  const doUpdate = () => {
   // setLastCheck('');
    setVersionDelayStart('')
    hardReload();
  }

  const delayUpdate = () => {
    setIsOpen(false);
    setVersionDelayStart(Date.now());

    delayUpdateTimer.current = setTimeout(() => {
      delayUpdateTimer.current = null;
      setVersionDelayStart('');
      checkVersion();
    }, DELAY_SEC * 1000);
  }

  const hardReload = async() => {
    await fetch(window.location.href, {
      headers: {
        Pragma: 'no-cache',
        Expires: '-1',
        'Cache-Control': 'no-cache',
      },
    });
    window.location.reload();
  }


  return (
    <Modal  isOpen={isOpen} toggle={toggleModal} size="sm" id="version-update-modal" unmountOnClose={false}>
      <ModalHeader toggle={toggleModal}>
        Update Available
      </ModalHeader>
      <ModalBody>
        <div className="text-center mb-2" >
          <Mastodon width="80" height="80" className="" />
        </div>
        <p>A newer version of Mastodon is available. Be sure that any changes have been saved.</p>

        <div className="mt-3 d-flex flex-column">
          <Button
            size="sm"
            onClick={doUpdate}
            className="mb-1"
            block
          >
            Update Now
          </Button>

          <Button
            color="link"
            size="sm"
            onClick={delayUpdate}
            block
          >
            Not now, I'm working
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default VersionChecker;
