import React, {
  useEffect, useState, useMemo, useContext,
} from 'react';
import {Link} from 'react-router-dom';
import { Button, Spinner,} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { endOfDay, startOfMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import PagedTable from '../../../../Components/Table/PagedTable';
import {
  DateFormatter,
  MoneyFormatter,
  NoDataComponent,
} from '../../../../Components/Table/Table';
import { LiveFilteringProvider } from '../../../../Providers/LiveFilteringProvider';
import { titleCase } from '../../../../utils/string';
import CardIcon from '../../../../Components/Icons/Card/CardIcon';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import RightDateAndControls from '../../../../Components/Table/RightDateAndControls';
import usePagedTableState from '../../../../Hooks/usePagedTableState';
import { AppContext } from '../../../../Providers/AppProvider';
import confirm from "reactstrap-confirm";
import usePermission from "../../../../Hooks/usePermission";
import {Filter} from "../../../../Components/Table/Filter";
import classnames from 'classnames';
import DownloadFileLink from "../../../../Components/DownloadFileLink";

const DateCell = React.memo(({ value, timezone }) => (
  <div className="occurred-at">
    <div><DateFormatter value={value} format="E yyyy/MM/dd" timezone={timezone} /></div>
    <div><small><DateFormatter value={value} format="h:mm:ss aaa zzz" timezone={timezone} /></small></div>
  </div>
));

const CreditCardCell = ({ cards, cardId }) => {
  const card = cardId ? cards.find((c) => c.id.toString() === cardId.toString()): undefined;
  return (
    <div>
      {card
        ? (
          <span>
            <CardIcon type={card.type} width={42} />
            <span className="ms-2">
              <small>
                {card.last_four}
              </small>
            </span>
          </span>
        )

        : <div className="text-center">&mdash;</div>}
    </div>
  );
};

const AmountCell = React.memo(({ amount, type }) => (
  <div className={classnames(type, {negative:amount < 0})}>
    {
          type === 'refund' ? (
            <span>
              -<MoneyFormatter value={amount} />
            </span>
          ) : (
            <MoneyFormatter value={amount} />
          )
        }
  </div>
));

const rxGuid = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;

const MessageCell = React.memo(({message="", allowAuction}) => {
  // Create a link where the message is "Auction <auction guid>"
  const match = allowAuction && message && message.match(rxGuid);

  if (allowAuction && match && message.toLowerCase().indexOf('auction') >= 0) {
    return  <Link target="_blank" to={`/reports/auction/${match[0]}`} className="link auction-detail-link p-0">{message}</Link>
  }
  return <span>{message}</span>
});

// Note can be the payment plan type, or when a refund was requested on a charge
// const NoteCell = ({paymentPlan, refundedAt}) =>(
//   <div>
//     {paymentPlan && <div>Payment Plan: {titleCase(paymentPlan)}</div>}
//     {refundedAt && <div>Refunded requested on <DateFormatter value={refundedAt} format="YYYY/MM/DD"/></div>}
//   </div>
//
// );

const StatusCell = ({ status, message }) => (
  <>
    <div>
      {status ? (
        <>
          <FaIcon
            size="sm"
            icon={status === 'accepted' ? 'check' : 'times'}
            color={status === 'accepted' ? 'green' : 'red'}
            className="me-1"
          />
          {titleCase(status)}
        </>
      ) : (
        <div className="text-center">&mdash;</div>
      )}

    </div>
    {message && <small>{message}</small>}
  </>
);

const ActionCell = ({
                      allowEdit, type, status, amount, invoice_number, refunded_at, isUpdating, onClickRefund,
                    }) => {

  if (!allowEdit || type !== 'charge' || status !== 'accepted' || !invoice_number) return null;

  if (refunded_at) {
    return (
      <div>
        Refunded requested on
        <DateFormatter value={refunded_at} format="yyyy/MM/dd" />
      </div>
    );
  }

  return (
    <span className="action-item">
      <Button
        color="link"
        size="sm"
        className="p-0 m-0"
        onClick={() => onClickRefund(invoice_number, amount)}
        disabled={isUpdating}
      >
          Refund {isUpdating && <Spinner size="sm" />}
        </Button>
    </span>

  );
};

const ActivityTypeChoices = [
  {'label':'Charge', value:'charge'},
  {'label':'Refund', value:'refund'},
  {'label':'Transaction', value:'transaction'},
]


const DefaultFilters = {
  start_date: startOfMonth(new Date()),
  end_date: endOfDay(new Date()),
  activity_type: ['charge','refund']
};

const Activity = ({ accountId, cards, refreshAccount }) => {
  // TODO: need notification result for success adding funds (in Cards)
  // TODO: success notification for refund
  // TODO: isUpdating state
  const app = useContext(AppContext);
  const allowEdit = usePermission('accounting.edit');
  const allowAuction = usePermission('auctions.view');
  const Accounting = React.useMemo(() => app.getApi('accounting'), []);
  const userTimezone = app.user ? app.user.timezone : '';
  const [transactions, setTransactions] = useState([]);
  //const [isUpdating, setIsUpdating] = useState(false);
  const [refundInvoice, setRefundInvoice] = useState(null); //the invoice being refunded
  const [isLoading, setIsLoading] = useState(false);
  const { filters, setFilters } = usePagedTableState(
    DefaultFilters,
    {},
    { useQueryString: false, useTableControls: false },
  );



  // On mount, read query string for response, get credit card list
  useEffect(() => {
    getActivity();
  }, [filters]);

  const onRefresh = () => getActivity();

  const reset = () => setFilters(DefaultFilters);

  const handleChangeDate = (selection) => {
    const newFilters = {...filters};
    newFilters.start_date = selection.startDate
    newFilters.end_date = endOfDay(selection.endDate)
    setFilters(newFilters);

  };

  const handleChangeFilter = (param, val) => {
    if (val) {
      setFilters({...filters, [param]:val})
    } else {
        const newFilters = {...prev};
        delete newFilters[param];
        setFilters( newFilters );
    }

  };

  const filterParamsToApi = () => {
    const params = { };

    if (filters.start_date && filters.end_date) {
      params.start_date = zonedTimeToUtc(filters.start_date, userTimezone).toISOString();
      params.end_date = zonedTimeToUtc(filters.end_date, userTimezone).toISOString();
    }

    if (filters.activity_type && filters.activity_type.length) {
      params.activity_type = filters.activity_type;
    }

    return params;
  }

  const getActivity = async () => {
    setIsLoading(true);
    setRefundInvoice(null);


    const trns = await Accounting.getActivity(accountId, filterParamsToApi());
    if (trns) {
      setTransactions(trns);
    } else {
      notify(`Unable to get transactions: ${Accounting.error.name}`, 'error');
    }
    setIsLoading(false);
  };

  const downloadActivity = async (fileType) => {
    setRefundInvoice(null);
    const params = filterParamsToApi();

    const download = await Accounting.downloadActivity(accountId, fileType, params);

    return download;
  };

  const handleRefund = async(invoice_number, amount) => {
    const execute = await refundConfirm(invoice_number, amount);
    if (execute) {
      doRefund(invoice_number);
    }

  };

  const refundConfirm = async (invoice_number, amount) => await confirm({
    title: 'Confirm Refund',
    message: <p>Refund <MoneyFormatter value={amount} /> for invoice <code>{invoice_number}</code>?</p>,
    confirmText: 'Refund',
    cancelText: 'Cancel',
  });

  const doRefund = async (invoice_number) => {

    setRefundInvoice(invoice_number);
    const ref = await Accounting.createRefund(accountId, invoice_number);
    if (ref) {
      notify('The refund request has been submitted', 'success');
      getActivity();
      refreshAccount();
    } else {
      notify(`Unable to execute refund: ${Accounting.error.name}`, 'error');
    }
    setRefundInvoice(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'created_at',
        disableSortBy: true,
        Cell: ({ value }) => <DateCell value={value} timezone={userTimezone} />,
        maxWidth: 130,
      },
      {
        Header: 'User',
        accessor: 'user.name',
        maxWidth: 80,
        Cell: ({ value }) => value || <span>System</span>,
      },
      {
        Header: 'Type', //charge, refund, transaction
        accessor: 'type',
        disableSortBy: true,
        Cell: ({ value }) => titleCase(value),
        maxWidth: 85,
      },
      {
        Header: 'Charge To', //Cash or credit
        accessor: 'wallet_type',
        disableSortBy: true,
        Cell: ({ value }) => titleCase(value),
        maxWidth: 85,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        disableSortBy: true,
        className: 'amount-cell numeric',
        Cell: ({ row }) => <AmountCell {...row.original} />,
        maxWidth: 100,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'status-cell',
        disableSortBy: true,
        Cell: ({ row, value }) => <StatusCell status={value} message={row.message} />,
        maxWidth:100
      },
      {
        Header: 'Card',
        accessor: 'card_id',
        disableSortBy: true,
        Cell: ({ value }) => <CreditCardCell cards={cards} cardId={value} />,
        maxWidth: 120,
      },
      {
        Header: 'Invoice',
        accessor: 'invoice_number',
        disableSortBy: true,
        maxWidth: 170,
      },
      {
        Header: 'Message',
        accessor: 'message',
        disableSortBy: true,
        Cell: ({ value }) => <MessageCell message={value} allowAuction={allowAuction}/>,
        minWidth: 170,
      },
      // {
      //   Header:'Note',
      //   id:'note_cell',
      //   sortable:false,
      //   Cell:props=><NoteCell paymentPlan={props.original.payment_plan_type} refundedAt={props.original.refunded_at}/>,
      // },
      {
        Header: 'Action',
        id: 'actions',
        className: 'list-actions',
        Cell: ({ row }) => (
          <ActionCell
            {...row.original}
           // onClickRefund={() => setRefundInvoice(row.original.invoice_number)}
           // refundConfirm={refundInvoice === row.original.invoice_number}
           // onRefund={handleRefund}
            allowEdit={allowEdit}
            onClickRefund={handleRefund}
            onCancel={() => setRefundInvoice(null)}
            isUpdating={row.original.invoice_number === refundInvoice}
          />
        ),
        disableSortBy: true,
        headerStyle: { display: 'none' },
      },
    ],
    [refundInvoice],
  );

  const initialTableState = useMemo(() => ({
    sortBy: [{ id: 'created_at', desc: true }],
  }), []);

  return (
    <div id="transactions-container" className="form-section mt-3">

      <LiveFilteringProvider filters={filters}>

        <div className="d-flex justify-content-between mb-2">
          <h5>Transactions</h5>

          <div className="above-table-filters ">

            <div className="ms-2 min-250">
              <Filter
                placeholder="Activity Type"
                param="activity_type"
                options={ActivityTypeChoices}
                value={filters.activity_type || []}
                onChange={handleChangeFilter}
                onClear={() => handleChangeFilter('activity_type', "")}
                isMulti
              />
            </div>

            <RightDateAndControls
              startDate={filters.start_date}
              endDate={filters.end_date}
              allowFuture={false}
              onChangeDate={handleChangeDate}
              onRefresh={onRefresh}
              onReset={reset}
              timezone={userTimezone}
            />
          </div>

        </div>



        {!isLoading && transactions.length === 0 && <NoDataComponent>No transactions were found </NoDataComponent>}
        {/* React table has build in fade effect when loading. Hide only when done loading and no results */}
        {(isLoading || transactions.length > 0)
      && (
        <>
          <div className="mb-1">
            Download <DownloadFileLink
              downloadSource={async()=>downloadActivity('excel')}
              label="Excel"
              defaultFilename={`Mastodon_Accounting_Activity_Report`}
              title="Download Excel Spreadsheet"
              buttonSize="xs"
            />|
            <DownloadFileLink
              downloadSource={async()=>downloadActivity('csv')}
              label="CSV"
              defaultFilename={`Mastodon_Accounting_Activity_Report`}
              title="Download CSV"
              buttonSize="xs"
            />
          </div>
          <PagedTable
            columns={columns}
            initialState={initialTableState}
            className="transactions-table"
            /* data={FilterContext.filteredData} */
            data={transactions}
            loading={isLoading}
            noDataMessage="No transactions were found"
          />
        </>

      )}
      </LiveFilteringProvider>
    </div>
  );
};



Activity.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  refreshAccount: PropTypes.func,
  cards: PropTypes.array,
};

export default Activity;
