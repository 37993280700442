import React, {FC, useMemo} from "react";
import FlexTable from "../../../Components/Table/FlexTable";
import {DateFormatter} from "../../../Components/Table/Table";
import EntityId from "../../../Components/Table/EntityId";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {InputSchemaType} from "../../../types";
import {Button} from "reactstrap";
import {Stack, RowDivider} from "@thedmsgroup/mastodon-ui-components";


type InputSchemaTableProps = {
  data:InputSchemaType[];
  isLoading:boolean;
  onViewSchema:(schema:InputSchemaType) => void;
  onEditSchema:(schema:InputSchemaType) => void;
}

/*
 * Table that lists Input Schema
 */
const InputSchemaTable:FC<InputSchemaTableProps> = ({
                                                              data,
                                                              isLoading,
                                                              onViewSchema,
                                                              onEditSchema,
                                                            }) => {

  const columns = useMemo(() =>{

    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 40,
        filter: 'text',
        Cell: ({value}:{value:number}) => <EntityId id={value} />
      },
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 250,
        filter: 'text',
        Cell: ({row, value}:{row:any, value:string}) =>
          <div>
            <div>

              {value}
            </div>
            <small>{row.original.description}</small>
          </div>
      },
      /*{
        Header: 'Description',
        accessor: 'description',
        minWidth: 240,
        filter: 'text',
      },*/
      {
        Header: 'Schema',
        id:'schema',
        maxWidth:80,
        disableSortBy: true,
        Cell: ({row}:{row:any}) => (
          <Button
            color="link"
            className="inline"
            onClick={()=>onViewSchema(row.original)}
          >
            <FaIcon icon="eye" size="sm" />
            {' '}View
          </Button>
        )
      },
      {
        Header: 'Parent',
        minWidth: 120,
        accessor:'parent_id',
        disableSortBy: true,
        Cell: ({value, data}:{value:number|null, data:InputSchemaType[]}) => (
            <div>
              {value ? (
                <ParentSchemaCell parentId={value} allSchemas={data} onViewSchema={onViewSchema} />
              ) : (
                <span>&mdash;</span>
              )}
            </div>
          )
      },
      {
        Header: 'Extended By',
        minWidth: 200,
        accessor:'input_schemas',
        disableSortBy: true,
        Cell: ({value}:{value:InputSchemaType[]}) =>  <ExtendedByCell schemas={value} onViewSchema={onViewSchema} />

      },
      {
        Header: 'Created',
        accessor: 'created_at',
        defaultCanSort: true,
        width: 130,
        disableGlobalFilter: true,
        Cell: ({ value }:{value:string}) => <DateFormatter value={value} format="MMM do, y" />,
      },
      {
        Header: 'Modified',
        accessor: 'modified_at',
        defaultCanSort: true,
        disableGlobalFilter: true,
        Cell: ({ value }:{value:string}) => <DateFormatter value={value} format="MMM do, y" />,
      },
      {
        Header: '',
        id: 'actions',
        maxWidth: 60,
        defaultCanSort: false,
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ row }:{row:any}) => <ActionCell schema={row.original} onView={onViewSchema} onEdit={onEditSchema} />
      },
    ]

  }, [])


  return (
    <FlexTable
      columns={columns}
      initialState={{
        sortBy: [{id: 'name', desc: false}]
      }}
      className="input-schema-table striped"
      data={data}
      noDataMessage="No schemas found"
      loading={isLoading}
    />

  );
}

export default InputSchemaTable;

type ParentSchemaCellProps = {
  parentId:number;
  allSchemas: InputSchemaType[];
  onViewSchema: (schema:InputSchemaType) => void;
}

const ParentSchemaCell = ({parentId, allSchemas, onViewSchema}:ParentSchemaCellProps) => {
  const parentSchema = allSchemas.find((inp:InputSchemaType) => inp.id === parentId);
  if (parentSchema) {
    return (
      <span>
        <EntityId id={parentId}/> {parentSchema.name}
          <Button
            color="link"
            className="inline ms-1"
            onClick={() => onViewSchema(parentSchema)}
          >
            <FaIcon icon="eye" size="sm"/>
          </Button>
      </span>
    )
  }
  return <i>error: not found</i>
}

type ExtendedByCellProps = {
  schemas:InputSchemaType[];
  onViewSchema: (schema:InputSchemaType) => void;
}
const ExtendedByCell = ({ schemas, onViewSchema}:ExtendedByCellProps) =>  (
      <div>
        {schemas.length > 0 ? (
          <Stack gap="0.35em" divider={<RowDivider />} >
            {schemas.map((schema:InputSchemaType, i:number) => (
              <div className="mb-1" key={i}>
                <EntityId id={schema.id}/> {schema.name}
                <Button
                  color="link"
                  className="inline ms-1"
                  onClick={() => onViewSchema(schema)}
                >
                  <FaIcon icon="eye" size="sm"/>
                </Button>
              </div>
            ))}

          </Stack>
        ) : <span>&mdash;</span>
        }

      </div>
);

  type SchemaActionCellProps = {
    schema:InputSchemaType,
    onView:(schema:InputSchemaType)=>void;
    onEdit:(schema:InputSchemaType)=>void;
  }

  const ActionCell = ({
                        schema, onEdit, onView,
                      }:SchemaActionCellProps) => (
    <div className="list-actions">

        {/*<Button color="link" className="action-item inline" title="View" onClick={() => { onView(schema); }}>
          <FaIcon icon="eye" size="1x" />
        </Button>*/}

        <Button color="link" className="action-item inline" title="Edit" onClick={() => { onEdit(schema); }}>
          <FaIcon icon="cog" size="1x" />
        </Button>

    </div>
  );



