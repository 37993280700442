import * as React from 'react';

const Delta = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={props.width}
    height={props.height}
    viewBox={props.viewBox}
    preserveAspectRatio="xMidYMid meet"
    className="delta-icon"
  >
    <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
    <g transform="translate(0.000000,97.000000) scale(0.100000,-0.100000)" fill={props.fill} stroke="none">
      <path d="M446 888 c-7 -18 -95 -216 -195 -441 -99 -224 -181 -409 -181 -412 0 -3 185 -5 411 -5 244 0 409 4 407 9 -2 5 -93 205 -202 445 -116 253 -205 436 -213 436 -7 0 -19 -15 -27 -32z m122 -441 c67 -155 122 -285 122 -289 0 -5 -115 -8 -256 -8 -145 0 -254 4 -252 9 21 56 253 571 257 571 3 0 61 -127 129 -283z" />
    </g>
  </svg>
);

Delta.defaultProps = {
  viewBox: '0 0 97 97',
  width: 20,
  height: 20,
  fill: '#000000',
};
// const Delta = (props) => {
//   return (
//     <svg
//       width={props.width}
//       xmlns="http://www.w3.org/2000/svg"
//       version="1.0"
//   >
//   <path
//     fill={props.fill}
//     d="M 20.55086,341.32427 C 20.904411,340.36177 49.415052,275.33687 83.907852,196.82448 L 146.62201,54.074695 L 150.16469,54.074483 L 153.70738,54.074271 L 213.56017,197.82427 C 246.4792,276.88677 273.59905,341.91177 273.8265,342.32427 C 274.05395,342.73677 217.01534,343.07427 147.07404,343.07427 C 26.385345,343.07427 19.940781,342.98513 20.55086,341.32427 z M 222.95534,324.13265 C 220.71825,317.81318 138.51396,119.07517 138.13811,119.07757 C 137.74414,119.08008 64.648702,284.53923 49.914232,318.7815 L 47.206452,325.07427 L 135.24756,325.07427 C 189.87191,325.07427 223.16216,324.71687 222.95534,324.13265 z "
//      />
// </svg>)
//   }
//
// Delta.defaultProps = {
//   viewBox:"0 0 290 400",
//   width:290,
//   fill:'#000000'
// };

export default Delta;
