import React, { useRef, useContext } from 'react';
import {
  Button, Col, FormGroup, FormText, Row, Label, Input, FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { useDebouncedCallback } from 'use-debounce';
import RulesContext from '../../../../Providers/RulesContext';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import Configuration from '../../../../Components/Rules/Configuration';
import useCollectionTracker from '../../../../Hooks/useCollectionTracker';
import InputWithIcon from '../../../../Components/Form/InputWithIcon';
import usePermission from '../../../../Hooks/usePermission';
import IconCheckbox from '../../../../Components/Form/IconCheckbox';

// Mask dd.dd
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 3,
  // fixedDecimalScale:true
});

// Mask ddd
const modifierMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 3,
});

const ProxyBidding = ({ proxy, errors = {}, onChange }) => {
  const rulesContext = useContext(RulesContext);
  const allowEdit = usePermission('integrations.edit');
  const definitionsRef = useRef(rulesContext.api.getIntegrationDefinitionsByType('proxy_bidding'));
  const {
    api: collection,
    willDeleteIndex,
    editIndex,
    addMode,
  } = useCollectionTracker();

  const handleUpdate = (integration) => onChange({ ...proxy, integration });

  const handleDelete = () => {
    collection.onDelete();
    onChange({});
  };

  const handleAdd = () => {
    collection.add(0);
    onChange({
      integration: { name: '', settings: {} },
    });
  };

  const handleChangeModifierDebounced = useDebouncedCallback((value) => {
    onChange({
      ...proxy,
      bid_modifier: value,
    });
  }, 400);

  const handleChangeFloorDebounced = useDebouncedCallback((value) => {
    onChange({
      ...proxy,
      bid_floor: value,
    });
  }, 400);

  const handleChangeVendorAttributesDebounced = useDebouncedCallback((value) => {
    onChange({
      ...proxy,
      vendor_attributes: value,
    });
  }, 400);

  // const handleChangeDirectTransfer = (e) => onChange({...proxy, direct_transfer:e.target.checked})
  const handleChangeDirectTransfer = (name, checked) => onChange({ ...proxy, direct_transfer: checked });

  // Don't show anything if user can't edit and no integration exists (MST-979)
  if (!allowEdit && !proxy.integration) {
    return (null);
  }

  return (
    <div className="form-section">
      <div className="form-section-header">Proxy Bidding</div>

      <p>Receive bids through a third party service by configuring a proxy</p>

      {definitionsRef.current.length === 0 && <StandardAlert color="light" icon="alert">No integration configurations found</StandardAlert>}

      <Row>
        <Col lg={12} xl={9}>

          {!allowEdit && <StandardAlert color="light">Managing this setting requires additional permissions.</StandardAlert>}

          {proxy.integration !== undefined ? (
            <>
              <Configuration
                config={proxy.integration}
                definitions={definitionsRef.current}
                type="proxy_bidding"
                editMode={editIndex === 0}
                isNew={addMode}
                errors={errors}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                onApply={collection.apply}
                setEdit={collection.edit}
                willDelete={willDeleteIndex === 0}
                setWillDelete={(i) => collection.setWillDeleteIndex(i)}
                allowEdit={allowEdit}
                allowControls={allowEdit}
              />

              <div className="d-flex mt-3">
                <FormGroup className="me-3">
                  <Label>Bid Floor</Label>
                  <MaskedInput
                    mask={currencyMask}
                    defaultValue={proxy.bid_floor}
                    onChange={(e) => handleChangeFloorDebounced(e.target.value)}
                    disabled={!allowEdit}
                    render={(ref, props) => <InputWithIcon className="teeny" innerRef={ref} icon="dollar-sign" {...props} />}
                  />
                </FormGroup>

                <FormGroup className="ms-3">
                  <Label>Bid Modifier</Label>
                  <MaskedInput
                    mask={modifierMask}
                    defaultValue={proxy.bid_modifier}
                    onChange={(e) => handleChangeModifierDebounced(e.target.value)}
                    disabled={!allowEdit}
                    render={(ref, props) => <InputWithIcon className="teeny" innerRef={ref} icon="percent" iconSide="right" {...props} />}
                  />
                </FormGroup>

                {/* <FormGroup className="flex-fill ms-3">
                  <Label></Label>
                  <div className="d-flex ms-5 mt-2">
                    <div className="me-1"><Input
                      name="direct_transfer"
                      type="checkbox"
                      defaultChecked={!!proxy.direct_transfer}
                      onChange={handleChangeDirectTransfer}
                    /></div>
                    <div  className="flex-fill">
                        Direct Transfer
                    </div>
                  </div>
                </FormGroup>
*/}

              </div>

              <IconCheckbox
                name="direct_transfer"
                value={!!proxy.direct_transfer}
                onChange={handleChangeDirectTransfer}
                className="mt-2 mb-4"
              >

                Direct Transfer

              </IconCheckbox>

              <div>
                <FormGroup className="mt-2">
                  <Label>Vendor Attributes</Label>
                  <Input
                    type="textarea"
                    rows={2}
                    defaultValue={proxy.vendor_attributes || ''}
                    name="vendor_attributes"
                    onChange={(e) => handleChangeVendorAttributesDebounced(e.target.value)}
                    disabled={!allowEdit}
                    invalid={!!errors.vendor_attributes}
                  />
                  <FormText>Enter a valid JSON expression</FormText>
                  <FormFeedback>{errors.vendor_attributes}</FormFeedback>
                </FormGroup>
              </div>
            </>
          ) : (
            <>
              {allowEdit && (
                <Button color="link" onClick={handleAdd} disabled={editIndex !== null}>
                  <FaIcon icon="plus" className="me-1" />
                  Add Proxy
                </Button>
              )}
            </>

          )}

        </Col>
      </Row>

    </div>

  );
};

ProxyBidding.propTypes = {
  onChange: PropTypes.func.isRequired,
  proxy: PropTypes.object.isRequired,
  errors: PropTypes.object,
};
export default ProxyBidding;
