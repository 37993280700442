import React from 'react';
import CapsType from './CapsType';
import PingDedupeType from "./PingDedupeType";

/*
 * Tab content where we edit match caps and sale caps.
 */
const Caps = ({
  matchCaps, saleCaps, pingDedupes, onChange, errors,
}) => {

  const handleChangeMatchCaps = React.useCallback((caps) => onChange('match_caps', caps));
  const handleChangeSaleCaps = React.useCallback((caps) => onChange('sale_caps', caps));
  const handleChangePingDedupe = React.useCallback((dedupes) => onChange('ping_dedupe', dedupes));

  return (
    <>

      <CapsType
        title="Sale Caps"
        description="Limit the number of sales within a timeframe"
        className="sale-caps"
        caps={saleCaps}
        onChange={handleChangeSaleCaps}
        error={errors.sale_caps}
      />

      <CapsType
        title="Match Caps"
        description="Limit the number of matches within a timeframe"
        className="match-caps"
        onChange={handleChangeMatchCaps}
        caps={matchCaps}
        error={errors.match_caps}
      />

      <PingDedupeType
        title="Ping Dedupe"
        description="Dedupe pings within a timeframe"
        className="ping-dedupe"
        onChange={handleChangePingDedupe}
        dedupeRules={pingDedupes}
        error={errors.ping_dedupe}
      />

    </>
  );
};

export default Caps;
