import React from "react";
//import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

type InputSchemaOptionChoiceType = {
  value: number | string,
  label: string,
  description: string
}

/*
 * Styled option for react-select (SimpleSelect) dropdown with list of input schema choices
 * use in the react-select prop: formatOptionLabel={InputSchemaOptionLabel}
 * See react-select docs.
 * "choice" will have the choice value and label (and any other data you manually set into the choices)
 * "meta" (2nd prop) has context, inputValue, selectValue<array>
 *
 * Checkmark icon assumes that picker is configured as single-select
 */
export const InputSchemaOptionLabel = (choice:InputSchemaOptionChoiceType, {selectValue}: any) => {
 return (
    <div className="d-flex">
      {/*<div>
        {selectValue.length > 0 && choice.value && choice.value.toString() === selectValue[0].value.toString() &&
      <FaIcon icon="check" size="sm" className="me-1" color="green"/>}
      </div>*/}
      <div>{choice.label}</div>
      <div className="ms-3" style={{color: "#666", fontSize: "0.9rem"}}>
        <small>{choice.description || ""}</small>
      </div>

    </div>
  )
};
