import React, { useState, useContext } from 'react';
import {
  Row, Col, Form, FormGroup, Input, Button, Spinner,
} from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import useFormState from '../Hooks/useFormState';
import { AppContext } from '../Providers/AppProvider';
import DocTitle from '../Layout/DocTitle';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('The email address is not valid')
    .required('Please enter an email address'),
});

/*
 * Component with form that submits email to begin password reset process
 */
const ForgotPassword = () => {
  const app = useContext(AppContext);
  const [isPosting, setIsPosting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState({ email: '' }, validationSchema);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (isPosting) return;
    setSubmitError(false);
    const isValid = formApi.validate();
    if (isValid) {
      setEmail(email);
      setIsPosting(true);
      submitRequest(email);
    }
  };

  const submitRequest = async () => {
    const result = await app.api.requestPasswordReset(formValues.email);
    setIsPosting(false);
    if (result) {
      setSubmitSuccess(true);
    } else {
      // Generic response for bad or unknown email, status 400, name:"Invalid Request".
      // For security purposes we show "success" even for unknown email
      if (app.api.error.status === 400) {
        setSubmitSuccess(true);
      } else {
        // some problem with the network or api
        setSubmitError(true);
      }
    }
  };

  // We should not be here
  if (app.isAuthorized) {
    // or this.props.history.goBack() ?
    return <Navigate to="/accounts" replace={true} />;
  }

  return (
    <>
      <DocTitle pageTitle="Forgot Password" />
      <Row>
        <Col sm={12} md={{ size:4, offset:4 }} className="entry-form-panel">
          <h4>Forgot your password?</h4>
          <p>Enter the email address associated with your account to request a password reset.</p>
          <Form onSubmit={handleSubmit}>

            { formErrors.email && (
              <StandardAlert color="warning">
                {formErrors.email}
              </StandardAlert>
            )}

            { submitError === true && (
              <StandardAlert color="warning">
                There was a problem submitting your request. Please try again.
              </StandardAlert>
            )}

            { submitSuccess === true && (
              <StandardAlert color="info" icon="check">
                Request received. If a matching account was found, an email was sent to
                {' '}
                {email}
                {' '}
                to allow you to reset your password.
              </StandardAlert>
            ) }

            <FormGroup>

              <Input
                type="text"
                name="email"
                onChange={formApi.handleOnChange}
                value={formValues.email}
                placeholder="Email Address"
                disabled={submitSuccess}
                bsSize="lg"
              />

            </FormGroup>

            <Button type="submit" color="primary" size="sm" block disabled={isPosting || submitSuccess}>
              { isPosting === true ? (
                <Spinner color="light" size="sm" />
              ) : <span>Reset Password</span>}
            </Button>
            <Button size="sm" color="link" tag={Link} to="/Login" block className="mt-2 no-case">
              Return to sign-in
            </Button>

          </Form>
        </Col>
      </Row>


    </>

  );
};

export default ForgotPassword;
