import BaseAPI from './BaseAPI';

class AccountingAPI extends BaseAPI {
  constructor(requestCallback) {
    // Instead of having the UI talk directly to the accounting service,
    // it now routes all requests through core.
    // Since there are a lot of specialized requests beyond CRUD,
    // we kept this class instead of using only CoreAPI class.

    // Note: accountID provided in the requests is the Mastodon account id.
    // This will be translated to the accounting account id when the request
    // is passed on.

    // super(process.env.API_URL_ACCOUNTING, []);
    super(`${process.env.API_URL_CORE}/accounting`, [], requestCallback);
  }

  getAccount = async (account_id) => {
    const url = `${this.url}/${account_id}`;
    return await this.makeRequest('get', url, {});
  };

  updateAccount = async (account_id, account) => {
    const url = `${this.url}/${account_id}`;
    return await this.makeRequest('put', url, account);
  };

  addAccountCredit = async (account_id, amount) => {
    const url = `${this.url}/credits/${account_id}`;
    return await this.makeRequest('post', url, { amount, memo: 'Mastodon UI' });
  };

  createRefund = async (account_id, invoice_number) => {
    const url = `${this.url}/refunds/${account_id}`;
    const params = { invoice_number };
    return await this.makeRequest('post', url, params);
  };

  transfer = async (params) => {
    const url = `${this.url}/transfers`;
    return await this.makeRequest('post', url, params, {}, true);
  }

  // getAccountCharges = async(account_id) =>{
  //   const url = `${this.url}/charges`;
  //   const params = {account_id};
  //   return await this.makeRequest( 'get', url, params );
  // }

  getCreditCardList = async (account_id) => {
    const url = `${this.url}/credit-cards/${account_id}`;
    return await this.makeRequest('get', url);
  };

  getActivity = async (account_id, params) => {
    const url = `${this.url}/activity/${account_id}`;
    return await this.makeRequest('get', url, params);
  };

  downloadActivity = async (account_id, file_type, params) => {
    const url = `${this.url}/activity/${account_id}/download/${file_type}`;
    return await this.makeRequest('get', url, params, { responseType: 'blob' } );
  };

  // not used?
  getRefunds = async (account_id) => {
    const url = `${this.url}/refunds/${account_id}`;
    return await this.makeRequest('get', url);
  };

  // not used?
  getCharges = async (account_id) => {
    const url = `${this.url}/charges/${account_id}`;
    return await this.makeRequest('get', url);
  };

  // query the Accounting api for the URL of the MeS checkout page
  getCheckoutPage = async (account_id, return_url, cancel_url) => {
    const url = `${this.url}/checkout-page/${account_id}`;
    const params = { return_url, cancel_url };
    return await this.makeRequest('post', url, params);
  };

  chargeToCard = async (account_id, card_id, amount) => {
    const url = `${this.url}/credit-cards/${account_id}/charge`;
    const params = { card_id, amount };
    return await this.makeRequest('post', url, params);
  };

  updateCard = async (account_id, card_id, priority, auto_billing_eligible) => {
    const url = `${this.url}/credit-cards/${account_id}/${card_id}`;
    const params = { priority, auto_billing_eligible };
    return await this.makeRequest('put', url, params);
  };

  removeCard = async (account_id, card_id) => {
    const url = `${this.url}/credit-cards/${account_id}/${card_id}`;
    return await this.makeRequest('delete', url, {});
  };
}

export default AccountingAPI;
