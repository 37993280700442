import React from 'react';
import {HelpPopper}  from '@thedmsgroup/mastodon-ui-components';
import { HEADER_TIPS } from './constants';

/*
 * Wrapper around column header content that will add a tooltip for some columns.
 * (Later, React-table will wrap this with Sorting functionality)
 */
const ReportHeader = (props) => {

  return (
    <div className="rpt-header d-flex flex-nowrap">
      <div>{props.label}</div>
      {HEADER_TIPS[props.col] && <div><HelpPopper name={`rpt-head-${props.col}`}>{HEADER_TIPS[props.col]}</HelpPopper></div>}
    </div>
  );
};

export default ReportHeader;
