import React from 'react';
import {Button} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import useLocalStorage from "../Hooks/useLocalStorage";

type PageSectionPropsType = {
  title?:string|React.ReactNode;
  subtitle?:string|React.ReactNode;
  className?:string;
  tools?:any;
  children: React.ReactNode;
  sectionNav?: React.ReactNode;
  storageKey?:string,
  expandable?:boolean;
  expanded?:boolean;
}


/*
 * Component for Card layout wrapping a unit of content on the page
 * Can have a title, buttons, or tabs
 *
 */
const PageSection = ({
                       title="",
                       subtitle="",
                       children,
                       tools,
                       sectionNav,
                       className,
                       storageKey="",
                       expandable,
                       expanded
                     }:PageSectionPropsType) => {

  // Storing expand state in local storage is optional; if key is empty,
  // hook will act like useState
  const [isExpanded, setExpanded] = useLocalStorage(storageKey, expandable ? Boolean(expanded) : true);

  return (
    <div className={classnames(["page-section", "card", className], {expandable, show:expandable && isExpanded, collapsed:!isExpanded})}>

      {isExpanded ? (
        <>
          {sectionNav !== undefined
          && (
            <div className="page-section-nav">
              {sectionNav}
            </div>
          )}

          {title && (
            <div className="page-section-header d-flex align-items-center justify-content-between">

              <h5 className="page-title">
                {title}
                {subtitle && <span className="subtitle">{subtitle}</span>}
                {expandable &&
                <Button size="1x"  color="link"  className="inline ms-2" onClick={()=>setExpanded(false)}>
                  <FaIcon icon="chevron-circle-up" size="1x"  />
                </Button>}
              </h5>

              {tools !== undefined
              && (
                <div className="page-section-tools">
                  {tools}
                </div>
              )}
            </div>
          )}

          <div className="page-body">
            {children}
          </div>
        </>
      ) : (
        <div className="page-section-header m-0 d-flex align-items-center">
          <h5 className="page-title">
            {title}
            {subtitle && <span className="subtitle">{subtitle}</span>}
          </h5>
          {expandable && (
            <Button size="1x" color="link" className="inline ms-2" onClick={()=>setExpanded(true)}>
              <FaIcon icon="chevron-circle-down" size="1x"  />
            </Button>
          )}

        </div>


      )}



    </div>
  )
};


export default PageSection;
