const payloads = [
  {
    label: 'Minimal',
    payload: {
      data: {
        zipcode: '92109',
        ip_address: '127.0.0.1',
        user_agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/43.0.2357.124 Safari/537.36',
        source_url: 'www.acme.com/signup',
      },
    },
  },
  // {
  //     label: "Medium",
  //     payload: {
  //         source_token: sourceToken,
  //         visitor: {
  //             ip_address: "127.0.0.1",
  //             user_agent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/43.0.2357.124 Safari/537.36",
  //         },
  //         data: {
  //             zip: "92109",
  //             currently_insured: true,
  //             drivers: [
  //                 {
  //                     birth_date: "1980-02-22",
  //                 },
  //             ],
  //             vehicles: [
  //                 {
  //                     "annual_mileage": 25000,
  //                 },
  //             ],
  //         },
  //         "extras": {
  //             "url": "www.acme.com",
  //         },
  //     },
  // },
  {
    label: 'LM - Regular',
    payload: {
      data: {
        zipcode: '71601',
        ip_address: '127.0.0.1',
        user_agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/43.0.2357.124 Safari/537.36',
        currently_insured: true,
        drivers: [
          {
            birth_date: '1980-02-22',
          },
        ],
        vehicles: [
          {
            annual_mileage: 25000,
          },
        ],
        source_url: 'www.acme.com/signup',
      },
    },
  },
  {
    label: 'LM - High LTV',
    payload: {
      data: {
        zipcode: '71601',
        ip_address: '127.0.0.1',
        user_agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/43.0.2357.124 Safari/537.36',
        currently_insured: true,
        drivers: [
          {
            birth_date: '1980-02-22',
          },
        ],
        vehicles: [
          {
            annual_mileage: 25000,
          },
          {
            annual_mileage: 25000,
          },
        ],
        home_ownership: true,
        source_url: 'www.acme.com/signup',
      },
    },
  },
  {
    label: 'Complete',
    payload: {
      data: {
        ip_address: '127.0.0.1',
        user_agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/43.0.2357.124 Safari/537.36',
        address: '120 Jenkins Rd',
        address_2: 'Apt 12',
        bi_per_incident: 100000,
        bi_per_person: 50000,
        contact: 'James S. Silverman',
        continuous_coverage: 121,
        coverage_type: 'Standard Protection',
        current_company: 'Progressive',
        current_customer: 48,
        current_policy_expires: 2,
        currently_insured: 1,
        drivers: [
          {
            bankruptcy: 0,
            birth_date: '1968-09-12',
            credit_rating: 'Good',
            education: 'Associate',
            first_licensed: 17,
            gender: 'M',
            license_state: 'GA',
            license_status: 'Active',
            marital_status: 'Married',
            occupation: 'Other Technical',
            primary_vehicle: 0,
            relationship: 'self',
            sr_22: 0,
          },
        ],
        email: 'silverman@mediaalpha.com',
        home_length: 121,
        home_ownership: 1,
        incidents: [
          {
            description: 'More than 10 MPH over',
            driver: 0,
            incident_date: '06/2012',
            type: 'ticket',
          },
        ],
        phone: '2299429943',
        vehicles: [
          {
            alarm: 1,
            annual_mileage: 25000,
            auto_warranty: 1,
            average_mileage: 10,
            collision: 500,
            commute_days_per_week: 1,
            comprehensive: 500,
            current_mileage: 40000,
            make: 'HONDA',
            model: 'CIVIC SI',
            ownership: 'finance',
            primary_purpose: 'Commute Work',
            submodel: 'SEDAN 4 DOOR',
            vin: 'SHHFC1E50HH000000',
            year: 2017,
          },
        ],
        zipcode: '90210',
        source_url: 'www.acme.com/signup',
      },
    },
  },
];

const stateZips = {
  AA: '34002',
  AE: '09002',
  AK: '99501',
  AL: '35004',
  AP: '96201',
  AR: '71601',
  AS: '96799',
  AZ: '85001',
  CA: '90001',
  CO: '80001',
  CT: '06001',
  DC: '20001',
  DE: '19701',
  FL: '32003',
  FM: '96941',
  GA: '30002',
  GU: '96910',
  HI: '96701',
  IA: '50001',
  ID: '83201',
  IL: '60001',
  IN: '46001',
  KS: '66002',
  KY: '40003',
  LA: '70001',
  MA: '01001',
  MD: '20588',
  ME: '03901',
  MH: '96960',
  MI: '48001',
  MN: '55001',
  MO: '63005',
  MP: '96950',
  MS: '38601',
  MT: '59001',
  NC: '27006',
  ND: '58001',
  NE: '68001',
  NH: '03031',
  NJ: '07001',
  NM: '87001',
  NV: '88901',
  NY: '00501',
  OH: '43001',
  OK: '73001',
  OR: '97001',
  PA: '15001',
  PR: '00601',
  PW: '96939',
  RI: '02801',
  SC: '29001',
  SD: '57001',
  TN: '37010',
  TX: '73301',
  UT: '84001',
  VA: '20101',
  VI: '00801',
  VT: '05001',
  WA: '98001',
  WI: '53001',
  WV: '24701',
  WY: '82001',
}

const userAgents = {
  mobile: 'Mozilla/5.0 (compatible; MSIE 9.0; Windows Phone OS 7.5; Trident/5.0; IEMobile/9.0)',
  desktop: 'Mozilla/5.0 (X11; Linux) AppleWebKit/538.15 (KHTML, like Gecko) Chrome/18.0.1025.133 Safari/538.15 Midori/0.5',
}

export {payloads, stateZips, userAgents}
