import React from "react";
import { ShapeConnectorProps } from "./SvgConnector";

interface SConnectorProps extends ShapeConnectorProps {}

/**
 * S shape svg connector
 * @param startPoint
 * @param endPoint
 * @param width
 * @param height
 * @param stroke
 * @param strokeWidth
 * @param startArrow
 * @param endArrow
 * @param arrowSize
 * @param zIndex
 */
export default function SConnector(props: SConnectorProps) {
  const {
    direction,
    stroke,
    strokeWidth,
    startArrow,
    endArrow,
    startPoint,
    endPoint,
    arrowSize,
    ...rest
  } = props;

  const distanceX = props.endPoint.x - props.startPoint.x;
  const distanceY = props.endPoint.y - props.startPoint.y;
  const grids = 4;
  const stepX = distanceX / grids;
  const stepY = distanceY / grids;

  //overflow:visible to account for width of stroke going above or below start/end Y
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position:"absolute",
        overflow:"visible",
        top:`${props.startPoint.y}px`,
        left:`${props.startPoint.x}px`,
        width:`${props.width}px`,
        height:`${props.height}px`,
        zIndex: props.zIndex
      }}
    >
      <path

        d={`M
            0 0
            q
            ${(stepX * grids) / 2} 0
            ${(stepX * grids) / 2} ${(stepY * grids) / 2}
            q
            0 ${(stepY * grids) / 2}
            ${(stepX * grids) / 2} ${(stepY * grids) / 2}
        `}
        stroke={props.stroke || "orange"}
        strokeWidth={props.strokeWidth || 3}
        fill="transparent"
        className="connector"
      />
    </svg>
  );
}
