import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import classnames from 'classnames';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/*
 * Form entry for entering a pixel (script, url, etc)
 * Used in a list where you can add/remove
 */
const PixelForm = ({
  pixel, handleRemove, handleChange, className,
}) => {
  // debounced to prevent delays while typing, since we are updating an array in a large object
  const debouncedCallback = useDebouncedCallback((value) => {
    handleChange(value);
  }, 600);

  return (
    <div className={classnames('pixel-form', className, 'mb-3')}>

      <div className="d-flex">
        <div className="flex-grow-1">
          <Input
            type="textarea"
            defaultValue={pixel}
            rows={3}
            onChange={(e) => { debouncedCallback(e.target.value); }}
            placeholder="Enter pixel tag"
          />

        </div>
        <div className="ms-2">
          <Button onClick={handleRemove} title="Remove item" color="link"><FaIcon icon="times" /></Button>
        </div>
      </div>

    </div>
  );
};

PixelForm.propTypes = {
  pixel: PropTypes.string,
  handleRemove: PropTypes.func,
  handleChange: PropTypes.func,
  className: PropTypes.string,
};

export default PixelForm;
