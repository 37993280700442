import React, {
  useEffect, useState, useMemo, useContext,
} from 'react';
import { AppContext } from '../../Providers/AppProvider';
import PageSection from '../../Layout/PageSection';
import DocTitle from '../../Layout/DocTitle';
import CallsFilters from './CallsFilters';
import PagedTable from '../../Components/Table/PagedTable';
import FilterBar from '../../Components/Table/FilterBar';
import ProspectDetailModal from './ProspectDetailModal';
import { getCallColumnChoices } from './ColumnSelectChoices';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import {
  extractColumnsAndDataForLeads,
  convertFilterParams,
} from './api';
import './styles.scss';
import usePagedTableState from '../../Hooks/usePagedTableState';
import { format, startOfDay, endOfDay } from 'date-fns';
import { Button } from 'reactstrap';
import CallColumnSelect from './CallColumnSelect';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import DownloadFileLink from '../../Components/DownloadFileLink';
import usePermission from '../../Hooks/usePermission';
import Modal from './../Auctions/Details/Modal';
import BooleanIconCell from "../../Components/Table/BooleanIconCell";

const DefaultFilters = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};

const DefaultControls = {
  limit: 20,
  sort_by: 'received_time',
  sort_dir: 'desc',
  page: 1,
};

/*
 * Calls List - paged table with filters
 *
 */
const Calls = () => {
  const app = useContext(AppContext);
  const Reporting = React.useMemo(() => app.getApi('reporting'), []);
  const userTimezone = app.user.timezone;
  const csvDefaultName = `Mastodon_Calls_${format(new Date(), 'yyyy-dd')}.csv`;
  const {
    filters, setFilters, updateQuery, reset, controls, tableEvent,
  } = usePagedTableState(
    DefaultFilters,
    DefaultControls,
    { useQueryString: true, useTableControls: true, updateOnFilterChange: false },
  );
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({ data: [], columns: [], columnChoices: [] });
  const [pageCount, setPageCount] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [detailId, setDetailId] = useState();
  const [auctionId, setAuctionId] = useState();
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const allowViewSource = usePermission('sources.view');
  const allowViewAuction = usePermission('auctions.view');
  const columnChoices = useMemo(() => getCallColumnChoices(allowViewSource && allowViewAuction), []);

  const initialTableState = useMemo(() => ({
    // These columns are useful for detail view but we don't show them in the table
    hiddenColumns: ['auction_id', 'call_id'],
    sortBy: [
      { id: controls.sort_by, desc: true },
    ],
  }), []);

  useEffect(() => {
    if (filtersLoaded && !loading && tableEvent && tableEvent !== 'filters') {
      loadCalls();
    }
  }, [tableEvent, controls.sort_by, controls.sort_dir, controls.page, controls.limit]);

  //filters need to load first because sometimes they can adjust
  useEffect(() => {
    if (filtersLoaded) {
      loadCalls();
    }
  }, [filtersLoaded]);

  const CellResolver = (colId) => {
    if (colId === 'billable') {
      return BooleanIconCell;
    }
    return null;
  };

  const handleClearFilters = () => reset({ fields: filters.fields || [] });

  const handleUpdateColumns = (fields) => setFilters({ ...filters, fields });

  // Accounts filter is provided in detail request.
  // Rule is: without the explicit pii.view permission, you can only see unmasked PII if you are filtering by your direct (associated with user)
  // account, not child accounts.
  const loadDetail = async (id) => await Reporting.getProspectDetail(id, 'calls', filters.accounts ? {account_ids:filters.accounts} : {});

  const toggleDetail = (id) => setDetailId(id);
  const toggleAuction = (id) => setAuctionId(id);

  const loadCalls = async () => {
    setLoading(true);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    const apiFilters = convertFilterParams(filters, null);

    const {
      page, sort_by, sort_dir, limit,
    } = controls;

    const response = await Reporting.getCalls({
      ...apiFilters,
      page,
      sort_by,
      sort_dir,
      number_per_page: limit,
      // fields:'received_time,origin_number,call_id,vertical,source_name,cost,transfer_number,duration,billable,disposition,full_name,city,state'
    });

    if (response) {
      const { total, pages_count } = response;
      setPageCount(pages_count || 1);
      setTotalRows(total);
      const rpt = extractColumnsAndDataForLeads(response, CellResolver);

      // For column picker set selected fields if not already selected, based on columns in response.
      // Filter out hidden columns.
      if (!filters.fields?.length) {
        setFilters({ ...filters, fields: rpt.columns.map((c) => c.dataField).filter((c) => !initialTableState.hiddenColumns.includes(c)) });
      }

      // add in the actions column
      rpt.columns = [
        {
          id: 'actions',
          Cell: ({ row }) => <ActionCell row={row.original} openDetail={toggleDetail} openDebug={toggleAuction} allowViewAuction={allowViewAuction} />,
          maxWidth: 100,
        },
        ...rpt.columns,
      ];

      //console.log('Calls.js:on load, report', rpt);
      setReport(rpt);
    } else {
      notify(`Unable to load calls: ${Reporting.error.name}`, 'error');
    }
    updateQuery();
    setLoading(false);
  };

  const loadCSV = async () => {
    const apiFilters = convertFilterParams(filters, null);

    const response = await Reporting.getCalls({
      ...apiFilters,
    }, 'csv');

    return response;
  };

  const { data = [], columns = [] } = report;

  return (

    <>
      <DocTitle pageTitle="Calls" />

      <FilterBar>
        <CallsFilters
          filters={filters}
          api={Reporting}
          onLoadFilters={()=>setFiltersLoaded(true)}
          onChange={setFilters}
          onExecute={loadCalls}
          onClear={handleClearFilters}
          userTimezone={userTimezone}
          reportLoading={loading}
          product="calls"
        />
      </FilterBar>

      <PageSection title="Calls" className="overflow-section">

        <div className="d-flex mb-2">

          <CallColumnSelect
            choices={columnChoices}
            onUpdate={handleUpdateColumns}
            onApply={loadCalls}
            selectedCols={filters.fields}
            disabled={loading || data.length === 0}
          />

          <DownloadFileLink
            downloadSource={loadCSV}
            label="Download CSV"
            defaultFilename={csvDefaultName}
            disabled={loading || data.length === 0}
          />
        </div>

        <PagedTable
          data={data}
          columns={columns}
          initialState={initialTableState}
          className="reports-table striped"
          loading={loading}
          pageCount={pageCount}
          totalRows={totalRows}
          {...controls}
          noDataMessage="No calls were found"
        />

      </PageSection>

      <ProspectDetailModal
        callId={detailId}
        loadDetail={loadDetail}
        isOpen={!!detailId}
        cancel={() => toggleDetail(null)}
        product="calls"
        autoPlayId={0}
      />

      <Modal
        auctionId={auctionId}
        isOpen={!!auctionId}
        cancel={() => toggleAuction(null)}
      />

    </>
  );
};

const ActionCell = React.memo(({
  row, openDetail, openDebug, allowViewAuction,
}) => (
  <div className="row-actions">
    {row.billable === 'Yes' && (
    <Button color="link" className="pt-0 pb-0 pr-1" title="Call Detail" onClick={() => openDetail(row.call_id)}>
      <FaIcon icon="id-card" />
    </Button>
    )}
    {allowViewAuction && (
    <Button color="link" className="p-0" title="Auction/Delivery Debug" onClick={() => openDebug(row.auction_id)}>
      <FaIcon icon="comment-dollar" />
    </Button>
    )}
  </div>

));

export default Calls;
