// For now we only set bid modifiers by the hour interval, not less
export const BiddingScheduleConfig = {
  defaultValue: 100,
  max: 200,
  labelInterval: 20,
  lineInterval: 10,
  allowHourParting: true,
  showBaseline: true,
  valueSuffix: '%',
  dragStep: 1,
};
