import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { TimezoneOptions } from './FormCommon';

export const TimezoneSelect = ({
                          defaultTimezone = 'America/Los_Angeles',
                          selectedTimezone,
                          onSelectTimezone,
                          alignRight = true,
}) => (
  <UncontrolledDropdown>
    <DropdownToggle tag="a" className="timezone-toggle p-0 mt-1 link" caret size="sm" color="transparent">
      <TimezoneDisplay timezone={selectedTimezone || defaultTimezone} />
    </DropdownToggle>
    <DropdownMenu end={alignRight}>
      {TimezoneOptions.map((zone, i) => (
        <DropdownItem
          value={zone.value}
          key={i}
          onClick={() => onSelectTimezone(zone.value)}
        >
          {zone.name}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);

TimezoneSelect.propTypes = {
  defaultTimezone: PropTypes.string,
  onSelectTimezone: PropTypes.func.isRequired,
  selectedTimezone: PropTypes.string.isRequired,
  alignRight: PropTypes.bool,
};

export const TimezoneDisplay = ({ timezone }) => (
  timezone ? (<span className="tz-display">
    {TimezoneOptions.find((opt) => opt.value === timezone)?.name}
  </span>) : null
);

TimezoneDisplay.propTypes = {
  timezone: PropTypes.string,
};


