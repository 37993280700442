import { useState } from 'react';

/**
 * Hook used by components that handle an array of objects where items can be deleted or added,
 * or change state between display and edit mode, or delete prompt mode.
 *
 * Note: this only tracks what type of work is being done on what index. It does not
 * modify the collection.
 *
 * Use like:
 *   const{
 *   api:collection,
 *   willDeleteIndex,
 *   editIndex,
 *   addMode} = useCollectionTracker();
 *
 *   collection.apply(), etc
 */
const useCollectionTracker = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  // willDelete: if not null, show prompt for this item
  const [willDeleteIndex, setWillDeleteIndex] = useState(null);
  // addMode generally controls whether the add button is shown (hide if addMode)
  // (usually we only add one at a time)
  const [addMode, setAddMode] = useState(false);

  // Done editing
  const apply = () => {
    setEditIndex(null);
    setActiveIndex(null);
    setAddMode(false);
  };

  // switch to edit mode
  const edit = (index) => {
    setEditIndex(index);
    setActiveIndex(index);
    setWillDeleteIndex(null);
    setAddMode(false);
  };

  const add = (newIndex) => {
    setActiveIndex(newIndex);
    setWillDeleteIndex(null);
    setEditIndex(newIndex);
    setAddMode(true);
  };

  const onDelete = () => {
    setWillDeleteIndex(null);
    setAddMode(false);
    setEditIndex(null);
  };

  return {
    api: {
      apply,
      edit,
      add,
      onDelete,
      setWillDeleteIndex,
    },
    willDeleteIndex,
    editIndex,
    activeIndex,
    addMode,
  };
};

export default useCollectionTracker;
