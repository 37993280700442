import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  sub, startOfDay, endOfDay, startOfISOWeek, endOfISOWeek, startOfMonth, endOfMonth,
} from 'date-fns';

const now = new Date();
//
// const defaultReportsOLD = {
//   yesterday_hour:{
//     startDate:moment().subtract(1, "day").startOf('day'),
//     endDate:moment().subtract(1, "day").endOf('day'),
//     groupBy:'time_hour'
//   },
//   yesterday_account:{
//     startDate:moment().subtract(1, "day").startOf('day'),
//     endDate:moment().subtract(1, "day").endOf('day'),
//     groupBy:'account'
//   },
//   yesterday_vendor:{
//     startDate:moment().subtract(1, "day").startOf('day'),
//     endDate:moment().subtract(1, "day").endOf('day'),
//     groupBy:'vendor'
//   },
//   today_yesterday_account:{
//     startDate:moment().subtract(1, 'days').startOf('day'),
//     endDate:moment().endOf('day'),
//     pivot:'time_day',
//     groupBy:'account'
//   },
//   today_yesterday_vendor:{
//     startDate:moment().subtract(1, 'days').startOf('day'),
//     endDate:moment().endOf('day'),
//     pivot:'time_day',
//     groupBy:'vendor'
//   },
//   this_week_account:{
//     startDate:moment().startOf('isoWeek'),
//     endDate:moment().endOf('isoWeek'),
//     groupBy:'account'
//   },
//   this_week_vendor:{
//     startDate:moment().startOf('isoWeek'),
//     endDate:moment().endOf('isoWeek'),
//     groupBy:'vendor'
//   },
//   this_week_days:{
//     startDate:moment().startOf('isoWeek'),
//     endDate:moment().endOf('isoWeek'),
//     groupBy:'time_day'
//   },
//   this_week_last_week_day:{
//     startDate:moment().subtract(1, 'weeks').startOf('isoWeek'),
//     endDate:moment().endOf('isoWeek'),
//     pivot:'time_week',
//     groupBy:'time_day'
//   },
//   this_week_last_week_account:{
//     startDate:moment().subtract(1, 'weeks').startOf('isoWeek'),
//     endDate:moment().endOf('isoWeek'),
//     pivot:'time_week',
//     groupBy:'account'
//   },
//   this_week_last_week_vendor:{
//     startDate:moment().subtract(1, 'weeks').startOf('isoWeek'),
//     endDate:moment().endOf('isoWeek'),
//     pivot:'time_week',
//     groupBy:'vendor'
//   },
//   this_month_day:{
//     startDate:moment().subtract(1, "months").startOf('month'),
//     endDate:moment().subtract(1, "months").endOf('month'),
//     groupBy:'time_day'
//   },
//   this_month_account:{
//     startDate:moment().subtract(1, "months").startOf('month'),
//     endDate:moment().endOf('month'),
//     groupBy:'account'
//   },
//   this_month_vendor:{
//     startDate:moment().subtract(1, "months").startOf('month'),
//     endDate:moment().endOf('month'),
//     groupBy:'vendor'
//   },
// };

// console.log('DefaultReportLinks.js:sub', sub(now, {days:1}));
// console.log('DefaultReportLinks.js:test', startOfDay(sub(now, {days:1})), endOfDay(sub(now, {days:1})) );

const defaultReports = {
  yesterday_hour: {
    startDate: startOfDay(sub(now, { days: 1 })),
    endDate: endOfDay(sub(now, { days: 1 })),
    groupBy: 'time_hour',
  },
  yesterday_account: {
    startDate: startOfDay(sub(now, { days: 1 })),
    endDate: endOfDay(sub(now, { days: 1 })),
    groupBy: 'account',
  },
  yesterday_vendor: {
    startDate: startOfDay(sub(now, { days: 1 })),
    endDate: endOfDay(sub(now, { days: 1 })),
    groupBy: 'vendor',
  },
  today_yesterday_account: {
    startDate: startOfDay(sub(now, { days: 1 })),
    endDate: endOfDay(now),
    pivot: 'time_day',
    groupBy: 'account',
  },
  today_yesterday_vendor: {
    startDate: startOfDay(sub(now, { days: 1 })),
    endDate: endOfDay(now),
    pivot: 'time_day',
    groupBy: 'vendor',
  },
  this_week_account: {
    startDate: startOfISOWeek(now),
    endDate: endOfISOWeek(now),
    groupBy: 'account',
  },
  this_week_vendor: {
    startDate: startOfISOWeek(now),
    endDate: endOfISOWeek(now),
    groupBy: 'vendor',
  },
  this_week_days: {
    startDate: startOfISOWeek(now),
    endDate: endOfISOWeek(now),
    groupBy: 'time_day',
  },
  this_week_last_week_day: {
    startDate: startOfISOWeek(sub(now, { weeks: 1 })),
    endDate: endOfISOWeek(now),
    pivot: 'time_week',
    groupBy: 'time_day',
  },
  this_week_last_week_account: {
    startDate: startOfISOWeek(sub(now, { weeks: 1 })),
    endDate: endOfISOWeek(now),
    pivot: 'time_week',
    groupBy: 'account',
  },
  this_week_last_week_vendor: {
    startDate: startOfISOWeek(sub(now, { weeks: 1 })),
    endDate: endOfISOWeek(now),
    pivot: 'time_week',
    groupBy: 'vendor',
  },
  this_month_day: {
    startDate: startOfMonth(sub(now, { months: 1 })),
    endDate: endOfMonth(sub(now, { months: 1 })),
    groupBy: 'time_day',
  },
  this_month_account: {
    startDate: startOfMonth(sub(now, { months: 1 })),
    endDate: endOfMonth(now),
    groupBy: 'account',
  },
  this_month_vendor: {
    startDate: startOfMonth(sub(now, { months: 1 })),
    endDate: endOfMonth(now),
    groupBy: 'vendor',
  },
};

// Set of buttons that produce simple canned reports,
// Displayed in table area when reports page first loads and no
// user-selected report has been generated.
// Basically, a space-filler. When we implement Saved Reports,
// we will probably display those instead.
const DefaultReportLinks = (props) => {
  const executeReport = (report) => {
    if (defaultReports[report]) {
      props.loadReport(defaultReports[report]);
    }
  };
  return (
    <div id="default-report-links" className="ms-3 mt-3">
      <Row>
        <Col sm={12} md={5}>
          <div className="default-report-row d-flex">
            <div className="report-name">Yesterday:</div>
            <div>
              <Button color="link" onClick={() => executeReport('yesterday_hour')}>...by hour</Button>
              {' '}
            </div>
            <div>
              {props.reportType === 'advertiser' && <Button color="link" onClick={() => executeReport('yesterday_account')}>...by account</Button>}
              {props.reportType === 'vendor' && <Button color="link" onClick={() => executeReport('yesterday_vendor')}>...by vendor</Button>}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={5}>
          <div className="default-report-row d-flex">
            <div className="report-name">Today vs Yesterday:</div>
            <div>
              {props.reportType === 'advertiser' && <Button color="link" onClick={() => executeReport('today_yesterday_account')}>...by account</Button>}
              {props.reportType === 'vendor' && <Button color="link" onClick={() => executeReport('today_yesterday_vendor')}>...by vendor</Button>}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={5}>
          <div className="default-report-row d-flex">
            <div className="report-name">This Week:</div>
            <div>
              <Button color="link" onClick={() => executeReport('this_week_days')}>...by day</Button>
              {' '}
            </div>
            <div>
              {props.reportType === 'advertiser' && <Button color="link" onClick={() => executeReport('this_week_account')}>...by account</Button>}
              {props.reportType === 'vendor' && <Button color="link" onClick={() => executeReport('this_week_vendor')}>...by vendor</Button>}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={5}>
          <div className="default-report-row d-flex">
            <div className="report-name">This Week vs Last Week:</div>
            <div>
              <Button color="link" onClick={() => executeReport('this_week_last_week_day')}>...by day</Button>
              {' '}
            </div>
            <div>
              {props.reportType === 'advertiser' && <Button color="link" onClick={() => executeReport('this_week_last_week_account')}>...by account</Button>}
              {props.reportType === 'vendor' && <Button color="link" onClick={() => executeReport('this_week_last_week_vendor')}>...by vendor</Button>}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={5}>
          <div className="default-report-row d-flex">
            <div className="report-name">This Month</div>
            <div>
              <Button color="link" onClick={() => executeReport('this_month_day')}>...by day</Button>
              {' '}
            </div>
            <div>
              {props.reportType === 'advertiser' && <Button color="link" onClick={() => executeReport('this_month_account')}>...by account</Button>}
              {props.reportType === 'vendor' && <Button color="link" onClick={() => executeReport('this_month_vendor')}>...by vendor</Button>}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

DefaultReportLinks.propTypes = {
  loadReport: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired, // vendor or advertiser
};

export default DefaultReportLinks;
