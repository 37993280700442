import React, { useState, useEffect, useMemo } from 'react';
import {
  Input, InputGroup, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { isObject } from '../../utils/types';
import {JsonEditorLoadable} from "@thedmsgroup/mastodon-ui-components";

/* Row for custom key/value or key/object input */
const KeyValueRow = ({
  attribute = '', value = '', onChangeAttribute, onChangeValue, allowObjects= false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [valueType, setValueType] = useState(() => (allowObjects && (isObject(value) || Array.isArray(value)) ? 'object' : 'text'));
  const [currentValue, setCurrentValue] = useState(() => {
    if (isObject(value)) {
      return JSON.stringify(value, null, 2);
    } if (Array.isArray(value)) {
      return '{}';
    }
    return value;
  });

  useEffect(() => {
    if (valueType === 'object') {
      try {
        const parsed = JSON.parse(currentValue);
        if (parsed) {
          onChangeValue(parsed);
        }
      } catch (e) {

      }
    }
  }, [currentValue]);

  const handleChangeType = (type) => {
    if (type !== valueType) {
      setValueType(type);
      setCurrentValue('');
      onChangeValue('');
    }
  };

  const toggleDropDown = () => setIsOpen(!isOpen);


  const onChangeCode = (editorCode, isValid) => {
    if (editorCode !== currentValue && isValid) {
      setCurrentValue(editorCode);
    }
  };

  return (
    <div className="key-value-row flex-fill d-flex justify-content-between">
      <div className="key flex-grow-1">
        <Input
          type="text"
          value={attribute}
          placeholder="key"
          onChange={(e) => onChangeAttribute(e.target.value)}
        />
      </div>

      <div className="key-value-equals ms-2 me-2"><b>=</b></div>

      <div className="value">

        <InputGroup>

          {allowObjects && (
            <ButtonDropdown isOpen={isOpen} toggle={toggleDropDown}>
              <DropdownToggle caret>
                <FaIcon icon={valueType === 'text' ? faFont : 'list'} size="sm" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleChangeType('text')}>
                  <FaIcon icon={faFont} size="sm" />
                  {' '}
                  Text/Number
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeType('object')}>
                  <FaIcon icon="list" size="sm" />
                  {' '}
                  Object
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          )}


          {valueType === 'text'
            ? (
              <Input
                type="text"
                value={value}
                placeholder="value"
                onChange={(e) => onChangeValue(e.target.value)}
              />
            )
            : (
              <Input
                type="text"
                defaultValue=""
                placeholder="Enter valid JSON below"
                disabled
                key="disabled-object-input"
              />
            )}

        </InputGroup>

        {valueType === 'object' && (
          <div className="object-value flex-fill">
            <JsonEditorLoadable
              code={currentValue}
              onChange={onChangeCode}
            />
          </div>

        )}

      </div>

    </div>
  );
};

KeyValueRow.propTypes = {
  attribute: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  onChangeAttribute: PropTypes.func,
  onChangeValue: PropTypes.func,
  allowObjects: PropTypes.bool,
};

export default KeyValueRow;
