import React, {useState} from 'react';
import {
  Modal as BootstrapModal, ModalBody, ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Auction from './index';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";
import qs from 'query-string';

/*
 * Modal for auction detail view, wraps Auction Detail
 */
const Modal = ({ auctionId, isOpen, cancel, timezone='UTC', defaultTab }) => {
  //Permalink may be updated with some query string parameters based on selections within the auction detail, eg delivery ID
  const permalinkBase = `${location.protocol}//${location.host}/#/reports/auction/${auctionId}`;
  const [permalink, setPermalink] = useState(permalinkBase)

  const handleChangeFilters = (filters) => {
    setPermalink(permalinkBase + (filters ? '?' + qs.stringify(filters) : ''));
    // qs gets confused about our hash
    //setPermalink(qs.stringifyUrl({url:permalinkBase, query:filters}));
  }

  return (
    <BootstrapModal isOpen={isOpen} toggle={cancel} size="xlg" className="auction-detail" unmountOnClose={true}>
      <ModalHeader toggle={cancel}>
        Auction Detail: {auctionId}
      </ModalHeader>


      <div className="ms-3">
        <Link target="_blank" to={permalink} className="link auction-detail-link p-0">
          <FaIcon icon="external-link-alt" size="sm" />
          {' '}
          Permalink
        </Link>

        <CopyButton
          id="copy-permalink"
          textToCopy={permalink}
          buttonSize="sm"
        >
          <FaIcon icon="copy" size="sm" />
        </CopyButton>
      </div>

      <ModalBody>

        <Auction
          auction_id={auctionId}
          timezone={timezone}
          defaultTab={defaultTab}
          onChangeFilters={handleChangeFilters}
          useQueryString={false}
        />

      </ModalBody>
    </BootstrapModal>
  );
};

Modal.propTypes = {
  auctionId: PropTypes.string,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  cancel: PropTypes.func,
  timezone: PropTypes.string,
  defaultTab: PropTypes.string,
};

Modal.defaultProps = {

};

export default Modal;
