import React, { useState, useEffect } from 'react';
import {
  Button, FormText, Input, Label, Row, Col
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import AdDisplay from '../../../AdManager/AdDisplay';
import classnames from "classnames";
import usePermission from "../../../../Hooks/usePermission";

/*
 * Component for selecting ads to be used in a given rule.
 * Using the row select hook in react-table-7 is somewhat awkward as there
 * are no built-in callbacks on select.
 * For the reset functionality we could theoretically use the
 * useControlledState, but I wasn't able to make this work.
 * Instead we use a refresh key to re-render the table with its initial values.
 */
const Ads = ({
  ruleAds, accountAds, onChangeAds, overrideParent, isRootRule, accountId,
}) => {

  const [selectedAds, setSelectedAds] = useState(ruleAds);
  const [originalRuleAds] = useState(ruleAds);
  const allowEditOrder = usePermission('orders.edit');

  useEffect(() => {
    onChangeAds('ads', selectedAds);
  }, [selectedAds]);

  const handleSelectAd = (adId) => {
    setSelectedAds((prev) => {
      if (prev.includes(adId)) {
        prev.splice(prev.indexOf(adId), 1);
      } else {
        prev.push(adId);
      }
      return [...prev];
    });
  };
  const handleRemoveAd = (adId) => {
    setSelectedAds((prev) => {
        prev.splice(prev.indexOf(adId), 1);

      return [...prev];
    });
  };

  const handleSelectAll = () => {
    setSelectedAds(() => {
      const ads = [];
      if (selectedAds.length !== accountAds.length) {
        // not in an all-selected state
        accountAds.map((ad) => ads.push(ad.id));
      }
      return ads;
    });
  };

  const handleReset = () => setSelectedAds([...originalRuleAds]);

  return (
    <div className="rule-ad-form">

      {accountAds.length === 0 && (
      <StandardAlert color="danger">
        The account has no ads. This will block all bidding.
      </StandardAlert>
      )}


      <div className="form-section">
        <div className="form-section-header">Rule Ads {ruleAds.length >=1 && <span>({ruleAds.length})</span>}</div>


        {ruleAds.length > 0 ? (
          <>
            <p>These ads{!isRootRule && !overrideParent && <span>, and the ads from parent rules, </span>}
              {' '}will be used for this rule and all child rules, unless overridden.</p>
            {/* For all but account rule, allow override choice */}
            {!isRootRule  && (
              <div className="ad-override mb-3 d-flex">
                <div className="me-1">
                  <Input
                    name="override_parent_ads"
                    type="checkbox"
                    defaultChecked={!!overrideParent}
                    onChange={onChangeAds}
                    disabled={!allowEditOrder}
                  />
                </div>
                <div className="flex-fill">
                  <Label>
                    Override Parent Ads
                  </Label>
                  <FormText color="muted"> Use only the selected ads. Do not use ads defined in parent rules.</FormText>
                </div>
              </div>

            )}

            <div className="rule-ad-list ms-2">
              {accountAds.filter((ad) => ruleAds.includes(ad.id)).map((ad, i) => (
                <Row key={i} className="ad-row">
                  <Col sm={12} md={9} lg={10} className="ad-col">

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="ad-name">{ad.name}</div>
                      {allowEditOrder && (
                        <Button size="sm" color="link" className="inline" onClick={() => handleRemoveAd(ad.id)}>
                          <FaIcon icon="times" size="sm" /> Remove
                        </Button>
                      )}

                    </div>

                    <AdDisplay {...ad} />
                  </Col>

                </Row>

              ))}
            </div>
          </>



        ) : (
          <span className="me-2">
             <span>No ads are selected for this rule.</span>
          </span>
        )}

      </div>


        {accountAds && (

          <div className="form-section mt-2">
            <div className="form-section-header">Account Ads </div>
            <p>Select from the ads below to apply an ad to this rule</p>
            <div className="rule-ad-header mb-1">
              {allowEditOrder && (
                <>
                  <Button color="link" className="me-2" onClick={handleSelectAll}>Select All</Button>
                  <Button color="link" className="me-2" onClick={handleReset}>Reset</Button>
                </>
              )}

              <Button
                tag={Link}
                color="link"
                to={{
                  pathname: `/accounts/${accountId}/ads`,
                }}
                title="Account Ad Library"
              >
                Account Ad Library
              </Button>
            </div>

            <div className="rule-ad-list ms-2">
              {accountAds.map((ad, i) => {
                let isSelected = selectedAds.includes(ad.id)
                return (

                  <Row key={i} className="ad-row">
                    <Col sm={12} md={9} lg={10} className={classnames("ad-col", {selected:isSelected})}>

                      <div className="d-flex justify-content-between align-items-center">
                        <div className="ad-name">{ad.name}</div>

                        { isSelected ? (
                          <div>
                            <FaIcon icon="check-circle" size="sm"/> Selected
                          </div>
                        ) : (
                          <>
                            {allowEditOrder && (
                              <Button size="sm" color="link" className="inline" onClick={() => handleSelectAd(ad.id)}>
                                <FaIcon icon="plus" size="sm"/> Select
                              </Button>
                            )}
                          </>

                        )}


                      </div>

                      <AdDisplay {...ad} />
                    </Col>

                  </Row>
                )
              })}
            </div>
          </div>

        )}

      </div>



  );
};

const AdSelectIcon = React.memo(({ checked = false, onChange }) => (
  <FaIcon
    icon="check-circle"
    size="2x"
    style={{ margin: '0px 6px', color: checked ? '#009900' : '#e1e1e1', cursor: 'pointer' }}
    onClick={onChange}
  />
));
const AdRemoveIcon = React.memo(({  onChange }) => (
  <FaIcon
    icon="times"
    size="2x"
    style={{ margin: '0px 6px', color: '#990000', cursor: 'pointer' }}
    onClick={onChange}
  />
));

const SelectAllIcon = ({ checked = false, onChange }) => (
  <Button onClick={onChange} className="me-2" color="link">
    <FaIcon
      icon="check-circle"
      size="1x"
      style={{ margin: '0px 6px', color: checked ? '#009900' : '#e1e1e1', cursor: 'pointer' }}
    />
    Select All
  </Button>

);

Ads.propTypes = {
  ruleAds: PropTypes.array.isRequired,
  accountAds: PropTypes.array.isRequired,
  onChangeAds: PropTypes.func.isRequired,
  overrideParent: PropTypes.bool,
  isRootRule: PropTypes.bool,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Ads.defaultProps = {
  overrideParent: false,
  isRootRule: false,
};

export default Ads;
