import qs from 'query-string';
import { parse, format } from 'date-fns';

const startDateParams = ['from', 'after', 'start', 'startDate', 'purchased_after', 'received_after', 'since'];
const endDateParams = ['to', 'before', 'end', 'endDate', 'purchased_before', 'received_before'];
const defaultParseOptions = { parseNumbers: true, forceEndOfDay: false };

export function queryStringToFilters(queryString, dateFormat = 'yyyy-MM-dd', parseOptions={}) {
  const params = qs.parse(queryString, {...defaultParseOptions, ...parseOptions} );
  const refDate = new Date();
  const startParam = Object.keys(params).find((p) => startDateParams.includes(p));
  const endParam = Object.keys(params).find((p) => endDateParams.includes(p));

  //Transform to date. In some places we use relative dates like "now-1h"; pass these along as is.
  if (startParam && params[startParam]) {
    const dt = parse(params[startParam], dateFormat, refDate);
    if (dt && !isNaN(dt)) {
      params[startParam] = dt;
    }
  }


  if (endParam && params[endParam]) {
    const dt = parse(params[endParam], dateFormat, refDate);
    if (dt && !isNaN(dt) ) {
      params[endParam] = dt;
    }

  }

  // csv fields list to array
  if (params.fields) {
    params.fields = params.fields.split(',');
  }

  return params;
}

export function filtersToQueryString(filters, dateFormat = 'yyyy-MM-dd') {
  const qsData = { ...filters };

  // Format dates. Usually filter components provide data as data objects (fns requires this)
  // But Auctions list uses relative phrases like 'now-1h' which go into the query string as such
  const startParam = Object.keys(qsData).find((p) => startDateParams.includes(p));
  const endParam = Object.keys(qsData).find((p) => endDateParams.includes(p));

  if (startParam && qsData[startParam] && typeof qsData[startParam] === 'object') {
    try {
      qsData[startParam] = format(qsData[startParam], dateFormat);
    } catch (e) {
    }

  }

  if (endParam && qsData[endParam] && typeof qsData[endParam] === 'object') {
    try {
      qsData[endParam] = format(qsData[endParam], dateFormat);
    } catch (e) {
    }
  }

  // remove empties
  Object.keys(qsData).forEach((k) => !qsData[k] && delete qsData[k]);

  // array of fields becomes csv
  if (qsData.fields) {
    qsData.fields = qsData.fields.join(',');
  }

  return `?${qs.stringify(qsData)}`;
}
