import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { TreeContext } from './TreeProvider';

/*
 * Component for one branch in the rule tree. Recurses to itself for each sub-branch of the tree
 */
const TreeBranch = ({
  rule,
  level,
  expandToLevel,
  selectedRule,
  ancestors,
  ruleComponent: RuleComponent, // Could be advertiser rule or routing rule component
  isDisabled = false,
}) => {
  const treeContext = useContext(TreeContext);
  // Override expanded to make sure branch with selected rule is open.
  // For example, you add a new rule to a closed branch, the branch should open automatically.
  // Or, you can link directly to a rule with a rule id in the query string
  const isSelectedRuleBranch = selectedRule && (selectedRule.parent_id === rule.id);
  const isAncestorBranch = ancestors.some(((ancId) => ancId === rule.parent_id));
  const [expanded, setExpanded] = useState(level < expandToLevel || isSelectedRuleBranch || isAncestorBranch);

  const toggleBranch = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  // Open if selected
  useEffect(() => {
    if (isSelectedRuleBranch || isAncestorBranch || treeContext.currentBidEdit === rule.id || treeContext.search) {
      setExpanded(true);
    }
  }, [selectedRule, treeContext.search]);

  // Open if in edit mode
  useEffect(() => {
    if (treeContext.currentBidEdit === rule.id) {
      setExpanded(true);
    }
  }, [treeContext.currentBidEdit]);

  return (
    <div className="leaf">

      {/* TODO: Show only if descendants have search */}
      {rule.hide !== true
            && (
            <div className={classnames("rule-container d-flex", {root:level === 0})}>
                {/* ORIGINAL around icon-outer {level > 0 && (rule.rules.length > 0 || rule.hasChildren === true) && ( */}
                  <div className="tree-bracket" />
                  <div onClick={toggleBranch} className={classnames("icon-outer", {disabled:rule.rules.length === 0})}
                  >

                    <FaIcon
                      size="sm"
                      icon={['far', rule.rules.length > 0 ? (expanded ? 'minus-square' : 'plus-square') : 'square']}
                      className="icon-folder"
                    />

                  </div>




                <RuleComponent
                  rule={rule}
                  level={level}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  parentDisabled={isDisabled}
                />
            </div>
            )}

      {/* Map the rule's children. Render each sub-rule as a tree */}
      {(rule.rules.length > 0 || rule.hasChildren === true) && rule.hide !== true && (

        <div className={classnames('rule-branch', {
          expanded, closed: !expanded, disabled: rule.disabled, root: level === 0,
        })}
        >



              {/* Do we need a message if parent is disabled? {rule.disabled && <small><i>all disabled because parent!</i></small>} */}
              { rule.rules.map((child, i) => (
                <TreeBranch
                  key={i}
                  rule={child}
                  level={level + 1}
                  expandToLevel={expandToLevel}
                  selectedRule={selectedRule}
                  ancestors={ancestors}
                  ruleComponent={RuleComponent}
                  isDisabled={rule.disabled}
                />
              ))}


          </div>

      )}
      {rule.filteredCount > 0 && (
        <div className="rule filtered-count">
          <FaIcon icon="filter" size="1x" className="icon-rule" />
          {' '}
          <small>
            <i>
              (
              {rule.filteredCount}
              {' '}
              filtered)
            </i>
          </small>
        </div>
      )}
    </div>
  );
};

TreeBranch.propTypes = {
  rule: PropTypes.object.isRequired,
  ruleComponent: PropTypes.func.isRequired,
  selectedRule: PropTypes.object,
  ancestors: PropTypes.array,
  level: PropTypes.number,
  expandToLevel: PropTypes.number,
  isDisabled: PropTypes.bool,
};

TreeBranch.defaultProps = {
  level: 0,
  expandToLevel: 0,
  selectedRule: null,
  ancestors: [],

};

export default TreeBranch;
