import React from 'react';
import { Routes, Route } from 'react-router';
import EntryLayout from "../Layout/EntryLayout";
import AuthLayout from "../Layout/AuthLayout";
import MainContent from '../Layout/MainContent';
import './App.scss';

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ActivateUser from "./ActivateUser";
import PublisherPostDocs from "./Public/PublisherPostDocs";


const App = () =>  (
      <Routes>
          <Route element={<EntryLayout/>}>
            <Route path="/login" element={<Login/>} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/reset-password/:resetToken" element={<ResetPassword/>} />
            <Route path="/activate/:activationToken" element={<ActivateUser/>} />
            <Route path="/publishers/:sourceToken" element={<PublisherPostDocs/>} />
          </Route>
          <Route  element={<AuthLayout/>} >
            <Route path="/*" element={<MainContent/>} />
          </Route>
      </Routes>
  )


export default App;
