import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/*
 * Uses content-loader component for animated svg dots.
 */
const LoaderDots = ({active, inline, width}) => (
  <div className={classnames(['svg-loader-container m-auto', { active: active.toString(), inline: inline.toString() }])}>
    <div className="svg-loader d-inline" style={{ width: `${width.toString()}px`, left: `calc(50% - ${width.toString() / 2}px)` }}>
      {active && (
        <ContentLoader
          viewBox="0 0 110 40"
          speed={1.2}
          backgroundColor="transparent"
          backgroundOpacity="1"
          style={{ width: `${width.toString()}px` }}
          active={Boolean(active)}
          inline={Boolean(inline)}
          width={width.toString()}
        >
          <circle cx="10" cy="20" r="8" />
          <circle cx="54" cy="20" r="8" />
          <circle cx="98" cy="20" r="8" />
        </ContentLoader>
      )}
    </div>
  </div>
);

LoaderDots.metadata = {
  name: 'RioF',
  github: 'clariokids',
  description: 'Three Dots',
  filename: 'ThreeDots',
};

LoaderDots.defaultProps = {
  active: false,
  inline: false,
  width: 100,
};

LoaderDots.propTypes = {
  active: PropTypes.bool,
  inline: PropTypes.bool,
  width: PropTypes.number,
};

export default LoaderDots;
