import React from "react";
import {Modal, ModalBody, ModalHeader, } from "reactstrap";
// @ts-ignore
import JSONPretty from 'react-json-prettify';
// @ts-ignore
import { idea } from 'react-json-prettify/dist/themes';


/*
 * Modal for displaying a json value, prettified
 */
const JsonViewModal = ({
                                json, title, size="md", isOpen, close
                              }:any) => {

  return (
    <Modal isOpen={isOpen} toggle={close} size={size} scrollable>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody>
        <JSONPretty json={json} theme={idea} />

      </ModalBody>
    </Modal>
  );
};

export default JsonViewModal;
