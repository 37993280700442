
/*
 * An entity that is a composite of a channel-source relationship.
 * This is useful for the routing rule management, where an entity
 * with certain properties is expected
 */
class ChannelSourceRoute {
  constructor (channel, channelSource) {

    this.name = `Source "${channelSource.name}" to Channel "${channel.name}"`;
    this.channel = channel;
    this.source = {
      id: channelSource.id,
      name: channelSource.name,
      status: channelSource.status,
    }

    this.product = channel.product;
    this.vertical = channel.vertical;

    this.status = channelSource.eligibility.status;
    this.rule_id = channelSource.eligibility.rule_id
    this.distribution = channelSource.eligibility.distribution;
    this.fixed_distribution = channelSource.eligibility.fixed_distribution;
    this.last_matched_at = channelSource.eligibility.last_matched_at;
    this.last_sold_at = channelSource.eligibility.last_sold_at;

  }
}

export default ChannelSourceRoute
