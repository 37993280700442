import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  TabContent, TabPane, Nav, FormGroup, FormText, Input, Label, Card, Button, Row, Col, Spinner, InputGroup,
} from 'reactstrap';
import PageSection from '../../../Layout/PageSection';
import DocTitle from '../../../Layout/DocTitle';
import AdDisplay from '../../AdManager/AdDisplay';
import {JsonEditorLoadable} from "@thedmsgroup/mastodon-ui-components";
import useQueryState from "../../../Hooks/useQueryState";
import Select from "../../../Components/Form/SimpleSelect";
import './MatchChecker.scss';
import { FormTabFlat } from '../../../Components/Form/FormCommon';
import AuctionModal from '../../Auctions/Details/Modal';
import useApi from '../../../Hooks/useApi';
import { ProductChoices, StateChoices } from '../../../Components/Table/FilterChoices';
import {payloads, stateZips, userAgents} from './samples';
import { AppContext } from '../../../Providers/AppProvider';
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {SourceOptionLabel} from "../../../Components/Form/SourceOptionLabel";

const RequestForm = (props) => {
  const [payload, setPayload] = useState(
    typeof props.payload === 'object' ? JSON.stringify(props.payload, null, 2) : (props.payload ?? "")
  );
  const jsonEditorRef = useRef();

  const onSubmit = () => props.onSubmit && props.onSubmit(payload);

  const onSelectSample = (e) => {
    const sample = payloads.find((p) => p.label === e.target.value)?.payload;

    if (sample) {
      //setPayload(JSON.stringify(Object.assign({}, payload, sample), null, 2))
      const objPayload = JSON.parse(payload);
      setPayload(JSON.stringify({...objPayload, ...sample}, null, 2))
    } else {
      setPayload("{}")
    }
  };

  const onJsonChange = (val) => {
    setPayload(val);
  };


  const handleSourceSelection = source => {

    setPayload(JSON.stringify(
      Object.assign({}, JSON.parse(payload), {source_token: source?.token}),
      null,
      2
      )
    )
  }


  const handleStateSelection = e => {
    const zipcode = stateZips[e.target.value]
    setPayload(
      JSON.stringify(
        mergeData(JSON.parse(payload), {zipcode}),
        null,
        2
      )
    );

  }

  const handleDeviceSelection = e => {
    const ua = userAgents[e.target.value]
    setPayload(
      JSON.stringify(
        mergeData(JSON.parse(payload), {user_agent: ua}),
        null,
        2
      )
    )
  }

  return (
    <div>

      <SourceSelector onSelect={handleSourceSelection}  />

      <div className="d-flex">
        <FormGroup className="flex-fill me-1">
          <Label>Lead Preset</Label>
          <Input type="select" onChange={onSelectSample} defaultValue="Minimal">
            <option value="">--</option>
            {payloads.map((sample) => <option key={sample.label} value={sample.label}>{sample.label}</option>)}
          </Input>
        </FormGroup>
        <FormGroup className="flex-fill me-1">
          <Label>State</Label>
          <Input type="select" onChange={handleStateSelection}>
            <option value="">--</option>
            {StateChoices.map((state) => <option key={state.label} value={state.value}>{state.label}</option>)}
          </Input>
        </FormGroup>
        <FormGroup className="flex-fill">
          <Label>Device Type</Label>
          <Input type="select" onChange={handleDeviceSelection}>
            <option value="">--</option>
            {Object.keys(userAgents).map(deviceType => <option key={deviceType} value={deviceType}>{deviceType}</option>)}
          </Input>
        </FormGroup>
      </div>

      <div style={{ height: '100%' }}>
        <JsonEditorLoadable
          mode="code"
          ref={jsonEditorRef}
          code={payload}
          onChange={onJsonChange}
          htmlElementProps={{
            style: {
              minHeight: '500px',
            },
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <SubmitButton
          className="mt-3"
          submitting={props.submitting}
          color="primary"
          onClick={onSubmit}
        >
          Submit
        </SubmitButton>
      </div>

    </div>
  );
};

const mergeData = (original, newData) => {
  const origData = original.data ?? {}
  return {...original, data: {...origData, ...newData}}
}

/* Source picker and a collection of filters that limit source options */
const SourceSelector = ({onSelect}) => {

  const { api } = useApi();
  const [loading, setLoading] = useState(false)
  const [sources, setSources] = useState([])
  const [vendors, setVendors] = useState([])
  const [verticals, setVerticals] = useState([])
  const [filters, setFilters] = useState({})
  const [selectedSource, setSelectedSource] = useState(null)

  const onSelectProduct = product => setFilters({...filters, product})
  const onSelectVertical = vertical_id => setFilters({...filters, vertical:vertical_id})
  const onSelectVendor = vendor_id => setFilters({ ...filters, vendor:vendor_id})

  const loadOptions = async () => {
    loadSources(filters)

    const vendors = await api.endpoints.vendors.list()
    if (vendors) {
      setVendors(vendors.map(v=>({value:v.id, label:v.name})))
    }
    const verticals = await api.endpoints.verticals.list()
    if (verticals) {
      setVerticals(verticals.map(v=>({value:v.id, label:v.display_name})))
    }
    // else notify error
  }

  const loadSources = async (filters) => {
    setLoading(true)

    const sources = await api.endpoints.sources.list({...filters, limit: 1000})
    if (sources?.data) {
      //setSources(sources.data)

      setSources(sources.data.map((s) => {
        return {id:s.id, value:s.id, label:s.name, status:s.status, token:s.token};
      }))
    }

    setLoading(false)
    // if no sources, set selected source to nothing
  }

  const handleSelectSource = (sourceId) => {
    setSelectedSource(sourceId)
    const source = sources.find(s=>s.id.toString() === sourceId.toString())
    if (source && onSelect) {

      onSelect(source);
    }
  }

  useEffect(() => {
    loadSources(filters)
  }, [filters])

  useEffect(() => {
    loadOptions()
  }, [])

  return <div>
    <Row >
      <Col sm={12} md={5}>
        <FormGroup className="flex-fill me-1">
          <Label>Vendor</Label>

          <Select
            isClearable
            isSearchable
            onChange={onSelectVendor}
            options={vendors}
            value={filters.vendor}
            tabSelectsValue
            backspaceRemovesValue
            isMulti={false}
            controlShouldRenderValue
            hideSelectedOptions
            closeMenuOnSelect
            placeholder="Select Vendor"
            invalid={false}
          />

        </FormGroup>
      </Col>

      <Col sm={12} md={4}>
        <FormGroup className="flex-fill me-1">
          <Label>Vertical</Label>

          <Select
            isClearable
            isSearchable
            onChange={onSelectVertical}
            options={verticals}
            value={filters.vertical}
            tabSelectsValue
            backspaceRemovesValue
            isMulti={false}
            controlShouldRenderValue
            hideSelectedOptions
            closeMenuOnSelect
            placeholder="Select Vertical"
            invalid={false}
          />
        </FormGroup>
      </Col>


      <Col sm={12} md={3}>
        <FormGroup className="flex-fill">
          <Label>Product</Label>
          <InputGroup>
            <Input
              type="select"
              onChange={e => onSelectProduct(e.target.value)}
              value={filters.product}
            >
              <option value="">Select Product</option>
              {ProductChoices.map((obj, i) => <option value={obj.value} key={i}>{obj.label}</option>)}
            </Input>
          </InputGroup>
        </FormGroup>
      </Col>

    </Row>

    <Row>
      <Col md={12} lg={9} >
          <FormGroup>
            <Label>Source</Label>
            <FormText className="d-block">Select a source to add a source token to the payload.</FormText>

              <Select
                isClearable={false}
                isSearchable
                onChange={handleSelectSource}
                options={sources}
                value={selectedSource}
                tabSelectsValue
                backspaceRemovesValue
                isMulti={false}
                controlShouldRenderValue
                hideSelectedOptions
                closeMenuOnSelect
                placeholder="Select Source (type to search)"
                invalid={false}
                formatOptionLabel={SourceOptionLabel}
                isLoading={loading}
              />

          </FormGroup>

      </Col>
    </Row>


  </div>
}

const AdResult = ({ ad }) => {
  // todo AdDisplay standardize parameters?
  return (
    <div>
      <AdDisplay {...ad} image={{ url: ad.image_url }} url_display={ad.display_url} />
      <div className="mt-3">
        <SubmitButton className="me-2" size="sm" target="_blank" to={ad.click_url}>Click</SubmitButton>
      </div>
      <hr />
    </div>
  );
};

const ResponseDetails = ({ data: {auction_id, bids} }) => {
  const [isOpen, setIsOpen] = useState(false)

  return <div>

    <AuctionModal
      auctionId={auction_id}
      isOpen={isOpen}
      cancel={() => setIsOpen(false)}
    />

    <div className="form-section">
      <div className="form-section-header">
        Auction <Button color="link" onClick={() => setIsOpen(true)} className="auction-detail-link p-0">{auction_id}</Button>
      </div>
    </div>

    {bids.length > 0
      ? <div>
        {bids.map((ad) => <AdResult key={ad.position} ad={ad} />)}
      </div>
      : <div>No results.</div>
    }
  </div>
}

const Results = ({response}) => {
  const [activeTab, setActiveTab] = useState('results');
  const jsonViewRef = useRef();

  const toggleTab = (tab) => setActiveTab(tab);

  useEffect(
    () => {
      const editor = jsonViewRef && jsonViewRef.current && jsonViewRef.current.jsonEditor;
      if (editor && response) {
        editor.update(response);
        editor.expandAll();
      }
    },
    [response],
  );

  return (
    <div>

      <Nav className="mastodon-tabs mb-3">

        <FormTabFlat
          id="results"
          label="Results"
          activeTab={activeTab}
          setActive={toggleTab}
        />
        <FormTabFlat
          id="raw"
          label="Raw Response (Advanced)"
          activeTab={activeTab}
          setActive={toggleTab}
        />
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="results">
          <Card body>
            {response
              ? <ResponseDetails data={response} />
              : <div>Submit lead form to review response.</div>}
          </Card>
        </TabPane>
        <TabPane tabId="raw">
          <Card>

            <JsonEditorLoadable
              mode="view"
              value={response}
              ref={jsonViewRef}
            />

          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
};

const MatchChecker = () => {
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] = useState(null);
  //TODO: link to this tool with a source id and/or auction id
  const [query,] = useQueryState({source:null, auction:""});
  const app = useContext(AppContext);
  const api = app.getApi('matching')

  const submitPayload = async (payload) => {
    setSubmitting(true);

    const res = await api.runAuction(payload);
    if (res) {
      console.log(res)
      setResponse(res);
    }

    setSubmitting(false);
  };

  return (
    <div>
      <DocTitle pageTitle="Match Checker" />

      <div>
        <Row>
          <Col md={12} lg={6}>
            <PageSection title="Match Tool">
              <RequestForm submitting={submitting} onSubmit={submitPayload} payload={payloads[0].payload} />
            </PageSection>
          </Col>
          <Col md={12} lg={6}>
            <PageSection>
              <Results response={response} />
            </PageSection>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const SubmitButton = (props) => {
  props = { ...props, disabled: props.submitting };
  return (
    <Button {...props}>
      {props.submitting && <Spinner color="light" size="sm" className="me-2" />}
      {props.children}
    </Button>
  );
};

export default MatchChecker;
