import React, {useMemo} from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
//import {UncontrolledTooltip} from "reactstrap";
import { IconData } from './constants';

/*
 * Show icons in a table cell describing aspects of an entity's routing rules.
 * Routing rules have a 'summary' field which is an array of strings.
 * It is derived from scanning all rules in a tree.
 * Note, because it is based on all rules, you can get contradictory values,
 * such as both 'include' and 'exclude' if rules have different flow control settings.
 * Here we just show one icon indicating a flow control setting exists.
 *
 * This icon component is NOT for each rule in the full rule tree. See RuleIcons
 */
const RoutingRuleIconTableCell = ({ruleId, summary, onOpenRules}) => {


  const iconData = useMemo(() => {

    return summary.map((item) => {
      switch(item) {
        case 'sale_caps':
        case 'match_caps':
          return IconData['sale_match_caps'];
        case 'include':
        case 'exclude':
          return IconData['flow_control'];
        case 'absolute':
          return IconData['payout'];
        case 'modifier':
          return IconData['margin'];
        case 'match_conditions':
          return IconData[item];
        default:
          return null;
      }
    }).filter(i => i)
  }, [])

  return (
    <span className="table-rule-icons rule-tree-icons">
      {iconData.map( (icon,i) => (
        <div className="table-tag" key={i} onClick={onOpenRules}>
          <div>
            <FaIcon
              id={`tree-icon-${icon.name}-${ruleId}`}
              icon={icon.icon}
              size="sm"
              className={icon.name}
            />
            <div className="label">{icon.title}</div>
          </div>

          {/*<UncontrolledTooltip target={`tree-icon-${icon.name}-${ruleId}`}>
            {icon.title}
          </UncontrolledTooltip>*/}
        </div>
      ))}
    </span>
  )
}

export default RoutingRuleIconTableCell;
