import React, {useEffect, useState} from "react";
import {Button, Tooltip} from "reactstrap";


type ApplyOrRefreshButtonProps = {
  pendingApply:boolean;
  isLoading:boolean;
  onRefresh: ()=>void;
  onApply:()=>void;
}

/*
 * Button that can toggle between applying a filter changed or refreshing current filters.
 * Can show a "click to apply" tooltip when it switches to Apply mode.
 *
 * Used for lists with complex filtering or longer load times, where we don't want every
 * filter tweak to trigger data fetch
 */
const ApplyOrRefreshButton = ({
                                pendingApply,
                                isLoading,
                                onRefresh,
                                onApply,
}:ApplyOrRefreshButtonProps) => {
  let tooltipTimer:number|null;
  const[showTip, setShowTip] = useState(false);

  const handleClick = () => {
    if (pendingApply) {
      onApply()
    } else {
      onRefresh();
    }
  }

  useEffect(()=>{
    if (!isLoading && pendingApply) {
      setShowTip(true);

      if (tooltipTimer) {
        tooltipTimer = null;
      }
      tooltipTimer = window.setTimeout(()=>setShowTip(false), 5000);
    } else if (isLoading) {
      tooltipTimer=null;
      setShowTip(false);
    }
  }, [pendingApply, isLoading])

  // <FaIcon icon="sync" />
  return (
    <>
      <Button size="sm" color="primary" onClick={handleClick} disabled={isLoading} id="btn-exec-filters">
        {pendingApply ? "Apply" : "Refresh"}
      </Button>
      <Tooltip
        placement="top"
        isOpen={showTip}
        target="btn-exec-filters"
        // toggle={toggleApplyTip}
        trigger="none"
        disabled={!pendingApply}
      >
        Click to Apply
      </Tooltip>
    </>
  )
}

export default ApplyOrRefreshButton
