import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';
import {OrderLastSoldPropertiesType} from "./advertiser";

/*
 * Component that formats the last_sold_at date of an order
 * Used in accounts list table cell and rules manager order info
 */
const OrderLastSold = React.memo<OrderLastSoldPropertiesType>(
    ({ date, isStale, orderId, status }) => {
        if (!date || status === 'archived') return null;

        return (
            <>
                <span
                    className={classnames({ stale: isStale })}
                    id={`last-sold-${orderId}`}
                >
                    {isStale && (
                        <FaIcon icon='exclamation-triangle' className='me-1' />
                    )}
                    <RelativeTime date={date} showTimezone={true} />
                </span>
            </>
        );
    }
);

OrderLastSold.displayName = "OrderLastSold";
export default OrderLastSold;
