import React, { FC, useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import useApi from '../Hooks/useApi';

type DownloadFileLinkPropsType = {
  identifier:string|number;
  downloadSource: (id:string|number)=>any|string;
  defaultFilename: string;
  disabled?: boolean;
  className?: string;
  label?: string;
  title?:string;
  buttonSize?:string;
}
/*
 * Button that downloads a file with an async request and a temporary invisible link.
 *
 * We don't have a central download endpoint, so this component requires
 * a property that defines the async getter function. It can be:
 *    - an async function
 *    - a string that names an endpoint in the global API class
 *       (for example, "datasheets" will fetch API.endpoints.datasheets.download)
 *
 * Example:
 * Gets "/datasheets/download/{id}"
 * <DownloadFileLink identifier={datasheet.file.id} downloadSource="datasheets" defaultFilename={datasheet.file.file_name}/>
 *
 * The expected return value is the entire response from axios, not just the data:
 * {data:Blob, status:200, headers:[], request:{}, config:{}}
 *
 */
const DownloadFileLink:FC<DownloadFileLinkPropsType> = ({
  identifier,
  downloadSource,
  defaultFilename,
  disabled=false,
  className="",
  label="",
  title = "Download file",
  buttonSize="sm"
}) => {
  const { api } = useApi();
  const [isDownloading, setIsDownloading] = useState(false);

  const getData = async (id:string|number) => {
    let response;
    if (typeof downloadSource === 'function') {
      response = await downloadSource(id);
    } else if (typeof downloadSource === 'string') {
      // download is an api endpoint
      if (api.endpoints[downloadSource]) {
        response = await api.endpoints[downloadSource].download(id);
      }
    }
    return response;
  };

  const download = async (id:string|number) => {
    setIsDownloading(true);
    try {
      const response = await getData(id);
      // console.log('DownloadFileLink.js:download response', response);
      if (response) {
        if (response.status && response.status !== 200) {
          notify(`Error downloading file: Status:${response.status}`, 'error');
        } else if (response.data) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          // @ts-ignore
          a.style = 'display: none';
          const url = window.URL.createObjectURL(response.data);

          // File name might be provided in header
          let filename;
          if (response.headers && response.headers['content-disposition']) {
            const matches = /"([^"]*)"/.exec(response.headers['content-disposition']);
            filename = (matches != null && matches[1] ? matches[1] : '');
          }

          if (!filename) {
            filename = defaultFilename || 'file';
          }

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          // @ts-ignore
          a.parentNode.removeChild(a);
        }
      } else {
        notify('Error downloading file: Empty data.', 'error');
      }
    } catch (e) {
      notify('Error downloading file...', 'error');
      console.log('Error downloading file ', e);
    } finally {
      // Keep spinner going for a bit, sometimes there is a lag while the browser brings up
      // the spreadsheet app or save dialog
      window.setTimeout(() => setIsDownloading(false), 1500);
    }
  };

  return (
    <>
      {isDownloading ? (
        <Button color="link" className={className} size={buttonSize} disabled>
          <Spinner size="sm" color="primary" title="downloading..." />
          {label && <span className="ms-1">{label}</span> }
        </Button>
      ) : (
        <Button color="link" size={buttonSize} className={className} title={title} onClick={() => download(identifier)} disabled={disabled}>
          <FaIcon icon="file-download" />
          {label && <span className="ms-1">{label}</span> }
        </Button>
      )}
    </>

  );
};


export default React.memo(DownloadFileLink);
