import React, {useState, useEffect} from "react";
import { Button, Nav, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormTabFlat } from '../../../Components/Form/FormCommon';
import PubSub from 'pubsub-js';
import PageSection from '../../../Layout/PageSection';
import OrderModal from '../Order/OrderModal';
import DocTitle from '../../../Layout/DocTitle';
import FilterBar from '../../../Components/Table/FilterBar';
import Filters from './Filters';
import AllAccounts from "./AllAccounts";
import usePermission from '../../../Hooks/usePermission';
import useApi from '../../../Hooks/useApi';
import usePagedTableState from "../../../Hooks/usePagedTableState";
import Favorites from "./Favorites";
import useFavoriteOrder from "../../../Hooks/useFavoriteOrder";
import {Account, Order} from "../../../types";
import useLocalStorage from "../../../Hooks/useLocalStorage";

const DefaultFilters = {
  search_type:'accounts',
};

const DefaultControls = {
  limit: 20,
  sort_by: 'name',
  sort_dir: 'asc',
};

const ListHeaderTools = ({ allowCreateAccount }:any) => (allowCreateAccount ? (
  <Button
    tag={Link}
    color="primary"
    size="sm"
    to={{ pathname: '/accounts/create' }}
  >
    New Account
  </Button>
) : null);

const OrderList = () => {

  const {favorites} = useFavoriteOrder();
  const {
    filters, setFilters, controls, updateQuery,
  } = usePagedTableState(
    DefaultFilters,
    DefaultControls,
    { useQueryString: true, useTableControls: true },
  );

  //Splitting up the query string filters, they go to different APIs
  const hasFilters = Object.keys(filters).filter(k => k !== 'search_type').length > 0;
  const [account, setAccount] = useState<Account|null>(null);
  const [order, setOrder] = useState<Partial<Order>|null>(null);
  const [favoriteOrderIds, setFavoriteOrderIds] = useState<number[]>(() => {
    return favorites ? favorites.map((fav:Order) => fav.id) : [];
  });
  // Show favorites or all tab based on stored setting, or if any favorites exists and there is no account/order filtering.
  const [orderViewSetting, setOrderViewSetting] = useLocalStorage(
    "favorites_orders_view",
    favorites?.length > 0 && !hasFilters ? 'favorites' : 'all'
    )
  const [activeTab, setActiveTab] = useState<string>(orderViewSetting);
  const [autoChangeTabs, setAutoChangeTabs] = useState(false);
  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [allRefreshKey, setAllRefreshKey] = useState<number>(99);
  const allowCreateAccount = usePermission('accounts.create');
  const { api } = useApi();


  // Pubsub for updating orders in accountMegaMenu
  const psToken = PubSub.subscribe('REFRESH_ACCOUNTS_PAGE', () => {
    setAllRefreshKey(allRefreshKey + 1);
  });

  // on mount and filter change
  useEffect(() => {

    // When list filters change we want the tab to switch to 'All' since that is the list that is filtered.
    // However, don't do this on load, since it should default to Favorites if any exist.
    if (autoChangeTabs) {
      handleSelectTab('all');
    } else {
      setAutoChangeTabs(true);
    }

    // unmount
    return function cleanup() {
      PubSub.unsubscribe(psToken);
    };
  }, [filters]);


  const handleSelectTab = (tab:string) => {
    setActiveTab(tab);
    setOrderViewSetting(tab);
  }

  const closeOrderModal = (refresh = false) => {

    setOrder(null);
    setOrderModal(false);

    if (refresh) {
      setAllRefreshKey(allRefreshKey + 1);
      // refresh accounts dropdown in header
      PubSub.publish('REFRESH_ACCOUNTS_MENU');
    }
  };

  const openOrderModal = (order:Partial<Order>|null, account:Account) => {
    if (!order) {
      order = {
        id: 0,
        name: '',
      };
    }
    setOrder(order);
    setAccount(account);
    setOrderModal(true);
  };


  // Update IDs
  const handleFavoritesUpdate = (id:number) => {
    //remove if in
    const newFavorites = [...favoriteOrderIds];
    const existing = favoriteOrderIds.indexOf(id)

    if (existing >= 0){
      newFavorites.splice(existing, 1);
    } else {
      newFavorites.push(id);
    }
    setFavoriteOrderIds(() => newFavorites);
  }

  const handleStatusChange = async (order:Order, status:string) => {
    if (order) {
      const result = await api.endpoints.orders.update({
        id: order.id, name: order.name, status,
      });

      //sync status in 2 tables by refreshing
      setAllRefreshKey(allRefreshKey + 1);
      setFavoriteOrderIds([...favoriteOrderIds])

      return Boolean(result);
    }
    return false;
  };

  const handleRefresh = () => {
    setAllRefreshKey(allRefreshKey + 1);
  }

  // Monitor stored favorites setting changes (from other tabs, etc)
  useEffect(() => {
    if (activeTab !== orderViewSetting) {
      setActiveTab(orderViewSetting);
    }
  }, [orderViewSetting]);


  return (
    <>

      <FilterBar>
        <Filters filters={filters}  onChange={setFilters}  onRefresh={handleRefresh}  />
      </FilterBar>


      <PageSection
        title="Accounts & Orders"
        tools={<ListHeaderTools allowCreateAccount={allowCreateAccount} />}
      >
        <DocTitle pageTitle="Accounts" />

        <Nav className="mastodon-tabs">
          <FormTabFlat
            id="all"
            label={hasFilters ? "All Accounts (filtered)" : "All Accounts"}
            activeTab={activeTab}
            setActive={handleSelectTab}
          />

          <FormTabFlat
            id="favorites"
            label="Favorites"
            activeTab={activeTab}
            setActive={handleSelectTab}
          />

        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="all">

            <AllAccounts
              filters={filters}
              controls={controls}
              updateQuery={updateQuery}
              onStatusChange={handleStatusChange}
              openOrderModal={openOrderModal}
              favoriteOrderIds={favoriteOrderIds}
              onFavorite={handleFavoritesUpdate}
              key={allRefreshKey}
            />
          </TabPane>

          <TabPane tabId="favorites">
            <Favorites
              onStatusChange={handleStatusChange}
              openOrderModal={openOrderModal}
              favoriteOrderIds={favoriteOrderIds}
              onFavorite={handleFavoritesUpdate}
            />

          </TabPane>

        </TabContent>


        {/* OrderModal is also used in AccountMegamenu */}
        <OrderModal
          order={order}
          account={account}
          isOpen={orderModal}
          cancel={closeOrderModal}
        />

      </PageSection>
    </>
  );
};


export default OrderList;
