import React from 'react';
import {
  Alert, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
// import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {JsonDisplay} from '@thedmsgroup/mastodon-ui-components';
// import GoalTracking from "./GoalTracking";

const GoalPostingModal = ({ isOpen, cancel }) => {
  const jsonExample = {
    sale_id: 'c3b7aad2-c221-4568-925b-5a2a97a01373',
    unique_id: 'sale-123',
    value: '12.99',
  };
  return (
    <Modal isOpen={isOpen} toggle={cancel} size="lg" id="post-goal-help-modal">
      <ModalHeader toggle={cancel}>
        The Mastodon Goal Webhook
      </ModalHeader>
      <ModalBody>
        <p>The Mastodon API provides a webhook for registering goals or conversions. POST to:</p>

        <Alert color="secondary">
          <strong>
            https://matching.platform.ue.co/goals/
            <code>&lt;goal_id&gt;</code>
          </strong>
        </Alert>

        <p>
          The
          <code>&lt;goal_id&gt;</code>
          {' '}
          to be placed in this URL is the 36 character
          unique ID associated with each goal that you create in Mastodon. It can be copied from the list of goals.
        </p>

        <p>The body of the request should contain JSON data in this format:</p>

        <JsonDisplay data={jsonExample} showCopy={false} />

        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>

          </thead>
          <tbody>
            <tr>
              <td><code>sale_id</code></td>
              <td>
                The
                <code>click_id</code>
                {' '}
                or
                <code>lead_id</code>
                {' '}
                of the click or lead you received from us.
              </td>
            </tr>
            <tr>
              <td><code>unique_id</code></td>
              <td>
                <b>Optional</b>
                . Your own unique ID for the conversion. This helps to de-duplicate multiple conversions.
              </td>
            </tr>
            <tr>
              <td><code>value</code></td>
              <td>
                <b>Optional</b>
                . A dollar value for the conversion. This will override the goal&apos;s configured value, if set.
              </td>
            </tr>
          </tbody>

        </table>

        <p>
          A successful response will have status
          <strong>201</strong>
          {' '}
          and a
          unique
          <code>conversion_id</code>
          {' '}
          that you can keep for your records.
        </p>

      </ModalBody>
    </Modal>
  );
};

export default GoalPostingModal;
