import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import Goal from './Goal';
import GoalPostingModal from './GoalPostingModal';
import useApi from '../../../Hooks/useApi';
import usePermission from "../../../Hooks/usePermission";

const Goals = ({ accountId }) => {
  const allowEdit = usePermission('accounts.edit');
  const [goals, setGoals] = useState([]);
  const [goalPostingModalOpen, setGoalPostingModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const { api } = useApi();

  useEffect(() => {
    loadGoals();
  }, []);

  const loadGoals = async () => {
    const goals = await api.endpoints.goals.list({ account_id: accountId });
    if (goals) {
      setGoals(goals);
    } else {
      // notify
    }
  };

  const toggleModal = () => setGoalPostingModalOpen(!goalPostingModalOpen);

  const addGoal = () => {
    setIsAdding(true); // TODO: when do we set this false?
    setGoals([...goals, {
      id: 0, name: '', account_id: accountId, value: '', url: '',
    }]);
  };

  const removeGoal = (itemIndex) => {
    if (goals.length > itemIndex) {
      const list = [...goals];
      if (list[itemIndex].id === 0) setIsAdding(false);
      list.splice(itemIndex, 1);
      setGoals(list);
    }
  };

  const handleSaveGoal = () => {
    setIsAdding(false);
  };

  return (
    <Row>
      <Col>
        <div className="form-section">
          <div className="form-section-header">Goal Pixels</div>
          <p>
            Create tracking pixels for named events, with an optional dollar value. In addition to placing these pixels
            on visited pages, you can trigger a goal by
            {' '}
            <Button className="inline" color="link" onClick={toggleModal}>using a webhook</Button>
            .
          </p>
          {goals.length === 0
        && (
        <StandardAlert color="light">
          <span>
            No goals are configured.
            {allowEdit && <Button color="link" onClick={addGoal} className="p-0 ms-2">Add a goal</Button>}


          </span>
        </StandardAlert>
        )}

          <div className="goals-container">

            {goals.map((goal, i) => (
              <Goal
                key={i}
                goal={goal}
                onRemove={() => removeGoal(i)}
                onAdd={addGoal}
                onSaved={handleSaveGoal}
                reloadList={loadGoals}
                allowEdit={allowEdit}
              />
            ))}

            {goals.length > 0 && allowEdit && (
            <div className="text-right">
              <Button color="primary" disabled={isAdding} onClick={addGoal}>
                <FaIcon icon="plus" />
                {' '}
                <span>Add Goal</span>
              </Button>
            </div>
            )}
          </div>
        </div>
      </Col>

      {allowEdit && (
        <GoalPostingModal isOpen={goalPostingModalOpen} cancel={toggleModal} />
      )}


    </Row>
  );
};

export default Goals;
