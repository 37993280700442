import React from 'react';
import {
  Row, Col, Nav, Navbar, NavItem,
} from 'reactstrap';

const Footer = () => (
  <footer id="footer">
    <Row className="footer-contents">
      <Col md={12} lg={4} className="d-flex align-items-end mb-lg-0 mb-sm-3">
        <Navbar expand="md" className="">
          <Nav fill>
            <NavItem>
              <a target="_blank" rel="noopener noreferrer" href="https://ue.co/terms-of-services.html">
                Terms of Service
                      </a>
            </NavItem>
            <NavItem>
              <a target="_blank" rel="noopener noreferrer" href="https://ue.co/privacy-policy.html">
                Privacy Policy
                      </a>
            </NavItem>
            <NavItem>
              <a href="mailto:support@app.platform.ue.co">
                Contact
                      </a>
            </NavItem>
          </Nav>
        </Navbar>
      </Col>

      <Col md={12} lg={4}>
        <div className="d-flex flex-column text-md-left text-lg-center">
          <div className="company"><img src={require('../images/dms-logo-blue-small.png')} alt="Digital Media Solutions" /></div>
          <div>(877) 423-1366</div>
          <div>MST version: {process.env.REACT_APP_VERSION}</div>
          <div>225 Broadway Suite 2200, San Diego, CA 92101 USA</div>
        </div>
      </Col>

      <Col md={12} lg={4} className="d-flex align-items-end mt-sm-3 mt-lg-0">
        <div className="ms-lg-auto">&copy; 2020 Digital Media Solutions&reg;, LLC. All Rights Reserved. </div>
      </Col>

    </Row>
  </footer>
);

export default Footer;
