import React from 'react';
import {
  Row, Col,
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Required } from '../../../Components/Form/FormCommon';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";

const Details = ({
  vendor, errors, onChange, isNew
}) => {

  return (
    <Row>
      <Col sm={6}>
        <FormGroup>
          <Label>
            Name
            {' '}
            <Required />
          </Label>
          <Input
            type="text"
            name="name"
            onChange={onChange}
            value={vendor.name || ''}
            invalid={!!errors.name}
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>

      </Col>

      <Col sm={6}>

        <FormGroup>
          <Label>
            API Key
            {' '}
            {vendor.token && (
              <CopyButton
              id={`copy-token`}
              textToCopy={vendor.token}
              buttonSize="sm"
              className="m-0 ms-1 p-0"
            />)}

          </Label>

          <Input
            type="text"
            name="token"
            id="key"
            value={vendor.token || ''}
            readOnly={true}
            placeholder={ isNew ? ("A token will be automatically created.") : undefined}
          />

          <Label className={"mt-2"}>
            Crypto Key
            {' '}
            {vendor.crypto_key && (
              <CopyButton
                id={`copy-token`}
                textToCopy={vendor.crypto_key}
                buttonSize="sm"
                className="m-0 ms-1 p-0"
              />)}
          </Label>

          <Input
            type="text"
            name="crypto_key"
            id="crypto_key"
            value={vendor.crypto_key || ''}
            readOnly={true}
            placeholder={ isNew ? ("A crypto key will be automatically created.") : undefined}
          />

        </FormGroup>

      </Col>
    </Row>
  );
};

Details.propTypes = {
  vendor: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
};

export default Details;
