import React, {
  useState, useEffect, useMemo, useCallback, useRef, Suspense, lazy, useContext,
} from 'react';
import {
  Col, Row, Button, Input, Label, FormText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import useLocalStorage from "../../../../Hooks/useLocalStorage";
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedCallback } from 'use-debounce';
import classnames from 'classnames';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import {HelpPopper}  from '@thedmsgroup/mastodon-ui-components';
import { LiveFilteringProvider, LiveFilteringContext } from '../../../../Providers/LiveFilteringProvider';
import {Filter, TextSearch, ButtonToggleFilter} from "../../../../Components/Table/Filter";
import usePermission from '../../../../Hooks/usePermission';
import RulesContext from '../../../../Providers/RulesContext';
import{ singularOrPlural} from "../../../../utils/string";
import FlexTable from "../../../../Components/Table/FlexTable";
import StatusToggler from "../../../../Components/Form/StatusToggler";
import {MaskedInputGroup} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";

const MAX_MODIFIER = 5000;

const ChannelStatusChoices = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
  { value: 'any', label: 'Any' },
];

const LazyBulkEditChannelsModal = lazy(() => import(/* webpackChunkName: "RuleModals" */ './BulkEditChannelsModal'));

/*
 * Form for setting bid modifiers and bid ceilings on channels.
 */
const Channels = ({
                    rule,
                    channels,
                    modifiers = [],
                    overrideParentModifiers = false,
                    isRootRule = false,
                    onChange,
                    errors,
                  }) => {
  const rulesContext = useContext(RulesContext);
  const allowEdit = usePermission('orders.edit');
  const allowBidCeiling = usePermission('rules_advanced.edit');
  const [allModifiers, setAllModifiers] = useState([]);
  //stored setting dictates if list view is ALL or APPLIED
  const [viewFilter, setViewFilter] = useLocalStorage("channel_modifiers_filter_view", 'applied')

  const [bulkEditModal, setBulkEditModal] = useState(false);

  // Ref: Workaround for problems with getting current state of allModifiers from the change handlers;
  const stateRef = useRef();
  stateRef.current = allModifiers;

  const toggleBulkEditModal = () => setBulkEditModal(!bulkEditModal);

  const handleChangeView = (isViewApplied) => {
    setViewFilter(isViewApplied ? 'applied' : 'all')
  }

  // Compose the rows: Existing channel modifiers + unset modifiers for all other channels
  useEffect(() => {
    if (!allModifiers.length) {
      const cm = channels.map((ch) => {
        let row = {
          channel_name: ch.name,
          channel_id: ch.id,
          bid_modifier: '',
          bid_ceiling: '',
          channel_status: ch.status,
          status: 'disabled',
        };
        const mod = modifiers.find((m) => m.channel_id === ch.id) ;

        if (mod) {
          row = { ...row, ...mod };
        }
        return row;
      });

      setAllModifiers(cm);
    }
  }, [channels]);

  const handleModifierChange = useCallback((bid_modifier, channel_id) => {

    bid_modifier = isNaN(parseInt(bid_modifier)) ? '' : parseInt(bid_modifier);
    if (bid_modifier && bid_modifier > MAX_MODIFIER) bid_modifier = MAX_MODIFIER;
    const status = bid_modifier !== '' ? 'manual' : 'disabled';

    const index = stateRef.current.findIndex((mod) => {
      return mod.channel_id.toString() === channel_id.toString()
    });
    if (index >= 0) {
      const newModifiers = Object.assign([], stateRef.current); //[...allModifiers];
      newModifiers[index] = {...newModifiers[index], bid_modifier, status };
      setAllModifiers(newModifiers);
      updateModifiers(newModifiers);

    }

  }, [stateRef.current]);

  const handleCeilingChange = (bid_ceiling, channel_id) => {
    bid_ceiling = isNaN(parseFloat(bid_ceiling)) ? '' : parseFloat(bid_ceiling);
    const index = stateRef.current.findIndex((mod) => mod.channel_id === channel_id);
    if (index >= 0) {
      const newModifiers = Object.assign([], stateRef.current); //[...allModifiers];
      newModifiers[index] = {...newModifiers[index], bid_ceiling };
      setAllModifiers(newModifiers);
      updateModifiers(newModifiers);
    }
  };

  // Update parent with items that have a bid modifier
  const updateModifiers = (newModifiers) => {

    let filtered = newModifiers.filter((cm) => cm.status === 'manual' && !isNaN(parseInt(cm.bid_modifier)));
    // Remove channel_name and sort by channel_id
    // or we get a false positive for formIsDirty
    filtered = filtered.map((cm) => {
      const { channel_name, ...rest } = cm;
      return rest;
    });
    doSort(filtered, 'channel_id', true);
    onChange('channel_modifiers', filtered);

  };


  const doSort = (arr, col, asc) => {
    if (asc) {
      arr.sort((a, b) => (a[col] > b[col] ? 1 : -1));
    } else {
      arr.sort((a, b) => (a[col] < b[col] ? 1 : -1));
    }
  };

  const handleClear = (channel_id) => {
    const newList = stateRef.current.map((mod) => {
      if (mod.channel_id === channel_id) {
        return {
          ...mod,
          bid_modifier: '',
          bid_ceiling: '',
          status: 'disabled',
        };
      }
      return mod;
    });
    setAllModifiers(newList);
    updateModifiers(newList);
  };

  const handlePostBulkUpdate = (newRule) => {
    // Bulk update saves the rule, but only the channel_modifiers.
    // It's possible that the rule has other changes before the update.
    // Here, we are merging the new channel modifiers into the existing (and possibly changed) rule.
    // TODO: this will probably reset the "isDirty" flag, removing the message. Can we preserve it?
    rulesContext.api.setSelected({ ...newRule, ...rule, channel_modifiers: newRule.channel_modifiers }, true);
  };

  const handleChangeOverride = (e) => onChange('override_parent_channel_modifiers', e.target.checked);

  return (
    <div className="form-section channel-modifiers">
      <div className="form-section-header d-flex justify-content-between">
        <div>Channel Bid Modifiers</div>
        {allowEdit && (
          <Button size="sm" color="link" className="inline" onClick={toggleBulkEditModal}>
            <FaIcon icon="columns" className="me-1" />
            Bulk Edit
          </Button>
        )}

      </div>
      <p>
        Use a percentage to modify bids by channel. If not set, or zero, the channel will not be used.
        {allowBidCeiling && <span> The bid ceiling is optional.</span>}
      </p>


      <div className="mt-3">

        { Object.keys(errors).length > 0 && (
          <StandardAlert color="info" className="validation">
            Please check that all active channels have a modifier value (0 to 5000%) or are blank
          </StandardAlert>
        )}

        {channels?.length === 0 && (
          <StandardAlert color="light">
            No channels found for this vertical and product
          </StandardAlert>
        )}

        {/* For all but account rule, allow override choice */}
        {!isRootRule && allModifiers.length > 0 && (
          <div className="form-section parent-override mb-3 d-flex">
            <div className="me-1">
              <Input
                name="override_parent_channel_modifiers"
                type="checkbox"
                defaultChecked={!!overrideParentModifiers}
                onChange={handleChangeOverride}
              />
            </div>
            <div className="flex-fill">
              <Label>
                Override Parent Channel Modifiers
              </Label>
              <FormText> (Override parent modifiers instead of modifying them)</FormText>
            </div>
          </div>

        )}

        {channels?.length > 0 && (
          <Row>
            <Col sm={12}>

              <LiveFilteringProvider filters={{channel_status:'active',bid_modifier:viewFilter === 'applied'}}>

                <ModifierFilters
                  channelsCount={allModifiers.length}
                  modifiersCount={modifiers.length}
                  onChangeView={handleChangeView}
                />


                <ModifierTable
                  data={allModifiers}
                  onBidChange={handleModifierChange}
                  onCeilingChange={handleCeilingChange}
                  onClear={handleClear}
                  allowEdit={allowEdit}
                  allowBidCeiling={allowBidCeiling}
                />

              </LiveFilteringProvider>

            </Col>
          </Row>


        )}

        <Suspense fallback={<div />}>
          <LazyBulkEditChannelsModal
            isOpen={bulkEditModal}
            close={toggleBulkEditModal}
            rule={rule}
            onAfterSave={handlePostBulkUpdate}
          />
        </Suspense>

      </div>

    </div>
  );
};

const ModifierFilters = ({modifiersCount, channelsCount, onChangeView}) => {
  const context = useContext(LiveFilteringContext);

  const handleChangeView = (param, value) => {

    context.updateFilter(param, value);
    onChangeView(value); //sets local storage
  }

  return (
    <>
      <ListCount
        allCount={channelsCount}
        filteredCount={context.filteredCount}
        modifierCount={modifiersCount}
      />
      <div className="above-table-filters mt-1 mb-3">

        <div className="boxed-filter search-filter flex-fill">

          <TextSearch
            value={context.filters.channel_name ?? ""}
            placeholder="Search..."
            onUpdate={(val) => context.updateFilter("channel_name", val)}
            onClear={() => context.resetFilter("channel_name")}
          />
        </div>

        <div className="ms-2 min-250">
          <Filter
            placeholder="Status"
            param="channel_status"
            options={ChannelStatusChoices}
            value={context.filters?.channel_status ?? ""}
            onChange={context.updateFilter}
            onClear={() => context.resetFilter("channel_status")}
            isMulti={false}
          />
        </div>

        <div className="ms-2">
          <ButtonToggleFilter
            param="bid_modifier"
            active={context.filters.bid_modifier || false}
            onChange={handleChangeView}
            labelOn="Applied"
            labelOff="All"
          />
        </div>

      </div>
    </>

  )
}
const BidModifierInput = ({
                            id, modifier="", onChange,
                          }) => {

  // When we handle this as number, it tends to interpret '' as 0
  const handleChangeDebounced = useDebouncedCallback((val, mask) => {
    // Mask insists on 0 even if ''
    val = mask.unmaskedValue || '';

    // Mask seems to fire onAccept when we filter the list, even if the bid didn't change
    // so test for change
    if (val.toString() !== modifier.toString()) {
      onChange(mask.unmaskedValue, id);
    }
  }, 600);


  return (
    <MaskedInputGroup
      mask={Number}
      unmask="typed"
      padFractionalZeros={false}
      scale={0}
      min={0}
      max={MAX_MODIFIER}
      type="text"
      name="name"
      onAccept={handleChangeDebounced}
      value={modifier}
      suffix="%"
      className="teeny"
    />
  )
};



const BidCeilingInput = ({
                           channelId, bidCeiling='', disabled = false, onChange,
                         }) => {
  const handleChangeDebounced = useDebouncedCallback((val, mask) => {
    if (mask.unmaskedValue.toString() !== bidCeiling.toString()) {
      onChange(mask.unmaskedValue, channelId);
    }
  }, 600);

  return (
    <MaskedInputGroup
      mask={Number}
      unmask="typed"
      padFractionalZeros
      scale={2}
      min={0}
      max={999}
      radix="."
      type="text"
      name="name"
      onAccept={handleChangeDebounced}
      value={bidCeiling}
      prefix="$"
      className="teeny"
      disabled={disabled}
    />
  )
};



// eg, 50 channels available (30 shown), 2 applied modifiers
const ListCount = ({filteredCount, allCount, modifierCount}) => {
  return (<small className="list-count">
    {allCount} channels available {filteredCount < allCount && (<span> ({filteredCount || "none"} shown)</span>)},
    {' '}{modifierCount || 'no'} {singularOrPlural(modifierCount, 'modifier', 'modifiers')} applied
  </small>)
};



const BidCell = ({channelId, modifier, onChange, allowEdit}) => {

  return (
    <div>
      {allowEdit ? (
        <BidModifierInput
          id={channelId}
          modifier={modifier}
          onChange={onChange}
        />
      ) : (
        <span>
          {modifier > 0 ? `${modifier}%` : modifier === 0 ? '0' : <>&mdash;</>}
        </span>
      )}

    </div>
  );
}

const ChannelCell = ({name, status, modifier}) => {
  return (
    <div className={classnames({archived:status === 'archived', blocked:modifier === 0})}>
      <span className="channel-name">{name}</span>
      {status === 'archived' && (
        <>
          <span className="channel-tag inactive-channel">Inactive</span>
          <HelpPopper name="inactive-channel" title="Inactive Channel">
            This channel is no longer active.
          </HelpPopper>
        </>

      )}
      {modifier === 0 && (
        <>
          <span className="channel-tag blocked-channel">Blocked</span>
          <HelpPopper name="blocked-channel" title="Blocked Channel">
            When the bid modifier is zero, activity on this channel is blocked.
          </HelpPopper>
        </>

      )}
    </div>
  )
}

const ModifierTable = ({data, onBidChange, onCeilingChange, onClear, allowBidCeiling, allowEdit}) => {

  // hide columns depending on permissions
  const initialTableState = useMemo(() => {
    const state = {
      sortBy: [{ id: 'channel_name', desc: false }],
      hiddenColumns: []
    };
    if (!allowBidCeiling) {
      state.hiddenColumns.push('bid_ceiling')
    }
    if (!allowEdit) {
      state.hiddenColumns.push('clear-btn-col')
    }
    return state;
  }, []);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'channel_name',
      defaultCanSort: true,
      minWidth: 200,
      className: 'name-cell',
      Cell: ({row, value}) => <ChannelCell name={value} modifier={row.original.bid_modifier} status={row.original.channel_status} />
    },
    {
      Header: 'Status',
      accessor: 'channel_status',
      defaultCanSort: true,
      maxWidth: 120,
      className: 'status-cell',
      disableGlobalFilter: true,
      Cell: ({ value }) => <StatusToggler status={value} allowEdit={false} />,
      filter: (rows, colIds, filterVal) => {
        if (filterVal && filterVal !== 'any') {
          return rows.filter((row) => row.original.channel_status === filterVal)
        }

        return rows
      }
    },
    {
      Header:'Bid Modifier',
      headerClasses: 'channel-modifer-bid-column',
      accessor: 'bid_modifier',
      Cell: ({row, value}) => <BidCell
        channelId={row.original.channel_id} /* not used */
        modifier={value}
        onChange={onBidChange}
        allowEdit={allowEdit}
      />,
      maxWidth:100,
      filter: (rows, colIds, filterVal) => {
        // filterVal will be boolean. True = return rows where a modifier is set
        if (filterVal) {
          return rows.filter((row) => {
            return  !isNaN(parseInt(row.original.bid_modifier))
          })
        }
        return rows;
      }
    },
    {
      Header: 'Bid Ceiling',
      headerClasses: 'channel-ceiling-column',
      accessor: 'bid_ceiling',
      Cell: ({row, value}) => <BidCeilingInput
        bidCeiling={value}
        channelId={row.original.channel_id}
        disabled={row.original.bid_modifier === ''}
        onChange={onCeilingChange}
      />,
      maxWidth: 120,
    },
    {
      Header: '',
      id: 'clear-btn-col',
      Cell: ({row}) => <div>
        { (row.original.bid_modifier !== '' || row.original.bid_ceiling !== '') && (
          <Button
            color="link"
            size="sm"
            className="ms-1 p-0"
            onClick={() => onClear(row.original.channel_id)}
            title="Remove channel modifier"
          >
            clear
          </Button>
        )}

      </div>,
      width:100
    }
  ], [])

  return (
    <FlexTable
      columns={columns}
      initialState={initialTableState}
      className="channel-modifiers-table striped"
      data={data}
      noDataMessage="No modifiers found"
    />
  )
}

Channels.propTypes = {
  rule: PropTypes.object,
  channels: PropTypes.array,
  modifiers: PropTypes.array,
  onChange: PropTypes.func,
  overrideParentModifiers: PropTypes.bool,
  isRootRule: PropTypes.bool,
  errors: PropTypes.object,
};

export default Channels;
