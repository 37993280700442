
// default props to use with react-select component
export const DefaultReactSelectProps = {
  isSearchable: true,
  isMulti: false,
  controlShouldRenderValue: true,
  hideSelectedOptions: false,
  closeMenuOnSelect: true,
  getOptionValue:(opt) => opt.value.toString()
};
