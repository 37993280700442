import React, {
  useEffect, useState, Suspense, lazy,
} from 'react';
import {
  Modal, ModalBody, ModalHeader, Row, Col, Fade, Spinner, Button,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { DateFormatter } from '../../Components/Table/Table';
import { titleCase } from '../../utils/string';

const ReactAudioPlayer = lazy(() => import('react-audio-player'));
const RECORDING_URL_BASE = 'https://call-recordings.dmsengage.com/recordings?url=';
// import {DurationFormatter} from "../../Components/Table/Table";

/*
Lead data

account_name: "Nationwide Health Advisors"
address: "100 Main Street"
auction_id: "e87ca63b-dc20-4263-8d40-9b72ecd38d7a"
bid: "$2.00"
call_id: 0
channel_name: "UE Health Leads"
city: "Crowley"
cost: "$2.00"
current_company: "Other"
currently_insured: "No"
drivers: null
email: "mistyride1977@gmail.com"
first_name: "Misty"
full_name: "Misty Roberson"
home_owner: "No"
last_name: "Roberson"
lead_id: "e5b53a49-e91e-4142-823a-6a4c3c7a7658"
order_name: "NHA Health Leads"
phone: "+12542294898"
product: "Leads"
purchased_time: "2020-12-07 22:00:27"
source_name: "ZQ Health Leads"
state: "TX"
transaction_id: 1017330
vehicles: null
vendor_name: "ZipQuote"
vertical: "Health Insurance"
zipcode: "76036"

 */

const DetailHeader = ({
  loading, fullName, product, billable,
}) => {
  const loadingText = product === 'calls' ? 'Call Detail' : 'Lead Detail';
  let displayText;

  if (fullName) {
    displayText = fullName;
  } else if (product === 'calls' && billable === 'No') {
    displayText = 'Unbillable Call';
  } else {
    displayText = loadingText;
  }

  return (
    <h5>
      {loading ? <span>{loadingText}</span> : <span>{displayText}</span>}
    </h5>
  );
};

const FormatCall = ({ autoPlay, ...data }) => (
  <Row>
    <Col sm={12} md={4} className="mt-sm-1">
      <div className="detail-table mb-2">
        <DetailRow label="Billable" value={data.billable} />
        <DetailRow label="Disposition" value={titleCase(data.disposition)} />
        <DetailRow label="Duration" value={data.duration || <i>Unknown</i>} />
        <DetailRow label="Account Billable Duration" value={data.account_billable_duration} />
        {/* <DetailRow label="Device" value={lead.device}/> */}
        <DetailRow label="Call ID" value={data.call_id} />
      </div>
    </Col>

    {data.recording_url
      && (
      <Col sm={12} md={8} className="mt-sm-1">
        <Suspense fallback={<div>Loading</div>}>
          <ReactAudioPlayer src={RECORDING_URL_BASE + encodeURIComponent(data.recording_url)} controls autoPlay={autoPlay} className="w-100" />
        </Suspense>
      </Col>
      )}
  </Row>
);

const FormatContact = ({
  fullName, city, country, state, zipcode, address, address2, email, phone, date_of_birth,
}) => (
  <div className="contact d-flex justify-content-between">
    <div className="d-flex flex-column">
      <div>{fullName ?? <i>Unknown name</i>}</div>
      <div>
        {address ?? <i>Unknown address</i>}
        {address2 && (
          <span>
            {' '}
            (
            {address2}
            )
          </span>
        ) }
      </div>
      <div>
        {city ?? <i>Unknown city</i>}
        ,
        {' '}
        {state ?? <i>Unknown state</i>}
        {' '}
        {zipcode}
        {' '}
        {country && country.toLowerCase() !== 'usa' ? country : ''}
      </div>
    </div>
    <div className="d-flex flex-column">
      {email && (
        <div>
          <FaIcon icon="envelope" size="sm" className="me-1" />
          {email}
        </div>
      )}
      {phone && (
        <div>
          <FaIcon icon="phone" size="sm" className="me-1" />
          {phone}
        </div>
      )}
      {date_of_birth && (
        <div>
          D.o.B:
          {date_of_birth && <DateFormatter value={date_of_birth} format="M/dd/y" />}
        </div>
      )}
      {date_of_birth && (
        <div>
          D.o.B (FNS):
          {date_of_birth && <DateFormatter value={date_of_birth} format="MM/dd/y" />}
        </div>
      )}
    </div>
  </div>
);

const FormatInsurance = ({
  current_company, currently_insured, medicare_type, denied_coverage, vertical,
}) => (
  <div className="detail-table">
    <DetailRow label="Current company" value={current_company} />
    <DetailRow label="Currently insured" value={currently_insured ? 'Yes' : 'No'} />
    {vertical === 'Health Insurance' && (
      <>
        <DetailRow label="Medicare type" value={medicare_type || 'N/A'} />
        <DetailRow label="Denied coverage" value={denied_coverage ? 'Yes' : 'No'} />
      </>
    )}

  </div>
);

// At the moment no health data is returned from Looker
const FormatHealth = ({
  gender, height_inches, weight_pounds, tobacco_user, pregnant, preexisting_conditions, social_security, income, household_size,
}) => (
  <div className="detail-table">
    <DetailRow label="Gender" value={gender || <i>Unknown</i>} />
    <DetailRow label="Height (inches)" value={height_inches || <i>Unknown</i>} />
    <DetailRow label="Weight (pounds)" value={weight_pounds || <i>Unknown</i>} />
    <DetailRow label="Tobacco user" value={tobacco_user || <i>Unknown</i>} />
    <DetailRow label="Pregnant" value={pregnant || <i> Unknown</i>} />
    <DetailRow label="Pre-existing conditions" value={preexisting_conditions || <i>Unknown</i>} />
    <DetailRow label="Social security" value={social_security || <i>Unknown</i>} />
    <DetailRow label="Income" value={income || <i>Unknown</i>} />
    <DetailRow label="Household size" value={household_size || <i>Unknown</i>} />
  </div>
);

const FormatAuto = ({ drivers = [], vehicles = [] }) => (
  <>
    <div>
      {drivers?.length > 0 ? (
        <div className="detail-table mb-2">
          <h5>Drivers</h5>
          {drivers.map((drv, i) => (
            <div className="driver mb-1" key={i}>
              <span>
                  {drv.gender === 'M' ? 'Male' : 'Female'}
                  {' '}
                  |
                  {' '}
                  {drv.marital_status}
                  {' '}
                  | D.o.B:
                  {' '}
                  {drv.date_of_birth && <DateFormatter value={drv.date_of_birth} format="MM/dd/y" />}
                </span>
            </div>

          ))}
        </div>
      ) : <span>No driver data</span>}
    </div>
    <div>
      {vehicles?.length > 0 ? (
        <div className="detail-table">
          <h5>Vehicles</h5>
          {vehicles.map((v, i) => (
            <div className="vehicle mb-1" key={i}>
              <span>
                  {v.year}
                  {' '}
                  {v.make}
                  {' '}
                  {v.model}
                  {' '}
                  | miles:
                  {' '}
                  {v.annual_mileage}
                  m | commute:
                  {' '}
                  {v.commute_mileage}
                  m
                </span>
            </div>
          ))}
        </div>
      ) : <span>No vehicle data</span>}
    </div>
  </>
);

const FormatLead = ({
  lead, cost, product, billable, vendor, vertical,
}) => (
  <div className="detail-table">
    { (product !== 'Calls' || billable === 'Yes') && (
    <>
      <DetailRow label="Vendor" value={vendor || <i>Unknown</i>} />

      {lead.tcpa_disclosure !== undefined && <DetailRow label="TCPA" value={lead.tcpa_disclosure ? 'Yes' : 'No'} />}
      <DetailRow label="Vertical" value={vertical} />
      <DetailRow label="Product" value={titleCase(lead.product)} />
      {product === 'calls'
        ? <DetailRow label="Received At" value={<DateFormatter value={lead.received_time} format="PPpp" />} />
        : <DetailRow label="Purchase Date" value={<DateFormatter value={lead.purchased_time} format="PPpp" />} />}

      <DetailRow label="Cost" value={cost} />
      {/* <DetailRow label="Bid" value={lead.bid}/> */}
      {lead.device && <DetailRow label="Device" value={lead.device || ''} />}
      {lead.lead_id && <DetailRow label="Lead ID" value={lead.lead_id || ''} />}
      {/* {lead.source_name && <DetailRow label="Lead Source" value={lead.source_name || ''}/>} */}
      {lead.channel_name && <DetailRow label="Channel" value={lead.channel_name || ''} />}
    </>
    )}

    <DetailRow label="Auction ID" value={lead.auction_id || ''} />

  </div>
);

const DetailRow = ({ label, value, children }) => (
  <div className="detail-row">
    <label>{label}</label>
    <div>{value || children}</div>
  </div>
);
/*
 Differences between product leads
 *********************************
 Calls has a data.lead object, Lead does not

 home ownership
    both lead and call have data.home_owner (Yes/No).
    Call has data.lead.home_ownership (Yes/No)

 billable (Yes/No)
    Call has it, Lead does not

 tcpa_url
    Call has it in data.lead.  Lead does not have it.

 date
    Call is data.received_time, Lead is data.purchased_time

 Vertical
    Call:  has data.vertical="Auto Insurance", and also data.lead.vertical="auto-insurance"
    Lead:  has data.vertical="Auto Insurance"

 Drivers/Vehicles arrays
    Call has them in both data and data.lead
    Lead for auto-insurance has them in data

 */
/*
 * Modal for lead detail
 */
const ProspectDetailModal = (props) => {
  // console.log('CallDetailModal.js:props', props);
  const [fadeIn, setFadeIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [lead, setLead] = useState(null);
  const [hideNonbillable, setHideNonBillable] = useState(false);
  const [showHealth, setShowHealth] = useState(false);

  useEffect(() => {
    if (!data && props.callId) {
      load();
    }

    return () => {
      setData(null);
      setLead(null);
      setLoading(true);
    };
  }, [props.callId]);

  const load = async () => {
    const result = await props.loadDetail(props.callId);
    if (result?.data) {
      // Calls data has a 'lead' object with some unique data, and some data that is in the parent data.
      // Leads data does not have a lead object.
      setData(result.data);
      setLead(result.data.lead || result.data);
      setHideNonBillable(props.product === 'calls' && result.data.billable === 'No');
    } else {
      // TODO: error notice
    }
    setLoading(false);
  };


  if (!props.callId) return (null);

  return (
    <Modal isOpen={props.isOpen} toggle={props.cancel} size="xlg" className="modal-prospect-detail">
      <ModalHeader toggle={props.cancel} tag="div">
        <DetailHeader loading={loading} product={props.product} fullName={data?.full_name} billable={data?.billable} />
        {data && (
        <div className="subheader">
          <b>
            {data?.account_name}
            {' '}
            &gt;
            {' '}
            {data?.order_name}
          </b>
        </div>
        )}
      </ModalHeader>

      <ModalBody>
        {loading && <div style={{ marginTop: '150px' }}><Spinner size="lg" className="m-auto d-block" color="secondary" /></div>}
        {data && !loading && (

          <Fade in={fadeIn} exit={false}>

            <Row>

              <Col sm={12} md={6} className="mt-sm-1">
                <legend>Contact</legend>
                <FormatContact fullName={data.full_name} {...lead} />
              </Col>

              <Col sm={12} md={6} className="mt-sm-1">
                <legend>Insurance</legend>
                <FormatInsurance {...lead} />
              </Col>

              <Col sm={12} md={6} className="mt-2">
                <legend>Lead</legend>
                {/* tcpa_url={lead.tcpa_url} tcpa_disclosure={lead.tcpa_disclosure} */}
                <FormatLead
                  lead={lead}
                  cost={data.cost}
                  product={props.product}
                  billable={data.billable}
                  vendor={data.vendor_name}
                  vertical={data.vertical}
                />
              </Col>

              <Col sm={12} md={6} className="mt-sm-2 mt-md-2">
                {data.vertical === 'Auto Insurance' && !hideNonbillable
                && (
                <div className="mt-2">
                  <legend>Driver & Car</legend>
                  <FormatAuto drivers={lead.drivers} vehicles={lead.vehicles} />
                </div>
                )}

                {/* MST-1017 some call leads have a home ownership boolean */}
                {!hideNonbillable && typeof data.home_owner !== 'undefined'
                && (
                <div className="mt-2">
                  <legend>Home</legend>
                  <div className="detail-table">
                    <DetailRow label="Home Ownership" value={data.home_owner} />
                  </div>
                </div>
                )}

                {(data.vertical === 'Health Insurance' || data.vertical === 'Medicare') && !hideNonbillable
                && (
                <div className="mt-2">
                  <legend>
                    <div>Health</div>
                    {showHealth ? (
                      <Button size="sm" color="link" onClick={() => setShowHealth(false)}><FaIcon icon="chevron-down" /></Button>
                    ) : (
                      <Button size="sm" color="link" onClick={() => setShowHealth(true)}><FaIcon icon="chevron-right" /></Button>
                    )}
                  </legend>

                  {showHealth && <FormatHealth {...lead} />}

                </div>
                )}

              </Col>

            </Row>

            {props.product === 'calls'
            && (
            <Row className="mt-2">
              <Col>
                <legend>Call</legend>
                <FormatCall {...data} autoPlay={props.autoPlayId && data.call_id === props.autoPlayId} />
              </Col>
            </Row>
            )}

          </Fade>
        )}

      </ModalBody>
    </Modal>
  );
};

ProspectDetailModal.propTypes = {
  loadDetail: PropTypes.func, // may load calls or leads depending on parent component
  product: PropTypes.string,
  getDetail: PropTypes.func,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  cancel: PropTypes.func,
  autoPlayId: PropTypes.number,
};

ProspectDetailModal.defaultProps = {

};

export default ProspectDetailModal;
