import React, {FC, useRef, useEffect} from "react";
import {Bar } from "react-chartjs-2";
import type { ChartOptions} from 'chart.js';
import {AggIntervalToUnit} from "./chart";
import autocolors from 'chartjs-plugin-autocolors';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart as ChartJS, BarController, BarElement, TimeScale, LinearScale, CategoryScale, Legend, Tooltip} from 'chart.js';
ChartJS.register(BarController, BarElement,  TimeScale, LinearScale, CategoryScale, Legend, Tooltip, autocolors, annotationPlugin );
import 'chartjs-adapter-date-fns';

type BucketGraphStackedProps = {
  labels:string[];
  datasets:any[];
  options:ChartOptions;
  className?:string;
  styles?:Record<string, any>;
  title?:string;
  xLabel?:string;
  minTime?:string;
  maxTime?:string;
  showNow?:boolean;
  showNowLabel?:boolean;
  aggInterval?:string;
  loading?:boolean;
}

/*
 * Uses ChartJS to render a stacked bar graph with multiple data sets, inside of a styled div.
 *
 * We use the autocolors plugin for more color range. The default Colors module has a limited palette,
 * and begins to repeat when there are many datasets.
 *
 * Annotation plugin is for the optional "now" indicator line
 *
 * TODO: figure out chart types, they have been difficult to apply
 */
const StackedBarChart:FC<BucketGraphStackedProps> = ({
                                            labels,
                                            datasets,
                                            options,
                                            styles={},
                                            className= "",
                                            title="",
                                            xLabel="",
                                            minTime="",
                                            maxTime="",
                                            showNow="",
                                            showNowLabel="",
                                            aggInterval=""
}) => {
  const chartRef = useRef<ChartJS<'bar'>>();

  // Update x scale unit in options if aggregation interval changes
  useEffect(() => {
    if (aggInterval && chartRef.current) {
      const map = AggIntervalToUnit as Record<string, string>
      const intervalChar = aggInterval.charAt(aggInterval.length - 1);
      const unit:string|undefined = map[intervalChar];
      if (unit) {
        //@ts-ignore
        chartRef.current.options.scales.x.time.unit = unit;
      }

      if (xLabel) {
        //@ts-ignore
        chartRef.current.options.scales.x.title.text = xLabel;
      }



      // Use min/max time to make sure the chart time axis does not narrow down
      // if given limited data in the requested time range (MRK-10573)
      //https://www.chartjs.org/docs/latest/axes/cartesian/time.html#min-max-configuration
      if (minTime) {
        //@ts-ignore
        chartRef.current.options.scales.x.min = minTime;
      }
      if (maxTime) {
        //@ts-ignore
        chartRef.current.options.scales.x.max = maxTime;
      }

      if (!chartRef.current.options.plugins) {
        chartRef.current.options.plugins = {}
      }

      if (showNow) {
        const dNow = new Date();
        const nowLine = {
            type:"line",
            xMin:dNow,
            xMax:dNow,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          init:false
        } as any;

        if (showNowLabel) {
          nowLine.label = {content:"Now", display:true,rotation:-90, padding:3,position:'end',font:{size:'10px'}, backgroundColor:"rgb(255, 99, 132)"}
        }

        //@ts-ignore
        chartRef.current.options.plugins = {
          annotation: {
            annotations: {
              nowLine
            }
          }
        };
      }


      //@ts-ignore
      chartRef.current.update();
    }
  }, [aggInterval, datasets, chartRef.current, xLabel])

  // @ts-ignore
  return (
    <div style={styles} className={className}>
      {title && <h5>{title}</h5>}

        <Bar
          ref={chartRef}
          //@ts-ignore
          options={options}
          data={{labels, datasets}}
          //@ts-ignore
          plugins={[autocolors, annotationPlugin]}
        />

    </div>
  )
}

export default StackedBarChart;
