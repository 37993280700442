import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import EntityId from "../Table/EntityId";
import React from "react";

/*
 * A function that can be provided as react-select's formatOptionLabel function
 * that will show the entity ID along with the entity name in the options
 *
 * Value is assumed to be the id, and label == name
 */
export const EntityOptionLabel = ({
                             value, label, selected,
                           }: {value:number|string, label:string,  selected:boolean}) => (
    <div className="d-flex align-items-center">
      <div>{selected && <FaIcon icon="check" size="sm" className="me-1" color="green" />}</div>
      <div><EntityId id={value} /> {label}</div>
    </div>

);
