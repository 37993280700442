/*
 * Lists integrations and rejection counts for given rejection category
 */
import NumberFormat from "react-number-format";
import React from "react";

type IntegrationsRejectionsCountsProps = {
  rejectionsByCategory: Record<string, {integration:string, count:number}[]>
  category: string;
  className?:string;
}

const IntegrationRejectionList = ({rejectionsByCategory, category, className=""}:IntegrationsRejectionsCountsProps) => {

  return (
    <div className={`${className} integrations-rejected`}>
      <div className="form-section border">

        <div className="list thin-scrollbar">
          {rejectionsByCategory && typeof rejectionsByCategory[category] !== 'undefined' ? (
            <>
              {rejectionsByCategory[category].map((data:any,i:number) => (
                <div className="data-row" key={i}>
                  <label>{data.integration}</label>
                  <div className="text-end me-1"><NumberFormat value={data.count} displayType="text" thousandSeparator /></div>
                </div>
              ))}
            </>
          ) : (
            <i>None found</i>
          )}

        </div>

      </div>
    </div>
  )
}

export default IntegrationRejectionList
