import React from 'react';
import { Button } from 'reactstrap';

const InlineConfirmDelete = ({
  prompt, onDelete, onCancel, justify = 'center',
}) => (
  <div className={`inline-confirm d-flex justify-content-${justify}`}>
    <div className="text-center">
      <div>{prompt}</div>
      <Button onClick={onDelete} size="sm" color="danger" className="me-2">Yes</Button>
      <Button onClick={onCancel} size="sm" color="secondary" outline>No</Button>
    </div>

  </div>
);

export default InlineConfirmDelete;
