import BaseAPI from "../../../Services/BaseAPI";

class RuleSearchApi extends BaseAPI {
  constructor() {
    super(process.env.API_URL_CORE);
  }

  list = (params) => this.makeRequest('get', `${this.url}/rule-search`, params);

}

export default RuleSearchApi
