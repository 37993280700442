/*
 * Recursive functions for working with a rule tree (order rules, channel rules)
 */

// Use id to find a rule in the tree
export function getDeep(rule, targetId) {
  let match = null;
  if (rule && targetId) {
    if (rule.id === targetId) {
      match = rule;
    } else if (rule.rules.length) {
      rule.rules.map((child) => {
        const matchChild = getDeep(child, targetId);
        if (matchChild) match = matchChild;
      });
    }
  }

  return match;
}

// Get an array of ids of a rule's ancestors
// NOT USED
export function getAncestorIds(rootRule, rule) {
  let ancestors = [];
  const parent = getDeep(rootRule, rule.parent_id);
  if (!parent) {
    return ancestors;
  } if (parent.id === rootRule.id) {
    ancestors.push(parent.id);
    return ancestors;
  }
  ancestors.push(parent.id);
  const nextAncestors = getAncestorIds(rootRule, parent);
  ancestors = [...ancestors, ...nextAncestors];

  return ancestors;
}

// Find most immediate ancenstor of rule where disabled property is set true
// We might not be using this
export function getDisabledAncestor(rootRule, rule) {
  if (!rule) return null;
  let disabledParent = null;
  const parent = getDeep(rootRule, rule.parent_id);
  if (parent) {
    if (parent.disabled) {
      disabledParent = parent;
    } else {
      disabledParent = getDisabledAncestor(rootRule, parent);
    }
  }
  return disabledParent;
}

export function getAncestorWithProps(rootRule, rule, funcMap, result = []) {
  if (!rule) return null;

  // Go up the rule tree
  const parent = getDeep(rootRule, rule.parent_id);

  // How to know when to stop?

  if (parent) {
    Object.entries(funcMap).map(([prop, fn]) => {
      // If most recent prop not found yet, apply functions to parent to get values
      // console.log('TreeUtil.js:testing prop in parent:', prop, parent.label);
      if (!result[prop]) {
        const value = fn(parent);
        if (value) {
          result[prop] = { id: parent.id, label: parent.label, value };
        }
      }
    });

    result = getAncestorWithProps(rootRule, parent, funcMap, result);
  }
  return result;
}

// Update a rule object in the tree
export function setDeep(parent, targetItem) {
  if (parent.id === targetItem.id) {
    return targetItem;
  }
  parent.rules = parent.rules.map((item) => {
    // Found it, update it
    if (item.id === targetItem.id) return targetItem;
    // Go deeper
    if (item.rules.length) {
      item = setDeep(item, targetItem);
    }
    return item;
  });

  return parent;
}

// Add a rule object to the tree
export function addDeep(parent, newItem) {
  if (parent.id === newItem.parent_id) {
    if (!newItem.priority) {
      // priority 0 or not set, make top child
      parent.rules = [newItem, ...parent.rules]; // add to top
    } else {
      // priority > 0, splice into children
      parent.rules.splice(newItem.priority, 0, newItem);
    }

    return parent;
  }

  parent.rules = parent.rules.map((item) => {
    item = addDeep(item, newItem);
    return item;
  });

  return parent;
}

// Remove a rule object from the tree
export function removeDeep(parent, targetRule) {
  if (parent.id === targetRule.parent_id) {
    parent.rules = parent.rules.filter((item) => item.id !== targetRule.id);
    return parent;
  }
  parent.rules = parent.rules.map((item) => {
    item = removeDeep(item, targetRule);
    return item;
  });

  return parent;
}

//
// export function getAncestors(targetRule, rootRule, result=null) {
//
//   //set up result object
//   if(!result) result = {ancestors:[], match:false};
//   if(!targetRule) return result;
//
//   if(rootRule.id === targetRule.parent_id){
//     // This is the parent of the target, consider this a match
//     result.ancestors.push(rootRule);
//     result.match = true;
//   } else if (rootRule.rules.length){
//     //Recurse all the way down this node until we get a match
//     let nodeResult = {ancestors: [rootRule], match:false};
//     rootRule.rules.map( childRule =>{
//       if(!nodeResult.match) {
//         nodeResult = getAncestors(targetRule, childRule, nodeResult);
//
//         if(nodeResult.match){
//           result.ancestors = [...result.ancestors, ...nodeResult.ancestors];
//           result.match = true;
//         }
//
//       }
//     });
//   }
//
//   return result;
// }

// Get tree of ancestor rules for give rule (used for breadcrumbs)
// export function getRuleTree( rule ){
//   if(!rule) return [];
//   const {ancestors} = this.getAncestors( rule, this.state.orderRule);
//   ancestors.push(rule);
//   return ancestors;
// }
