import BaseAPI from './BaseAPI';

/* Used for DNC requests */
class PrivacyAPI extends BaseAPI {
  constructor() {
    super(process.env.API_URL_PRIVACY, []);
  }

  getPii = async (params) => await this.makeRequest('get', `${this.url}/admin/pii`, params);

  optOut = async (params) => await this.makeRequest('post', `${this.url}/admin/optout`, params);
}

export default PrivacyAPI;
