import PageSection from "../../../../Layout/PageSection";
import {Button, Input, InputGroup, InputGroupText, Nav, Spinner, Table} from "reactstrap";
import EnhancedSelect from "../../../../Components/Form/EnhancedSelect";
import React, {useEffect, useState} from "react";
import {PostResp, Ping, PingResp, PingPost, PostRespNoSale} from "./ExamplePostJSONs";
import {Tab, Tabs} from "react-bootstrap";

import "highlight.js/styles/base16/one-light.css";
import hljs from 'highlight.js';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";

const pingUrl = "https://matching.platform.ue.co/ping"
const pingPostUrl = "https://matching.platform.ue.co/pingpost/{auction_id}"
const postUrl = "https://matching.platform.ue.co/post"

const PostingInstructions = ({instructions}) => {
  if (instructions === 'error') {
    return <div className={"text-center p-3"}>
      <h3>Uh oh</h3>
      <p>Something went wrong. The link may be invalid.</p>
    </div>
  }

  if (!instructions) {
    return <div className="d-flex align-items-center justify-content-center" style={{height: '250px'}}>
      <div>
        <Spinner color="secondary" size="lg"/>
      </div>
    </div>
  }

  useEffect(() => {
    hljs.highlightAll();
  }, [])

  const [source, setSource] = useState();

  let opts = [];
  if (instructions.sources) {
    opts = [];
    instructions.sources.map((src) => {
      if (src.status === 'active') {
        opts.push({value: src.id, label: src.name})
        if (!source)
          setSource(src)
      }
    })
  }

  const handleSourceTokenChange = (sourceId) => {
    setSource(old => {
      const inst = instructions.sources.find(i => i.id == sourceId);
      return inst || old
    })
  }

  const exampleJsonPost = {
    source_token: source?.token ?? '',
    data: source?.schema_example ?? '',
  };

  return (
    <>
      <PageSection title="Lead Submission">
        <div className="row">
          <div className="col">
            <h5>How do I submit lead information?</h5>
            <p>The below posting instructions should help you understand how to send lead information to us via API,
              and
              what to expect in return.
              <br/>
              Send your test lead or any questions to your account manager or email: <a
                href="mailto:adops@dmsgroup.com">adops@dmsgroup.com</a>
              <br/>
              <br/>
              {!instructions?.api_key.includes('Login') &&
                <p>A <a href={`/#/publishers/${source?.token}`} target="_blank">public version</a> of
                  this page is available to be shared with your developers. The link will change based on the selected
                  Source.
                  <br/>
                </p>
              }
              For additional instructions, you can visit the <a href="https://docs.platform.ue.co/" target="_blank">API
                Documentation</a>
            </p>
            <br/>
          </div>

          <div className={"col"}>
            <h5>Source Information</h5>
            <div style={{maxWidth: '500px'}}>
              <div className={"row g-0"}>
                <div className={"col-md-auto"}>
                  <InputGroupText>
                    SOURCE
                  </InputGroupText>
                </div>
                <div className={"col w-75"}>
                  <EnhancedSelect
                    onChange={handleSourceTokenChange}
                    options={opts}
                    placeholder={"Select a Source..."}
                    value={'' + source?.id}
                  />
                </div>
              </div>

              <br/>
              <InputGroup size="sm">
                <InputGroupText>
                  SOURCE TOKEN
                </InputGroupText>
                <Input readOnly value={source?.token}/>
                <CopyButton textToCopy={source?.token} id={"id-copy-source-token"}>Copy</CopyButton>
              </InputGroup>
              <br/>
              <InputGroup size="sm">
                <InputGroupText>
                  API KEY
                </InputGroupText>
                <Input readOnly value={instructions?.api_key}></Input>
                {instructions.api_key && instructions.api_key.includes('Login')
                  ?
                  <Button className="mx-1" onClick={() => window.location = '/#/dashboard/publishers'}> Login </Button>
                  : <CopyButton textToCopy={instructions?.api_key} id={"id-copy-api-key"}>Copy</CopyButton>}

              </InputGroup>
              <br/>
            </div>
          </div>

        </div>

        <br/>
        <br/>

        <h3>API Examples & Schema</h3>
        <p></p>

        <Tabs
          defaultActiveKey="ping"
          className="mb-1"
        >
          <Tab eventKey="ping" title="Ping" className="p-2">
            <p>
              The Ping resource is a secure way to offer up lead properties needed to match to our advertisers, but do
              not need to include contact or PII information for that customer.<br/>

              This is used only to find demand for that consumer and return a bid or no match response. <br/>

              No Match responses should not be interpreted as errors or that anything was sent incorrectly in regards to
              structure or syntax.<br/>

              The ping is considered the first of two parts that make up sale and should not be considered a complete
              transaction. Leads & Calls require a pingpost for a complete transaction. Clicks require a click from a
              user :-)
            </p>
            <InputGroup size="sm" style={{width: '40%'}}>
              <InputGroupText>
                REQUEST URL
              </InputGroupText>
              <Input readOnly value={pingUrl}/>
              <CopyButton textToCopy={pingUrl} id={"id-copy-api-url"}>Copy</CopyButton>
            </InputGroup>
            <br/>
            <div className="row">
              <div className="col">
                <h4>Request</h4>
                <Tabs defaultActiveKey={"json"}>
                  <Tab eventKey={"json"} title={"JSON"}>
                    <JsonExample data={Ping(source?.token, source?.schema_example)} scrollable/>
                  </Tab>
                  <Tab eventKey={"curl"} title={"curl"}>
                    <CurlExample json={Ping(source?.token, source?.schema_example)} apiKey={instructions?.api_key} url={pingUrl} />
                  </Tab>
                </Tabs>
              </div>
              <div className="col">
                <h4>Response</h4>
                <JsonExample data={PingResp} scrollable/>
              </div>
            </div>
          </Tab>
          <Tab eventKey="pingpost" title="PingPost" className="p-2">
            <p>
              A Ping Post is the completion and final agreement of the sale. Here you send the remaining Contact and/or
              PII information for a consumer.
              <br/>
              You should expect the information to be delivered to the end buyer in near realtime, but this is not
              always required.
            </p>
            <InputGroup size="sm" style={{width: '40%'}}>
              <InputGroupText>
                REQUEST URL
              </InputGroupText>
              <Input readOnly value={pingPostUrl}/>
              <CopyButton textToCopy={pingPostUrl} id={"id-copy-api-url"}>Copy</CopyButton>
            </InputGroup>
            <br/>
            <div className="row">
              <div className="col">
                <h4>Request</h4>
                <Tabs defaultActiveKey={"json"}>
                  <Tab eventKey={"json"} title={"JSON"}>
                    <JsonExample data={PingPost(source?.schema_example)} scrollable/>
                  </Tab>
                  <Tab eventKey={"curl"} title={"curl"}>
                    <CurlExample json={PingPost(source?.schema_example)} apiKey={instructions?.api_key} url={pingPostUrl} />
                  </Tab>
                </Tabs>
              </div>
              <div className="col">
                <h4>Response</h4>
                <JsonExample data={PostResp} scrollable/>
              </div>
            </div>
          </Tab>
          <Tab eventKey="post" title="Post" className="p-2">
            <p>A Post is sending a full lead to be sold immediately.</p>
            <InputGroup size="sm" style={{width: '40%'}}>
              <InputGroupText>
                REQUEST URL
              </InputGroupText>
              <Input readOnly value={postUrl}/>
              <CopyButton textToCopy={postUrl} id={"id-copy-api-url"}>Copy</CopyButton>
            </InputGroup>
            <br/>
            <div className="row">
              <div className="col">
                <h4>Request</h4>
                <Tabs defaultActiveKey={"json"}>
                  <Tab eventKey={"json"} title={"JSON"}>
                    <JsonExample data={exampleJsonPost} scrollable/>
                  </Tab>
                  <Tab eventKey={"curl"} title={"curl"}>
                    <CurlExample json={exampleJsonPost} apiKey={instructions?.api_key} url={postUrl} />
                  </Tab>
                </Tabs>
              </div>
              <div className="col">
                <h4>Response</h4>
                <Tabs defaultActiveKey="201">
                  <Tab eventKey="201" title="201">
                    <JsonExample data={PostResp} scrollable/>
                  </Tab>
                  <Tab eventKey="200" title="200">
                    <JsonExample data={PostRespNoSale} scrollable/>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Tab>

          <Tab eventKey="input-schema" title="Input Schema" className="p-2">
            <div className="row">
              <h4>Input Schema</h4>

              <div className="col-7">
                <div className="overflow-scroll" style={{maxHeight: '700px'}}>
                  {/*todo: sticky table header */}
                  <Table size="sm" bordered>
                    <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Validation</th>
                      <th>Description</th>
                      <th>Example</th>
                    </tr>
                    </thead>
                    <tbody>
                    <Source source={source}/>
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="col">
                <JsonExample data={source?.schema_example}/>
              </div>
            </div>
          </Tab>

        </Tabs>

      </PageSection>
    </>
  )
};

const Source = ({source}) => {

  if (!source) {
    return <></>
  }

  const schema = source?.schema

  return (
    <>
      {Object.keys(schema).map(key => {
        let field = schema[key]
        let isRequired = field.required ? '(Required)' : '';
        let validation = `${field.type} ${isRequired}`;
        let fieldStr = JSON.stringify(field.example)
        // Remove quote wrapped example values after they've been stringified.
        if (fieldStr[0] === '"')
          fieldStr = fieldStr.slice(1, -1);
        return <tr key={key}>
          <td className="w-25">{key}</td>
          <td>{validation}</td>
          <td>{field.description}</td>
          <td>{fieldStr}</td>
        </tr>
      })}
    </>
  )
};

const JsonExample = ({data, scrollable}) => {
  if (!data) return <></>

  // todo: scrollable
  if (data instanceof Object)
    data = JSON.stringify(data, null, 2)

  return (
    <>
    <pre>
      <CopyButton className="float-end" textToCopy={data} id={"id-json-example"}>Copy</CopyButton>
      <div className="hljs p-2" dangerouslySetInnerHTML={{
        __html: hljs.highlight(data, {language: 'json'}).value
      }}>
      </div>
  </pre>
    </>
  );
};

const curlExample = (json, apiKey, url) => `curl \\
  --header 'Authorization: Bearer ${apiKey}' \\
  --json '
  ${JSON.stringify(json, null, 4)}' \\
  ${url}`

const CurlExample = ({json, apiKey, url}) => {
  const example = curlExample(json, apiKey, url);
  return (
    <pre>
      <CopyButton className="float-end" textToCopy={example} id={"id-json-example"}>Copy</CopyButton>
      <div className="p-2" dangerouslySetInnerHTML={{
      __html: hljs.highlight('$ ' + example, {language: 'bash'}).value
      }}></div>
    </pre>
  );
}

export default PostingInstructions;
