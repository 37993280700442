import React, { useState, useEffect, useContext } from 'react';
import { InputGroup,  Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import useApi from '../../Hooks/useApi';
import { AppContext } from '../../Providers/AppProvider';
import EnhancedSelect from "./EnhancedSelect";

/*
 * React-Select element for that fetches choices from the API.
 * Verticals are cached in AppProvider.
 * Shows spinner while loading.
 */
const DynamicEnhancedSelect = ({
  name,
  endpoint,
  action = 'list',
  params={},
  value = '',
  placeholder = 'Select...',
  onChange,
  labelProperty='name',
  invalid,
  ...selectProps
}) => {

  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState(app.getCache(endpoint));
  const api = useApi(endpoint, action);

  // fetch on load
  useEffect(() => {
    if (!items || !items.length) {
      api.fetch(params);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (api.result) {

      //if paged, use 'data' array
     // setItems(api.result.data ?? api.result);
      const dataset = api.result.data ?? api.result;

      const choices =  dataset.reduce((acc, item) => {
          if (item.id && typeof item[labelProperty] !== 'undefined'){
            acc.push({value:item.id, label:item[labelProperty]})
          }
          return acc;
        }, [])
      setItems(choices)
      app.setCache(endpoint, choices);
      setIsLoading(false);
    } else if (api.error) {
      console.log('Unable to load items for select ', name, api.error);
    }
  }, [api.result, api.error]);

  return (
    <>
      { isLoading ? (
        <div className="inline-loading">
          <Spinner size="sm" />
          {' '}
          Loading...
        </div>
      ) : (
        <InputGroup>

          <EnhancedSelect
            onChange={onChange}
            options={items}
            value={value || ''}
            isInvalid={invalid}
            placeholder={placeholder}
            {...selectProps}
          />
        </InputGroup>
      )}
    </>

  );
};

DynamicEnhancedSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  action: PropTypes.string,
  labelProperty: PropTypes.string,
  params: PropTypes.object,
};
DynamicEnhancedSelect.defaultProps = {
  onChange: () => {},
  invalid: false,
  labelProperty: 'name',
};

export default DynamicEnhancedSelect;
