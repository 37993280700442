import {useDateRangeValue} from "@thedmsgroup/mastodon-ui-components";
import {intervalToDuration, differenceInSeconds} from "date-fns";

export const useChartInterval = () => {
  const {valueToDate} = useDateRangeValue();

  const unitLabels = {
    ms: 'millisecond',
    s: 'second',
    m: 'minute',
    h: 'hour',
    d: 'day',
    w: 'week',
    M: 'month',
    y: 'year',
  }

  /*
   * Given an interval selection and time range values, convert to an API interval
   * and scale the interval if needed.
   * Examples:
   * 'auto' for 'now-1h' (last hour) converts to '1m'
   * '1m' for 'now-1h'  remains '1m'
   * '1s' for 'now-1h' is scaled to '1m'
   */
  const intervalToApi = (interval, start, end, maxBuckets=60) => {
    let scaledInterval;
    if (interval === 'auto') {
      interval = getAutoChartInterval(start, end);
    } else {
      scaledInterval = getScaledInterval( interval, start, end, maxBuckets)
    }
    return {interval, scaledInterval}
  }

  // convert the date selection (which might be an absolute date, or relative dates like 'now-1h')
  // into start and end dates
  const normalizeDateSelection = (start, end) => {
    const startDate = valueToDate('start', start);
    let endDate = valueToDate('end', end)

    //Sometimes end dates are in the future, eg "today", "this week"
    //In that case, change end date to now because we aren't getting date for the future
    //so it will not be charted
    const nowUTC = new Date();
    if (endDate > nowUTC) {
      endDate = nowUTC;
    }
    return {startDate, endDate}
  }

  const getAutoChartInterval = (start, end) => {
    const {startDate, endDate } = normalizeDateSelection(start, end)
    if (startDate && endDate && endDate > startDate) {
      const { years, months,weeks, days, hours, minutes, seconds} = intervalToDuration({start:startDate, end: endDate})
     // console.log("useChartInterval.js:",`weeks: ${weeks}, days: ${days}, hours:${hours}, min:${minutes}, sec:${seconds}` );
      if (years > 0) {
        return years > 1 ? '1y' : '1w';
      } else if (months > 0) {
        return months > 2 ? '1w' : '1d'
      } else if (weeks > 0) {
        if (weeks === 1) {
          return '3h'
        } else if (weeks < 8) {
          return '12h'
        }
        return '1d'
      } else if (days > 0) {
        if (days < 2) {
          return '30m'
        } else if (days < 5) {
          return '1h'
        }
        return '3h';
      } else if (hours > 0 ) {
        if (hours < 3) {
          return '1m'
        } else if (hours < 8) {
          return '5m'
        } else if (hours < 17) {
          return '10m'
        }
        return '30m';
      } else if (minutes > 0 ) {
        if (minutes <= 2) {
          return '1s'
        } else if (minutes <= 5) {
          return '5s'
        } else if (minutes <= 10) {
          return '10s'
        }
        return '30s';
      } else if (seconds > 0 ) {
        if (seconds <= 10) {
          return '200ms'
        } else if (seconds <= 10) {
          return '400ms'
        }
        return '1s';
      }

    }


    return '';
  }

  const getIntervalLabel = (interval, showOne) => {
    let label = '';
    if (interval && typeof interval === 'string') {
      const {amount, unit} = parseInterval(interval);
      if (amount && unit) {
        const unitLabel = unitLabels[unit];
        if (unitLabel) {
          label = `${unitLabel}${amount > 1 ? 's':''}`
          if (amount > 1 || showOne) {
            label = `${amount} ${label}`
          }
        }
      }
    }
    return label
  }


  /*
   * Get an adjusted interval if the given interval would produce too many chart buckets.
   */
  const getScaledInterval = (interval, start, end, maxBuckets=60) => {
    let newInterval;
    const {startDate, endDate } = normalizeDateSelection(start, end)

    if (startDate && endDate && endDate > startDate) {

      const durationSec = differenceInSeconds(endDate, startDate);
      const intervalSec = intervalToSeconds(interval);
      if (intervalSec && durationSec > intervalSec) {
        //given interval makes too many chart buckets
        if (Math.ceil(durationSec / intervalSec) > maxBuckets) {

          const scale = ['1s', '30s', '1m', '5m', '10m', '30m', '1h', '3h', '12h', '1d', '1w', '1M', '1q'];
          const scaleIndex = scale[interval] ? scale.indexOf(interval) : 0;
          if (scaleIndex < scale.length - 1) {
            for (let i = scaleIndex + 1; i < scale.length - 1; i++) {
              const intervalSec = intervalToSeconds(scale[i]);
              if (Math.ceil(durationSec / intervalSec) <= maxBuckets) {
                newInterval = scale[i];
                break;
              }
            }
          }

        }
      }
    }

    return newInterval;
  }

  const intervalToSeconds = (interval) => {
    const {amount, unit} = parseInterval(interval);
    if (amount && unit) {
      switch(unit) {
        case 's':
          return amount * 1;
        case 'm':
          return amount * 60;
        case 'h':
          return amount * 60 * 60;
        case 'd':
          return amount * 24 * 60 * 60;
        case 'w':
          return amount * 7 * 24 * 60 * 60;
        case 'M':
          return amount * 30 * 7 * 24 * 60 * 60;
      }
    }
    return 0;
  }

  // const intervalUnitToSeconds = (unit) => {
  //
  //     switch(unit) {
  //       case 's':
  //         return 1;
  //       case 'm':
  //         return 60;
  //       case 'h':
  //         return 60 * 60;
  //       case 'd':
  //         return 24 * 60 * 60;
  //       case 'w':
  //         return 7 * 24 * 60 * 60;
  //       case 'M':
  //         //approximate
  //         return 30 * 7 * 24 * 60 * 60;
  //       default:
  //         return 0;
  //     }
  //
  // }

  const parseInterval = (interval) => {
    const result = {amount:null, unit: null}
    if (typeof interval === 'string') {
      const matches = interval.match(/(\d+)([a-zA-Z]+)/)
      if (matches && matches.length >= 3) {
        result.amount = matches[1];
        result.unit = matches[2];
      }
    }
    return result;
  }

  return {intervalToApi, getAutoChartInterval, getIntervalLabel, getScaledInterval}
}
