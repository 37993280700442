import React from 'react';
import {useRouteError, useLocation, useNavigate} from "react-router-dom";
import {Button} from "reactstrap";
import PageSection from "./PageSection";

// Using with react-router errorElement
// <Route path="/thing" element={<Thing/>} errorElement={<ErrorBoundary/> />
// Note: apparently this does not work inside descendent <Routes> elements, only at the top
const ErrorBoundary = () => {
  let error = useRouteError();
  const location = useLocation();
  const navigate = useNavigate();


  return (
    <div id="error-boundary">
      <PageSection title="Something went wrong" className="m-3">

        <p>Sorry about that.</p>

        {error.message && <pre>{error.message}</pre>}

        <div className="d-flex">
          <Button size="sm" onClick={()=>navigate(location)}>Reload the page</Button>
          <Button size="sm" className="ms-2" onClick={()=>navigate("/accounts")}>Home</Button>
        </div>


      </PageSection>
    </div>

  )
}

ErrorBoundary.displayName = "ErrorBoundary";
export default ErrorBoundary
