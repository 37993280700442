import React, { useEffect, useState, useContext } from 'react';
import {
  Row, Col, FormGroup, Input, Label, Button, Alert, FormFeedback, InputGroup, Spinner, ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import { Required } from '../../../Components/Form/FormCommon';
import useFormState from '../../../Hooks/useFormState';
import LoadingBar from '../../../Layout/LoadingBar';
import DynamicSingleSelect from '../../../Components/Form/DynamicSingleSelect';
import EnhancedSelect from "../../../Components/Form/EnhancedSelect";
import { AppContext } from '../../../Providers/AppProvider';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please enter a channel name'),
  product: Yup.string().trim().required('Please select a product'),
  vertical_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select a vertical'),
  account_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select an account'),
});

/*
Component with form for editing/creating a Channel
 */
const Channel = ({ channel, closeModal }) => {
  const app = useContext(AppContext);
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      id: channel.id,
      name: channel.name,
      description: channel.description,
      product: channel.product,
      vertical_id: channel.vertical.id,
      account_id: channel.account.id,
      status: channel.status,
    },
    ValidationSchema,
  );

  const [accountOptions, setAccountOptions] = useState([]);
  const isNew = (channel.id === 0);

  useEffect(
    () => {
      if (!accountOptions.length) {
        loadAccountListOptions();
      }
    },
  );

  const loadAccountListOptions = async () => {
    const accounts = await app.api.endpoints.accounts.list();
    setAccountOptions(accounts.map(acc => ({value:acc.id, label:acc.name})));
  };

  const handleSelectAccount = (id) => formApi.setValue('account_id', id ?? "");
  const handleSelectVertical = (id) => formApi.setValue('vertical_id', id ?? "");


  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating channel' : 'Updating channel');

    const ch = { ...formValues };
    delete ch.accountList;

    let result = null;

    if (ch.id) {
      result = await app.api.endpoints.channels.update(ch);
    } else {
      result = await app.api.endpoints.channels.create(ch);
    }

    app.showLoader(false);

    if (!result) {
      notify(`Unable to save channel: ${app.api.error.name}`, 'error');
    } else {
     closeModal(true);
    }
  };

  return (
    <>
      <LoadingBar name="modal" />

      {!formIsValid
            && (
            <Alert color="warning" className="alert-form-validation">
              <FaIcon icon="exclamation-triangle" size="lg" />
              {' '}
              Please correct the errors below
              before continuing
            </Alert>
            )}

      <div className="modal-content-background">
        <Row>
          <Col sm={12}>
            <FormGroup>
              <Label>
                Name
                {' '}
                <Required />
              </Label>
              <Input
                type="text"
                name="name"
                onChange={formApi.handleOnChange}
                value={formValues.name}
                invalid={!!formErrors.name}
              />
              <FormFeedback>{formErrors.name}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>
                Description
              </Label>

              <Input
                type="textarea"
                name="description"
                onChange={formApi.handleOnChange}
                value={formValues.description}
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Account
                {' '}
                { isNew && <Required />}
              </Label>

              { isNew && accountOptions.length !== 0 && (
                <>
                  <EnhancedSelect
                    isClearable
                    isSearchable
                    onChange={handleSelectAccount}
                    options={accountOptions}
                    value={formValues.account_id || ''}
                    tabSelectsValue
                    backspaceRemovesValue
                    isMulti={false}
                    controlShouldRenderValue
                    hideSelectedOptions
                    closeMenuOnSelect
                    placeholder="Select Account"
                    isInvalid={!!formErrors.account_id}

                  />
                  <FormFeedback>{formErrors.account_id}</FormFeedback>
                </>


              )}


              {/* New Order: Spin until we get Accounts */}
              { isNew && accountOptions.length === 0 && (
              <div className="inline-loading">
                <Spinner size="sm" />
                {' '}
                Loading...
              </div>
              )}

              {/* Read only text for existing order */}
              { !isNew && formValues.account_id && (
              <>
                <Input type="text" defaultValue={channel.account.name} readOnly />
                <Input type="hidden" name="account_id" defaultValue={channel.account?.id} />
              </>
              )}

            </FormGroup>

            <FormGroup>

              <Label>
                Vertical
                {' '}
                { isNew && <Required />}
              </Label>
              { isNew && (

              <DynamicSingleSelect
                onChange={formApi.handleOnChange}
                defaultValue={formValues.vertical_id}
                name="vertical_id"
                endpoint="verticals"
                invalid={!!formErrors.vertical_id}
                labelProperty="display_name"
                params={{options:true}}
              />

              )}

              {/* Read only text for existing channel */}
              { !isNew && formValues.vertical_id && (
                <Input type="text" defaultValue={channel.vertical.display_name} readOnly />
              )}
              <FormFeedback>{formErrors.vertical_id}</FormFeedback>
            </FormGroup>

            <FormGroup>

              <Label>
                Product
                {' '}
                { isNew && <Required />}
              </Label>
              { isNew && (

                <InputGroup>
                  <Input
                    type="select"
                    onChange={formApi.handleOnChange}
                    defaultValue={formValues.product}
                    name="product"
                    invalid={!!formErrors.product}
                  >
                    <option value="">Select...</option>
                    <option value="clicks">Clicks</option>
                    <option value="calls">Calls</option>
                    <option value="leads">Leads</option>
                  </Input>
                  <FormFeedback>{formErrors.product}</FormFeedback>
                </InputGroup>

              )}

              {/* Read only text for existing channel */}
              { !isNew && formValues.product && (
                <Input type="text" defaultValue={formValues.product} readOnly />
              )}
              <FormFeedback>{formErrors.product}</FormFeedback>
            </FormGroup>

            {/* status for existing channel (new ones set active by default) */}
            { !isNew && (
            <FormGroup>
              <Label>
                Status
              </Label>
              <InputGroup>
                <Input
                  type="select"
                  onChange={formApi.handleOnChange}
                  value={formValues.status}
                  name="status"
                >
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>
                </Input>
              </InputGroup>
            </FormGroup>

            )}

          </Col>
        </Row>

      </div>

      <ModalFooter>

          <Button
            onClick={() => closeModal()}
            color="link"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            size="sm"
          >
            Save
          </Button>

      </ModalFooter>
    </>
  );
};

Channel.propTypes = {
  closeModal: PropTypes.func.isRequired,
  channel: PropTypes.object,
};

export default Channel;
