import React, { useMemo } from 'react';
import { Spinner } from 'reactstrap';

//  Wrapper for React.lazy loading
//  from: https://artemdemo.com/blog/20181125-lazy-load-components-in-react/
//  usage:
//  const LazyThing = Lazify( () => import(/* webpackChunkName: "Stuff" */ "./Thing"));
//  then: <LazyThing spinner={true}/>  (optional show spinner)
//  or:
//  <Route path='/thing' component={LazyThing}/>
//  (the webpackChunkName is optional but can be useful in dev)
//
//  Modified with useMemo
const lazify = (loader) => (props) => {
  // const Component = React.lazy(loader);

  // useMemo: lazy loaded components that rely on query string parameters
  // tend to remount when parameters change. useMemo hook prevents that.
  const Component = useMemo(() => React.lazy(loader), [
    loader,
  ]);

  const loadingFallback = (() => {
    if (React.Children.count(props.children) > 0) {
      return props.children;
    }
    return props.spinner ? (<Spinner size="lg" />) : (null);
  })();

  return (
    <React.Suspense fallback={loadingFallback}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default lazify;
