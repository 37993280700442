import React, { useRef, useEffect } from 'react';
import { FormFeedback, Input } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/*
 * Reactstrap input with an icon in the background
 * (see layout/main.scss for styles, and _faBackground.scss for sass functions)
 *
 * If you need a ref to the input use  <InputWithIcon innerRef={myRef} ....
 * Use inputSize="sm" attribute when smaller input is desired
 */
const InputWithIcon = ({
                         icon,
                         iconSize="sm",
                         iconSide="left",
                         containerClass="",
                         onClear=null,
                         inline=false,
                         autofocus=false,
                         ...restProps
}) => {
  const inputRef = useRef(null);
  // Note on right aligned icons:
  // This might conflict with reactstrap's input invalid state, which puts an svg X on the right in the background.
  // Maybe turn off background or background-image for .form-control.is-invalid ?

  // Wrapping in div keeps it in a block which ensures that input will go below label in a FormGroup.
  // The input-icon-container is inline-block which works with fixed-width input classes like tiny or teeny
  // (specifically, ensures that the right-aligned icon stays within the input).
  // However, sometimes you want it to expand like a block div. Pass containerClass="expands".

  // focus on load if indicated
  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleClear = () => {
    if (inputRef.current) inputRef.current.value = '';
    onClear();
  };

  // The TextSearch component uses 'defaultValue' instead of value property because of debouncing.
  // This results in the value not clearing properly in some cases.
  // useEffect to the rescue.
  useEffect(() => {
    if (restProps.defaultValue !== undefined && !restProps.defaultValue && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [restProps.defaultValue]);

  return (
    <div className={classnames({ 'd-inline': inline })}>
      <div className={classnames('input-icon-container', containerClass, [`icon-${iconSide}`], { 'size-sm': restProps.bSize === 'sm' })}>
        <FaIcon icon={icon} size={iconSize} className={classnames('input-icon', iconSide)} />
        {onClear && <FaIcon icon="times" className="input-icon right clear" onClick={handleClear} />}
        <Input innerRef={inputRef} size={restProps.bSize || ''} {...restProps} />
        <FormFeedback>{restProps.error}</FormFeedback>
      </div>
    </div>
  );
};

InputWithIcon.propTypes = {
  iconSize: PropTypes.string,
  iconSide: PropTypes.string,
  icon: PropTypes.string,
  onClear: PropTypes.func,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  autofocus: PropTypes.bool,
};

InputWithIcon.defaultProps = {
  iconSize: 'sm',
  iconSide: 'left',
  icon: '',
  onClear: null,
  containerClass: '',
  className: '',
  inline: false,
};

export default InputWithIcon;
