import React, { useContext, useState, useEffect } from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, InputGroup, UncontrolledDropdown} from "reactstrap";
import { Filter, TextSearch } from '../../../Components/Table/Filter';
import { StatusChoices, ProductChoices } from '../../../Components/Table/FilterChoices';
import RightDateAndControls from '../../../Components/Table/RightDateAndControls';
import { AppContext } from '../../../Providers/AppProvider';
import {useFilters} from "../../../Hooks/useFilters";

const Filters = ({filters, onChange, onRefresh}) => {
  const app = useContext(AppContext);
  const [choices, setChoices] = useState({});
  const {onChangeFilter, clearSearch, reset} = useFilters(filters, onChange, {defaultFilters:{search_type:'accounts'}});

  // load choices
  useEffect(() => {
    const getChoices = async () => {
      const verticals = await app.api.endpoints.verticals.list({options:true});
      if (verticals) {
        setChoices({
          status: StatusChoices,
          product: ProductChoices,
          vertical: verticals.map((item) => ({ value: item.id, label: item.display_name })),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);


  return (
    <div className="d-flex justify-content-between position-relative">
      {/*<div className={classnames(['loading-overlay', { show: loading }])} />*/}
      <div className="d-flex">
        <div className="boxed-filter search-filter me-3">
          <InputGroup className="flex-nowrap align-items-center">
            <TextSearch
              value={filters.search || ''}
              placeholder={"Search " + filters.search_type}
              onUpdate={(val) => onChangeFilter('search', val)}
              onClear={clearSearch}
              autofocus
              style={{minWidth:"250px"}}
            />
            <UncontrolledDropdown>
              <DropdownToggle caret size="xs" color="link">{filters.search_type}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { onChangeFilter('search_type','accounts'); }}>Account</DropdownItem>
                <DropdownItem onClick={() => { onChangeFilter('search_type', 'orders'); }}>Order</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

          </InputGroup>


        </div>

        <div className="min-250">
          <Filter
            placeholder="Vertical"
            param="vertical_id"
            options={choices.vertical}
            value={filters['vertical_id'] || []}
            onChange={onChangeFilter}
            isMulti={false}
          />
        </div>

        <div className="min-250">
          <Filter
            placeholder="Product"
            param="product"
            options={choices.product}
            value={filters['product'] || []}
            onChange={onChangeFilter}
            isMulti={false}
          />
        </div>

        <div className="min-250">
          <Filter
            placeholder="Order Status"
            param="order_status"
            options={choices.status}
            value={filters['order_status'] || ""}
            onChange={onChangeFilter}
            isMulti={false}
          />
        </div>

      </div>

      <RightDateAndControls onRefresh={onRefresh} onReset={reset} />
    </div>
  );
};

export default Filters;
