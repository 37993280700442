
/* A hook that works around an iMask issue.
 * https://github.com/uNmAnNeR/imaskjs/issues/881
 * iMask recommends using their 'onAccept' as a change handler,
 * but unfortunately it will fire on load, causing our form
 * validator to falsely believe the form has changed.
 * This hook checks for an event, and fires the provided callback
 * if a user event exists.
 *
 * The callback should accept "value" at minimum, but could accept (value, mask, event)
 * https://github.com/uNmAnNeR/imaskjs/tree/master/packages/react-imask
 *
 * usage:
 * const myHandler = (val) => console.log('the value from the masked input!', val)
 * You can just use 'onAccept', or call it your own name
 * const {onAccept:myAcceptHandler} = useImaskNiceAccept(myHandler)
 * <MaskedInput onAccept={myAcceptHandler} ...other props... />
 *
 * TODO: incorporate this workaround into the UI library component "MaskedInput"
*/
const useImaskNiceAccept = (callback) => {

  const onAccept = (value, mask, evt) => {
    if (evt && typeof callback === 'function') {
      callback(value, mask, evt);
    }
  }
  return {onAccept}
}

export default useImaskNiceAccept
