import React from 'react';
import './styles.scss';

type AdDisplayProps = {
  url: string;
  url_display: React.ReactNode|string;
  image_url: string;
  headline: string;
  description: string;
  html_description: React.ReactNode|string;
  image?: {
    id:string|number;
    url:string;
  };
  logo?: {
    id:string|number;
    url:string;
  };
}
/*
 * For static display of ad in its current state.
 * Used in lists or editor preview
 */
const AdDisplay = ({
    url,
    url_display,
    image_url,
    headline,
    description,
    html_description,
  image,
  logo
                   }: AdDisplayProps) => (
  <div className="ad-display">
    <div className="ad-display-img">
      {/* TBD: determine image size, maybe as params in request for ads */}
      <img className="" src={image?.url ?? image_url} width="180" />
    </div>
    <div className="ad-display-body flex-fill">
      <h5>{headline}</h5>

      <div className="d-flex">
        <div className="ad-display-description flex-fill" dangerouslySetInnerHTML={{ __html: html_description || description }} />
        {logo?.id  && (
          <div className="ms-2">
            {' '}
            <img className="" src={logo.url} width="175" />
          </div>
        )}
      </div>

      <div className="ad-display-url">
        <a href={url} onClick={(e) => e.preventDefault()}>
          {url_display || url}
        </a>
      </div>
    </div>
  </div>
);


export default AdDisplay;
