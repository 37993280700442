// import LocalStorageService from "../../../../Services/LocalStorageService";
// import moment from "moment";

// const PENDING_TTL = 500;

/*
 * Functions for managing credit cards,
 * including storing and retrieving pending credit card info in session storage.
 *
 * NOTE: the pending card stuff is not currently used. It displayed a newly added card in a pending state
 * until the API updated it  (TODO: change date functions to date-fns)
 * Instead, we just display a message saying the card will be available to use in a few minutes
 */
const CardService = {

  getCardNameFromType(type) {
    switch (type.toLowerCase()) {
      case 'amex':
      case 'americanexpress':
      case 'ax':
      case 'am':
        return 'American Express';
      case 'visa':
      case 'vs':
        return 'Visa';
      case 'mastercard':
      case 'mc':
        return 'Mastercard';
      case 'discover':
      case 'di':
        return 'Discover';
      case 'jcb':
      case 'jc':
        return 'JCB';
      case 'diners':
      case 'dn':
        return 'Diners Club';
      default:
        return 'Unknown Card Type';
    }
  },

  getLast4(num) {
    return num.length > 4 ? num.slice(-4, num.length) : num;
  },

  // getPendingCards() {
  //   const cards = LocalStorageService.getSessionItem('PENDING_CARDS');
  //   return cards || [];
  // },
  //
  // storePendingCard(number, type, exp) {
  //   number = this.getLast4(number);
  //   const cards = this.getPendingCards();
  //   if (Array.isArray(cards) && !cards.some(card => card.last_four === number)) {
  //     cards.push({last_four: number, type, expiration_date: exp, time: moment().format()});
  //     LocalStorageService.setSessionItem('PENDING_CARDS', cards);
  //   }
  // },

  // Checks pending cards from storage, clears if they are in the API list or stale
  // (There's no official status of 'stale' from the API, we're just assuming that if
  // MeS never called our server with the response, the card is lost and we don't want
  // to keep it in the list
  //   updatePendingCards(cardsOnFile) {
  //     const cards = this.getPendingCards();
  //     if (!cards || cards.length === 0) {
  //       sessionStorage.removeItem('PENDING_CARDS');
  //       return null;
  //     } else {
  //       let filtered = cards.reduce((acc, storedCard) => {
  //         console.log('Funds.js:updatePendingCards, moment check', storedCard.time, moment().diff(storedCard.time, 'seconds'));
  //         if (moment().diff(storedCard.time, 'seconds') < PENDING_TTL) {
  //           if (!cardsOnFile.some(card => card.last_four === storedCard.last_four)) {
  //             acc.push(storedCard);
  //           }
  //         }
  //
  //         return acc;
  //       }, []);
  //       if (filtered.length) {
  //         LocalStorageService.setSessionItem('PENDING_CARDS', filtered);
  //         return filtered;
  //       } else {
  //         sessionStorage.removeItem('PENDING_CARDS');
  //         return null;
  //       }
  //     }
  //
  //
  //   },

  // removePendingCard(last4) {
  //   const cards = this.getPendingCards();
  //   const filtered = cards.filter( card => card.last_four !== last4);
  //   if (filtered.length) {
  //     LocalStorageService.setSessionItem('PENDING_CARDS', filtered);
  //   } else {
  //     sessionStorage.removeItem('PENDING_CARDS');
  //   }
  //
  //   return filtered.length ? filtered : null;
  // },

};

export default CardService;
