import React from 'react';
import ReactDOM from 'react-dom';

const DocTitle = ({ pageTitle, siteTitle }) => {
  const titleEl = document.getElementsByTagName('title')[0];

  let fullTitle;
  if (pageTitle) {
    fullTitle = `${pageTitle} - ${siteTitle}`;
  } else {
    fullTitle = siteTitle;
  }

  return ReactDOM.createPortal(fullTitle || '', titleEl);
};

DocTitle.defaultProps = {
  pageTitle: null,
  siteTitle: 'Mastodon: by DMS Group',
};

export default DocTitle;
