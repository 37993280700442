import React, { useState } from 'react';
import {
  Button, FormGroup, Input, Label, Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';

const EditCard = ({
  card, updateCard, removeCard, cancelEdit, isUpdating,
}) => {
  const [autoBilling, setAutoBilling] = useState(card.auto_billing_eligible);
  const [deleteCardConfirm, setDeleteCardConfirm] = useState(false);

  const handleUpdateCard = () => {
    updateCard(autoBilling);
  };

  return (
    <div className="edit-card mt-1 mb-1 py-3 px-2">
      <div className="cc-auto-pay-col d-flex justify-content-between">
        <div>
          <FormGroup check>
            <Label check>
              <Input
                name="exclusive"
                type="checkbox"
                defaultChecked={!!autoBilling}
                onChange={(e) => setAutoBilling(e.target.checked)}
              />
              Use for automatic payments
              {' '}
              <br />
              <small>(if payment plan is configured)</small>
            </Label>
          </FormGroup>
        </div>
        <div>
          {deleteCardConfirm === true ? (
            <>
              {isUpdating === true ? (
                <Spinner size="sm" />
              ) : (
                <div className="inline-confirm">
                  <span className="me-2">Remove card?</span>
                  <Button onClick={() => removeCard(card.id)} size="sm" color="danger" className="me-2">Yes</Button>
                  <Button onClick={() => setDeleteCardConfirm(false)} size="sm" color="secondary" outline>No</Button>
                </div>
              )}
            </>
          ) : (
            <>
              {isUpdating === true ? (
                <Spinner size="sm" />
              ) : (
                <>
                  <Button color="link" size="sm" className="me-2" onClick={cancelEdit}>Cancel</Button>
                  <Button color="danger" size="sm" className="me-2" onClick={() => setDeleteCardConfirm(true)}>Remove Card</Button>
                  <Button color="primary" size="sm" onClick={handleUpdateCard}>Update</Button>

                </>
              )}
            </>
          )}
        </div>

      </div>
    </div>
  );
};

EditCard.propTypes = {
  card: PropTypes.object,
  updateCard: PropTypes.func,
  removeCard: PropTypes.func,
  cancelEdit: PropTypes.func,
  isUpdating: PropTypes.bool,
};

export default EditCard;
