import React, {
  useEffect, useContext, useReducer, useRef, useMemo,
} from 'react';
import {
  Alert, Button, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown,
} from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { startOfDay, endOfDay, differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import { useRowSelect } from 'react-table';
import FlexTable from '../../Components/Table/FlexTable';
import { AppContext } from '../../Providers/AppProvider';
import ReportsFilters from './ReportsFilter';
import {
  extractColumnsAndData,
  convertFilterParams,
  validateViewColumns,
} from './api';
// import Storage from "../../Services/storage"
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import PageSection from '../../Layout/PageSection';
import { queryStringToFilters, filtersToQueryString } from '../../Components/Table/queryString';
import Select from '../../Components/Form/SimpleSelect';
import DefaultReportLinks from './DefaultReportLinks';
import LocalStorageService from '../../Services/LocalStorageService';
import LoadTimeWarning from './LoadTimeWarning';

const LoadTimeWarningThresholdDays = 29;
const DISABLE_LOAD_WARNING_KEY = 'disable_report_load_warning';//

const NoData = () => <Alert color="light">No results...</Alert>;

const stateReducer = (state, action) => {
  const {
    name, report, filterSettings, hiddenColumns, selectedRows,
  } = action;
  switch (name) {
    case 'load_report':
      return {
        ...state, loading: true, hasQuery: true, report: {},
      };
    case 'load_fail':
      return { ...state, loading: false, report: {} };
    case 'load_complete':
      return {
        ...state, loading: false, report, filterSettings, hiddenColumns,
      };
    case 'download':
      return { ...state, downloading: true };
    case 'download_complete':
      return { ...state, downloading: false };
    case 'set_view_columns':
      return { ...state, filterSettings, hiddenColumns };
    case 'set_filters':
      return { ...state, filterSettings };
    case 'clear_filters':
      return {
        ...state, hasQuery: false, report: {}, filterSettings,
      };
    case 'load_time_warning_on':
      return { ...state, loadTimeWarning: true };
    case 'load_time_warning_off':
      return { ...state, loadTimeWarning: false, loading: false };
    case 'disable_load_time_warning':
      return { ...state, loadTimeWarning: false, disableLoadTimeWarning: true };
    case 'select_rows':
      return { ...state, selectedRows };

    default:
      return state;
  }
};

const DefaultState = {
  loading: false,
  downloading: false,
  loadTimeWarning: false,
  disableLoadTimeWarning: true,
  report: {},
  filterSettings: {},
  selectedRows: {},
  hasQuery: false,
};

/*
 * Paged report of values aggregated in a date period.
 * Columns are determined dynamically from server response.
 */
const AggregateReport = ({
  reportType,
  csvDefaultName,
  title,

}) => {
  const app = useContext(AppContext);
  const Reporting = React.useMemo(() => app.getApi('reporting'), []);
  const reportFunc = Reporting.resolveAggregateReportFunc(reportType);
  const navigate = useNavigate();
  const location = useLocation();
  const doLoad = useRef(false);
  const userTimezone = app.user.timezone;

  // For react table
  const initialTableState = useMemo(() => ({
    sortBy: [{ id: '', desc: true }],
  }), []);

  const defaultFilterSettings = useMemo(() => ({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    // groupBy: ['time_day'],
  }));

  let FS;
  // let hasQuery = false;

  // Disabling storage - use query string or default
  if (location.search) {
    FS = queryStringToFilters(location.search);
    // Make sure we have dates at minimum
    FS = { ...defaultFilterSettings, ...FS };

    const newQuery = filtersToQueryString(FS);
    if (newQuery !== location.search) {
      navigate({ search: String(newQuery) });
    }
    // hasQuery = true;
    // storage.set(settingsKey, filterSettings)
  } else {
    FS = { ...defaultFilterSettings };
    // filterSettings = storage.get(settingsKey, {...defaultFilterSettings})
    // history.push({
    //   search: filtersToQueryString({...defaultFilterSettings}),
    // })
  }

  const [state, dispatch] = useReducer(
    stateReducer,
    // INITIAL REDUCER STATE
    {
      ...DefaultState,
      filterSettings: FS,
      loadTimeWarning: false,
      disableLoadTimeWarning: !!LocalStorageService.getLocalItem(DISABLE_LOAD_WARNING_KEY),
      hasQuery: !!location.search,
      hiddenColumns: [],
    },
  );

  useEffect(() => {
    // TODO: parse and update filters, load report
  }, [location.search]);

  // This doLoad ref is set by executeDefinedReport. When executing a defined report, we want the filter state to be
  // updated, and then run the report. Unfortunately, with hooks or reducers, there are no callbacks after setting state.
  // This useEffect watches the ref and acts like a post-update callback for loading the report.
  useEffect(() => {
    // console.log('AggregateReportFunc.js:useEffect doLoad changed', doLoad.current, state.filterSettings);
    if (doLoad.current) {
      preExecute();
      doLoad.current = false;
    }
  }, [doLoad.current]);

  // React-table has a 'hiddenColumns' array of column ids.
  // view_cols is a list of selected column ids to view.
  // Put the view_cols into the filterSettings and query string.
  // Set hiddenColumns with column ids NOT in view_cols.
  const handleUpdateViewCols = (view_cols) => {
    const newSettings = { ...state.filterSettings, view_cols };

    navigate({
      search: filtersToQueryString(newSettings),
    });

    dispatch({
      name: 'set_view_columns',
      filterSettings: newSettings,
      hiddenColumns: getHiddenColumns(view_cols, state.report.columns),
    });
  };

  const getHiddenColumns = (visibleColIds, tableCols) =>
    // console.log('AggregateReport.js:getHiddenCOlumns', visibleColIds, tableCols);
    (visibleColIds.length
      ? tableCols.reduce((acc, col) => {
        if (!visibleColIds.includes(col.id)) {
          acc.push(col.id);
        }
        return acc;
      }, [])
      : []); // none hidden

  const onSelectRow = (index) => {
    const selected = { ...state.selectedRows };
    if (selected[index]) {
      selected[index] = !selected[index];
    } else {
      selected[index] = true;
    }
    dispatch({
      name: 'select_rows',
      selectedRows: selected,
    });
  };

  const loadReport = async (settings = {}) => {
    // TODO: fix default sorting.
    // if we call up defined report grouping by account, request automatically has sort by param, and result is sorted:
    // https://clicks-api.ue-tech.uedev.com/reports/looker/advertiser.json?from=2021-07-18T00:00:00&to=2021-07-18T23:59:59&group_by=account&sort_by=account&sort_dir=asc
    // But the table is not sorted
    // table instance has setSortBy, but how to call if from here?
    // maybe change initialSort, set autoResetSortBy=true

    dispatch({ name: 'load_report' });

    const params = convertFilterParams(settings, userTimezone);
    try {
      const result = await reportFunc(params);
      if (result) {
        const rpt = extractColumnsAndData(result);
        // console.log('AggregateReport.js:extracted report', rpt);
        // filterSettings.view_columns might have columns that are not in report.columnChoices
        // (because the settings were from a previous report that did not have those columns) dffduy
        settings.view_cols = validateViewColumns(settings.view_cols, rpt.columnChoices);

        dispatch({
          name: 'load_complete',
          report: rpt,
          filterSettings: settings,
          hiddenColumns: getHiddenColumns(settings.view_cols, rpt.columns),
        });
      } else {
        notify("Oops, couldn't load report...", 'error');
        dispatch({ name: 'load_fail' });
      }
    } catch (err) {
      // if (axios.isCancel(err)) {
      //   console.error(`Cancelling previous request: ${err.message}`);
      // } else {
      notify("Oops, couldn't load report...", 'error');
      console.error(err);
      //  }
      dispatch({ name: 'load_fail' });
    }
  };

  const loadReportCSV = async () => {
    if (!csvDefaultName) return;
    dispatch({ name: 'download' });
    const settings = state.filterSettings;

    try {
      const params = convertFilterParams(settings, userTimezone);
      const response = await reportFunc(params, 'csv');

      if (response && response.data) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        // data will be blobby
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = csvDefaultName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.parentNode.removeChild(a);
      } else {
        notify("Oops, couldn't load CSV (empty data)...", 'error');
      }
    } catch (e) {
      notify("Oops, couldn't load CSV...", 'error');
      console.error(e);
    } finally {
      dispatch({ name: 'download_complete' });
    }
  };

  const executeReport = () => {
    const search = filtersToQueryString({ ...state.filterSettings });
    if (search !== location.search) {
      navigate({
        search,
      });
    }

    loadReport(state.filterSettings);
  };

  const executeDefinedReport = (filterSettings) => {
    // TODO: need some way to callback and executeReport after state update
    doLoad.current = true;
    dispatch({
      name: 'set_filters',
      filterSettings,
      hasQuery: true,
    });
  };

  const handleClearFilters = () => {
    navigate({
      search: filtersToQueryString({}),
    });
    dispatch({
      name: 'clear_filters',
      filterSettings: { ...defaultFilterSettings },
    });
  };

  const handleChangeFilters = (filterSettings, executeReport) => {
    if (Object.keys(filterSettings).length === 0) {
      filterSettings = { ...defaultFilterSettings };
    }
    // console.log('AggregateReport.js:handleChangeFilters', filterSettings);
    // dispatch({filterSettings}, ()=>{
    //   executeReport && executeReport()
    // });
    dispatch({ name: 'set_filters', filterSettings });
  };

  // useEffect( () => {
  //
  // }, [state.filterSettings])

  const onFiltersLoaded = (filterSettings) => {
    if (state.hasQuery) {
      // if query already known, execute
      preExecute();
    } else if (filterSettings) {
      // loaded filter choices might be alter the filters with default choices
      dispatch({ name: 'set_filters', filterSettings });
    }
  };

  const preExecute = () => {
    // Check if date range is over warning threshold or warning is disabled
    if (!state.loadTimeWarningDisabled && getDateRangeDays() >= LoadTimeWarningThresholdDays) {
      // this.setState({loadTimeWarning: true});
      dispatch({ name: 'load_time_warning_on' });
    } else {
      executeReport();
    }
  };

  const getDateRangeDays = () => {
    const { startDate, endDate } = state.filterSettings;
    if (startDate && endDate) {
      return differenceInDays(startDate, endDate); // endDate.diff(startDate, 'days')
    }
    return 0;
  };

  const onDismissLoadTimeWarning = (doLoad, noWarn) => {
    if (noWarn) {
      LocalStorageService.setLocalItem(DISABLE_LOAD_WARNING_KEY, true);
      dispatch({ name: 'disable_load_time_warning' });
    }

    if (doLoad) {
      dispatch({ name: 'load_time_warning_on' });
      executeReport();
    } else {
      dispatch({ name: 'load_time_warning_off' });
    }
  };

  // console.log('AggregateReport.js:view_Cols from state', state.filterSettings.view_cols);
  // console.log('AggregateReport.js:hidden from state', state.hiddenColumns);

  const {
    loading, hasQuery, downloading, report: { data = [], columns = [], columnChoices = [] }, loadTimeWarning,
  } = state;

  return (
    <>
      <ReportsFilters
        reportType={reportType}
        settings={state.filterSettings}
        defaultSettings={defaultFilterSettings}
        onChange={handleChangeFilters}
        onClear={handleClearFilters}
        onExecute={preExecute}
        onFiltersLoaded={onFiltersLoaded}
        dataSource={Reporting}
        userTimezone={userTimezone}
        reportLoading={loading}
      />
      <PageSection
        title={title}
        className="overflow-section"
      >

        {loadTimeWarning && <LoadTimeWarning onDismiss={onDismissLoadTimeWarning} />}

        {/* For old version of reports too? */}
        {!loading && hasQuery && columns.length > 0 && (
          <div className="d-flex">
            <div>
              <UncontrolledDropdown className="me-2">
                <DropdownToggle color="link" caret className="ms-0 pl-0">
                  <FaIcon icon="columns" size="sm" className="fa-icons me-1" />
                  <span className="title">
                    Columns
                    {state.filterSettings.view_cols && state.filterSettings.view_cols.length > 0 && (
                    <span>
                      {' '}
                      (
                      {state.filterSettings.view_cols.length}
                      {' '}
                      selected)
                    </span>
                    )}
                  </span>
                </DropdownToggle>
                <DropdownMenu id="view-columns-dropdown" className="select-filters-dropdown dropDown">

                  <div className="modal-body">
                    <Select
                      autoFocus={false}
                      placeholder="Select columns to view..."
                      classNamePrefix="select-filter"
                      isClearable
                      onChange={handleUpdateViewCols}
                      options={columnChoices}
                      value={state.filterSettings.view_cols || []}
                      tabSelectsValue
                      backspaceRemovesValue
                      isSearchable
                      isMulti
                      menuIsOpen
                      controlShouldRenderValue
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      maxMenuHeight={200}
                    />

                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            {csvDefaultName
            && (
            <Button
              onClick={loadReportCSV}
              color="link"
              className="ms-2"
              disabled={loading || columns.length === 0}
            >
              {downloading ? <Spinner size="sm" className="me-1" /> : <FaIcon icon="file-csv" className="me-1" />}
              Download CSV
            </Button>
            )}
          </div>

        )}

        {!hasQuery && (
          <DefaultReportLinks loadReport={executeDefinedReport} reportType={reportType} />
        )}

        {loading && <div id="report-spinner"><Spinner color="secondary" size="lg" /></div>}
        {!loading && hasQuery && columns.length === 0 && <NoData />}
        {!loading && columns.length > 0
          && (
          <FlexTable
            data={state.report.data}
            columns={columns}
            initialState={initialTableState}
            className="reports-table striped"
            loading={loading}
            noDataMessage="No rows were found"
            hiddenColumns={state.hiddenColumns}
            onClickRowCallback={onSelectRow}
            selectedRowIds={state.selectedRows}
            addinHooks={[useRowSelect]}
            isStateControlled
            showFooter
          />
          )}

      </PageSection>

    </>

  );
};

AggregateReport.propTypes = {
  reportType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  csvDefaultName: PropTypes.string,
};

AggregateReport.defaultProps = {
  csvDefaultName: 'Report',
};

export default AggregateReport;
