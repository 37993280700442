import React from 'react';
import PropTypes from 'prop-types';
import {
  Visa, Mastercard, Amex, CardDefault, Discover, Jcb, Diners,
} from './index';

const getComponentFromType = (type) => {
  if (!type) return CardDefault;
  switch (type.toLowerCase()) {
    case 'amex':
    case 'americanexpress':
    case 'ax':
    case 'am':
      return Amex;
    case 'visa':
    case 'vs':
      return Visa;
    case 'mastercard':
    case 'mc':
      return Mastercard;
    case 'discover':
    case 'di':
      return Discover;
    case 'jcb':
    case 'jc':
      return Jcb;
    case 'diners':
    case 'dn':
      return Diners;
    default:
      return CardDefault;
  }
};

const CardIcon = ({ type, width, className }) => {
  const CardComponent = getComponentFromType(type);

  return (
    <span className={className}>
      <CardComponent width={width} />
    </span>
  );
};

CardIcon.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default CardIcon;
