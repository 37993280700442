import React from 'react';
import { DateFormatter, DurationFormatter } from '../../../Components/Table/Table';
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {faConciergeBell, faGavel} from "@fortawesome/free-solid-svg-icons";
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";

const ucfirst = (string) => {
  return string[0].toUpperCase() + string.substring(1)
}

const auctionToEvents = (auction) => {
  const events = []
  events.push({
    id: 'auction_started_at',
    when: auction.started_at,
    label: 'Internal event - MST Ping for Match', //aka ping received
    icon: faConciergeBell,
    description:'Consumer went through IVR and we ping MST for Match'
  })

  // MRK-8863 requested display of new event action, but this has not been implemented yet
  // in the data (see MRK-9025);
  // events.push({
  //   id: 'call_connect_attempt',
  //   when: auction[TBD],
  //   label: 'Call Connect Attempt to Advertiser',
  //   icon: faConciergeBell,
  //   description:'MST initiated the call connection to the advertiser. (Consumer will hear ringing until client answers)'
  // })

  if (auction.ivr_call) {
    events.push({
      id: 'ivr_call_received',
      when: auction.ivr_call.received_at,
      label: 'Consumer Initiated Call',
      icon: 'phone',
      description:'Consumer dialed DID and initiated call connection'
    })
    events.push({
      id: 'consumer_ended_call',
      when: auction.ivr_call.ended_at,
      label: 'Consumer Call Ended',
      icon: 'phone',
      description: 'Consumer hung up the connection.'
    })
  }

  if (auction.pii) {
    events.push({
      id: 'pii_received',
      when: auction.pii.received_at,
      label: 'Internal event - Post Initiated to Advertiser', //aka post received
      icon: 'paper-plane',
      description:'Lead details fired to client & returned destination number to MST '
    })
  }

  if (auction.received_call) {
    events.push({
      id: 'call_received',
      when: auction.received_call.received_at,
      label: 'Call Received',
      icon: 'phone',
    })
    events.push({
      id: 'advertiser_ended_call',
      when: auction.received_call.ended_at,
      label: 'Advertiser Call Ended',
      icon: 'phone',
      description: 'Advertiser hung up the connection.'
    })
    if (auction.received_call.connected_at) {
      events.push({
        id: 'call_connected',
        when: auction.received_call.connected_at,
        label: 'Call Connected',
        icon: 'phone',
        description: 'Call was answered within 20 seconds by buyer. This is when we measure billable tracking from.'
      })
    }
  }

  if (auction.deliveries) {
    for (let d of auction.deliveries) {
      events.push({
        id: 'delivery_sent',
        when: d.sent_at,
        label: `${d.integration_info?.label || d.integration.name} [${d.type}]`,
        icon: 'envelope',
      })
    }
  }

  // this is a bit awkward since sales are not available on the auction root
  const sales = auction.matches.reduce((sales, m) => {
    if (m.final_bid?.sales) {
      return [...sales, ...m.final_bid.sales]
    }
    return sales
  }, [])
  for (let s of sales) {
    events.push({
      id: 'sold_at',
      when: s.sold_at,
      label: ucfirst(auction.product.slice(0, -1)) + ` Sold`,
      icon: faGavel,
    })
  }

  // convert dates
  events.map(e => {
    e.when = e.when ? new Date(e.when) : null;
    return e
  })

  // Sort events by time ascending
  // Some times can be null, sort them at the bottom (will be displayed as pending)
  events.sort((a, b) => {
    if (!b.when) {
      return -1
    } else if (!a.when) {
      return 1;
    }

    return a.when - b.when
  })

  return events
}

const Duration = ({duration}) => {
   return '..' + duration + 's..'
}

/* Display data from received_call   */
const Timeline = ({ auction }) => {
  const events = auctionToEvents(auction)

  let last = null

  return <>
    <div className="form-section timeline">
      <div className="form-section-header">Timeline</div>

      {events.map((e, i) => {
        //e.when might be null (end_date when call has not yet completed)
        const diff = last && e.when ? parseInt((e.when - last)/1000) : 1
        last = e.when


        return <div className="data-row" key={i}>
          {/*<div className="me-2" style={{width: 40}}>{diff > 0 && <Duration duration={diff} />}</div>*/}
          <div style={{width: 80}}>
            {e.when ? (
              <>
                {diff > 0 && <DateFormatter value={e.when} format="hh:mm:ss" />}
              </>
            ) : (
              <i>pending</i>
            )}

          </div>
          <div className="ms-3">
            <span id={e.id}>{e.icon && <FaIcon className="me-2" icon={e.icon} />}{e.label}</span>
            {e.description && <HelpPopper name={e.id} iconClass="ms-2">{e.description}</HelpPopper>}
          </div>
        </div>
      })}
    </div>
  </>
}

export default Timeline;
