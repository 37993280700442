import React from 'react';
import {Navigate, Routes, Route} from "react-router-dom";
import AdvertiserReport from './AdvertiserReport';
//import PublisherReport from './PublisherReport';
import Calls from './Calls';
import Leads from './Leads';
import ProtectedRoute from '../../Components/ProtectedRoute';
import Page from './../Auctions/Details/Page';

// Routes are releative to '/reports'
const Landing = () => (
    <Routes>
      <Route path="advertiser" element={<ProtectedRoute permission="advertiser_reports.view"/>}>
        <Route index element={<AdvertiserReport/>}/>
      </Route>

      {/*  MRK-8107 hide publisher report
    <Route path="publisher" element={<ProtectedRoute permission="publisher_reports.view" /> } >
      <Route element={<PublisherReport />} />
    </Route>*/}

      <Route path="calls" element={<ProtectedRoute permission="calls.view"/>}>
        <Route index element={<Calls/>}/>
      </Route>

      <Route path="leads" element={<ProtectedRoute permission="leads.view"/>}>
        <Route index element={<Leads/>}/>
      </Route>

      <Route path="auction" element={<ProtectedRoute permission="auctions.view"/>}>
        <Route index element={<Page/>}/>
      </Route>

      <Route path="auction/:auction_id" element={<ProtectedRoute permission="auctions.view"/>}>
        <Route index element={<Page/>}/>
      </Route>


      <Route element={() => <Navigate to="/accounts"/>}/>
    </Routes>
  );

export default Landing;
