/*
 * Service that does "/me" auth check, cross-tab auth, and logout
 */


class Authorizer {
  constructor(authUrl, logoutCallback) {
    this.authUrl = authUrl;
    this.logoutCallback = logoutCallback;

    this.tabQueryTimer = null;

    // Listen for localStorage changes (for auth sharing between tabs)
    window.addEventListener('storage', this.handleBroadcast);
  }

  /* SHARE AUTH ACROSS MULTIPLE TABS
     * A tab "broadcasts" a request for auth token by setting a localStorage item,
     * which triggers a storage event.
     * Before doing auth check, test if we have a token, get it from another tab if not, then make request
     */

  /* Using the storage event to broadcast between tabs. */
  broadcastTokenRequest = () => {
    // Timer, since we don't really know if the broadcast query failed
    if (!this.tabQueryTimer) {
      this.tabQueryTimer = window.setTimeout(() => {
        // No tab responded with a token
        window.dispatchEvent(new Event('Mastodon.CrossTabTokenFail'));
      }, 1000);
      // Trigger storage event
      localStorage.setItem('TokenRequest', Date.now().toString());
      localStorage.removeItem('TokenRequest');
    }
  };


  broadcastLogout = (message = '') => {
    localStorage.setItem('LogoutAllTabs', message);
    localStorage.removeItem('LogoutAllTabs');
  };

  broadcastLogin = (token) => {
    localStorage.setItem('LoginAllTabs', JSON.stringify(token));
    localStorage.removeItem('LoginAllTabs');
  };

  handleBroadcast = (storageEvent) => {
    //console.log('Authorizer.js:handleBroadcast', storageEvent.key, storageEvent.newValue);
    if (storageEvent.key === 'TokenRequest' && storageEvent.newValue) {
      // Another tab is requesting an auth token
       //console.log('Authorizer.js:got a token request from another tab, sending....', storageEvent.newValue);
      if (window.sessionStorage.UE_CLICKS_AUTH_TOKEN) {
        localStorage.setItem('TokenResponse', JSON.stringify(window.sessionStorage.UE_CLICKS_AUTH_TOKEN));
      } else {
        localStorage.setItem('TokenResponse', '');
      }

      localStorage.removeItem('TokenResponse');
    } else if (storageEvent.key === 'TokenResponse' || storageEvent.key === 'LoginAllTabs') {
      // Another tab gave us a current token. Set and re-auth.
      if (storageEvent.newValue) {
        if (this.tabQueryTimer) window.clearTimeout(this.tabQueryTimer);
        try {
          const token = JSON.parse(storageEvent.newValue)
          if (token) {
            this.setAuthToken(token);
            window.dispatchEvent(new Event('Mastodon.CrossTabTokenSuccess')); //calls authorize()
          }
        } catch(e) {}

      }
    } else if (storageEvent.key === 'LogoutAllTabs') {
      this.logout(storageEvent.newValue, false);
    }

  };


  getAuthToken = () => window.sessionStorage.UE_CLICKS_AUTH_TOKEN;

  // Can be called on login, or from cross-tab message
  setAuthToken = (token, doBroadcast= false) => {
    window.sessionStorage.setItem("UE_CLICKS_AUTH_TOKEN", token);
    if (doBroadcast) {
      this.broadcastLogin(token);
    }

  }

  getRequestOptions = () =>
    ({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    }) ;

  logout = (message = '', broadcast = true) => {
    window.sessionStorage.removeItem('UE_CLICKS_AUTH_TOKEN');
    if (broadcast) this.broadcastLogout(message); // notify all tabs
    this.logoutCallback && this.logoutCallback(message);
  };

  // called after updating My Profile
  refreshAuth = () => this.fetchInfo();

  // Does the auth check
  authorize = async () => {
    // Try to get authorization from another tab if no token in this tab
    if (!this.getAuthToken()) {
      this.broadcastTokenRequest();
      return 'pending';
    }

    const options = this.getRequestOptions();

    const result = await fetch(this.authUrl, options);

    if (!result.ok) {
      let msg
       msg = result.json().then((data) => {
        if (data.error !== undefined && data.error.message !== undefined) {
          return data.error.message
        }
      })

      if (msg !== undefined) {
        return msg
      }
    }

    if (result.ok) {
      return result.json();
    }

    return false;
  };
}

export default Authorizer;
