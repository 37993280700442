import {useCallback, useEffect, useState} from 'react';
import LocalStorageService from "../Services/LocalStorageService";

/*
 * Hook for reading and setting a value in local storage.
 * Handles changes across tabs.
 * Shamelessly inspired by https://synth.app/blog/uselocalstorage-hooks-are-nice
 * and https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts
 *
 * Usage:
 * const [mySetting, setMySetting] = useLocalStorage("MY_SETTING", {"gofish":true});
 * // If there is an external change to mySetting (such as another tab sets it)
 * // then mySetting will change here (you will need a useEffect watcher to handle it)
 */
const useLocalStorage = (key:string, initialValue?:any) => {

// pull the initial value from local storage if it is already set
  const [storedValue, setStoredValue] = useState<any>(() => {
    if(!key) return initialValue;
    const exValue = LocalStorageService.getLocalItem(key)
    if (typeof exValue === 'undefined') {
      return typeof initialValue === 'undefined' ? null : initialValue;
    }
    return exValue;
  })


  const handleStorageChange = useCallback((evt:StorageEvent|CustomEvent) => {
    if (key) {
      if ((evt as StorageEvent)?.key && (evt as StorageEvent).key !== key) {
        return
      }
      setStoredValue(LocalStorageService.getLocalItem(key))
    }

  }, []);

  // install the listeners for external changes (such as other tabs)
  useEffect(() => {

    // "storage" event is a special browser event triggered by changes in OTHER tabs, not current
    window.addEventListener("storage", handleStorageChange)
    // for current tab, custom event
    window.addEventListener("local-storage", handleStorageChange)

    // stop listening on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange)
      window.removeEventListener("local-storage", handleStorageChange)
    }
  }, [storedValue])



  const setValue = (val:any) => {
    if (key) {
      LocalStorageService.setLocalItem(key, val);
      setStoredValue(val);
      window.dispatchEvent(new Event('local-storage'));
    } else {
      setStoredValue(val);
    }

  }

  return [storedValue, setValue]
}

export default useLocalStorage;

