import { useState, useContext } from 'react';
import { AppContext } from '../Providers/AppProvider';

/*
 * Hook for using the global API class with functional components
 * Usage:
 *    // assign hook result to whatever variable you want
 *    const api = useApi('accounts', 'list');
 *    const data =  api.fetch(someRequestData);// call the api
 *    // Then use api.result, api.error
 *    useEffect( ()=>{ if(api.result) ...do stuff... }, [api.result, api.error]);
 *
 *    You can also get the result directly in an async wrapper:
 *    const getStuff = async() => { const stuff = await api.fetch(); ...do stuff...}
 *
 */
const useApi = (resource, action) => {

  const appContext = useContext(AppContext);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const endpoint = resource ? appContext.api.endpoints[resource] : null;
  // if(!endpoint) throw new Error(`Unknown API endpoint ${resource}`);

  const fetch = async (data) => {
    if (!endpoint || !action) {
      setError('Undefined endpoint or action');
    }
    setError(null);
    setIsLoading(true);
    const res = await endpoint[action](data);
    setResult(res);
    setIsLoading(false);
    if (!res) {
      setError(appContext.api.error); // object with name and message
    }
    return res;
  };

  return {
    api: appContext.api, result, error, fetch, isLoading,
  };
};

export default useApi;
