import { useEffect,useState, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { filtersToQueryString, queryStringToFilters } from '../Components/Table/queryString';


const DefaultControls = {
  page: 1,
  limit: 20,
  sort_by: '',
  sort_dir: '',
};

const DefaultOptions = {
  // Read and set query string
  // In some cases we use this hook in a secondary table (like change log)
  // and don't want to alter the query string
  useQueryString: false,

  useTableControls: true,// track page, limit, sort values
  urlDateFormat: undefined,
  // Options for the query string parser
  // Safer to leave IDs as strings because some form elements always return value as string
  parseOptions: {parseNumbers: false},
  zeroPageIndex: true,
  allowedFilters: []
};


/*
 * Hook for maintaining table state.
 * Keeps state of any and all filters as well as controls for page, limit, sort, etc.
 * Optionally updates the query string with the current state, or responds to query string
 * changes such as back button.
 *
 * Note: it's useful to keep table "controls" (page, limit, etc) separate from filters
 * because different components handle them.
 */
const useTableState = (
  initialFilters = {},
  initialControls = {},
  options = {}
) => {

  const navigate = useNavigate();
  const location = useLocation();
  const lastSearch = useRef(location.search);
  options = { ...DefaultOptions, ...options };

  const cleanFilters = (filters) => {
    if (options.allowedFilters && options.allowedFilters.length) {
      filters = {...filters};
      options.allowedFilters.forEach( f => {
        if (typeof filters[f] !== 'undefined') {
          delete filters[f]
        }
      })
    }
    return filters;
  }

  const stateFromQueryString = () => {
    const qsData = queryStringToFilters(location.search || {}, options.urlDateFormat, options.parseOptions);
    let{
      page, limit, sort_by, sort_dir, ...filters
    } = qsData;
    if (!filters || !Object.keys(filters).length) filters = initialFilters;

    filters = cleanFilters(filters);

    let controls = {};
    if (options.useTableControls) {
      controls = {...DefaultControls, ...initialControls,};
      if (page) {
        controls.page = page;
      }
      if (sort_by) {
        controls.sort_by = sort_by;
      }
      if (sort_dir) {
        controls.sort_dir = sort_dir
      }
      if (limit) {
        controls.limit = limit;
      }
    }

    return {
      filters,
      filterArray: Object.entries(filters).map(([id, value]) => {
        return {id, value}
      }),
      controls}
  }

  const [state, setState] = useState(() => stateFromQueryString())

  // targetQuery:
  // Current filters and controls turned into a query string.
  // Used to update the browser location as needed (if configured to do so)
  // Can also be monitored by the consuming component to know when to update data
  const targetQuery = useMemo(() => {
    let qsData = { ...state.filters };
    delete qsData.diffInMinutes;
    if (options.useTableControls) {
      qsData = {...qsData, ...state.controls};
    }
    return filtersToQueryString(qsData, options.urlDateFormat);
  }, [state])

  //console.group()
  //console.log('usePagedTableState2.js:state', state)
  //console.log('usePagedTableState2.js:targetQuery', targetQuery);
  //console.groupEnd();



  const setFilters = (filters) => {
    filters = cleanFilters(filters);

    setState((old) => {
      const newState = {
        ...old,
        filters,
        filterArray: filtersToArray(filters)
      }
      if (options.useTableControls) {
        newState.controls.page = 1;
      }
      return newState;
    })
  }

  // React table filter state is an array of {id, value}
  const filtersToArray = (f) => {
    return Object.entries(f).map(([id, value]) => {
      return {id, value}
    })
  }

  const setPagination = (pagination) => {
    //console.log('usePagedTableState2.js:update Pagination', pagination);
    const p = {
      page: pagination.pageIndex + 1 ,
      limit: pagination.pageSize
    }
    setState((old) => {
      return {
        ...old,
        controls: {...old.controls, ...p}
      }
    })
  }

  const getPagination = () => {
    return {
      pageIndex: options.zeroPageIndex ? state.controls.page-1 : state.controls.page,
      pageSize: state.controls.limit
    }
  }

  //const handleSetSortBy = (sort_by) => setState((old) => {
  //       return {...old, controls: {...old.controls, ...sort_by}}
  //     });
  //const handleSetSortDir = (sort_dir) => setState((old) => {
  //       return {...old, controls: {...old.controls, ...sort_dir}}
  //     });
  // const handleSetLimit = (limit) => setState((old) => {
  //       return {...old, controls: {...old.controls, ...limit}}
  //     });

  // Used for ReactTable onSort event which sets both field and direction
  // arg is array: [{id:"purchased_at", desc:false}]
  // We are assuming it's not multisort since we config the tables multisort=false
  const setSorting = (rtSort) => {

    const sort = {};
    if (rtSort && rtSort.length) {
      sort.sort_by = rtSort[0].id;
      sort.sort_dir = rtSort[0].desc ? 'desc' : 'asc';
    }
    setState((old) => {
      return {...old, controls: {...old.controls, ...sort}}
    })
  };

  const getSorting = () => {
    return [{id:state.controls.sort_by, desc: state.controls.sort_dir === 'desc'}]
  }


  // Handle change of location or target
  useEffect(() => {
    //console.log("useTableState.js useEffect targetQuery, location.search", targetQuery, location.search);
    if (options.useQueryString) {
      if (location.search !== lastSearch.current) {
        // The query string changed (for example, user clicked the back button)
        // Derive the new state from the query string
        //console.log('usePagedTableState2.js:useEffect SEARCH CHANGED', `old:"${lastSearch.current}", new:"${location.search}"`);
        lastSearch.current = location.search;
        setState(stateFromQueryString());
      } else if (targetQuery !== location.search) {
        // The state changed (filters and/or controls), so update the query string
        //console.log('usePagedTableState2.js: useEffect NAVIGATING to:', `targetQuery:"${targetQuery}", from location.search:"${location.search}"`);
        // Use navigate "replace" if there is no current query string.
        // This is because landing on "/my-list" may immediately be updated with default params "/my-list?page=1&..."
        // and we don't want two history entries (then you would have to "back" through both)
        navigate(targetQuery, {replace: location.search === ""})
      }
    }

  }, [targetQuery, location.search]);

  const reset = (resetTo = {}) => {
    const filters = {...initialFilters, ...resetTo};
    setState({
      filters,
      filterArray:filtersToArray(filters),
      controls: {...DefaultControls, ...initialControls},
    })
  };

  return {
    filters:state.filters,
    controls:state.controls,
    filterArray: state.filterArray,
    setFilters,
    reset,
    setPagination,
    pagination: getPagination(),
    setSorting,
    sorting:getSorting(),
    query: targetQuery,
  };
};

export default useTableState;
