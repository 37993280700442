import React, { useState } from 'react';
// import {toUserTimezone} from "../../utils/dates";
import PropTypes from 'prop-types';
import usePermission from '../../../Hooks/usePermission';
import PageSection from '../../../Layout/PageSection';
import { Button, TabContent, TabPane, Nav, NavLink, NavItem } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import lazify from "../../../utils/Lazify";

const LazyChangeLog = lazify(() => import(/* webpackChunkName: "ChangeLog" */ '../../ChangeLog/EntityChangeLog'));

/*
 * Change Log List - displayes changes for specific entity
 * Intended to be embedded in  the entity's detail page.
 * Force a refresh (for example, if parent entity has saved a change)
 * by updating a key property on this component.
 */
const ChangeLog = ({order, rule, attributes, className="", refreshKey=0}) => {

  const [activeTab, setActiveTab] = useState('order');
  const allowViewChanges = usePermission('changes.view');
  if (!allowViewChanges) return (null);

  const [expanded, setExpanded] = useState(false);

  const handleSelectTab = (tab) => setActiveTab(tab);

  const toggleExpand= () => setExpanded((prev) => !prev);


  return (

    <PageSection
      title={!expanded ? "Change Log" : activeTab === 'rule' ? "Change Log for Rule" : "Change Log for Order"}
      subtitle={activeTab === 'rule' ? rule.label : ""}
      className={classnames(['entity-change-log', 'mt-4', className])}
      tools={(
        <Button
          className="inline ms-auto"
          onClick={toggleExpand}
          color="link"
        >
          <FaIcon icon={expanded ? 'chevron-up' : 'chevron-down'} size="1x" />
        </Button>
      )}
    >
      <div className={classnames(['fader', { show: expanded }])}>
        <Nav tabs className="mb-2">

          <NavItem
            className={classnames({ active: activeTab === "order" })}
          >
            <NavLink
              className={classnames({ active: activeTab === "order" })}
              onClick={()=>handleSelectTab("order")} href="#">Order (and all rules)</NavLink>

          </NavItem>
          <NavItem
            className={classnames({ active: activeTab === "rule" })}
          >
            <NavLink
              className={classnames({ active: activeTab === "rule"})}
              onClick={()=>handleSelectTab("rule")} href="#">Current Rule</NavLink>

          </NavItem>
        </Nav>



        <TabContent activeTab={activeTab}>
          <TabPane tabId="order">
            <LazyChangeLog
              expandablePageSection={false}
              entityId={order.id}
              entityType="order"
              entityName={order.name}
              accountId={order.account.id}
              refreshKey={refreshKey}
              attributes={attributes}
            />
          </TabPane>
          <TabPane tabId="rule">
            <LazyChangeLog
              expandablePageSection={false}
              entityId={rule.id}
              entityType="targetrule"
              entityName={rule.label}
              accountId={order.account.id}
              refreshKey={refreshKey}
              attributes={attributes}
            />
          </TabPane>
        </TabContent>

      </div>
    </PageSection>
  );
};

ChangeLog.propTypes = {
  order: PropTypes.object,
  rule: PropTypes.object,
  attributes: PropTypes.object,
  className: PropTypes.string,
  refreshKey: PropTypes.number, // change to force update
};

ChangeLog.defaultProps = {
  attributes: {},
  className: '',
  refreshKey: 0,
};
export default ChangeLog;
