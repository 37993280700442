import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { Button, Input, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import RulesContext from '../../Providers/RulesContext';

const CopyWidget = ({ rule, cancel, close }) => {
  const [label, setLabel] = useState(`${rule.label} (Copy)`);
  const rulesContext = useContext(RulesContext);
  const inputEl = useRef(null);

  const handleCopyClick = () => {
    rulesContext.api.copyRule(rule, label);
    close();
  };

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, []);

  return (

    <FormGroup className="copy-rule-widget">

      <Input innerRef={inputEl} value={label} placeholder="Rule name" onChange={(e) => setLabel(e.target.value)} />

      <div className="d-flex mt-2 justify-content-end">
        <Button size="sm" color="link" onClick={cancel}>
          Cancel
        </Button>
        <Button color="primary" size="sm" className="me-2" onClick={handleCopyClick}>
          Create Copy
        </Button>

      </div>

    </FormGroup>

  );
};

CopyWidget.propTypes = {
  rule: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

CopyWidget.defaultProps = {
  isRootRule: false,
};

export default CopyWidget;
