import { useMemo } from 'react';
import { matchSorter } from 'match-sorter';
/*
 * Hook for using react-table global search (search multiple columns)
 * Applies a text filter using matchSorter CONTAINS.
 * usage:
 * //Provide accessors for the columns to search on
 * const globalFilterType =  useTableGlobalFuzzyFilter(["original.name", "original.alias"]);

 *  // and provide these values in the table props
 *  // (If you have other custom filter types, merge these with the GlobalFilterType)
 *     filterTypes={globalFilterType}
 *     globalFilter="globalType"
 *
 * In the column definition you can disable global filtering on a column with disableGlobalFilter:true
 */
const useTableGlobalFuzzyFilter = (keys = []) => {
  const globalFilterFn = useMemo(() => {
    const fn = (rows, colIds, filterValue) => matchSorter(rows, filterValue, {
      threshold: matchSorter.rankings.CONTAINS,
      sorter: (items) => items, // disabled match sorting
      keys,
    });

    // Let the table remove the filter if the string is empty
    fn.autoRemove = (val) => !val;

    return fn;
  }, [keys]);

  const globalFilterType = useMemo(() => ({
    globalType: globalFilterFn,
  }), []);

  return globalFilterType;
};

export default useTableGlobalFuzzyFilter;
