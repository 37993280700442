import React from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import PropTypes from 'prop-types';

type AccountBalancesProperties = {
  accountId:number;
  cash:string;
  credit:string;
  isLow:boolean;
  hasOrders:boolean;
}
/*
 * Display of an account's cash/credit balances with warning for low balances
 */
const AccountBalances = React.memo<AccountBalancesProperties>(({
  accountId, cash, credit, isLow = false, hasOrders,
}) => {
  const lowBal = hasOrders && isLow;

  return (
    <div className="accounting d-flex justify-content-end align-content-center">

      {lowBal
          && (
          <div className="bal-alert">
            <FaIcon icon="exclamation-triangle" />
            {' '}
            Low balance
          </div>
          )}

      <div className={classnames(['bal-pod', 'me-2', { 'bal-alert': lowBal, 'no-orders': !hasOrders }])}>
        <div className="type">Cash</div>
        <div className="bal"><NumberFormat value={cash} displayType="text" thousandSeparator prefix="$" decimalScale={0} /></div>
      </div>

      <div className={classnames(['bal-pod', 'me-2', { 'no-orders': !hasOrders }])}>
        <div className="type">Credit</div>
        <div className="bal"><NumberFormat value={credit} displayType="text" thousandSeparator prefix="$" decimalScale={0} /></div>
      </div>

      <div>
        <Link
          to={{
            pathname: `/accounts/${accountId}/settings`,
            search: 'tab=funds',
          }}
          title="Account funds management"
          className="link"
        >
          Funds
        </Link>
      </div>
    </div>
  );
});


AccountBalances.displayName = "AccountBalances";
export default AccountBalances;
