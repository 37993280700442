const CallColumnChoices = [
  // {value:'call_id', label:'Call ID'},
  { value: 'received_time', label: 'Received Time' },
  { value: 'disposition', label: 'Disposition' },
  // {value:'phone', label:'Phone'}, //Same as origin_number
  { value: 'origin_number', label: 'Origin Number' },
  { value: 'transfer_number', label: 'Transfer Number' },
  { value: 'destination_number', label: 'Destination Number' },
  { value: 'billable', label: 'Billable' },
  { value: 'duration', label: 'Duration' },
  { value: 'account_billable_duration', label: 'Duration Threshold (account)' },
  { value: 'vendor_billable_duration', label: 'Duration Threshold (vendor)' },
  { value: 'cost', label: 'Cost' },
  // Currently revenue mostly the same as cost. We might need to restrict by permission
  // {value:'revenue', label:'Revenue'},
  { value: 'account_name', label: 'Account Name' },
  { value: 'order_name', label: 'Order Name' },
  { value: 'vertical', label: 'Vertical Name' },
  { value: 'source_name', label: 'Source Name' },
  { value: 'channel_name', label: 'Channel Name' },
  { value: 'vendor_name', label: 'Vendor Name' },
  { value: 'buyer_name', label: 'Buyer Name' },
  { value: 'broker_name', label: 'Broker Name' },
  { value: 'full_name', label: 'Full Name' },
  { value: 'city', label: 'City' },
  { value: 'state', label: 'State' },
];

export const getCallColumnChoices = (allowViewSource) => (
  allowViewSource ? CallColumnChoices : CallColumnChoices.filter((c) => c.value !== 'source_name')
);

const LeadColumnChoices = [
  // {value:'lead_id', label:'Lead ID'},
  { value: 'purchased_time', label: 'Purchased Time' },
  // {value:'phone', label:'Phone'}, //Same as origin_number
  { value: 'origin_number', label: 'Origin Number' },
  { value: 'transfer_number', label: 'Transfer Number' },
  { value: 'destination_number', label: 'Destination Number' },
  { value: 'cost', label: 'Cost' },
  { value: 'product', label: 'Product' },
  // Currently revenue mostly the same as cost. We might need to restrict by permission
  // {value:'revenue', label:'Revenue'},
  { value: 'account_name', label: 'Account Name' },
  { value: 'order_name', label: 'Order Name' },
  { value: 'vertical', label: 'Vertical Name' },
  { value: 'source_name', label: 'Source Name' },
  { value: 'channel_name', label: 'Channel Name' },
  { value: 'vendor_name', label: 'Vendor Name' },
  { value: 'buyer_name', label: 'Buyer Name' },
  { value: 'broker_name', label: 'Broker Name' },
  { value: 'full_name', label: 'Full name' },
  { value: 'city', label: 'City' },
  { value: 'state', label: 'State' },
];

// TODO: we might be able to use just one function for Lead and Calls depending on how
// requirements work out
export const getLeadColumnChoices = (allowViewSource) => (
  allowViewSource ? LeadColumnChoices : LeadColumnChoices.filter((c) => c.value !== 'source_name')
);
