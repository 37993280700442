import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { matchSorter } from 'match-sorter';
import usePagedTableState from '../Hooks/usePagedTableState';

/*
 * Provider that wraps a table and set of filters
 * and allows "live" (not by api request) filtering of data.
 * The filters component consumes the filters state, and the
 * table consumes the filteredData.
 *
 * For text search across multiple fields, provide an array of searchKeys.
 * The items in the array are usually field names, but they could be
 * resolver functions or dot notation. This is useful when one field's data is an object,
 * and you want to search within it.
 *
 */

const LiveFilteringContext = React.createContext();


const LiveFilteringProvider = (props) => {
  const [initialFilters] = useState(props.filters || {});
  const { filters, setFilters, updateQuery } = usePagedTableState(
    props.filters || {},
    null,
    { useQueryString: props.useQueryString, useTableControls: false },
  );

  // React-table feature, can provide a string to search on all columns.
  // @see: https://react-table.tanstack.com/docs/api/useGlobalFilter
  // Technically this value could be a function
  const [globalFilter, setGlobalFilter] = useState('');
  const [filteredCount, setFilteredCount] = useState(0);

  // Update results when filters change
  // Can use refreshKey prop to force update independent of filters asdf
  // (For example, if the data has changed. In some tables  there are inputs in the rows, and the data updates)
  useEffect(() => {
    if (props.useQueryString) {
      updateQuery();
    }
  }, [filters, props.refreshKey]);

  const updateFilter = (param, value) => {
    const f = { ...filters };
    if (value && !(Array.isArray(value) && value.length === 0)) {
      f[param] = value;
    } else if (param in f) {
      delete f[param];
    }

    setFilters(f);
  };

  const resetFilter = (param) => {
    // TODO: reset to default value
    const f = { ...filters };
    if (f.hasOwnProperty(param)) {
      delete f[param];
      setFilters(f);
    } else if (param === 'global') {
      setGlobalFilter('');
    }
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    setGlobalFilter('');
  };

  const updateGlobalFilter = (val) => setGlobalFilter(val);

  /*
   * If not using built-in filtering with react-table, use this method.
   * Useful where filtering rows with filterable sub-tables.
   * See the documentation for MatchSorter https://github.com/kentcdodds/match-sorter
   */
  const applyFilters = (rows, subtableName, skipFilters) => {
    // console.log('LiveFilterProvider.js:applyFilters, filters ' + subtableName);

    // Apply each filter to the data list, method depending on type
    Object.entries(filters).map(([param, val], i) => {
      let field;

      if (!skipFilters || !skipFilters.includes(param)) {
        // Subtable params should be like 'orders|product' or 'orders|vertical.name'
        if (param.indexOf('|') > 0) {
          if (subtableName) {
            const [subtable, subtableField] = param.split('|');
            if (subtable === subtableName) {
              field = subtableField;
            }
          }
        } else {
          field = param;
        }
      }

      if (field) {
        if (field === 'search_term') {
          // Search_term often searches on multiple fields (searchKeys)
          // Sorting behavior disabled to keep the original table sort
          if (val && props.searchKeys) {
            rows = matchSorter(rows, val, {
              keys: props.searchKeys,
              threshold: matchSorter.rankings.CONTAINS,
              sorter: (items) => items, // disabled match sorting
            });
          }
        } else if (Array.isArray(val)) {
          // multi-select widget  (react-select)
          if (val.length) {
            rows = val.reduceRight(
              (results, term) => {
                const r = matchSorter(rows, term.toString(), { keys: [field] });
                return [...results, ...r];
              },
              [],
            );
          }
        } else {
          // simple equal
          rows = matchSorter(rows, val, {
            keys: [field],
            threshold: matchSorter.rankings.CONTAINS,
            sorter: (items) => items, // disabled match sorting
          });
        }
      }
    });
    return rows;
  };

  const isFiltered = Object.keys(filters).length > 0;

  return (
    <LiveFilteringContext.Provider value={{
      filters,
      isFiltered,
      updateFilter,
      resetFilter,
      resetFilters,
      applyFilters,
      globalFilter,
      updateGlobalFilter,
      filteredCount,
      setFilteredCount
    }}
    >
      { props.children }
    </LiveFilteringContext.Provider>
  );
};

LiveFilteringProvider.propTypes = {
  data: PropTypes.array, // data rows to be filtered
  filters: PropTypes.object, // current state of filters
  globalFilter: PropTypes.string, // react-table feature, filters all columns
  searchKeys: PropTypes.array, // which keys to match for text search
  subTableKey: PropTypes.string,
  refreshKey: PropTypes.number,
  children: PropTypes.node, // nodes wrapped by this context,
  useQueryString: PropTypes.bool, // read and set filters in query stirng
};

LiveFilteringProvider.defaultProps = {
  data: [],
  filters: {},
  useQueryString: false,
  refreshKey: 0,
};

export { LiveFilteringProvider, LiveFilteringContext };
