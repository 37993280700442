import React, { FC, useEffect, useState } from 'react';
import PageSection from '../../../Layout/PageSection';
import DocTitle from '../../../Layout/DocTitle';
import {
  Button,
} from "reactstrap";
import {InputSchemaType} from "../../../types";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import InputSchemaTable from "./InputSchemaTable";
import InputSchemaForm from "./InputSchemaForm";
import InputSchemaApi from "./InputSchemaApi";
import Filters from "./Filters";
import InputSchemaViewModal from "./InputSchemaViewModal";
import usePermission from "../../../Hooks/usePermission";
import {LiveFilteringContext, LiveFilteringProvider} from "../../../Providers/LiveFilteringProvider";
import FilterBar from "../../../Components/Table/FilterBar";

//import useQueryState from "../../../Hooks/useQueryState";

/*

        "id": 1,
        "name": "Charity",
        "description": "",
        "content": {
        },
        "parent_id": null,
        "input_schemas": [],
        "created_at": "2023-03-29T17:48:32+00:00",
        "modified_at": "2023-03-29T17:48:22+00:00"
 */
const SchemaApi = new InputSchemaApi();

/*
 * Input Schema Editor
 * Permission based on input_schema.edit (component is in a ProtectedRoute)
 */
const InputSchema: FC<any> = () => {

 // const [query, setQuery] = useQueryState({}, {historyAction:"replace"});
  const[loading, setLoading] = useState<boolean>(true)
  const[viewSchema, setViewSchema] = useState<InputSchemaType|null>(null)
  const[editSchema, setEditSchema] = useState<InputSchemaType|null>(null)
  const[list, setList] = useState<any[]>([])

  const allowInputSchemaEdit = usePermission('input_schema.edit');
  if (!allowInputSchemaEdit) return null;

  useEffect(() => {
    load();
  }, [])

  const load = async() => {
    const result:any = await SchemaApi.list();
    if (result) {
      setList(result);
    } else {
      //@ts-ignore
      notify(`Unable to load data: ${SchemaApi.error ? SchemaApi.error.name : ""}`, 'error');
    }
    setLoading(false);
  }

  //May get a click event, or true for refreshing
  const closeEditModal = (e?:any|boolean) => {
    setEditSchema(null);
    if (e === true) {
      load();
    }
  }

  const closeViewSchema = () => setViewSchema(null);

  const handleViewSchemaContent = (inputSchema:InputSchemaType) => {
    setViewSchema(inputSchema);
  }

  const handleEditSchema = (schema:InputSchemaType) => {
    setEditSchema(schema);
  }

  const handleCreateNew = () => {
   setEditSchema({
     id:0,
     name: "",
     description: "",
     parent_id:null,
     input_schemas:[],
     content:{
       "type": "object",
       "properties": {}
     }
   } as InputSchemaType);
  }


  return (
    <div>
      <DocTitle pageTitle="Input Schema" />

      <LiveFilteringProvider useQueryString filters={{}}>

        <FilterBar>
          <Filters loading={loading} />
        </FilterBar>

        <PageSection
          title="Input Schema"
          tools={<Button size="sm" onClick={handleCreateNew} >Create</Button>}
        >


          <InputSchemaTable
            data={list}
            isLoading={loading}
            onViewSchema={handleViewSchemaContent}
            onEditSchema={handleEditSchema}
          />


          <InputSchemaViewModal
            schema={viewSchema}
            isOpen={Boolean(viewSchema)}
            cancel={closeViewSchema}
          />
        </PageSection>

      </LiveFilteringProvider>

      {editSchema && <InputSchemaForm
        schema={editSchema}
        allSchema={list}
        isOpen={Boolean(editSchema)}
        close={closeEditModal}
        onView={handleViewSchemaContent}
      />}
    </div>
  )
}

export default InputSchema;
