import React, {FC} from "react";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

const StatusDot: FC<any> = ({status, className="", size="xs"}:any) => {
  return (
    <div className={`status-dot ${className}`} style={{display:"inline-block"}}>
      {status === 'active' && <span><FaIcon icon="circle" color="limegreen" size={size} title="Active" /></span>}
      {status === 'paused' && <span><FaIcon icon="circle" color="gold" size={size} title="Paused" /></span>}
      {status === 'archived' && <span><FaIcon icon="circle" color="red" size={size} title="Archived" /></span>}
    </div>
  )
}

export default StatusDot;
