import React, { useContext, useCallback } from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {UncontrolledTooltip} from "reactstrap";
import RulesContext from '../../Providers/RulesContext';
import { IconData } from './constants';
// import usePermission from "../../Hooks/usePermission";

/*
 * Renders a row of icons that describe various rule states. Used in rules tree.
 */
const RuleIcons = ({ rule, ruleType="targeting", iconSet = [] }) => {
  const rulesContext = useContext(RulesContext);

  if (!rule) return (null);

  const handleIconClick = useCallback((e, tab) => {
    e.stopPropagation();

    rulesContext.api.setActiveTab(tab, rule);
    rulesContext.api.setSelected(rule, tab);

  }, []);

  return (
    <div className="rule-tree-icons">
      {iconSet.map((iconName, i) => (
          <RuleIcon
            key={i}
            rule={rule}
            ruleType={ruleType}
            name={iconName}
            show={rule.properties?.includes(iconName)}
            iconData={IconData[iconName]}
            onClick={handleIconClick}
          />
      ))}

    </div>
  );
};

/*
 * Icon - shows the icon or null based on rule property
 * (When rule has match conditions and match_is_required, show a special composite icon)
 *
 */
const RuleIcon = React.memo(({
  rule, ruleType, name, show, iconData, onClick,
}) => (iconData && show ? (
  <>

    {name === "match_conditions" && rule.match_is_required ? (
      <>
        <span
          className="fa-layers fa-fw"
          onClick={(e) => onClick(e, iconData.tab)}
          id={`tree-icon-${name}-${rule.id}`}
        >
          <FaIcon icon={iconData.icon} size="sm" className={name} />
          <FaIcon icon={"circle"}  size="sm" transform="right-8 up-5" color="white" />
          <FaIcon icon={"exclamation-circle"}  size="sm" transform="right-8 up-5" color="red" />
        </span>
        <UncontrolledTooltip target={`tree-icon-${name}-${rule.id}`}>
          Match Conditions (match is required for bid)
        </UncontrolledTooltip>
      </>

    ) : (
      <>
        <FaIcon
          id={`tree-icon-${name}-${rule.id}`}
          icon={iconData.icon}
          size="sm"
          className={name}
          onClick={(e) => onClick(e, (
            ruleType === 'routing' && iconData.routing_tab ? iconData.routing_tab : iconData.tab
          ))}
        />
        <UncontrolledTooltip target={`tree-icon-${name}-${rule.id}`}>
          {iconData.title}
        </UncontrolledTooltip>
      </>

    )}

  </>

) : (null)));

RuleIcons.propTypes = {
  rule: PropTypes.object,
  product: PropTypes.string,
  iconSet: PropTypes.array,
};

export default RuleIcons;
