import React from 'react';
import ContentLoader from 'react-content-loader';

/*
 * https://github.com/danilowoz/create-content-loader/blob/master/src/Gallery/insertYourLoaderHere/Gmail.js
 */
const TreeSkeleton = (props) => (
  <ContentLoader
    height={500}
    width="100%"
    speed={0.8}
    {...props}
  >
    <rect x="10" y="20" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="60" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="100" rx="0" ry="0" width="100%" height="30" />
    <rect x="10" y="140" rx="0" ry="0" width="100%" height="30" />
    <rect x="10" y="180" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="220" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="260" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="300" rx="0" ry="0" width="100%" height="30" />
    <rect x="10" y="340" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="380" rx="0" ry="0" width="100%" height="30" />
    <rect x="30" y="420" rx="0" ry="0" width="100%" height="30" />

  </ContentLoader>
);

TreeSkeleton.metadata = {
  name: 'Caio Davi',
  github: 'caio-davi',
  description: 'Rules Manager Skeleton',
  filename: 'RulesManagerSkeleton',
};

export default TreeSkeleton;
