import React, {
  useState, useCallback, useMemo,
} from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import Select from 'react-select'; // "../../../Components/Form/SimpleSelect";
// import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import IconCheckbox from '../../../Components/Form/IconCheckbox';
import InputWithIcon from '../../../Components/Form/InputWithIcon';
import {notify, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import RoutingRulesModal from '../../Routing/RulesManager/Modal';
import {HelpPopper}  from '@thedmsgroup/mastodon-ui-components';
import useFormState from '../../../Hooks/useFormState';
import useApi from '../../../Hooks/useApi';
import ChannelSourceRoute from "../../Routing/RulesManager/ChannelSourceRoute";
import StatusToggler from "../../../Components/Form/StatusToggler";
import {SourceOptionLabel} from "../../../Components/Form/SourceOptionLabel";

// We decided to allow distribution to be nullable
// import * as Yup from "yup";
// const ChannelSourceValidation = (
//   Yup.addMethod(Yup.mixed, 'validChannelSources', function(message) {
//     return this.test('test-channel-sources', message,
//       function(channelSources) {
//         let valid = true;
//         channelSources.map( (item) => {
//           //console.log('ChannelSourceDetail.js:testing', item, parseInt(item.distribution));
//           if( isNaN(parseInt(item.distribution))){
//             valid = false;
//           }
//         });
//
//         return valid;
//       })
//   })
// );
//
// const ChannelSourceValidationSchema = Yup.object().shape({
//   sources: Yup.mixed().validChannelSources('Please check that all dates are filled out, and from/to dates are in the right order'),
// });

const percentMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 3,
});


const DefaultChannelSource = {
  id:0,
  name:"",
  status: "",
  eligibility: {
    distribution:"",
    fixed_distribution:false,
    status: "active"
  }

}
const ChannelSourceDetail = ({ channel, sources = [], allSources, onCancel, refreshChannels }) => {

  // options without current selections
  const [selectedChannelSource, setSelectedChannelSource] = useState(null);
  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  const [addIndex, setAddIndex] = useState(sources.length ? null : 0);

  const sortDistributionDesc = (items) => items.sort((a, b) => (parseInt(a.distribution) < parseInt(b.distribution) ? 1 : -1));

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      sources: sources.length ? sortDistributionDesc([...sources])
                : [{...DefaultChannelSource}],
    },
  );
;
  const [loading, setLoading] = useState(false);
  const { api } = useApi();

  const sourceOptions = useMemo(() => {
    return allSources.reduce((acc, src) => {
        // Filter all source select options by product/vertical of the channel.
        // Filter out those already selected.
        if (
          (src.vertical.id === channel.vertical.id && src.product === channel.product)
          && (!formValues.sources.some(s => s.id === src.id))
        ) {
          acc.push({
            value: src.id,
            label: src.name,
            status: src.status,
            id: src.id,
            name: src.name
          });
        }

        return acc;
      }, [])
    },
    [formValues.sources]
  );

  const handleChangeDistribution = useCallback((val, index) => {
    const sources = [...formValues.sources];

    if (sources[index]) {
      sources[index].eligibility.distribution = val;
      formApi.setValues({ sources });
    }
  }, [formValues]);

  const handleChangeFixed = useCallback((fixed, index) => {
    const sources = [...formValues.sources];
    if (sources[index]) {
      sources[index].eligibility.fixed_distribution = fixed;

      formApi.setValues({ sources });
    }
  }, [formValues]);

  const handleChangeEligibilityStatus = useCallback((status, index) => {
    const sources = [...formValues.sources];
    if (sources[index]) {
      sources[index].eligibility.status = status;

      formApi.setValues({ sources });
    }
  }, [formValues]);

  const handleChangeSource = useCallback((source, index) => {
    const sources = [...formValues.sources];
    if (sources[index]) {
      sources[index] = source;
      formApi.setValues({ sources });
    }
  }, [formValues]);

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = async () => {
    setLoading(true);

    const chn = { ...channel };
    const sources = removeEmptySources(formValues.sources);

    chn.sources = sources.map((row) =>
      ({
        source_id: row.id,
        channel_id: channel.id,
        ...row.eligibility
      }));

    const result = await api.endpoints.channels.update(chn);
    setLoading(false);

    if (result) {
      notify('The channel sources were saved', 'success', );
      formApi.setValues({ sources: result.sources });
      setAddIndex(null);
      refreshChannels();
    } else {
      console.log('ChannelSourceDetail.js:error saving channel', api.error.name);
      notify(`Unable to save channel-source: ${api.error.name}`, 'error', { containerId: `channel-source-${channel.id}` });
    }
  };

  // close the subrow
  const handleCancel = () => {
    formApi.setValues([...sources])
    setAddIndex(null);
    onCancel();
  };


  const handleAddNew = () => {
    let newSources = removeEmptySources(formValues.sources);
    newSources.push({...DefaultChannelSource})
    setAddIndex(newSources.length - 1);
    formApi.setValue("sources", newSources);
  }

  //We're just removing rows without selected sources instead of bothering with validation
  const removeEmptySources = (arr) =>  arr.filter(src => src.id)


  const handleRemove = (index) => {
    let sources = [...formValues.sources];
    sources.splice(index, 1);
    sources = removeEmptySources(sources);
    formApi.setValues({ sources });
    setAddIndex(null);

  };

  const sortRows = () => {
    const sources = sortDistributionDesc([...formValues.sources]);
    // setRows(sorted)
    formApi.setValues({ sources });
  };

  const handleOpenRules = (index) => {
    const source = formValues.sources[index];

    if (source) {
      if (source.eligibility.rule_id) {
        setSelectedChannelSource(new ChannelSourceRoute(channel, source));
        setRulesModalOpen(true);
      } else {
        notify('Please save before editing rules', 'info', { containerId: `channel-source-${channel.id}` });
      }
    }
  };

  const handleCloseRulesModal = () => {
    setRulesModalOpen(false);
    setSelectedChannelSource(null);
  };

  return (
    <div className="form-section channel-source-detail position-relative">


      <div className="form-section-header">Channel Sources</div>

      {formValues.sources.length === 0
      && <StandardAlert color="light">No sources are assigned.</StandardAlert>}

      {formIsValid === false
      && (
      <StandardAlert color="warning" className="alert-form-validation">
        All sources require a value (zero is allowed)
      </StandardAlert>
      )}

      <div className="ms-4 me-4">


        <div className="source-rows">

          {formValues.sources.map((source, i) => (
            <SourceRow
              index={i}
              source={source}
              sourceOptions={sourceOptions}
              isNew={i === addIndex}
              onRemove={() => handleRemove(i)}
              onChangeSource={handleChangeSource}
              onChangeDistribution={(e) => handleChangeDistribution(e.target.value, i)}
              onChangeFixed={(name, value) => handleChangeFixed(value, i)}
              onChangeEligibilityStatus={(status) => handleChangeEligibilityStatus(status, i)}
              openRules={() => handleOpenRules(i)}
              key={`source-${i}`}
            />
          ))}


        </div>

      </div>

      <div className="d-flex align-items-center justify-content-end mt-3">
        <Button color="link" size="sm" onClick={handleAddNew} >
          <FaIcon icon="plus" />
          {' '}
          <span>Add Source</span>
        </Button>

        <Button color="text" size="sm"  onClick={handleCancel} disable={loading}>
          Cancel
        </Button>

        <Button color="primary" size="sm"  onClick={handleSave} disable={loading}>
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>


      </div>

      {selectedChannelSource && (
        <RoutingRulesModal
          entityType="channelsource"
          entity={selectedChannelSource}
          isOpen={rulesModalOpen}
          close={handleCloseRulesModal}
        />
      )}

    </div>
  );
};


const SourceRow = ({
  index,
  isNew,
  source,
  onRemove,
  onChangeSource,
  onChangeDistribution,
  onChangeFixed,
  onChangeEligibilityStatus,
  openRules,
  sourceOptions
}) => {

  const handleSelectSource = (selected) => {
    if (selected ) {
      onChangeSource({
        id: selected.value,
        name: selected.label,
        status: selected.status,
        eligibility: {
          distribution:"",
          fixed_distribution: false,
          status: "active"
        }
      } , index);
    }

  }

  return (
    <div className="d-flex channel-source-row align-items-center">

      {isNew ? (
        <div className="cs-cell name flex-fill me-2">
          <div className="flex-fill">
            <Select
              isClearable
              isSearchable
              onChange={handleSelectSource}
              options={sourceOptions}
              defaultValue={source}
              tabSelectsValue
              backspaceRemovesValue
              isMulti={false}
              controlShouldRenderValue
              hideSelectedOptions
              closeMenuOnSelect
              placeholder="Select Source (type to search)"
              invalid={false}
              formatOptionLabel={SourceOptionLabel}
            />
          </div>
        </div>
      ) : (
        <div className="cs-cell name flex-fill me-2">
          {source.name}
          {source.status !== "active" && <div className="source-status">{source.status}</div>}
        </div>
      )}


      <div className="cs-cell distro d-flex align-items-center" title="Distribution %">
        <DistributionInput value={source.eligibility.distribution} onChange={onChangeDistribution}/>

        <FixedCheckbox value={!!source.eligibility.fixed_distribution} onChange={onChangeFixed}/>
      </div>


      <div className="cs-cell status d-flex align-items-center">
        <StatusToggler
          status={source.eligibility.status}
          target={index}
          onChange={(id, status) => onChangeEligibilityStatus(status, id)}
          showArchived={false}
          allowEdit={true}
        />
        <HelpPopper name="channel-source-status" title="Channel-Source Status" iconClass="ms-1">
          Activate or pause a specific channel source. This does not affect
          the status of the channel or source in general.
        </HelpPopper>
      </div>


      <div className="cs-cell links d-flex justify-content-end">
        <Button color="link" className="inline me-2" onClick={openRules} title="Channel-Source routing rules">
          <FaIcon icon="clipboard-list" size="sm"/>
          {" "}
          Rules
        </Button>
        <Button onClick={onRemove} color="link" size="sm" className="inline" title="Remove">
          <FaIcon icon="times" size="sm" color="darkred"/>
        </Button>
      </div>



    </div>
  )
};


const DistributionInput = React.memo(({ value, onChange }) => (
  <div className="me-2">

    <MaskedInput
      mask={percentMask}
      name="distribution"
      value={value}
      onChange={onChange}
      placeholder="Open"
      render={(ref, props) => <InputWithIcon className="teeny" icon="percent" iconSide="right" innerRef={ref} {...props} />}
    />

  </div>
));

const FixedCheckbox = React.memo(({ value, onChange }) => (
  <div>
    <IconCheckbox
      name="fixed_distribution"
      value={!!value}
      onChange={onChange}
    >
      Fixed
      {' '}
      <HelpPopper name="source-distribution" title="Distribution">
        Optionally specify a distribution percentage.
        The value may be automatically raised to adjust for low volume
        in other sources. When fixed, the distribution will not change.
      </HelpPopper>
    </IconCheckbox>

  </div>
));

ChannelSourceDetail.propTypes = {
  channel: PropTypes.object,
  sources: PropTypes.array,
  onCancel: PropTypes.func,
  allSources: PropTypes.array,
  refreshChannels: PropTypes.func
  // refresh:PropTypes.func
};
export default ChannelSourceDetail;
