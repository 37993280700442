import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import DocTitle from '../../../../Layout/DocTitle';
import PageSection from '../../../../Layout/PageSection';
import AttributesTable from './Table';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import FilterBar from '../../../../Components/Table/FilterBar';
import Filters from './Filters';
import { LiveFilteringProvider } from '../../../../Providers/LiveFilteringProvider';
import usePermission from '../../../../Hooks/usePermission';
import useApi from '../../../../Hooks/useApi';
import Attribute from '../Detail';

const ListHeaderTools = ({ allowCreate, buttonClick }) => {
  if (!allowCreate) return (null);
  return (
    <Button
      onClick={buttonClick}
      color="primary"
      size="sm"
    >
      New Attribute
    </Button>
  );
};

const AttributeList = (props) => {
  const [loading, setLoading] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const allowEdit = usePermission('attributes.edit');
  const allowCreate = usePermission('attributes.create');
  const { api } = useApi();

  const toggleDetail = (refresh = false) => {
    setIsDetailOpen(!isDetailOpen);
    if (refresh === true) {
      load();
    }
  };

  const handleCreateNew = () => {
    handleSelectAttribute(
      {
        id: 0,
        name: '',
        alias: '',
        product: '',
        vertical: null,
        group_id: null,
        input_type: '',
        data_type: '',
        scope: '',
        options: {},
      },
    );
  };

  const handleSelectAttribute = (attr) => {
    if (attr) {
      setSelectedAttribute(attr);
      setIsDetailOpen(true);
    }
  };

  if (!allowEdit) return (null);

  useEffect(() => {
    if (!attributes.length) {
      load();
    }
  }, []);

  const load = async (refresh = false) => {
    if (!refresh) {
      setLoading(true);
    }

    const attr = await api.endpoints.attributes.list();

    if (attr === false) {
      notify(`Unable to load attributes: ${api.error.name}`, 'error');
    } else {
      setAttributes(attr);
    }

    if (!refresh) {
       setLoading(false);
    }
  };

  // TODO: will we have status, or active flag?
  const handleStatusChange = async (id, status) => {
    const result = await api.endpoints.attributes.update({ id, status });
    return result;
  };

  return (

    <>
      <DocTitle pageTitle="Attributes" />

      <LiveFilteringProvider filters={{}}>

        <FilterBar>
          <Filters loading={loading} onRefresh={() => load(true)} />
        </FilterBar>

        <PageSection
          title="Attributes"
          tools={<ListHeaderTools allowCreate={allowCreate} buttonClick={handleCreateNew} />}
        >

          <AttributesTable
            loading={loading}
            data={attributes}
            onSelect={handleSelectAttribute}
            onStatusChange={handleStatusChange}
          />

        </PageSection>

      </LiveFilteringProvider>

      <Modal isOpen={isDetailOpen} toggle={toggleDetail} size="xlg" id="edit-attribute-modal">
        {selectedAttribute && (
          <>
            <ModalHeader toggle={toggleDetail}>
              {
              selectedAttribute.id === 0
                ? 'Create Attribute'
                : (
                  <>
                    Edit Attribute |
                    <span className="modal-subheader">{selectedAttribute.name}</span>
                  </>
                )
            }
            </ModalHeader>
            <ModalBody>

              <Attribute
                attribute={selectedAttribute}
                close={toggleDetail}
              />

            </ModalBody>
          </>
        )}

      </Modal>
    </>
  );
};

export default AttributeList;
