import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormText,
  Button,
  Input,
  Label,
} from 'reactstrap';
import MaskedInput from 'react-text-mask';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  DayList,
  WeekSummarySVG,
  SchedulerModal,
  StandardAlert
} from "@thedmsgroup/mastodon-ui-components";
// import confirm from "reactstrap-confirm";

// Mask ddd,ddd
const numberMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 8,
});

/*
 *  Component for a numeric setting that can be scheduled by day/hour
 *  Presents a text input, and a visual display of the week's adjustments (svg graph).
 *  Provides a link to open the Scheduler component in a modal.
 *
 *  The text input is for the default value to be (optionally) modified.
 *  The scheduled modifications are set in a modal.
 *  In some cases, like bidding, the bid value is set in another part of the form,
 *  so we use the hideInput property.
 *
 *  "config" property should be the Scheduler component config:
 *    {default:100, max:200, labelInterval:20, lineInterval:10};
 *  minimum is presumed zero.
 *  optional config for:
 *    allowHourParting:false  (prevents splitting hour into halves or quarters)
 *    showBaseline: false   highlighted horizontal value line indicates default value. Default true.
 *    valueSuffix: "%"
 */
const SchedulableSettingInput = (props) => {
  const [modal, setModal] = useState(false);
  const [hasSchedule, setHasSchedule] = useState(false);
  const [clearPrompt, setClearPrompt] = useState(false);
  const thisDayIndex = new Date().getDay();
  // Note: Daylist is monday first
  const [selectedDay, setSelectedDay] = useState(DayList[thisDayIndex > 0 ? thisDayIndex - 1 : 6]);

  useEffect(() => {
    if (props.schedule) {
      setHasSchedule(Object.keys(props.schedule).length > 0);
    }
  }, [props.schedule]);

  const handleClickDay = (day) => {
    setSelectedDay(day);
    setModal(true);
  };

  const clearSchedule = () => {
    props.onChange(props.scheduleSetting, {});
    setClearPrompt(false);
    setHasSchedule(false);
  };

  return (
    <div className={classnames(['scheduled-setting-input', 'mb-4', props.className])}>
      {props.hideInput !== true && (
        <FormGroup>
          <Label>{props.title}</Label>
          <div className="d-flex">
            <div>
              <MaskedInput
                mask={numberMask}
                placeholder="open"
                value={props.value || ''}
                disabled={hasSchedule}
                onChange={(e) => props.onChange(props.setting, e.target.value)}
                render={(ref, props) => <Input className="teeny" innerRef={ref} {...props} />}
              />
              {/* optional extra form elements that relate to input, like a checkbox for override parent */}
              {props.children}
            </div>

            <div className="flex-grow-1">
              { hasSchedule === true && (
              <StandardAlert color="light" icon={clearPrompt ? 'warning' : 'info'}>
                {clearPrompt === true ? (
                  <>
                    Are you sure?
                    <Button color="warning" size="sm" className="ms-3" onClick={clearSchedule}>
                      Yes
                    </Button>
                    <Button color="link" className="ms-2" size="sm" onClick={() => setClearPrompt(false)}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    This setting has scheduled adjustments
                    {props.allowEdit && (
                      <>
                        <Button color="link" className="ms-2" onClick={() => handleClickDay(null)}>
                          <FaIcon icon="calendar-times" size="sm" />
                          {' '}
                          Edit Schedule
                        </Button>
                        <Button color="link" className="ms-2" onClick={() => setClearPrompt(true)}>
                          <FaIcon icon="times" size="sm" />
                          {' '}
                          Clear Schedule
                        </Button>
                      </>
                    )}

                  </>
                )}

              </StandardAlert>
              )}
            </div>

          </div>
          {props.note && <FormText>{props.note}</FormText>}
        </FormGroup>
      )}

      {/* Show Summary graph and edit icon if we have a schedule, or alert and create button if no schedule */}
      <FormGroup>

        {hasSchedule && props.allowEdit
              && (
              <Button color="link" className="mb-2" onClick={() => handleClickDay(null)} title="Edit Schedule">
                <FaIcon icon="edit" />
                {' '}Edit {props.title} Schedule
              </Button>
              )}

        <div>
          { hasSchedule === true ? (
            <>
              {props.summaryNote && <p>{props.summaryNote}</p>}
              <WeekSummarySVG
                config={props.config}
                schedule={props.schedule}
                onChangeSelectedDay={handleClickDay}
                selectedDay={selectedDay}
                labelSize={props.size}
              />
            </>

          ) : (
            <StandardAlert color="light" icon="info">
              There are no scheduled adjustments for this setting{' '}
              {props.allowEdit && (
                <Button color="link" className="inline ms-1" onClick={() => handleClickDay(null)}>
                  <FaIcon icon="calendar-times" size="sm" />
                  {' '}
                  Set Schedule
                </Button>
              )}

            </StandardAlert>
          )}

        </div>
      </FormGroup>

      <SchedulerModal
        timezone={props.scheduleTimezone}
        config={props.config}
        title={props.title}
        startDay={selectedDay}
        isOpen={modal}
        schedule={props.schedule}
        onUpdate={props.onChange}
        close={() => setModal(false)}
      />
    </div>

  );
};

SchedulableSettingInput.propTypes = {
  scheduleSetting: PropTypes.string,
  scheduleTimezone: PropTypes.string,
  setting: PropTypes.string,
  note: PropTypes.string,
  title: PropTypes.string,
  hideInput: PropTypes.bool,
  summaryNote: PropTypes.string,
  schedule: PropTypes.object,
  config: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  allowEdit: PropTypes.bool,
};

SchedulableSettingInput.defaultProps = {
  hideInput: false,
  allowEdit: true,
};

export default SchedulableSettingInput;
