import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Lazify from '../../../utils/Lazify';
import './styles.scss';

const LazyIntegrationList = Lazify(() => import(/* webpackChunkName: "Tools" */ './List'));
const LazyDetails = Lazify(() => import(/* webpackChunkName: "Tools" */ './Details'));
const LazyDetailsEdit = Lazify(() => import(/* webpackChunkName: "Tools" */ './Details/NewRevision'));

const Integrations = () => (
    <Routes>
      <Route index element={<LazyIntegrationList/>}/>
      <Route path=":integration_id" element={<LazyDetails/>}/>
      <Route path=":integration_id/:revision/edit" element={<LazyDetailsEdit/>}/>
      <Route path=":integration_id/edit" element={<LazyDetailsEdit/>}/>
      <Route element={() => <Navigate to=""/>}/>
    </Routes>
)


export default Integrations;
