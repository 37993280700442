
export const DispositionHelp = {
  abandoned: 'The caller has ended the call before a conference could be established',
  answered: '',
  blocked: 'We blocked this call for some reason',
  busy: 'Call was rejected by destination',
  canceled: '',
  cheat: '',
  completed: '',
  'double-dial': 'Call received for same origin/destination pair within too short of a time-span.',
  expired: 'Call received after transfer request has expired',
  failed: '',
  gathering: '',
  'in-progress': 'Alias for answered.',
  machine: '',
  'no-answer': '',
  'pending-analysis': 'The actual disposition is not yet available, but will be shortly',
  redirected: '',
  'ringing_inbound': '',
  'ringing_outbound': '',
  'transfer_failed': 'We were unable to transfer the call for technical reasons',
  'unknown-destination':'Call received for an unknown number.',
} as Record<string, string>;

export const RECORDING_URL_BASE = 'https://call-recordings.dmsengage.com/recordings?url=';
