// Application entry point
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, RouterProvider, createHashRouter, createRoutesFromElements } from 'react-router-dom';
import {NotificationContainer, NotificationPosition} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify"
import App from './App/App';
import { AppProvider } from './Providers/AppProvider';
import { FaLibrary } from './Layout/FaLibrary';
import ErrorBoundary from "./Layout/ErrorBoundary";
// import registerServiceWorker from './registerServiceWorker';

// import(
//   /* webpackChunkName: "faLibrary" */
//   /* webpackPreload: true */
//   "./Layout/FaLibrary").then((module) => {
//   if(module){
//     module.FaLibrary();
//   }
// });

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)

FaLibrary();

const AuthUrl = `${process.env.API_URL_CORE}/me`;
const AppVersion = `${process.env.REACT_APP_VERSION}`;
const AppHash = `${process.env.REACT_APP_HASH}`;

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="*" element={<App/>} errorElement={<ErrorBoundary/>} />
  )
)

const AppRouter = () => (
  <>
    <NotificationContainer
      containerId="dms-main-toast"
      className="dms-toast-container"
      toastClassName="dms-toast"
      bodyClassName="dms-toast-body"
      position={NotificationPosition.TOP_CENTER}
      hideProgressBar
      theme="light"
    />

    <AppProvider authUrl={AuthUrl} version={AppVersion} buildHash={AppHash}>
      <RouterProvider router={router} />
    </AppProvider>
  </>
);

ReactDOM.render(
  <AppRouter />,
  document.getElementById('app'),
);
// registerServiceWorker();
