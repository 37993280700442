import React, { useState, useEffect, useLayoutEffect, useMemo, useContext, ChangeEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button, ButtonGroup, Row, Col, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu
} from 'reactstrap';
import PubSub from 'pubsub-js';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { AppContext } from '../Providers/AppProvider';
import useApi from '../Hooks/useApi';
import useFavoriteOrder from "../Hooks/useFavoriteOrder";
import useMatchPath from '../Hooks/useMatchPath';
import InputWithIcon from './Form/InputWithIcon';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import { titleCaseWord } from '../utils/string';
import OrderModal from '../App/AdvertiserManager/Order/OrderModal';
import usePermission from '../Hooks/usePermission';
import StatusDot from "./Icons/StatusDot";
import {Account, Order} from "../types";
import useLocalStorage from "../Hooks/useLocalStorage";

type AccountsObject = {
  [key:number]:Partial<Account> & {orders:Order[]}
}

/**
 * Large dropdown with searchable list of all accessible accounts, their orders, and links to various functions
 */
const AccountMegaMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const app = useContext(AppContext);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [filtered, setFiltered] = useState<Account[]>([]);
  const {favorites} = useFavoriteOrder();
  // show favorites or all tab based on stored setting
  const [orderViewSetting, setOrderViewSetting] = useLocalStorage(
    "favorites_orders_view",
    favorites?.length > 0 ? 'favorites' : 'all'
  )
  const [view, setView] = useState<string>(orderViewSetting);
  const [searchVal, setSearchVal] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // Selections, set from route params or on click list items
  const [selectedAccount, setSelectedAccount] = useState<Account|null>(null);
  const [selectedOrder, setSelectedOrder] = useState<Order|null>(null);
  const [editOrder, setEditOrder] = useState<Order|null>(null); // might be different from current route
  const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false);
  const [pubSubToken, setPubSubToken] = useState<string>('');
  const inpRef = React.createRef(); // useRef(); ?? the useRef hook won't work, maybe because it's forwarded?
  // Custom hooks
  const api = useApi('accounts', 'list');
  const {accountId:routeAccountId, orderId:routeOrderId, setMatchPath} = useMatchPath(location.pathname);
  const allowViewUsers = usePermission('users.view');
  const allowViewAds = usePermission('ads.view');
  const allowViewLeads = usePermission('leads.view');
  const allowViewCalls = usePermission('calls.view');
  const [showLeadsLink, setShowLeadsLink] = useState<boolean>(false);
  const [showCallsLink, setShowCallsLink] = useState<boolean>(false);

  //Invert the favorites so it is a list by account
  const favoritesByAccount:Account[] = useMemo(() => {
    if (!favorites.length) return [];
    const accounts:AccountsObject = {};
    if (favorites) {
      favorites.map((order:Order) => {
        if (!accounts[order.account.id]){
          accounts[order.account.id] = {...order.account, orders:[]};
        }
        accounts[order.account.id].orders.push(order);
      })
    }


    return Object.values(accounts).sort((a, b) => {
      if (a.name && b.name) {
        return a.name > b.name ? 1 : -1
      }
      return 0;
    }) as Account[];
  }, [favorites])



  const load = async() => {
    const result = await api.fetch({
      page: 1,
      limit:1000
    });

    if (result) {
      setAllAccounts(result.data);

      if (view === 'favorites') {
        toggleView('favorites')
      } else {
        setFiltered(result.data);
      }

    }
  }

  const gotoRoute = (route:string) => { navigate(route); setIsOpen(false); };

  const handleEditOrder = (order:Order) => {
    setEditOrder(order);
    setOrderModalOpen(true);
  };

  const handleCloseOrderModal = (refresh:boolean=false) => {
    setOrderModalOpen(false);
    if (refresh) {
      load()
      PubSub.publish('REFRESH_ACCOUNTS_PAGE');
    }
  };

  const hasOrderWithProduct = (account:Account|null, product:string):boolean => {
    if (account && account.orders) {
      return account.orders.some((ord) => ord.product === product);
    }
    return false;
  };

  const toggleView = (v:string) => {
    if (v === 'favorites'){
      setFiltered(favoritesByAccount);
      if (favoritesByAccount.length) {
        setSelectedAccount(favoritesByAccount[0])
        scrollToAccount(favoritesByAccount[0].id)
      } else {
        setSelectedAccount(null)
      }

      setSearchVal('')
    } else {
      if (!selectedAccount) {
        setSelectedFromRoute();
      } else {
        scrollToAccount(selectedAccount.id)
      }
      setFiltered(allAccounts)
    }
    setView(v);
    setOrderViewSetting(v)
  }

  //Called on mount, when route changes, or when toggling view to All
  const setSelectedFromRoute = () => {
    if (allAccounts?.length) {
      let targetAccount;
      let routeAccount = allAccounts.find((acc) => acc.id === routeAccountId);
      if (!routeAccount && allAccounts.length === 1) routeAccount = allAccounts[0]; // only one account
      const routeOrder = routeAccount ? routeAccount.orders.find((ord) => ord.id === routeOrderId) : null;
      if (routeAccount || routeOrder) {
        targetAccount = routeAccount;
        app.dispatch({ type: 'current_account', value: routeAccount });
        setSelectedOrder(routeOrder ?? null);
      } else if (app.currentAccount) {
        //setSelectedAccount(allAccounts.find((acc) => acc.id === app.currentAccount.id));
        targetAccount = allAccounts.find((acc) => acc.id === app.currentAccount.id)
      }

      if (targetAccount) {
        setSelectedAccount(targetAccount);
      } else {
        setSelectedAccount(allAccounts[0]);
      }
      //setView('all');
    }
  };

  const scrollToAccount = (id:number) => {
    const el = document.getElementById(`account-link-${id}`)
    if (el) {
      //sometimes we need a bit of time to render because tabs have to switch etc
      setTimeout(()=> {
        if (!isScrolledIntoView(el)){
          el.scrollIntoView({behavior:"auto"})
        }
      }, 200)
    }
  }

  const isScrolledIntoView = (elem:HTMLElement) => {
    const container = document.getElementById('account-list-pane')
    if (container) {
      const { bottom, height, top } = elem.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    }
  }

  // Load data and subscribe on mount, unsubscribe pubsub on unmount
  // TODO: account filter for user role (this might be on server side)
  useEffect(() => {
    // Pubsub: accounts list page subscribes to  updates here, and vice-versa
    if (!pubSubToken) {
      setPubSubToken(PubSub.subscribe('REFRESH_ACCOUNTS_MENU', () => {
       load()
        setSelectedAccount(null);
      }));
    }
    load()
    return () => { PubSub.unsubscribe(pubSubToken); };
  }, []);

  // Focus to input on mount
  useEffect(() => {
    if (isOpen && inpRef.current) { // @ts-ignore
      inpRef.current.focus();
    }
    }, [inpRef]);

  // Monitor favorites changes
  useEffect(() => {
    if (!favorites?.length && view !== 'all') toggleView('all');
  }, [favorites]);

  // Monitor stored favorites setting changes
  useEffect(() => {
    if (view !== orderViewSetting) {
      toggleView(orderViewSetting);
    }
  }, [ orderViewSetting]);


  useLayoutEffect(()=> {
    if (selectedAccount) {
      scrollToAccount(selectedAccount.id);
    }
  }, [view])

  // Extract account/order when location changes
  useEffect(() => {
    setMatchPath(location.pathname);
  }, [location.pathname]);

  // Set selected account when accounts load or route changes
  useEffect(() => {
    setSelectedFromRoute();
  }, [allAccounts, routeAccountId, routeOrderId]);

  // Handle account selection change, set viewable links
  useEffect(() => {
    setShowLeadsLink(hasOrderWithProduct(selectedAccount, 'leads'));
    setShowCallsLink(hasOrderWithProduct(selectedAccount, 'calls'));
  }, [selectedAccount]);

  useLayoutEffect(()=>{
    if (isOpen && selectedAccount) {
      scrollToAccount(selectedAccount.id)
    }
  }, [isOpen])

  // execute search
  useEffect(() => {
    if (allAccounts) {
      if (searchVal) {
        const filtered = allAccounts.filter((a) => a.name.toLowerCase().search(searchVal.toLowerCase()) !== -1);
        setView('all');
        setFiltered(filtered);
        setSelectedAccount(filtered.length === 1 ? filtered[0] : null);
      } else {
        setFiltered([...allAccounts]);
        if (app.currentAccount) {
          const acc = allAccounts.find((acc) => acc.id == app.currentAccount.id)
          setSelectedAccount(acc ?? null);
        }
      }
    }
  }, [searchVal]);

  if (!allAccounts) return (null);

  return (
    <>
      <Dropdown nav isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle nav caret className={classnames({ active: location.pathname.indexOf('accounts') > 0 })}>
          {/*<MegaMenuButton account={selectedAccount} order={selectedOrder} />*/}
          Advertisers
        </DropdownToggle>
        <DropdownMenu id="select-account-dropdown" className="animate dropDownX" end>
          <div id="account-mega-menu" className="card-body">

            <Row className="align-items-center">
              <Col sm={9}>
                <div className="search-wrapper search-filter">
                  <InputWithIcon
                    icon="search"
                    iconSize="lg"
                    placeholder="Search for Accounts"
                    value={searchVal}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => setSearchVal(e.target.value)}
                    innerRef={inpRef}
                    containerClass="expands"
                  />
                </div>
              </Col>
              <Col className="text-end">
                {/* TODO: Display based on role permissions */}
                {/* TODO: Open modal? for now we are just going to the accounts page, which has the modals */}
                {/* <Button outline block onClick={()=>{ gotoRoute( "/accounts");}}>New Account</Button> */}
                <Button color="link" size="xs" onClick={() => { gotoRoute('/accounts'); }}>Account Dashboard</Button>
              </Col>
            </Row>

            <Row className="account-header border-top border-bottom">
              <Col sm={4}>
                <div className="d-flex align-items-center">
                  <h5>ACCOUNTS</h5>
                  <ButtonGroup className="ms-3">
                    <Button size="xs" outline active={view==='all'} onClick={()=>toggleView('all')}>All</Button>
                    <Button size="xs" outline active={view==='favorites'} onClick={()=>toggleView('favorites')} disabled={!favorites?.length}>Favorite Orders</Button>
                  </ButtonGroup>
                </div>

              </Col>
              <Col className="d-flex align-items-center justify-content-between">
                {selectedAccount && (
                  <>
                    <h5>{selectedAccount.name}</h5>

                    <div className="account-actions text-right">

                      {allowViewLeads && showLeadsLink && (
                        <Button color="link" size="sm" title={`View leads for account ${selectedAccount.name}`} onClick={() => gotoRoute(`/reports/leads?accounts=${selectedAccount.id}`)}>
                          <FaIcon icon="id-card" size="sm" />
                          &nbsp;Leads
                        </Button>
                      )}

                      {allowViewCalls && showCallsLink && (
                        <Button color="link" size="sm" title={`View calls for account ${selectedAccount.name}`} onClick={() => gotoRoute(`/reports/calls?accounts=${selectedAccount.id}`)}>
                          <FaIcon icon="phone" size="sm" />
                          &nbsp;Calls
                        </Button>
                      )}

                      {allowViewAds === true && (
                        <Button color="link" size="sm" title={`View and Edit ads for account ${selectedAccount.name}`} onClick={() => gotoRoute(`/accounts/${selectedAccount.id}/ads`)}>
                          <FaIcon icon="images" size="sm" />
                          &nbsp;Ads
                        </Button>
                      )}

                      {allowViewUsers === true && (
                        <Button color="link" size="sm" title={`Edit users for account ${selectedAccount.name}`} onClick={() => gotoRoute(`/accounts/${selectedAccount.id}/users`)}>
                          <FaIcon icon="users" size="sm" />
                          &nbsp;Users
                        </Button>
                      )}

                      {/* <Button  size="sm" color="warning" onClick={()=>{
                                    gotoRoute( "/accounts");
                                }}>Account Dashboard</Button> */}

                      {/* <Button  size="sm" color="primary" onClick={()=>{
                                    gotoRoute( "/accounts");
                                }}>New Order</Button> */}
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={4} className="account-list-col border-right" id="account-list-pane">
                <Nav vertical>
                  {filtered.map((account, i) => (
                    <NavItem key={i} id={`account-link-${account.id}`}>
                      <NavLink
                        className={classnames({ active: selectedAccount && account.id === selectedAccount.id })}
                        onClick={() => { setSelectedAccount(account); }}
                      >
                        {account.name}
                      </NavLink>

                    </NavItem>
                  ))}
                </Nav>
              </Col>
              <Col className="account-orders-col">
                <OrderDetails
                  account={selectedAccount}
                  selectedOrder={selectedOrder}
                  gotoRoute={gotoRoute}
                  onEditClick={handleEditOrder}
                  allowViewLeads={allowViewLeads}
                  allowViewCalls={allowViewCalls}
                />
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </Dropdown>

      <OrderModal
        order={editOrder}
        account={selectedAccount}
        isOpen={orderModalOpen}
        cancel={handleCloseOrderModal}
      />

    </>

  );
};

type OrderDetailsPropertiesType = {
  account:Account|null;
  selectedOrder:Order|null;
  gotoRoute:(route:string) => void;
  onEditClick: any;
  allowViewLeads: boolean;
  allowViewCalls:boolean;
}

const OrderDetails = ({
  account, selectedOrder, gotoRoute, onEditClick, allowViewLeads, allowViewCalls,
}:OrderDetailsPropertiesType) => {
  if (!account) return (null);

  const allowEdit = usePermission('orders.edit');

  // TODO: if account has no orders
  if (account.orders.length === 0) {
    return (<StandardAlert color="light">This account has no orders</StandardAlert>);
  }

  return (
    <>
      <Row className="order-header">
        <Col>
          <h5>Orders</h5>
        </Col>
      </Row>
      <div>

        {account.orders.map((order:Order, i:number) => {
          const base = `/accounts/${account.id}/order/${order.id}`;
          return (
            <div className={classnames({ 'order-row': true, selected: selectedOrder && order.id === selectedOrder.id })} key={i}>
              <StatusDot status={order.status}  className="order-status" />
              <div className="order-name" onClick={() => gotoRoute(`${base}/rules`)}>{order.name}</div>

              <div className="order-vertical">{order.vertical.display_name}</div>
              <div className="order-product">{titleCaseWord(order.product)}</div>
              <div className="order-actions">
                <span onClick={() => gotoRoute(`${base}/rules`)}>
                  <FaIcon
                    icon="clipboard-list"
                    size="sm"
                    title={`Rules and targets for ${order.name}`}
                  />
                </span>


                {/* TODO: open modal */}
                {allowEdit && (
                  <span onClick={() => onEditClick(order)}>
                     <FaIcon
                       icon="cog"
                       size="sm"
                       title={`Order settings for ${order.name}`}
                     />
                  </span>

                )}

                {order.product === 'leads' && allowViewLeads && (
                  <span onClick={() => gotoRoute(`/reports/leads?accounts=${account.id}&orders=${order.id}`)}>
                    <FaIcon
                      icon="id-card"
                      size="sm"
                      title={`Lead prospects for ${order.name}`}
                    />
                  </span>

                )}
                {order.product === 'calls' && allowViewCalls && (
                  <span onClick={() => gotoRoute(`/reports/calls?accounts=${account.id}&orders=${order.id}`)}>
                    <FaIcon
                      icon="phone"
                      size="sm"
                      title={`Call prospects for ${order.name}`}
                    />
                  </span>

                )}

              </div>
            </div>
          );
        })}

      </div>
    </>
  );
};

/* Button that toggles the dropdown, displays selected account and order */
// const MegaMenuButton = ({ account, order }) =>
// // Will there be a default account/order that we can set?
//
//   (
//     <>
//       <small>
//         Accounts {account && (<span>&gt; {account.name}</span>)}
//       </small>
//       {order && <div className="order">{order.name}</div>}
//     </>
//   );
export default AccountMegaMenu;
