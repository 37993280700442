import React from 'react';
import classnames from 'classnames';
import {
  Card, CardBody,
} from 'reactstrap';

/*
 * Component Wrapper for Card layout wrapping a filter bar that appears above tables
 * Filters are arranged as nav items.
 * <Nav navbar className="me-auto" >
 *   <NavItem className="boxed-filter search-filter">
 *     <Filter....
 */
const FilterBar = ({ className="", children, ...restProps }) => (
  <div className={classnames(['page-section-filters', 'align-items-center', 'mb-3', className])} {...restProps}>

    <Card>
      <CardBody>

        {children}

      </CardBody>

    </Card>

  </div>
);

/*
<Navbar  expand="md">
            <Collapse navbar>
              {children}
            </Collapse>
          </Navbar>
 */

export default FilterBar;
