import React from 'react';
import AggregateReport from './AggregateReport';
import DocTitle from '../../Layout/DocTitle';

const AdvertiserReport = (props) => {
  const today = new Date();

  return (
    <>
      <DocTitle pageTitle="Advertiser Report" />
      <AggregateReport
        reportType="advertiser"
        title="Advertiser Report"
        csvDefaultName={`MastodonAdvertiserReport-${today.toISOString().split('T')[0]}.csv`}
      />
    </>
  );
};

export default AdvertiserReport;
