import React, {useContext, useRef} from "react";
import RulesContext from "../../../../Providers/RulesContext";
import usePermission from "../../../../Hooks/usePermission";
import useCollectionTracker from "../../../../Hooks/useCollectionTracker";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import {Button, Col, Row} from "reactstrap";
import Configuration from "../../../../Components/Rules/Configuration";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const SaleConfirmation = (args) => {
  return <SaleConfirmationIntegration {...args} />
}

export default SaleConfirmation

const SaleConfirmationIntegration = ({ saleConfirmation, errors = {}, onChange }) => {
  const rulesContext = useContext(RulesContext);
  const allowEdit = usePermission('integrations.edit');
  const definitionsRef = useRef(rulesContext.api.getIntegrationDefinitionsByType('sale_confirmation'));
  const {
    api: collection,
    willDeleteIndex,
    editIndex,
    addMode,
  } = useCollectionTracker();

  const handleUpdate = (integration) => onChange({ ...saleConfirmation, integration });

  const handleDelete = () => {
    collection.onDelete();
    onChange({});
  };

  const handleAdd = () => {
    collection.add(0);
    onChange({
      integration: { name: '', settings: {} },
    });
  };

  // Don't show anything if user can't edit and no integration exists (MST-979)
  if (!allowEdit && !saleConfirmation.integration) {
    return (null);
  }

  return (
    <div className="form-section">
      <div className="form-section-header">Sale Confirmation</div>

      <p>Confirm lead sale by sending data to a third party. This is also referred to as POST integration.</p>

      {definitionsRef.current.length === 0 && <StandardAlert color="light" icon="alert">No integration configurations found</StandardAlert>}

      <Row>
        <Col lg={12} xl={9}>

          {!allowEdit && <StandardAlert color="light">Managing this setting requires additional permissions.</StandardAlert>}

          {saleConfirmation.integration !== undefined ? (
            <>
              <Configuration
                config={saleConfirmation.integration}
                definitions={definitionsRef.current}
                type="sale_confirmation"
                editMode={editIndex === 0}
                isNew={addMode}
                errors={errors}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                onApply={collection.apply}
                allowEdit={allowEdit}
                setEdit={collection.edit}
                willDelete={willDeleteIndex === 0}
                setWillDelete={(i) => collection.setWillDeleteIndex(i)}
                allowControls={allowEdit}
              />

            </>
          ) : (
            <>
              {allowEdit && (
                <Button color="link" onClick={handleAdd} disabled={editIndex !== null}>
                  <FaIcon icon="plus" className="me-1" />
                  Add Sale Confirmation Integration
                </Button>
              )}
            </>

          )}

        </Col>
      </Row>

    </div>

  );
};

SaleConfirmationIntegration.propTypes = {
  onChange: PropTypes.func.isRequired,
  saleConfirmation: PropTypes.object.isRequired,
  errors: PropTypes.object,
};
