import React, { useMemo } from 'react';
import classnames from "classnames";
import StatusDot from "../../../Components/Icons/StatusDot";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';

const SourceNode = React.forwardRef((
  {
    source,
    currentHover,
    currentSelection,
    onMouseEnter,
    onMouseLeave,
    onExamine,
    onOpenRules,
    onSelect,
    reconnect
  }:any,
  ref:React.LegacyRef<HTMLDivElement>
) => {

  // const handleClick = () => onSelect(source);

  const handleExamine = (e: any) => {
    e.stopPropagation();
    onExamine(source);
  }

  const handleOpenRules = (e: any) => {
    e.stopPropagation();
    onOpenRules(source);
  }


  const isRelated = useMemo(()=>{
    if (currentHover) {
      if (currentHover.object === "source") {
        return currentHover.id === source.id.toString();
      } else if (currentHover.sources ) {
        return currentHover.sources.includes(source.id.toString());
      }
    }

    return false;
  }, [currentHover])

  const isSelected = useMemo(()=>{
    if (currentSelection) {
      if (currentSelection.object === "source") {
        return currentSelection.id === source.id.toString();
      } else if (currentSelection.sources ) {
        return currentSelection.sources.includes(source.id.toString());
      }
    }
    return false;
  }, [currentSelection])


  return (
    <div
      ref={ref}
      className={classnames("node-row source", {related:isRelated, selected: isSelected})}
      data-id={source.id}
      data-object="source"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
    >
      <div
        className="node-pod"
      >
        <div className="node-pod-header">
          <div className="node-pod-title">
            <div>
              <StatusDot status={source.status} className="me-1"/>
              <span className="id me-1" title="Source ID">{source.id}</span>
              {source.name}
            </div>
            <div className="actions">
              <Button color="link" size="sm" onClick={handleExamine} title="Examine source routes">
                <FaIcon icon="eye" size="sm"/>
              </Button>
              <Button color="link" size="sm" onClick={handleOpenRules} title="View/Edit routing rule">
                <FaIcon icon="clipboard-list" size="sm"/>
              </Button>
            </div>
          </div>

        </div>

      </div>

      <div className="node-pod-footer d-flex">
        <div><label>Match:</label> <RelativeTime date={source.last_matched_at} uniqueId={`src-${source.id}-match`} showTimezone={true} /></div>
        <div><label>Sale:</label> <RelativeTime date={source.last_sold_at} uniqueId={`src-${source.id}-sale`} showTimezone={true} /></div>
      </div>


    </div>
  )
})

export default SourceNode;
