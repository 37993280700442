import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DocTitle from "../../Layout/DocTitle";
import useApi from "../../Hooks/useApi";
import PageSection from "../../Layout/PageSection";
import PostingInstructions from "../Dashboard/Publishers/PostingInstructions";

const PublisherPostDocs = () => {
  const {api} = useApi();
  const {sourceToken} = useParams();
  const [instructions, setInstructions] = useState();

  const getPostingInstructions = async () => {
    try {
      const postingInstructions = await api.client.get(`/public/publishers/instructions/${sourceToken}`);
      setInstructions(postingInstructions);
    } catch (exception) {
      setInstructions('error')
      console.error('Error fetching Publisher Posting Instructions:', exception)
    }
  }

  useEffect(() => {
    getPostingInstructions()
  }, [sourceToken])

  return (
    <>
      <DocTitle pageTitle="Publisher Documentation"/>

      <PageSection>
        <PostingInstructions instructions={instructions}/>
      </PageSection>

    </>
  );
};

export default PublisherPostDocs;
