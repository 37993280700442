import { useContext } from 'react';
import { AppContext } from '../Providers/AppProvider';
/*
 * Hook for providing a boolean indicating a permission value in global state
 * usage:
 * const[allowViewAdvReports] = usePermission('advertiser_reports.view');
 */
const usePermission = (perm:string):boolean => {
  const appContext = useContext(AppContext);

  return Boolean(perm && appContext.permissions.includes(perm));
};

export default usePermission;
