import { useState, useEffect } from 'react';
import { matchPath } from 'react-router-dom';

const getPathMatch = (pathToMatch:string, pattern:string):any =>
  /* const pattern = [
        "/accounts/:accountId/ads",
        "/accounts/:accountId/order/:orderId",
       // "/accounts/:accountId",
       //

    ]; */
  matchPath({
    path: pattern,
  },
    pathToMatch)
;

  type UseMatchPathReturn = {
    accountId:number|null;
    orderId:number|null;
    setMatchPath:(path:string)=>void;
  }
/*
 * Hook for reading account id and order id from router path
 */
const useMatchPath = (path:string):UseMatchPathReturn => {
  const [accountId, setAccountId] = useState<number|null>(null);
  const [orderId, setOrderId] = useState<number|null>(null);

  const setMatchPath = (path:string) => {
    const accountMatchPath = getPathMatch(path, '/accounts/:accountId');
    setAccountId(accountMatchPath ? parseInt(accountMatchPath.params.accountId) : null);
    const orderMatchPath = getPathMatch(path, '/accounts/:accountId/order/:orderId');
    setOrderId(orderMatchPath ? parseInt(orderMatchPath.params.orderId) : null);
  };

  useEffect(() => {
    if (path) {
      setMatchPath(path);
    }
  }, [path]);

  return {accountId, orderId, setMatchPath} as UseMatchPathReturn;
};

export default useMatchPath;
