import React from 'react';
import EnhancedSelect from "../Form/EnhancedSelect";
import PropTypes from 'prop-types';

// Styles for attribute selection, see https://react-select.com/styles
const AttributeSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#333',
    margin: 0,
    marginLeft: '4px',
    padding: '2px',
    fontSize: '0.85rem',
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    color: '#572770',
    fontWeight: 'bold',
    margin: 0,
    marginLeft: '4px',
    padding: 0,
    marginBottom: '3px',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 5000,
  }),
};

// Create array for use in attribute type select with grouped attribute options
const AttributeSelectOptions = (attributeGroups) => {
  const options = Object.entries(attributeGroups).map(([groupName, groupAttributes]) => {
    const group = { label: groupName, options: [] };
    group.options = groupAttributes.map((attr) => ({ label: attr.label, value: attr.alias }));
    return group;
  });

  return options;
};

/*
 * React-select for target attributes
 * attributeGroups is object with attribute data grouped by attribute group
 */
const TargetSelect = (props) =>  (
    <EnhancedSelect
      value={props.value}
      options={AttributeSelectOptions(props.attributeGroups)}
      styles={AttributeSelectStyles}
      isMulti={false}
      isSearchable
      onChange={props.onChange}
      placeholder="Search for target type"
    />
  );

TargetSelect.propTypes = {
  value: PropTypes.string,
  attributeGroups: PropTypes.object,
  onChange: PropTypes.func,
};

TargetSelect.defaultProps = {
  value:"", //the attribute alias
  attributeGroups: {},
};

export default TargetSelect;
