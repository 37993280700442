import React, { FC, ReactNode, RefObject, useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from "react-dom";
import RoutingApi from "./RoutingApi";
import useQueryState from "../../../Hooks/useQueryState";
import { Spinner  } from 'reactstrap';
import SourceNode from "./SourceNode";
import EligibilityNode from "./EligibilityNode";
import ChannelNode from "./ChannelNode";
import OrderNode from "./OrderNode";
import SvgWrapper from "./connector/SvgWrapper"
import SvgConnector from "./connector/SvgConnector";
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";
import LoaderDots from '../../../Layout/LoaderDots';
import Filters from "./Filters";
import "./styles.scss";
import RoutingRulesModal from "../RulesManager/Modal";
import ChannelSourceRoute from "../RulesManager/ChannelSourceRoute";

const routingApi = new RoutingApi();

type RoutingMapPropertiesType = {
  useQuery?:boolean;
  sourceId?:number;
  channelId?:number;
  orderId?:number;
  accountId?:number;
};

type NodeFocusType = {
  id: string,
  object: string,
  channels: string[],
  sources:string[],
  orders:string[]
}

/*
 * Routing Map
 * Show a route map from a selected source, channel, or order.
 */
const RoutingMap: FC<RoutingMapPropertiesType> = ({
  useQuery=true,
  sourceId,
  channelId,
  accountId,
  orderId
}) => {
  const topScrollRef = useRef() as RefObject<HTMLDivElement>;
  const bottomScrollRef = useRef() as RefObject<HTMLDivElement>;
  const [loading, setLoading] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(false);

  // If this component is its own page we will use the query string for state,
  // but if used in a modal on some other page, we won't read the query string
  const [query, setQuery] = useQuery
    ? useQueryState({}, {parseOptions:{parseNumbers: false}})
    : useState<any>(() => {
        if (sourceId) {
          return {source:sourceId.toString()}
        } else if (channelId) {
          return {channel:channelId.toString()}

        } else if (accountId && orderId) {
          return {account: accountId.toString(), order:orderId.toString()}
        }
      });

  const [flowView, setFlowView] = useState("");
  const [orderChannel, setOrderChannel] = useState<any | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<any | null>();
  const [selectedSource, setSelectedSource] = useState<any | null>();
  const [selectedOrder, setSelectedOrder] = useState<any | null>();

  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  const [routingRule, setRoutingRule] = useState<any>(null);

  //The route nodes
  const [flowSources, setFlowSources] = useState<any>([]);
  const [flowEligibilities, setFlowEligibilities] = useState<any>([]);
  const [flowChannels, setFlowChannels] = useState<any>([]);
  const [flowOrders, setFlowOrders] = useState<any>([]);

  // Map of related IDs for quick access during browser even handling
  const [sourceChannelMap, setSourceChannelMap] = useState({});

  const refs = useRef<{ [key: string]: any }>({});
  const mapAreaRef = useRef<HTMLDivElement>(null);
  const [mapAreaHeight, setMapAreaHeight] = useState<any>("auto");
  const [draw, redraw] = useState("0");

  // Contains node relations of hovered or selected node
  const[nodeHover, setNodeHover] = useState<NodeFocusType | null>(null);
  const[nodeSelect, setNodeSelect] = useState<NodeFocusType | null>(null);


  // We change the height of the map area dynamically so all items fit, and to avoid vertical scrolling.
  // (because map items are positioned absolutely, they won't automatically adjust the parent container height)
  const getMapHeight = () => {
    if (mapAreaRef.current) {
      const colHeights = Array.from(mapAreaRef.current.children).map((col) => {
        return col.clientHeight;
      });
      return Math.max(...colHeights) + 100;
    }
    return "auto";
  }

  // Force redraw of connector lines
  const reconnect = useCallback(()=> {
    redraw(Math.random().toString());
    window.setTimeout(() => {
      setMapAreaHeight(getMapHeight());
    }, 300)

  }, []);


  const handleScroll: React.EventHandler<React.UIEvent<ReactNode>> = (event: React.UIEvent<React.ReactNode> ) => {
    const targetDiv: HTMLDivElement = event.target as HTMLDivElement;

    if(targetDiv === topScrollRef.current && bottomScrollRef.current) {
      //@ts-ignore
      bottomScrollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
    else if(targetDiv === bottomScrollRef.current && topScrollRef.current) {
      //@ts-ignore
      topScrollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  // On hovering over a node, update a state object with data about
  // nodes related to the activated node.
  // All nodes will read the updated relation object and light up accordingly.
  const handleNodeHover = useCallback((e: any) => {
     e.stopPropagation();
    setNodeHover(buildNodeFocusData({...e.currentTarget.dataset}))
  }, [sourceChannelMap, orderChannel]);

  const handleNodeHoverOut = useCallback((e: any) => {
    setNodeHover(null);
  }, []);

  const handleNodeSelect = useCallback((e: any) => {
    e.stopPropagation();
    const dataset = {...e.currentTarget.dataset}
    ReactDOM.unstable_batchedUpdates(()=>{
      setNodeSelect(buildNodeFocusData(dataset))
      setQuery({...query, selected:`${dataset.object}:${dataset.id}`}, "replace")
    })

  }, [sourceChannelMap, orderChannel]);

  // When we hover over or click on a node, we get data from html attributes identifying the node.
  // Use routing data to create an object describing the related nodes so we can light them up.
  const buildNodeFocusData = (focusData: NodeFocusType) => {

    if (focusData.object === "source") {
      //channels belonging to hovered source
      // @ts-ignore
      focusData['channels'] = sourceChannelMap[focusData.id] ?? [];
      focusData['sources'] = [focusData.id];

    } else if (focusData.object === "channel") {
      // sources belonging to hovered channel
      focusData['sources'] = Object.entries(sourceChannelMap).reduce((acc:any, [sourceId, channels]:any) => {
        if (channels.includes(focusData.id)){
          acc.push(sourceId);
        }
        return acc;
      }, []);
      focusData['channels'] = [focusData.id];

    } else if (focusData.object === "order") {
      if (flowView === "order") {
        //light up all the things
        focusData['sources'] = flowSources.map((s:any) => s.id.toString())
        focusData['channels'] = flowChannels.map((c:any) => c.id.toString())

      } else if (orderChannel) {
        focusData['sources'] = flowView === "source" ? [selectedSource.id.toString()] : orderChannel.sources.map((s: any,) => s.id.toString());
        focusData['channels'] = [orderChannel.id.toString()];
      }


    } else if (focusData.object === "eligibility") {
      // Node for the eligibility relationship of source and channel
      // Relationships are one to one. ID is a composite (sourceId-channelId)
      const [sourceId, channelId] = focusData.id.split("-");
      focusData['channels'] = [channelId];
      focusData['sources'] =  [sourceId];
    }


    return focusData;
  }

  const addRef = (key: string) => {
    if (refs.current[key]?.current) {
      return refs.current[key];
    } else {
      const newRef = React.createRef<any>();
      refs.current[key] = newRef;
      return newRef;
    }
  }

  const handleChangeFilters = (filters: any) => {
    setQuery(filters || {});
  }

  //Set a key to force redraw of connectors
  useEffect(() => {
    redraw(Math.random().toString());
  }, [refs]);


  // Switch/adjust map view based on query
  useEffect(() => {

    // load views if source, query, or order param changes
      if (query.source) {
        if (flowView !== "source" || selectedSource.id.toString() !== query.source) {
          loadSourceView(query.source);
        }
      }

      if (query.channel) {
        if (flowView !== "channel" || selectedChannel.id.toString() !== query.channel) {
          loadChannelView(query.channel)
        }

      }
      if (query.order) {
        if (flowView !== "order" || selectedOrder.id.toString() !== query.order) {
          loadOrderView(query.order)
        }
      }


      // If url param for selection changes, mark the selected node route
      // TODO: Fix: init functions will set NodeSelect to null so it undoes what we set here
      // if (query.selected) {
      //   const [object, id] = query.selected.split(":");
      //   if (object && id) {
      //     console.log('Routing2.tsx: selection from query', object, id);
      //     if (!nodeSelect || (nodeSelect.object !== object || nodeSelect.id !== id)) {
      //       setNodeSelect(buildNodeFocusData({object, id} as NodeFocusType))
      //     }
      //
      //   }
      // }

  }, [query])

  useEffect(()=>{
    reconnect();
  }, [flowSources, flowChannels, flowOrders])


  // This isn't quite working either, was calling it from initialization functions,
  // but buildNodeFocusData might be run before the batch state update is done
  const getSelectedFromQuery = () => {
    if (query.selected) {
      const [object, id] = query.selected.split(":");
      if (object && id) {
        if (!nodeSelect || (nodeSelect.object !== object || nodeSelect.id !== id)) {
          return buildNodeFocusData({object, id} as NodeFocusType);
        }

      }
    }

    return null;
  }

  const loadChannelView = async (channelId:any) => {
    setLoading(true);
    const result = await routingApi.channelRoutes(channelId)
      //await api.endpoints.channels.show( channelId );
    if (result) {
      initializeChannelView(result)
    }
    setLoading(false)
  }

  const loadSourceView = async (sourceId:any) => {
    setLoading(true);
    const result = await routingApi.sourceRoutes(sourceId);
      //await api.endpoints.sources.show( sourceId );
    if (result) {
      initializeSourceView(result);
    }

    setLoading(false)
  }

  const loadOrderView = async (orderId:any) => {
    setLoading(true);
    const result = await routingApi.channelsForOrder(orderId);
      //await api.endpoints.sources.show( sourceId );
    if (result) {
      initializeOrderView(result);
    }

    setLoading(false)
  }

  const loadChannelOrders = async (channelId:any) => {
    setLoadingOrders(true);
    const result = await routingApi.ordersForChannel(channelId);
    if (result) {
      // TODO: group by account?
      setFlowOrders(result);
    }
    setLoadingOrders(false)
  }

  // For given channel, load source & eligibility data feeding it
  // const loadRoutesToChannel = async (channelId:any) => {
  //
  //   const channel: any = await channelRoutes(channelId)
  //   if (channel) {routing.
  //     ReactDOM.unstable_batchedUpdates(()=>{
  //       setSelectedChannel(channel);
  //       setSourceChannelMap( prev => {
  //         const scMap = {};
  //         // @ts-ignore
  //         channel.sources.forEach((s:any) => scMap[s.id.toString()] = [channel.id.toString()]);
  //         return scMap;
  //       })
  //       setSelectedSource(null);
  //       setFlowSources(channel.sources);
  //       //setFlowOrders([]);
  //     })
  //
  //     reconnect();
  //   }
  // }


  const initializeSourceView = (source: any) => {
    if (source) {
      // Batched state updates will be automatic in React 18
      // Hopefully we can live with "unstable"
      ReactDOM.unstable_batchedUpdates(()=>{
        setFlowSources([source])
        setFlowChannels(source.channels)
        setFlowEligibilities(source.channels.map((ch:any) => {
          return {
            source_id: source.id,
            channel_id: ch.id,
            eligibility: ch.eligibility,
          }
        }))
        setOrderChannel(null);
        //One source is mapping to multiple channels
        setSourceChannelMap({[source.id]: source.channels.map((c:any,) => c.id.toString())})
        setSelectedSource(source);
        setSelectedChannel(null);
        setFlowView("source");
        setFlowOrders([]);
        setNodeHover(null);
        setNodeSelect(null);
      }) // batch state

    }
  }

  const initializeChannelView = (channel: any) => {
    if (channel) {
      ReactDOM.unstable_batchedUpdates(()=>{
        setSelectedChannel(channel);
        setOrderChannel(channel);
        setFlowEligibilities(channel.sources.map((src:any) => {
          return {
            source_id: src.id,
            channel_id: channel.id,
            eligibility: src.eligibility,
          }
        }))
        setSourceChannelMap( prev => {
          //multiple sources are mapping to one channel
          const scMap = {};
          // @ts-ignore
          channel.sources.forEach((s:any) => scMap[s.id.toString()] = [channel.id.toString()]);
          return scMap;
        })
        setSelectedSource(null);
        setFlowView("channel");
        setFlowChannels([channel]);
        setFlowSources(channel.sources);
        setFlowOrders([]);
        setNodeHover(null);
        setNodeSelect(null);
      }) //batch state

      loadChannelOrders(channel.id)

    }
  }

  const initializeOrderView = (result: any) => {
    if (result) {
      const{order, rules, channels} = result;

      //Get unique sources and eligibility nodes from channels
      const sources:any = {};
      const eligibilities:any = {}
      Object.values(channels).map((ch:any)=>{
        ch.sources.forEach((s:any)=>{
          if (!sources[s.id]) {
            sources[s.id] = s;
          }
          const elKey = `${s.id}-${ch.id}`;
          if (!eligibilities[elKey]) {
            eligibilities[elKey] = {
              source_id: s.id,
              channel_id: ch.id,
              eligibility: s.eligibility
            }
          }


        })

      });

      order['rules'] = Object.values(rules); //Do this on the API side? Do we need the keys?
      ReactDOM.unstable_batchedUpdates(()=>{
        setSelectedOrder(order);
        setFlowChannels(Object.values(channels)); // do we need the ID keys, maybe just array?

        // THere will be one relationship for every source-channel combo
        setFlowEligibilities(Object.values(eligibilities))

        setFlowView("order");
        setSelectedSource(null);
        setSelectedChannel(null);
        setFlowSources(Object.values(sources));
        setFlowChannels(Object.values(channels));
        //Multiple sources may map to multiple channels
        setSourceChannelMap( prev => {
          const scMap: any = {};
          // @ts-ignore
          Object.values(channels).map((ch:any)=>{
            ch.sources.forEach((s:any) => {
              if (!scMap[s.id.toString()]) {
                scMap[s.id.toString()] = [ch.id.toString()]
              } else {
                scMap[s.id.toString()].push(ch.id.toString());
              }

            });
          })

          return scMap;
        })
        setNodeHover(null);
        setNodeSelect(null);
      }) // batch state
    }
  }

  const handleExamineChannel = (channel: any) => {
    if ( channel) {
      setQuery({channel: channel.id})
      setSelectedChannel(channel);
    }
  }

  const handleExamineOrder = (order: any) => {
    if ( order ) {
      setQuery({account:order.account.id, order: order.id})
      //setSelectedOrder(order);
    }
  }

  // A channel can be selected even if the flowView is not 'channel'.
  // For example, flowView=source and you click a channel to see the related orders.
  // const handleSelectChannel = (channel: any) => {
  //   if (channel) {
  //     setSelectedChannel(channel);
  //     if (flowView === "order") {
  //       // In order view, clicking on a channel loads related source & eligibility nodes
  //       //TEMP DISABLE loadRoutesToChannel(channel.id);
  //     } else {
  //       // In source or channel view, clicking on a channel loads related orders
  //       handleGetChannelOrders(channel);
  //     }
  //
  //   }
  // }

  const handleExamineSource = (source: any) => {
    if ( source) {
      setQuery({source: source.id})
      setSelectedSource(source);
    }
  }

  const handleGetChannelOrders = (channel: any) => {

    if (channel) {
      if (!orderChannel || orderChannel.id !== channel.id) {
        setOrderChannel(channel);
        loadChannelOrders(channel.id)
      }
    }

  }

  const openRulesModalFromSource = (s:any) => {

    setRoutingRule({
      // @ts-ignore
      entity: s,
      type: 'source',
    });
    setRulesModalOpen(true);
  };

  const openRulesModalFromChannel = (c:any) => {
    setRoutingRule({
      // @ts-ignore
      entity: c,
      type: 'channel',
    });
    setRulesModalOpen(true);
  };

  const openRulesModalFromChannelSource = (eligibility:any, sourceId:any, channelId:any) => {
    let channel, source;
    if (flowView === "channel") {
      channel = selectedChannel;
      source = selectedChannel.sources.find((s:any) => s.id.toString() === sourceId.toString())
    } else {
      channel = flowChannels.find((c:any) => c.id.toString() === channelId.toString());
      source = selectedSource;
    }

    if (channel) {
      setRoutingRule({
        // @ts-ignore
        entity: new ChannelSourceRoute(channel, {...source, eligibility}),
        type: 'channelsource',
      });
      setRulesModalOpen(true);
    }

  };

  const closeRulesModal = () => {
    setRulesModalOpen(false);
    setRoutingRule(null);
  }

  return (
    <div className="routing-map">


        {/* z-index so dropdowns overlay abs positioned route map stuff */}
        <div className="above-table-filters position-relative" style={{zIndex:10}}>
          <Filters filters={query} onChange={handleChangeFilters} loading={false} />
        </div>


          {flowView && (
            <div className="mt-3">
              <h4>
                {flowView === "source"
                 ? <span>Routes from Source</span>
                 : flowView === "channel" ?  <span>Routes through Channel</span>
                 : flowView === "order" ? <span>Routes to Order</span>
                 : "Route Map"
                }
              </h4>

              {/* "Fake" top scrollbar that adjust dynamically to real bottom scrollbar in hz-scroll */}
              <div
                className="top-scroll"
                ref={topScrollRef}
                //@ts-ignore
                onScroll={handleScroll}
              >
                <div></div>
              </div>

              <div
                className="hz-scroll"
                ref={bottomScrollRef}
                //@ts-ignore
                onScroll={handleScroll}
              >
                {/*<div className="position-relative">*/}

                  <div className="column-container map-area" ref={mapAreaRef} style={{height:mapAreaHeight}}>
                    <div className="node-col sources">
                      <div className="node-display">
                        <div className="node-header">
                          <h5>
                            {flowView === "source" ? "Source" : <span>Sources<NodeCount count={flowSources.length}/></span>}
                          </h5>
                        </div>

                        <div className="node-list">
                          {/* Route Map from one source */}
                          {flowView === "source" && selectedSource && (
                            <SourceNode
                              source={selectedSource}
                              onMouseEnter={handleNodeHover}
                              onMouseLeave={handleNodeHoverOut}
                              onOpenRules={openRulesModalFromSource}
                              ref={addRef(`source-${selectedSource.id}`)}
                              currentHover={nodeHover}
                              currentSelection={nodeSelect}
                              reconnect={reconnect}
                              onSelect={handleNodeSelect}
                              onExamine={handleExamineSource}
                            />
                          )}

                          {/* List sources belonging to selected channel or order */}
                          {(flowView === "channel" && selectedChannel || flowView === "order")  && (
                            <>
                              {flowSources.length > 0 ? (
                                <>
                                  {flowSources.map((src:any, i:number) => <SourceNode
                                    source={src}
                                    onMouseEnter={handleNodeHover}
                                    onMouseLeave={handleNodeHoverOut}
                                    ref={addRef(`source-${src.id}`)}
                                    currentHover={nodeHover}
                                    currentSelection={nodeSelect}
                                    onExamine={handleExamineSource}
                                    onOpenRules={openRulesModalFromSource}
                                    onSelect={handleNodeSelect}
                                    reconnect={reconnect}
                                    key={i}
                                  />)}
                                </>
                              ) : (
                                <i>None</i>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                    </div>


                    <div className="node-col eligibility">
                      <div className="node-display">
                        <div className="node-header">
                          <h5>
                            Eligibility
                            <HelpPopper
                              name="eligibility-route-help"
                              iconClass="ms-1"
                            >
                              <p>
                                Indicates a source's eligibility for matching on a channel, with configurable distribution.
                                {" "} Dates are the most recent match and sale on the channel-source.
                              </p>

                            </HelpPopper>
                          </h5>
                        </div>

                        <div className="node-list">
                          {flowEligibilities.length === 0  && <i>None</i>}

                          {/* original condition: flowView === "channel" */}
                          {flowEligibilities.length > 0 &&  (
                            <>
                              {flowEligibilities.map((el:any,i:number)=>(
                                <EligibilityNode
                                  eligibility={el.eligibility}
                                  sourceId={el.source_id}
                                  channelId={el.channel_id}
                                  currentHover={nodeHover}
                                  currentSelection={nodeSelect}
                                  ref={addRef(`eligibility-${el.source_id}-${el.channel_id}`)}
                                  onMouseEnter={handleNodeHover}
                                  onMouseLeave={handleNodeHoverOut}
                                  onOpenRules={openRulesModalFromChannelSource}
                                  onSelect={handleNodeSelect}
                                  reconnect={reconnect}
                                  key={i}
                                />
                              ))}
                            </>
                            )}

                        </div>
                      </div>
                    </div>



                    <div className="node-col channels">

                      <div className="node-display">
                        <div className="node-header">
                          <h5>
                            {flowView === "channel" ? "Channel" : <span>Channels<NodeCount count={flowChannels.length}/></span>}
                          </h5>
                        </div>

                        <div className="node-list">
                          {flowView === "channel" && selectedChannel &&  (
                            <ChannelNode
                              channel={selectedChannel}
                              currentHover={nodeHover}
                              currentSelection={nodeSelect}
                              ref={addRef(`channel-${selectedChannel.id}`)}
                              onMouseEnter={handleNodeHover}
                              onMouseLeave={handleNodeHoverOut}
                              onExamine={handleExamineChannel}
                              onGetOrders={handleGetChannelOrders}
                              onSelect={handleNodeSelect}
                              onOpenRules={openRulesModalFromChannel}
                              reconnect={reconnect}
                            />
                          )}

                          {/* List channels related to selected source */}
                          {flowView === "source" && selectedSource && (
                            <>
                              {flowChannels?.length > 0 ? (
                                <>
                                  {flowChannels.map((ch:any, i:number) => <ChannelNode
                                    channel={ch}
                                    sourceId={selectedSource.id}
                                    currentHover={nodeHover}
                                    currentSelection={nodeSelect}
                                    ref={addRef(`channel-${ch.id}`)}
                                    onMouseEnter={handleNodeHover}
                                    onMouseLeave={handleNodeHoverOut}
                                    onExamine={handleExamineChannel}
                                    onSelect={handleNodeSelect}
                                    onGetOrders={handleGetChannelOrders}
                                    onOpenRules={openRulesModalFromChannel}
                                    reconnect={reconnect}
                                    key={i}
                                  />)}
                                </>
                              ) : (
                                <i>The selected source has no channels</i>
                              )}
                            </>
                          )}

                          {/* List channels related to selected Order */}
                          {flowView === "order"  && (
                            <>
                              {flowChannels?.length > 0 ? (
                                <>
                                  {flowChannels.map((ch:any, i:number) => <ChannelNode
                                    channel={ch}
                                    currentHover={nodeHover}
                                    currentSelection={nodeSelect}
                                    ref={addRef(`channel-${ch.id}`)}
                                    onMouseEnter={handleNodeHover}
                                    onMouseLeave={handleNodeHoverOut}
                                    onExamine={handleExamineChannel}
                                    onSelect={handleNodeSelect}
                                    onGetOrders={handleGetChannelOrders}
                                    onOpenRules={openRulesModalFromChannel}
                                    reconnect={reconnect}
                                    key={i}
                                  />)}
                                </>
                              ) : (
                                <i>The selected order has no channels</i>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>



                    <div className="node-col orders">
                      <div className="node-display">
                        <div className="node-header">
                          <h5>
                            {flowView === "order" ? "Order" : <span>Orders<NodeCount count={flowOrders.length}/></span>}
                          </h5>
                        </div>

                        <div className="node-list">
                          {loadingOrders && <Spinner size="sm" />}

                          {flowView === "order" ? (
                            <>
                              {/* One order  */}
                              {selectedOrder && (
                                <OrderNode
                                  order={selectedOrder}
                                  rules={selectedOrder.rules}
                                  channel={orderChannel}
                                  currentHover={nodeHover}
                                  currentSelection={nodeSelect}
                                  isOrderView={true}
                                  ref={addRef(`order-${selectedOrder.id}`)}
                                  onMouseEnter={handleNodeHover}
                                  onMouseLeave={handleNodeHoverOut}
                                  onExamine={null}
                                  onSelect={handleNodeSelect}
                                  reconnect={reconnect}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {/* list of orders related to selected channel */}
                              {!orderChannel && !loadingOrders && <i>Click a channel to see related orders</i>}
                              {orderChannel && !loadingOrders && (
                                <>
                                  {flowOrders.length ? (
                                    <>
                                      {flowOrders.map((co:any, i:number) => <OrderNode
                                        order={co.order}
                                        rules={co.rules}
                                        channel={orderChannel}
                                        currentHover={nodeHover}
                                        currentSelection={nodeSelect}
                                        ref={addRef(`order-${co.order.id}`)}
                                        isOrderView={false}
                                        onMouseEnter={handleNodeHover}
                                        onMouseLeave={handleNodeHoverOut}
                                        onExamine={handleExamineOrder}
                                        onSelect={handleNodeSelect}
                                        reconnect={reconnect}
                                        key={i}
                                      />)}
                                    </>
                                  ) : (
                                    <i>No orders found</i>
                                  )}
                                </>
                              )}
                            </>
                          )}

                        </div>
                      </div>

                    </div>

                    {loading && <div className="loader-container">
                      <LoaderDots active={loading} width={160} />
                    </div>}

                  </div> {/* columns container, map-area */}

                  {/* CONNECTOR LINES */}
                  <SvgWrapper >
                    {flowEligibilities.length > 0 && (
                      <>
                        {/* Connect source to multiple source-channels (eligibilities) */}
                        {flowEligibilities.map((el:any,i:number) => (
                          <SvgConnector
                            /* @ts-ignore */
                            el1={refs.current[`source-${el.source_id}`]?.current}
                            /* @ts-ignore */
                            el2={refs.current[`eligibility-${el.source_id}-${el.channel_id}`]?.current}
                            elParent={mapAreaRef.current}
                            shape="arc"
                            direction="l2r"
                            roundCorner={true}
                            endArrow={false}
                            strokeWidth={2}
                            stroke={"#ced4da"}
                            selectedColor={"#147CFF"}
                            zIndex={1}
                            isHover={nodeHover?.channels &&
                            ( nodeHover &&
                              nodeHover.channels.includes(el.channel_id.toString())
                              && nodeHover.sources.includes(el.source_id.toString())
                            )}
                            isSelected={nodeSelect?.channels &&
                            ( nodeSelect &&
                              nodeSelect.channels.includes(el.channel_id.toString())
                              && nodeSelect.sources.includes(el.source_id.toString())
                            )}

                            key={`sc-${i}-${draw}`}
                          />
                        ))}

                        {/* Connect eligibility to corresponding channels */}
                        {flowEligibilities.map((el:any,i:number) => (
                          <SvgConnector
                            /* @ts-ignore */
                            el1={refs.current[`eligibility-${el.source_id}-${el.channel_id}`]?.current}
                            /* @ts-ignore */
                            el2={refs.current[`channel-${el.channel_id}`]?.current}
                            elParent={mapAreaRef.current}
                            shape="arc"
                            direction="l2r"
                            roundCorner={true}
                            endArrow={false}
                            strokeWidth={2}
                            stroke={"#ced4da"}
                            selectedColor={"#147CFF"}
                            zIndex={1}
                            isHover={nodeHover?.channels &&
                            ( nodeHover &&
                              nodeHover.channels.includes(el.channel_id.toString())
                              && nodeHover.sources.includes(el.source_id.toString())
                            )}
                            isSelected={nodeSelect?.channels &&
                            ( nodeSelect &&
                              nodeSelect.channels.includes(el.channel_id.toString())
                              && nodeSelect.sources.includes(el.source_id.toString())
                            )}

                            key={`sc-ch-${i}-${draw}`}
                          />
                        ))}
                      </>
                    )}


                    {/* Connectors from one channel to multiple orders */}
                    {orderChannel && flowOrders && !loadingOrders && (
                      <>
                        {flowOrders.map((channelOrder:any, i:number)=>(
                          <SvgConnector
                            /* @ts-ignore */
                            el1={refs.current[`channel-${orderChannel.id}`]?.current}
                            /* @ts-ignore */
                            el2={refs.current[`order-${channelOrder.order.id}`]?.current}
                            elParent={mapAreaRef.current}
                            shape="arc"
                            direction="l2r"
                            roundCorner={true}
                            endArrow={false}
                            strokeWidth={2}
                            stroke={"#ced4da"}
                            selectedColor={"#147CFF"}
                            zIndex={1}
                            isHover={nodeHover?.object === "order"
                              ? nodeHover.id === channelOrder.order.id.toString()
                              : nodeHover?.channels && nodeHover.channels.includes(orderChannel.id.toString())
                            }
                            isSelected={nodeSelect?.object === "order"
                              ? nodeSelect.id === channelOrder.order.id.toString()
                              : nodeSelect?.channels && nodeSelect.channels.includes(orderChannel.id.toString())
                            }
                            key={`or-${i}-${draw}`}
                          />
                        ))}
                      </>
                    )}

                    {/* Connectors from one order to multiple channels */}
                    {flowView === "order" && flowChannels &&  (
                      <>
                        {flowChannels.map((channel:any, i:number)=>(
                          <SvgConnector
                            /* @ts-ignore */
                            el1={refs.current[`channel-${channel.id}`]?.current}
                            /* @ts-ignore */
                            el2={refs.current[`order-${selectedOrder.id}`]?.current}
                            elParent={mapAreaRef.current}
                            shape="arc"
                            direction="l2r"
                            roundCorner={true}
                            endArrow={false}
                            strokeWidth={2}
                            stroke={"#ced4da"}
                            selectedColor={"#147CFF"}
                            zIndex={1}
                            isHover={nodeHover?.object === "order"
                              ? nodeHover.id === selectedOrder.id.toString()
                              : nodeHover?.channels && nodeHover.channels.includes(channel.id.toString())}
                            isSelected={nodeSelect?.object === "order"
                              ? nodeSelect.id === selectedOrder.id.toString()
                              : nodeSelect?.channels && nodeSelect.channels.includes(channel.id.toString())}
                            key={`or-${i}-${draw}`}
                          />
                        ))}
                      </>
                    )}
                  </SvgWrapper>

                {/* position-relative</div>*/}
              </div>

            </div>
          )}


        {rulesModalOpen && routingRule && (
          <RoutingRulesModal
            entity={routingRule.entity}
            entityType={routingRule.type}
            isOpen={rulesModalOpen}
            close={closeRulesModal}
          />
        )}

    </div>
  )
}

export default RoutingMap;

const NodeCount = ({count}: any) => {
  return count > 0 ? (<span className="node-count"> ({count})</span> ) : null;
}



