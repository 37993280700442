import React, { useContext } from 'react';
import classnames from 'classnames';
import { LiveFilteringContext } from '../../../Providers/LiveFilteringProvider';
import { TextSearch } from '../../../Components/Table/Filter';

const Filters = (props) => {
  const { filters, updateFilter, resetFilters } = useContext(LiveFilteringContext);

  return (

    <div className="d-flex position-relative">

      <div className={classnames(['loading-overlay', { show: props.loading }])} />

        <div className="boxed-filter search-filter">

          <TextSearch
            value={filters.name || []}
            placeholder="Search by name"
            onUpdate={(val) => updateFilter('name', val)}
            onClear={() => resetFilters()}
          />
        </div>

    </div>
  );
};

export default Filters;
