import { useState } from 'react';
import useApi from './useApi';
// import useDidMount from '../Hooks/useDidMount'

/*
 * Hook for using the global API class 'batchGet' with functional components,
 * Useful for populating multiple filters with dynamic data
 *
 * Usage:
 *    // assign hook result to whatever variable you want
 *    const api = useApi('accounts', 'list');
 *    //provide array of resources
 *    const batches =  await batcher.fetch( [
 *        {endpoint:'vendors',action:'list'},
 *        {endpoint:'verticals',action:'list'}
 *        {endpoint:'orders',action:'list',params:{account_id:10}}
 *        ])
 *    // You can handle api.result, api.error with useEffect
 *    useEffect( ()=>{ if(api.result) ...do stuff... }, [api.result, api.error]);
 *
 *
 */
const useApiBatch = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useApi();

  const fetch = async (resources) => {
    if (!Array.isArray(resources)) throw new Error('Resources must be an array');
    setError(null);
    setIsLoading(true);
    const batches = await api.batchGet(resources);
    setResult(batches);
    setIsLoading(false);
    if (!batches) {
      setError(api.error); // object with name and message
    }
    return batches;
  };

  return {
    result, error, fetch, isLoading,
  };
};

export default useApiBatch;
