import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import JSONPretty from 'react-json-prettify';
import { idea } from 'react-json-prettify/dist/themes';
import RulesContext from '../../../Providers/RulesContext';
import {
  SummaryItemWrapper,
  FlowControlSummary,
  TargetingSummary,
  RoutingCapsSummary,
  BiddingSummary,
  BidFalloffSummary,
  EnrichmentSummary,
  DestinationSummary,
  PingDedupeCapsSummary,
  SellStrategySummary,
} from '../../../Components/Rules/Summaries';

/*
 * Summary page for Routing rules
 */
const RuleSummary = ({
  rule, allowAdvanced, gotoTab,
}) => {
  const rulesContext = useContext(RulesContext);

  if (!rule) return (null);

  return (
    <div>
      <div className="rule-summary-section">

        <div className="rule-summary">

          <FlowControlSummary
            flowControl={rule.flow_control}
            gotoTab={gotoTab}
          />

          <BiddingSummary
            ruleType="routing"
            {...rule.bidding}
            floor={rule.account_bid_floor}
            gotoTab={gotoTab}
            label="Payout/Margin"
          />

          <TargetingSummary
            attributes={rulesContext.attributes}
            targets={rule.match_conditions}
            matchIsRequired={rule.match_is_required}
            expression={rule.match_expression}
            gotoTab={gotoTab}
          />

          <BidFalloffSummary
            bidFalloff={rule.bid_falloff}
            gotoTab={gotoTab}
          />

          <SellStrategySummary
            sellStrategy={rule.sell_strategy}
            gotoTab={gotoTab}
          />

          <RoutingCapsSummary
            caps={rule.sale_caps}
            title="Sale Caps"
            gotoTab={gotoTab}
          />

          <RoutingCapsSummary
            caps={rule.match_caps}
            title="Match Caps"
            gotoTab={gotoTab}
          />
          <PingDedupeCapsSummary
            caps={rule.ping_dedupe}
            title="Ping Dedupe Caps"
            gotoTab={gotoTab}
          />

          <EnrichmentSummary
            attributes={rulesContext.attributes}
            enrichments={rule.enrichment_addons}
            integrations={[]}
            tabName="integrations"
            gotoTab={gotoTab}
          />

          <DestinationSummary
            isRouting={true}
            product=""
            postSale={rule.delivery_destinations}
            tabName="integrations"
            gotoTab={gotoTab}
          />

          {allowAdvanced && (
          <AdvancedSummary
            internalData={rule.internal_data}
            title="Advanced Settings"
            gotoTab={gotoTab}
          />
          )}

          {/*
  MST-1970 hide schedule tab, sows confusion and chaos
          <ActivityScheduleSummary schedule={rule.schedule} timezone={timezone} gotoTab={gotoTab}/>

*/}

        </div>

      </div>

    </div>

  );
};

const AdvancedSummary = ({ internalData, title, gotoTab }) => {
  if (!internalData) return (null);

  return (
    <SummaryItemWrapper className="advanced-settings-summary" label={title} tabId="advanced" icon="cog" gotoTab={gotoTab}>
      <JSONPretty json={internalData} theme={idea} />
    </SummaryItemWrapper>
  );
};

RuleSummary.propTypes = {
  rule: PropTypes.object,
  gotoTab: PropTypes.func,
  timezone: PropTypes.string,
  allowAdvanced: PropTypes.bool,
};

RuleSummary.defaultProps = {

};

export default RuleSummary;
