import React from 'react';
import { Link } from 'react-router-dom';
import AccountBalances from './AccountBalances';
import usePermission from '../../../Hooks/usePermission';
import {Account} from "../../../types";

type AccountNameCellProperties = {
  account:Account;
}
/*
 * Used by account list table to product account name cell with links and account balances
 */
const AccountNameCell = React.memo<AccountNameCellProperties>(({
  account,
}) => {
  const allowEditAccount = usePermission('accounts.edit');
  const allowViewAds = usePermission('ads.view');
  const allowViewUsers = usePermission('users.view');
  const allowViewAccounting = usePermission('accounting.view');
  const allowViewLeads = usePermission('leads.view');
  const allowViewCalls = usePermission('calls.view');
  const showLeadsLink = allowViewLeads && account.orders.some((ord) => ord.product === 'leads');
  const showCallsLink = allowViewCalls && account.orders.some((ord) => ord.product === 'calls');

  return (
    <div className="account-cell d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <h5 className="account-name me-4 m-0 fw-bolder">{account.name}</h5>
        <div className="list-actions">
          {allowEditAccount && (
          <span className="action-item">
            <Link
              to={{
                pathname: `/accounts/${account.id}/settings`,
                search: 'tab=details',
              }}
              state={{account}}
              className="link"
              title={`Account settings for ${account.name}`}
            >
              Settings
            </Link>
            <span className="bull-delim">&bull;</span>
          </span>
          )}

          {allowEditAccount && (
            <span className="action-item">
              <Link
                to={{
                  pathname: `/accounts/${account.id}/settings`,
                  search: 'tab=goals'
                }}
                state={{account}}
                className="link"
                title={`Goals for ${account.name}`}
              >
                Goals
              </Link>
              <span className="bull-delim">&bull;</span>
            </span>
          )}

          {allowViewAds === true
          && (
          <span className="action-item">
            <Link
              title={`Ad Library for ${account.name}`}
              to={{
                pathname: `/accounts/${account.id}/ads`,
              }}
              state={{account}}
              className="link"
            >
              Ad Library
            </Link>
            <span className="bull-delim">&bull;</span>
          </span>
          )}

          {showLeadsLink && allowViewLeads && (
            <span className="action-item">
              <Link
                to={{
                  pathname: '/reports/leads',
                  search: `accounts=${account.id}&sort_by=received_time&sort_dir=desc`,
                }}
                className="link"
                title="View leads for this account"
              >
                Leads
              </Link>
              <span className="bull-delim">&bull;</span>
            </span>
          )}

          {showCallsLink && allowViewCalls && (
            <span className="action-item">
              <Link
                to={{
                  pathname: '/reports/calls',
                  search: `accounts=${account.id}&sort_by=received_time&sort_dir=desc`,
                }}
                title="View calls for this account"
                className="link"
              >
                Calls
              </Link>
              <span className="bull-delim">&bull;</span>
            </span>
          )}

          {allowViewUsers === true && (
            <span className="action-item">
              <Link
                title={`View users for ${account.name}`}
                to={`/accounts/${account.id}/users`}
                state={{account}}
                className="link"
              >
                Users
              </Link>
              <span className="bull-delim">&bull;</span>
            </span>
          )}
        </div>
      </div>

      {allowViewAccounting
      && (
      <AccountBalances
        accountId={account.id}
        cash={account.cash_balance}
        credit={account.credit_balance}
        isLow={account.low_balance}
        hasOrders={account.orders.length > 0}
      />
      )}

    </div>

  );
});


AccountNameCell.displayName = "AccountNameCell";

export default AccountNameCell;
