import React, { useState, useEffect } from 'react';
import {
  UncontrolledDropdown, DropdownMenu, DropdownToggle, Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {
  subWeeks, subMonths, startOfISOWeek, startOfMonth, endOfISOWeek, endOfMonth,
} from 'date-fns';
import classnames from 'classnames';
import { Filter, StackedFilter } from '../../Components/Table/Filter';
import FilterBar from '../../Components/Table/FilterBar';
import { StateChoices, ProductChoices } from '../../Components/Table/FilterChoices';
import FilterSummary from './FilterSummary';
import usePermission from '../../Hooks/usePermission';
import './styles.scss';
import RightDateAndControls from '../../Components/Table/RightDateAndControls';

const StaticChoices = {
  product: ProductChoices,
  device: [
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Desktop', label: 'Desktop' },
    { value: 'Tablet', label: 'Tablet' },
    { value: 'Other', label: 'Other' },
  ],
  state: StateChoices,
  boolean: [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
    { value: 'null', label: 'Unknown' },
  ],
  advertiser_groupBy: [
    { value: 'time_day', label: 'Date' },
    { value: 'time_month', label: 'Month' },
    { value: 'time_hour', label: 'Hour' },
    { value: 'account', label: 'Account' },
    { value: 'ad', label: 'Ad' },
    { value: 'channel', label: 'Channel' },
    { value: 'product', label: 'Product' },
    { value: 'vertical', label: 'Vertical' },
    { value: 'state', label: 'State' },
    { value: 'device_type', label: 'Device' },
    { value: 'tags', label: 'Tags' },
    { value: 'currently_insured', label: 'Currently Insured' },
    { value: 'goal_name', label: 'Goal Name' },
    { value: 'buyer_name', label: 'Buyer Name' },
  ],
  vendor_groupBy: [
    { value: 'time_day', label: 'Date' },
    { value: 'time_month', label: 'Month' },
    { value: 'time_hour', label: 'Hour' },
    { value: 'vendor', label: 'Vendor' },
    { value: 'source', label: 'Source' },
    { value: 'product', label: 'Product' },
    { value: 'vertical', label: 'Vertical' },
    { value: 'state', label: 'State' },
    { value: 'device_type', label: 'Device' },
    { value: 'currently_insured', label: 'Currently Insured' },
    { value: 'buyer_name', label: 'Buyer Name' },
  ],
  pivot: [
    { value: 'time_day', label: 'Day to Day' },
    { value: 'time_week', label: 'Week to Week (selected dates)' },
    { value: 'time_week_v_last', label: 'This Week vs Last Week' },
    { value: 'time_month', label: 'Month to Month (selected dates)' },
    { value: 'time_month_v_last', label: 'This Month vs Last Month' },
  ],
};

/*
 * A set of filter widgets to modify the query underlying a report.
 * For use with AggregateReport (advertiser, publisher)
 */
const ReportsFilters = (props) => {
  const [choicesLoading, setChoicesLoading] = useState(true);
  const [choices, setChoices] = useState({});
  const [summaries, setSummaries] = useState({
    filterSummary: null,
    groupBySummary: null,
    compareSummary: null,
  });
  const allowVendorView = usePermission('vendor_reports.view');

  useEffect(() => {
    // Wait for all choices (static and from API) to be set in state, then summarize current filters

    const getChoices = async () => {
      const { choices, defaults } = await props.dataSource.getFilterOptions();
      const staticChoices = { ...StaticChoices };
      // staticChoices.advertiser_groupBy = staticChoices.advertiser_groupBy.filter(opt => !opt.version || opt.version === props.version);
      // staticChoices.vendor_groupBy = staticChoices.vendor_groupBy.filter(opt => !opt.version || opt.version === props.version);

      if (!allowVendorView) {
        staticChoices.vendor_groupBy = staticChoices.vendor_groupBy.filter((opt) => opt.value !== 'vendor');
      }
      // if no permissions for sources, source filter choices are empty. Remove from static groupBy choices.
      if (choices.sources && choices.sources.length === 0) {
        staticChoices.vendor_groupBy = staticChoices.vendor_groupBy.filter((opt) => opt.value !== 'source');
      }
      setChoicesLoading(false);
      setChoices({ ...staticChoices, ...choices });

      // Set some default settings derived from choices response
      let newSettings = {};
      // Defaults object (expect array if empty)
      if (defaults && !Array.isArray(defaults) && Object.keys(defaults).length) {
        newSettings = defaults;
      }
      // just one account, preselect
      if (choices.accounts.length === 1) {
        newSettings = { accounts: choices.accounts[0].value, ...newSettings };
      }

      props.onFiltersLoaded(Object.keys(newSettings).length ? { ...newSettings, ...props.settings } : null);
    };
    if (Object.keys(choices).length === 0) {
      getChoices();
    }
  }, []);

  // Update filter summary
  useEffect(() => {
    // choices loaded async
    if (Object.keys(choices).length > 0) {
      summarizeFilterSettings();
    }
  }, [props.settings, choices]);

  const onChangeDate = (selection) => {
    mergeSettings({
      startDate: selection.startDate,
      endDate: selection.endDate,
    });
  };

  const onTimezoneChange = (tz) => {
    mergeSettings({
      timezone: tz,
    });
  };

  const onFilterChange = (filterName, val) => mergeSettings({ [filterName]: val });

  const onCompareFilterChange = (filterName, val) => {
    let { startDate, endDate } = props.settings;
    const now = new Date();
    if (val === 'time_week_v_last') {
      startDate = startOfISOWeek(subWeeks(now, 1));
      endDate = endOfISOWeek(now);
    } else if (val === 'time_month_v_last') {
      startDate = startOfMonth(subMonths(now, 1));
      endDate = endOfMonth(now);
    }
    mergeSettings({ [filterName]: val, startDate, endDate });
  };

  const summarizeFilterSettings = () => {
    const filterSummary = {};

    Object.entries(props.settings).map(([key, val]) => {
      switch (key) {
        case 'accounts':
          filterSummary[key] = getLabels(choices.accounts, val);
          break;
        case 'vendors':
          filterSummary[key] = getLabels(choices.vendors, val);
          break;
        case 'verticals':
          filterSummary[key] = getLabels(choices.verticals, val);
          break;
        case 'channels':
          filterSummary[key] = getLabels(choices.channels, val);
          break;
        case 'sources':
          filterSummary[key] = getLabels(choices.sources, val);
          break;
        case 'products':
          filterSummary[key] = getLabels(choices.product, val);
          break;
        case 'devices':
          filterSummary[key] = getLabels(choices.device, val);
          break;
        case 'states':
          filterSummary[key] = getLabels(choices.state, val);
          break;
        case 'tags':
          filterSummary[key] = getLabels(choices.tags, val);
          break;
        case 'currentlyInsured':
          filterSummary[key] = getLabels(choices.boolean, val);
          break;
      } // key not startDate, endDate, dateRange
    });

    Object.keys(filterSummary).forEach((key) => (filterSummary[key] == null) && delete filterSummary[key]);

    let groupBySummary = null;
    if (props.settings.groupBy) {
      groupBySummary = getLabels(choices[`${props.reportType}_groupBy`], props.settings.groupBy);
    }

    let compareSummary = null;
    if (props.settings.pivot) {
      compareSummary = getLabels(choices.pivot, props.settings.pivot);
    }

    setSummaries({ filterSummary, groupBySummary, compareSummary });
  };

  const getLabels = (options, values) => {
    if (values && Array.isArray(options)) {
      const selected = Array.isArray(values)
        ? options.filter((opt) => values.includes(opt.value))
        : options.filter((opt) => opt.value.toString() === values.toString());

      return selected.length ? selected.map((opt) => opt.label) : null;
    }
    return null;
  };

  const mergeSettings = (toMerge) => {
    props.onChange({ ...props.settings, ...toMerge });
  };

  return (
    <FilterBar>
      <div className="d-flex justify-content-between flex-lg-column flex-xl-row position-relative">
        <div className={classnames('loading-overlay', { show: props.reportLoading })} />
        <div className="flex-fill">
          <div className="d-flex">

            {/* Use of  inNavbar={true} disables popper and allows application of '.dropDown' animation class to menu */}
            <UncontrolledDropdown inNavbar disabled={choicesLoading} id="report-filters">
              {/* "nav" makes toggle <a> rather than <button> */}
              <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
                <FaIcon icon="filter" size="lg" className="fa-icons" />
                <span className="title d-block">
                  Applied Filters
                  {choicesLoading && <Spinner size="sm" color="secondary" className="ms-1" />}
                </span>
                <small>
                  <FilterSummary summaryData={summaries.filterSummary} />
                </small>
              </DropdownToggle>

              <DropdownMenu id="reports-filters-dropdown" className="select-filters-dropdown animate dropDown">

                <div id="report-filter-menu-dropdown" className="modal-body">
                  {props.reportType === 'advertiser'
                  && (
                  <StackedFilter
                    label="Account"
                    placeholder="Any Account"
                    options={choices.accounts || []}
                    param="accounts"
                    onChange={onFilterChange}
                    value={props.settings.accounts || []}
                    isMulti
                  />
                  )}
                  {props.reportType === 'vendor'
                  && (
                  <StackedFilter
                    label="Vendor"
                    placeholder="Any Vendor"
                    options={choices.vendors || []}
                    param="vendors"
                    onChange={onFilterChange}
                    value={props.settings.vendors || []}
                    isMulti
                  />
                  )}
                  <StackedFilter
                    label="Vertical"
                    options={choices.verticals || []}
                    param="verticals"
                    onChange={onFilterChange}
                    placeholder="Any Vertical"
                    value={props.settings.verticals || []}
                    isMulti
                  />
                  <StackedFilter
                    label="Product"
                    placeholder="Any Product"
                    param="products"
                    options={choices.product || []}
                    onChange={onFilterChange}
                    value={props.settings.products || []}
                    isMulti
                  />

                  {props.reportType === 'advertiser'
                  && (
                  <StackedFilter
                    label="Channel"
                    placeholder="Any Channel"
                    options={choices.channels || []}
                    param="channels"
                    value={props.settings.channels || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                  )}

                  {props.reportType === 'vendor'
                  && (
                  <StackedFilter
                    label="Source"
                    placeholder="Any Source"
                    options={choices.sources || []}
                    param="sources"
                    value={props.settings.sources || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                  )}

                  <StackedFilter
                    label="State"
                    placeholder="Any State"
                    param="states"
                    options={choices.state || []}
                    value={props.settings.states || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                  <StackedFilter
                    label="Device"
                    placeholder="Any Device"
                    param="devices"
                    options={choices.device || []}
                    value={props.settings.devices || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                  {props.reportType === 'advertiser'
                  && (
                  <StackedFilter
                    label="Tags"
                    placeholder="Any Tag"
                    param="tags"
                    options={choices.tags || []}
                    value={props.settings.tags || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                  )}
                  <StackedFilter
                    label="Currently Insured"
                    placeholder="Any Value"
                    param="currentlyInsured"
                    options={choices.boolean || []}
                    value={props.settings.currentlyInsured || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                </div>

              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown inNavbar className="ms-3" disabled={choicesLoading}>
              <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
                <FaIcon icon="th-large" size="lg" className="fa-icons" />
                <span className="title d-block">Group Results</span>
                <small>
                  <strong>By:</strong>
                  {' '}
                  {summaries.groupBySummary ? summaries.groupBySummary.join(',') : <i>No grouping</i>}
                </small>
              </DropdownToggle>
              <DropdownMenu id="reports-groupby-dropdown" className="select-filters-dropdown animate dropDown">

                <div className="modal-body">

                  <Filter
                    options={choices[`${props.reportType}_groupBy`] || []}
                    value={props.settings.groupBy || []}
                    param="groupBy"
                    onChange={onFilterChange}
                    onApply={null}
                    menuIsOpen
                    maxMenuHeight={200}
                    isMulti
                  />
                </div>

              </DropdownMenu>
            </UncontrolledDropdown>

{/* MRK-5184 Hide
            <UncontrolledDropdown inNavbar className="ms-3" disabled={choicesLoading}>
              <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
                <FaIcon icon="columns" size="lg" className="fa-icons" />
                <span className="title d-block">Compare</span>
                <small>
                  <strong>From:</strong>
                  {' '}
                  {summaries.compareSummary ? summaries.compareSummary.join(',') : <i>none</i>}
                </small>
              </DropdownToggle>
              <DropdownMenu id="reports-compare-dropdown" className="select-filters-dropdown animate dropDown">

                <div className="modal-body">
                  <Filter
                    options={choices.pivot || []}
                    value={props.settings.pivot || ''}
                    param="pivot"
                    onChange={onCompareFilterChange}
                    onApply={null}
                    menuIsOpen
                    maxMenuHeight={200}
                    isMulti={false}
                    controlShouldRenderValue
                    isSearchable={false}
                    closeMenuOnSelect
                  />
                </div>

              </DropdownMenu>
            </UncontrolledDropdown>*/}

          </div>
        </div>

        <RightDateAndControls
          startDate={props.settings.startDate}
          endDate={props.settings.endDate}
          onChangeDate={onChangeDate}
          onSelectTimezone={onTimezoneChange}
          timezone={props.settings.timezone}
          defaultTimezone={props.userTimezone}
          isLoading={props.reportLoading}
          onExecute={props.onExecute}
          executeLabel="View Report"
          onReset={props.onClear}
          className="reports"
        />

      </div>

    </FilterBar>
  );
};

ReportsFilters.propTypes = {
  userTimezone: PropTypes.string,
  settings: PropTypes.object.isRequired,
  defaultSettings: PropTypes.object, // need this still?
  onChange: PropTypes.func,
  onClear: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
  onFiltersLoaded: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired, // 'advertiser' or 'vendor'
  reportLoading: PropTypes.bool.isRequired,
};

ReportsFilters.defaultProps = {
  settings: {},
  reportLoading: false,
  defaultSettings: {},
};

export default ReportsFilters;
