import React, { useState } from 'react';
import {
  FormGroup, Label, Button, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import FileDropzone from '../../../Components/Form/FileDropzone';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import LoadingBar from '../../../Layout/LoadingBar';
import useApi from '../../../Hooks/useApi';
import {Required} from "../../../Components/Form/FormCommon";
import DynamicSingleSelect from "../../../Components/Form/DynamicSingleSelect";

const FILE_TYPES = 'text/plain,text/csv,application/vnd.ms-excel,application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/csv, text/x-csv, application/x-csv, text/comma-separated-values,text/x-comma-separated-values';

const GoalTrackingUpload = ({ close, accountId }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [goalId, setGoalId] = useState(null);
  const [note, setNote] = useState('');
  const [isBadFile, setIsBadFile] = useState(false);
  // const[description, setDescription] = useState('');
  const [formError, setFormError] = useState('');

  const { api } = useApi();

  // const handleChangeAccount = (e) => setAccountId(e.target.value);

  const handleChangeNote = (e) => setNote(e.target.value);

  const handleChangeGoal = (goal) => {
    setGoalId(goal.target.value)
  }

  const handleFilesAccepted = (files) => {
    setIsBadFile(false);
    setFormError(null);
    if (files && files.length) {
      setCsvFile(files[0]);
    } else {
      setCsvFile(null);
    }
  };

  const handleFilesRejected = (files) => {
    if (files && files.length) {
      setIsBadFile(true);
    } else {
      setIsBadFile(false);
    }
  };

  const handleUpload = () => {
    if (!csvFile) {
      setFormError('Please add a valid CSV file');
    } else if (isBadFile) {
      setFormError('The selected file is not valid');
    } else if (!accountId) {
      setFormError('Please choose a target account.');
    } else {
      setFormError(null);
      uploadFile();
    }
  };

  const uploadFile = async () => {
    setIsUploading(true);

    const formData = new FormData();

    formData.append('account_id', accountId);
    formData.append('goal_id', goalId);
    formData.append('note', note);
    formData.append('tracking_file', csvFile);

    const result = await api.endpoints.goalsTracking.create(formData);
    setIsUploading(false);

    if (result) {
      notify('Tracking file saved', 'success');
      close(true);
    } else {
      // header error will be Api.error.form.tracking_file
      const err = api.error?.form?.tracking_file;
      console.log('GoalTrackingUpload.js:', api.error, err);
      /*
      Error could look like:
      {
    "errors": {
        "tracking_file": "Invalid spreadsheet header: Header must exist and have columns sales_id, goal_name, and datetime"
    }
}
       */
      if (err) {
        setFormError(err || 'Unknown upload error');
      } else {
        notify(`Unable to upload file: ${api.error.name}`, 'error');
      }
    }
  };

  return (
    <div>

      <LoadingBar name="modal" active={isUploading} message="Uploading..." />

      {formError
      && (
      <StandardAlert color="info" className="validation">
        {formError}
      </StandardAlert>
      )}

      <p>
        Provide a CSV or spreadsheet file that matches lead tracking data to goals. The spreadsheet must at least
        have the <span style={{ color: 'red' }}>required</span>
        {' '} header fields as indicated below. Only the first worksheet will be processed. The optional <i>unique_id</i>
        {' '} value is used for de-duplication. The optional <i>quantity</i> value indicates how many conversions to record
        {' '}  for each sale (if using this column, provide 1 as the default value).
      </p>

      <div id="header-example-header">
        <div>Required Columns (at least one of these)</div>
        <div>Optional Columns</div>
      </div>

      <div id="header-example">
        <div className="req">auction_id</div>
        <div className="req">sale_id</div>
        <div className="req">email</div>
        <div className="req">phone</div>
        <div>datetime</div>
        <div>value</div>
        <div>unique_id</div>
        <div>quantity</div>

        <div>4e9b67f2...</div>
        <div>5271533</div>
        <div>joe@example.com</div>
        <div>+16198881234</div>
        <div>2021-07-12 16:11:00</div>
        <div>42.00</div>
        <div>4e9b67f2...</div>
        <div>2</div>
      </div>

      {<FormGroup >

        <Label >
          Goal <Required/>
        </Label>

          <DynamicSingleSelect
            onChange={handleChangeGoal}
            defaultValue=""
            endpoint="goals"
            name="goal_id"
            params={{account_id: accountId}}
          />

      </FormGroup>}

      <FormGroup>
        <Label>Note</Label>
        <Input type="text" name="note" placeholder="Describe this file" value={note} onChange={handleChangeNote} />
      </FormGroup>

      <FileDropzone
        fileTypes={FILE_TYPES}
        maxSize={10485760}
        disabled={isUploading}
        isUploading={isUploading}
        onDropAccepted={handleFilesAccepted}
        onDropRejected={handleFilesRejected}
        label="File"
        hint="Add a CSV file or Excel spreadsheet up to 10 megabytes"
      />

      <div className="d-flex justify-content-end">
        <Button color="link" onClick={close} size="sm">Cancel</Button>
        <Button color="primary" onClick={handleUpload} size="sm">Save</Button>
      </div>

    </div>
  );
};

GoalTrackingUpload.propTypes = {
  close: PropTypes.func.isRequired,
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default GoalTrackingUpload;
