import React from 'react';
import PropTypes from 'prop-types';
import {MoneyFormatter} from "../../../Components/Table/Table";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";

/* Display data from received_call   */
const BiddingLog = ({ auction }) => (
  <>
      <div className="form-section">
        <div className="form-section-header">Exclusive Bidding Decisions</div>

        {auction.logs?.exclusive_bidding && <div>
          <div className="data-row">
            <label>Shared Estimate</label>
            <div>
              {<MoneyFormatter value={auction.logs.exclusive_bidding.shared_estimate} />}
            </div>
          </div>
          <div className="data-row">
            <label>Shared Explanation</label>
            <div>
              <pre>{auction.logs.exclusive_bidding.shared_explanation}</pre>
            </div>
          </div>
        </div>}

        {!auction.logs?.exclusive_bidding && <StandardAlert color="light">
          No exclusive bidding decisions were made.
        </StandardAlert>}

      </div>
  </>
)

BiddingLog.propTypes = {
  auction: PropTypes.object,
}

export default BiddingLog;
