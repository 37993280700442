import React from 'react';
import PropTypes from 'prop-types';
import { tokenToWords } from '../../../utils/string';
import {JsonDisplay} from '@thedmsgroup/mastodon-ui-components';

/* Block reason and details */
const Block = React.memo(({ reason='', details={} }) =>
  // Reason (str) Details(object with various keys)
  (
    <div className="block d-flex flex-column">
      <div className="reason mb-1"><span>{tokenToWords(reason)}</span></div>
      <div>
        {details && Object.entries(details).map(([dtKey, val], i) => (
          <div className="sub-row" key={i}>
            <div className="key">{tokenToWords(dtKey)}</div>
            <div>{typeof val === 'object' ? <JsonDisplay data={val} scrollable={false} /> : val}</div>
          </div>
        ))}
      </div>
    </div>

  ));

Block.propTypes = {
  reason: PropTypes.string,
  details: PropTypes.object,
};

export default Block;

/*
Example
"Blocks":[
            {
               "Reason":"proxy_rejected",
               "Details":{
                  "delivery_id":154624270,
                  "disposition":"",
                  "integration_name":"alm_pingpost_auto_calls",
                  "reject_category":"duplicate",
                  "reject_details":"Duplicate lead found.",
                  "suppression":{
                     "Name":"alm_pingpost_auto_calls",
                     "Post":false,
                     "Settings":{
                        "api_key":"96087e6d2f3638a0e6e9292d14d376d37bfde9a1",
                        "passthrough_external_id":"vendor_external"
                     }
                  }
               }
            }
         ],
 */
