import React, { useEffect,  useState } from 'react';

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';

const RulesMap = {
  lowercase: 'Lowercase letter (a-z)',
  uppercase: 'Uppercase letter (A-Z)',
  number: 'Number (0-9)',
  specialchar: 'Punctuation or other (!@#$%...)',
  length: 'Minimum 15 characters',
  blacklist: 'Must not contain your name or email',
};

/*
Errors from server:
"Password must be at least 15 characters long"
"Password must contain an uppercase letter"
"Password must contain a lowercase letter"
"Password must contain a digit"
"Password must contain a special character"
"Password contains values from user name or email: a, b, c"
 */

const PasswordRules = ({errors}) => {
  const [resultMode, setResultMode] = useState(false);

  const [resultMap, setResultMap] = useState({
    lowercase: null,
    uppercase: null,
    number: null,
    specialchar: null,
    length: null,
    blacklist: null,
  });

  const errorToKey = (err) => {
    if (err.indexOf('long') > 0) {
      return 'length';
    } else if (err.indexOf('uppercase') > 0) {
      return 'uppercase';
    } else if (err.indexOf('lowercase') > 0) {
      return 'lowercase';
    } else if (err.indexOf('digit') > 0) {
      return 'number';
    } else if (err.indexOf('special') > 0) {
      return 'specialchar';
    } else if (err.indexOf('contains values') > 0) {
      return 'blacklist';
    }
    return '';
  }

  useEffect(() => {

    if (errors) {
      let results = {};
      setResultMode(true);
      const arrErrors = errors.split('|');
      arrErrors.map( (err) => {
        const key = errorToKey(err);
        if (key) {
          results[key] = false;
        }

      });

      setResultMap(results);
    }
  }, [errors]);


  // Toggle for is check mode
  return (
    <div id="password-rules">
      <h5>Your password requires:</h5>
      <ul className="ps-1">
        {Object.entries(RulesMap).map(([ruleKey, ruleText], i) => <li key={i}>
          {resultMode ? <RuleCheckIcon passed={resultMap[ruleKey] !== false} /> : <span>&bull;&nbsp;</span>}
          {ruleText}
        </li>)}
      </ul>
    </div>

  );
};

const RuleCheckIcon = ({passed}) => <FaIcon
  icon={passed ? 'check-circle' : 'times'}
  color={passed ? 'green' : 'red'}
  size="sm"
  className="me-2"
/>

export default PasswordRules;
