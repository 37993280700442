import React from 'react';
import ContentLoader from 'react-content-loader';
import PageSection from '../../../Layout/PageSection';

/*
 * https://github.com/danilowoz/create-content-loader/blob/master/src/Gallery/insertYourLoaderHere/Gmail.js
 */
const RuleSkeleton = (props) => (
  <PageSection
    className="rules-config-section"
    title="Rule Manager"
  >
    <ContentLoader
      height={510}
      width="100%"

      speed={0.8}
      {...props}
    >

      <rect x="0" y="20" rx="0" ry="0" width="100" height="30" />
      <rect x="120" y="20" rx="0" ry="0" width="100" height="30" />
      <rect x="240" y="20" rx="0" ry="0" width="100" height="30" />
      <rect x="360" y="20" rx="0" ry="0" width="100" height="30" />
      <rect x="480" y="20" rx="0" ry="0" width="100" height="30" />

      <rect x="0" y="60" rx="0" ry="0" width="100%" height="400" />

      <rect x="92%" y="480" rx="0" ry="0" width="100" height="32" />

    </ContentLoader>

  </PageSection>
);

RuleSkeleton.metadata = {
  name: 'Caio Davi',
  github: 'caio-davi',
  description: 'Reles Manager Skeleton',
  filename: 'RulesManagerSkeleton',
};

export default RuleSkeleton;
