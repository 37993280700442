import React, {FC, useContext} from "react";
import {
  Button, Modal, ModalHeader, ModalFooter, ModalBody, Input, FormGroup, Label, FormFeedback
} from "reactstrap";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import LoadingBar from '../../../../Layout/LoadingBar';
import { AppContext } from '../../../../Providers/AppProvider';
import { useNavigate } from 'react-router-dom';
import useFormState from "../../../../Hooks/useFormState";
import {Order} from "../../../../types";
/*
If later we want to clone across verticals, products, accounts
 import { ProductChoices } from '../../../../Components/Table/FilterChoices';
import {Filter} from "../../../../Components/Table/Filter";
import {VerticalMap} from "../../../../Components/Table/Table";
import {titleCase} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
 */



type CloneOrderFormValues = {
  name: string;
  status: string;
}


type CloneOrderModalType = {
  isOpen: boolean,
  close: any,
  order: Order,
  accountOrders: Order[]
}

const CloneOrderModal: FC<CloneOrderModalType> = ({
  isOpen,
  close,
  order,
  accountOrders
}) => {
  const app = useContext(AppContext);
  const navigate = useNavigate();
  const {
    formApi, formValues, formErrors
  }: any = useFormState({
    name:"",
    status: "paused"
  } as CloneOrderFormValues);


  const handleSave = () => {
    //@ts-ignore
    const {name, status} = formValues;
    const orderNames = accountOrders.map((o:Order) => o.name.toLowerCase());
    if (!name) {
      formApi.setError("name", "Name is required")
    } else if (orderNames.includes(name.toLowerCase())) {
      formApi.setError("name", "An existing order has this name already")
    }

    createOrder();
  }

  const handleCloseModal = () => {
    formApi.clearForm();
    close();
  }

  const createOrder = async () => {

    app.showLoader('modal', 'Copying Order...');

    const params = {
      name: formValues.name,
      status: formValues.status,
      product: order.product,
      vertical_id: order.vertical.id,
      account_id: order.account.id,
      copy_rules_from_id: order.id
    }
    const newOrder = await app.api.endpoints.orders.create(params)
    if (newOrder) {
      notify(`The order has been copied`, 'success');
      navigate(`/accounts/${order.account.id}/order/${newOrder.id}/rules`);
      handleCloseModal();
    } else {
      notify(`Unable to save order: ${app.api.error.name}`, 'error');
    }

    app.showLoader(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={close} size="md">
      <ModalHeader toggle={close}>Clone Order</ModalHeader>
      <div className="modal-subheader">{order.name}</div>
      <ModalBody>
        <LoadingBar name="modal" />

        <FormGroup>
          <Label>New Order Name</Label>

          <Input
            type="text"
            value={formValues.name}
            name="name"
            onChange={formApi.handleOnChange}
            invalid={!!formErrors.name}
          />
          <FormFeedback>
            {formErrors.name}
          </FormFeedback>
        </FormGroup>

        <FormGroup check>
          <Label>

            <Input
              type="radio"
              onChange={formApi.handleOnChange}
              value="paused"
              checked={formValues.status === "paused"}
              name="status"
            />
            Paused
        </Label>

        <Label>
          <Input
            type="radio"
            onChange={formApi.handleOnChange}
            value="active"
            //@ts-ignore
            checked={formValues.status === "active"}
            name="status"
            className="ms-2 me-1"
          />
          Active
        </Label>

        </FormGroup>

      </ModalBody>
      <ModalFooter>

        <Button
          onClick={handleCloseModal}
          color="link"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          size="sm"
        >
          Clone
        </Button>

      </ModalFooter>
    </Modal>
  );
};

export default CloneOrderModal;
