import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { LiveFilteringContext } from '../../../../Providers/LiveFilteringProvider';
import { Filter, TextSearch } from '../../../../Components/Table/Filter';
import { ProductChoices } from '../../../../Components/Table/FilterChoices';
import RightDateAndControls from '../../../../Components/Table/RightDateAndControls';
import useApiBatch from '../../../../Hooks/useApiBatch';

const Filters = ({ loading, onRefresh }) => {
  const [choices, setChoices] = useState({});
  // const{api} = useApi();
  const {
    filters,
    updateFilter,
    globalFilter,
    updateGlobalFilter,
    resetFilter,
    resetFilters,
  } = useContext(LiveFilteringContext);

  const batcher = useApiBatch();

  // load choices
  useEffect(() => {
    const getChoices = async () => {
      const batches = await batcher.fetch([
        { endpoint: 'verticals', action: 'list' },
        { endpoint: 'attributes', action: 'groups' },
      ]);
      if (batches && batches.length) {
        const [verticals, groups] = batches;
        setChoices({
          product: ProductChoices,
          vertical: verticals.map((item) => ({ value: item.id, label: item.display_name })),
          attributeGroups: groups.map((item) => ({ value: item.id, label: item.name })),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);

  return (

    <div className="attribute-filters d-flex justify-content-between position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />

      <div className="d-flex">
        <div className="boxed-filter search-filter me-3">

          <TextSearch
            value={globalFilter || ''}
            placeholder="Search by name / alias"
            onUpdate={updateGlobalFilter}
            onClear={() => resetFilter('global')}
            autofocus
          />

        </div>

        <div className="min-250">
          <Filter
            placeholder="Vertical"
            param="vertical.id"
            options={choices.vertical}
            value={filters['vertical.id'] || []}
            onChange={updateFilter}
            isMulti
          />
        </div>

        <div>
          <Filter
            placeholder="Product"
            param="product"
            options={choices.product}
            value={filters.product || []}
            onChange={updateFilter}
            isMulti
          />
        </div>

        <div className="min-250">
          <Filter
            placeholder="Group"
            param="group.name"
            options={choices.attributeGroups}
            value={filters['group.name'] || ""}
            onChange={updateFilter}
            isMulti={false}
          />
        </div>

      </div>

      <RightDateAndControls onRefresh={onRefresh} onReset={resetFilters} />

    </div>
  );
};

export default Filters;
