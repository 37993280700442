import React, { useState } from 'react';
import { FormFeedback, Input, InputGroup, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

/*
 * Reactstrap Input with icon that toggles password visibility.
*/
const PasswordWithToggle = ({ error, ...restProps }) => {
  const [inputType, setInputType] = useState('password');
  const handleClick = () => setInputType(inputType === 'password' ? 'text' : 'password');

  return (
    <InputGroup className={classnames('password-toggle', { visible: inputType === 'text'})}>
      <Input type={inputType} {...restProps} />
      <InputGroupText>
        <FaIcon icon={faEye} onClick={handleClick} title="Show and hide password" />
      </InputGroupText>
      <FormFeedback>{error}</FormFeedback>
    </InputGroup>
  );
};


export default PasswordWithToggle;
