import React from 'react';
import {Button} from 'reactstrap';
import {Link} from "react-router-dom";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

type EntityLinksProps = {
  entityId:string|number;
  accountId:string|number;
  label:string;
  linkPath:string;
  linkSearch:string;
  openRoutingModal:(filters:Record<string, any>)=>void;
  routingFilter?:string;
  className?:string;
}
/*
 * Shows source, channel, or order name with icon links to view/manage location and icon to open routing map.
 * routingFilter can be 'sourceId', 'orderId', 'channelId'
 * 'accountId' is needed with orderId
 */
const EntityLinks = ({
                       entityId,
                       accountId,
                       label,
                       linkPath,
                       linkSearch="",
                       openRoutingModal,
                       routingFilter,
                       className=""
}: EntityLinksProps) => {

  return (
    <div className={`d-flex align-items-center ${className} `}>
      <div>{label}</div>
      <div className="ms-1 d-flex no-wrap">
        <Link
          target="_blank"
          className="link"
          to={{
            pathname: linkPath,
            search: linkSearch,
          }}
          title={`View/Manage`}
        >
          <FaIcon icon="external-link-alt" size="sm" className="me-1"/>
        </Link>
        {routingFilter && (
          <Button
            size="sm"
            color="link"
            className="inline"
            onClick={() => openRoutingModal({[routingFilter]: entityId, accountId})}
            title="Routing Map">
            <FaIcon icon="project-diagram" size="sm"
            />
          </Button>
        )}

      </div>

    </div>
  )
}

export default EntityLinks;
