import React from 'react';
import {
  Button,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import KeyValueRow from './KeyValueRow';
import './KeyValueBuilder.scss';

const KeyValueBuilder = ({ values= [], onChange, className='', label = '' }) => {

  const onChangeAttribute = (key, i) => {
    const newValues = [...values];
    newValues[i].key = key;
    onChange(newValues);
  };

  const onChangeValue = (val, i) => {
    const newValues = [...values];
    newValues[i].value = val;
    onChange(newValues);
  };

  // Problem: in Integration, the change handler is expecting form event
  const addRow = () => onChange([...values, { key: '', value: '' }]);

  const removeRow = (index) => onChange(values.filter((pair, i) => i !== index));

  return (
    <div className={`key-value-builder ${className}`}>
      {values.length > 0 ? (
        <>
          {values.map((item, i) => (
            <div className="key-value-item d-flex mb-2" key={i}>
              <KeyValueRow
                attribute={item.key}
                value={item.value}
                onChangeAttribute={(key) => { onChangeAttribute(key, i); }}
                onChangeValue={(val) => onChangeValue(val, i)}
              />

              <div>
                <Button color="link" className="ms-2" onClick={() => removeRow(i)}>
                  <FaIcon icon="minus" size="sm" />
                </Button>
                <Button color="link" className="ms-1" onClick={addRow} size="sm">
                  <FaIcon icon="plus" size="sm" />
                </Button>
              </div>
            </div>
          ))}
        </>
      ) : (
        <Button color="link" className="" onClick={addRow} size="sm">
          <FaIcon icon="plus" className="me-1" size="sm" />
          Add {label}
        </Button>
      )}
    </div>
  );
};

KeyValueBuilder.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default KeyValueBuilder;
