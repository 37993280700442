import React from 'react';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import PropTypes from 'prop-types';
import './styles.scss';
import './RulesModal.scss';
import RulesManager from '.';
import { Link } from 'react-router-dom';
import { titleCase } from '../../../utils/string';
import LoadingBar from '../../../Layout/LoadingBar';
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";

/*
 * Modal container for routing rules configuration.
 * Entity may be Source, Vendor, Channel, or Channel-source (aka ChannelEligibility)
 */
const RoutingRulesModal = ({
  entity,
  entityType,
  isOpen,
  close,
  onRefresh,
}) => {
  const permalink = entityType === "channelsource"
    ? `/routing/channelsource/channel/${entity.channel.id}/source/${entity.source.id}/rules`
    : `/routing/${entityType}/${entity.id}/rules`

  return (
    <Modal isOpen={isOpen} toggle={close} size="full" className="rules-modal" id="routing-rules-modal">

      {entity.rule_id && (
        <>
          <ModalHeader toggle={close}>
            {entityType === "channelsource" ? "Channel-Source" : titleCase(entityType)} Routing Rules | <span
            className="modal-subheader">{entity.name}</span>
          </ModalHeader>

          <div className="modal-subheader">
            <Link
              target="_blank"
              to={permalink}
              className="link rule-manager-link p-0"
              title="Link to Routing Rules Manager"
            >
              <FaIcon icon="external-link-alt" size="sm"/>
              {" "}
              Permalink
            </Link>
            <CopyButton
              id="copy-link"
              textToCopy={`${location.protocol}//${location.host}/#${permalink}`}
              buttonSize="sm"
            >
              <FaIcon icon="copy" size="sm"/>
            </CopyButton>
          </div>

          <ModalBody className="" id="routing-rule-manager">
            <LoadingBar name="modal"/>
            <RulesManager
              entityRuleId={entity.rule_id}
              entityType={entityType}
              product={entity.product}
              verticalId={entity.vertical?.id}
              isModal={true}
              onRefresh={onRefresh}
            />

          </ModalBody>
        </>
      )}
    </Modal>

  )
};

RoutingRulesModal.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onRefresh: PropTypes.func
};

export default RoutingRulesModal;
