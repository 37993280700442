import React, {
  useState, useEffect, useReducer, useContext,
} from 'react';
import { Row, Col, Form, Button, Spinner } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import {Navigate} from "react-router-dom";
import * as Yup from 'yup';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import PasswordEntry from '../Components/Form/PasswordEntry';
import useFormState from '../Hooks/useFormState';
import { AppContext } from '../Providers/AppProvider';
import DocTitle from '../Layout/DocTitle';
import PasswordRules from './UserManager/User/PasswordRules';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .test('passwordStrength', 'Please strengthen your password', function (pw) {
      // return pw ? this.options.context.passwordScore >= 3 : true;
      return true;
    })
    .min(15, 'Passwords must have 15 or more characters')
    .required('Please enter a new password'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match (please confirm)'),
});

const activationStateReducer = (state, action) => {
  switch (action.type) {
    // case 'invalidForm':
    //  return {...state, isPosting:false, isValid:false, formError:action.error};
    case 'activationUnknown':
      return { ...state, isPosting: false, status: 'unknown' };
    case 'activationLoaded':
      let status = 'pending';
      if (action.data.user_verified) {
        status = 'verified';
      } else if (action.data.is_expired) {
        status = 'expired';
      }

       return { ...state, isPosting: false, status };
    case 'success':
      return {
        ...state, isPosting: false, isValid: true, result: 'success', formError: '', passwordRuleError: '',
      };
    case 'fail': // error from server
      return {
        ...state, isPosting: false, isValid: true, result: 'fail', formError: action.error, passwordRuleError: '',
      };
    case 'password_fail': // error from server about password rules
      return {
        ...state, isPosting: false, isValid: true, result: 'password_fail', formError: '', passwordRuleError: action.error,
      };
    case 'posting':
      return {
        ...state, isPosting: true, isValid: true, formError: '', result: '',
      };
    default:
      return state;
  }
};

const ActivateUser = () => {
  const app = useContext(AppContext);
  const {activationToken:token} = useParams();
  const initialFormValues = { password: '', confirm_password: '' };
  const [activationState, dispatch] = useReducer(activationStateReducer, {
    isPosting: false,
    isValid: true,
    formError: '',
    passwordRuleError: '',
    status: 'loading',
  });
  const { formApi, formValues, formErrors } = useFormState(initialFormValues, validationSchema);
  const [activationInfo, setActivationInfo] = useState(null);
  //const token = match.params.activationToken;

  // Get activation on load
  useEffect(() => {
    if (!activationInfo) {
      getActivationInfo();
    }
  }, []);

  // When the score changes, update the validation context
  // (extra data used during validation)
  // useEffect(() => {
  //   formApi.setValidationContext({ passwordScore });
  // }, [passwordScore]);

  const getActivationInfo = async () => {
    if (token) {
      const activation = await app.api.endpoints.activations.show(token);
      if (activation) {
        setActivationInfo(activation);
        dispatch({ type: 'activationLoaded', data: activation });
      } else {
        dispatch({ type: 'activationUnknown' });
      }
    }
  };

  const submitRequest = async (password) => {
    dispatch({ type: 'posting' });
    const result = await app.api.endpoints.activations.update({ id: token, password });
    if (result) {
      dispatch({ type: 'success' });
    } else {
      const err = app.api.error;
      if (err.form.password) {
        dispatch({ type: 'password_fail', error: err.form.password });
      } else {
        dispatch({ type: 'fail', error: 'We were unable to submit your request. Please try again.' });
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (activationState.isPosting) return;
    const isValid = formApi.validate();
    if (isValid) {
      submitRequest(evt.target.password.value);
    } else {
      // no special messaging, form fields will show error
      // dispatch({type:'invalidForm', error:'We '})
    }
  };

  // We should not be here
  if (app.isAuthorized) {
    return <Navigate to="/accounts" replace={true} />;
  }

  return (
    <>
      <DocTitle pageTitle="Activate" />
      { (activationState.result === 'success') && <Navigate to="/login" state={{activated: true}} replace={true}   />}
      { !token && <Navigate to="/login"  />}

      <Row>
        <Col sm={12} md={{ size: 6, offset: 3 }} className="entry-form-panel h-100">
          {/* Getting activation info */}
          {activationState.status === 'loading' && <div className="d-flex align-items-center justify-content-center" style={{ height: '250px' }}>
            <div>
              <Spinner color="secondary" size="lg" />
            </div>
          </div>
          }

          {activationState.status === 'verified' && (
            <>
              <h5>Welcome</h5>
              <StandardAlert color="warning">Account Verified</StandardAlert>
              <p>
                According to our records, your account has already been verified. Please
                &nbsp;<Link to="/login">sign in</Link>
                , or&nbsp;
                <Link to="/forgot-password">reset your password</Link>
                {' '}
                if you have forgotten it.
              </p>
            </>
          )}

          {activationState.status === 'expired' && (
            <>
              <h5>Welcome</h5>
              <StandardAlert color="warning">Activation Link Expired</StandardAlert>
              <p>
                We&apos;re sorry, your activation link has expired. Please contact your Mastodon account manager or
                &nbsp;<a href="mailto:support@app.platform.ue.co">UE.co customer service</a>
                .
              </p>
            </>
          )}

          {activationState.status === 'unknown' && (
            <>
              <h5>Activate My Account</h5>
              <StandardAlert color="warning">Unknown Activation</StandardAlert>
              <p>
                We&apos;re sorry, we were unable to find your activation record. Please contact your Mastodon account manager or
                {' '}<a href="mailto:support@app.platform.ue.co">UE.co customer service</a>
                .
              </p>
            </>
          )}

          {/* All good, show the form */}
          {activationState.status === 'pending' && (
            <>
              <h4>Welcome</h4>
              <p className="mb-4">To activate your account, please enter and confirm a password.</p>

              { !activationState.isValid && (<StandardAlert color="warning">{activationState.formError}</StandardAlert>)}
              { activationState.result === 'fail' && (
                <StandardAlert color="warning">
                  We were unable to activate your account.
                  Please try again, or contact your Mastodon account manager or <a href="mailto:support@app.platform.ue.co">DMS Group customer service</a>.
                </StandardAlert>
              )}
              { activationState.result === 'password_fail' && (
                <StandardAlert color="warning">
                  The submitted password does not meet security requirements.
                </StandardAlert>
              )}

              <div className="d-flex mt-2">
                <div className="flex-grow-1">
                  <Form onSubmit={handleSubmit}>


                    <PasswordEntry
                      password={formValues.password}
                      confirmPassword={formValues.confirm_password}
                      onChange={formApi.handleOnChange}
                      errors={formErrors}
                      options={{ noLabel: true }}
                      bsSize="lg"
                    />

                    <Button type="submit" color="primary" block disabled={activationState.isPosting}>
                      { activationState.isPosting === true ? (
                        <Spinner color="light" size="sm" />
                      ) : <span>Activate My Account</span>}
                    </Button>

                    <Button size="sm" color="link" tag={Link} to="/Login" block className="mt-2 no-case">
                      Return to sign-in
                    </Button>

                  </Form>
                </div>

                <div className="ms-4">
                  <PasswordRules errors={activationState.passwordRuleError} />
                </div>
              </div>

            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ActivateUser;
