import React, { useContext, useEffect } from 'react';
import { ProgressBar } from 'reprogressbars';
import { AppContext } from '../Providers/AppProvider';
import Mastodon from '../Components/Icons/DMS/Mastodon';

/*
 *  Progress bar style loader, covers area and shows animated horizontal bar at top
 *  ( visual only, does not  track actual loading progress)
 *  There's no queue of loaders so it's only one at a time. In general this loader is
 *  meant for obscuring an area and providing activity indication to the user while
 *  loading or saving remote data.
 *
 *  Component needs to have a reference to the AppContext
 *    const app = useContext(AppContext);
 *
 *  To apply to the whole page, put <LoadingBar name="main"/> at top of main content area
 *  Activate the loader:
 *    app.showLoader() //will default to area 'main' with default message
 *  Set false to deactivate
 *    app.showLoader(false)
 *
 *  For loaders in different areas such as modal, use a different name
 *  <LoadingBar name="modal"/>
 *  Activate the loader: app.showLoader('modal', 'Updating password...')
 *
 */
const LoadingBar = ({
  name, message="", active=false, overlay=true, scrollToTop,
}) => {
  const app = useContext(AppContext);

  // Determine if loader is currently in loading state
  // 1) prop 'active' set true in jsx
  // 2) global loader == true and loader name prop is the default (eg, 'main')
  // 3) global loader == 'some name' that matches this loader's name prop  (eg, 'modal')
  // const isLoading = (active === true ||  (name === LoadingBar.defaultProps.name ? loader===true : name === app.loader));
  const isLoading = (active === true || name === app.loader);

  useEffect(() => {
    if (isLoading && scrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isLoading]);

  return (
    <div className="loading-bar-container" data-state={isLoading ? 'active' : 'hidden'}>

      <ProgressBar isLoading={isLoading} className="loading-bar" height="4px" color="#5cb935" useBoxShadow />
      {overlay && (
        <>
          <div className="loading-bar-overlay"></div>
          {/* <div className={'loading-bar-message'}>{message || globalMessage}</div> */}
          <div className="loading-bar-message text-center">
            <div className="mb-1 me-1">
              <Mastodon />
            </div>
            <div>
              {message || app.loaderMessage}
            </div>

          </div>
        </>
      )}

    </div>
  );
};

LoadingBar.defaultProps = {
  name: 'main',
  active: false,
  scrollToTop: false,
  overlay: true
};

export default LoadingBar;
