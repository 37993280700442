import { useState, useContext } from 'react';
import { AppContext } from '../Providers/AppProvider';
import {Order} from "../types";
//import useApi from "./useApi";

const useFavoriteOrder = () => {
  const app = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  //const api = useApi();

  const addOrder = async (id:number) => {
    setLoading(true);
    const result = await app.api.endpoints.favorites.addOrder(id);
    if (result) {
      app.updateFavoriteOrders(result);
    }
    setLoading(false);
    return result;

  }

  const removeOrder = async (id:number) => {
    setLoading(true);
    const result = await app.api.endpoints.favorites.deleteOrder(id);
    if (result) {
      app.updateFavoriteOrders(result);
    }
    setLoading(false);
    return result;

  }

  const updateUserFavorites = (orders:Order[]) => app.updateFavoriteOrders(orders)

  return {
    favorites: app.favorite_orders,
    loading,
    addOrder,
    removeOrder,
    updateUserFavorites
  }
}

export default useFavoriteOrder;
