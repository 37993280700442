import React, {useState, useMemo, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {
  Label,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PageSection from '../../../Layout/PageSection';
import usePermission from '../../../Hooks/usePermission';
import { titleCaseWord } from '../../../utils/string';
import StatusToggler from '../../../Components/Form/StatusToggler';
import useApi from '../../../Hooks/useApi';
import useFavoriteOrder from "../../../Hooks/useFavoriteOrder";
import FaveHeart from "../../../Components/FaveHeart";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import OrderLastSold from '../OrderLastSold';
import CloneOrderModal from "./RulesConfig/CloneOrderModal";
import {Order, StatusType} from "../../../types";
import StatusDot from "../../../Components/Icons/StatusDot";


type OrderInfoBarProps = {
  order:Order
}

/*
 * Bar at top of rules manager shows order info and links
 */
const OrderInfoBar = ({ order }:OrderInfoBarProps) => {
  const [accountOrders, setAccountOrders] = useState<Order[]>([]);
  const navigate = useNavigate();
  const allowEditOrders = usePermission('orders.edit');
  const allowEditOrderStatus = usePermission('order_status.edit');
  const allowStatusToggle = (allowEditOrders || allowEditOrderStatus);
  const allowViewLeads = usePermission('leads.view');
  const allowViewCalls = usePermission('calls.view');
  const allowViewAuctions = usePermission('auctions.view');
  const allowCloneOrders = usePermission('orders_clone.create');
  const allowChangeLog = usePermission('changes.view');
  const {api} = useApi();
  const {favorites, addOrder, removeOrder} = useFavoriteOrder();

  const isFavorite = useMemo(
    () => {
      return favorites ? Boolean(favorites.find((fav:any) => fav.id === order.id)) : false;
    },
    [favorites]
  )

  const [isCloneOpen, setCloneOpen] = useState(false);

  useEffect(()=>{
    getExistingOrders(order.account.id)
  }, [])


  const getExistingOrders = async(account_id: any) => {
    // (this won't get archived orders but that's dandy
    if (account_id) {
      //setLoadingOrders(true);
      const orders = await api.endpoints.orders.list({account_id});
      if (orders) {
        setAccountOrders(orders);
      }
    }

  }

  const toggleClone = () => setCloneOpen(!isCloneOpen);

  const gotoOrder = (order:Order) => {
    navigate(`/accounts/${order.account.id}/order/${order.id}/rules`);
  }

  const handleStatusChange = async (orderId:number, status:StatusType) => {
    if (order) {

      const result = await api.endpoints.orders.update({
        id: orderId, name: order.name, status,
      });

      if (result) {
        notify('Order status updated', 'success');
      } else {
        notify('Unable to update order status', 'error');
      }

      return Boolean(result);
    }
    return false;
  };

  const handleSetFavorite = async (id:number, setAsFavorite:boolean) => {
    if (setAsFavorite) {
      await addOrder(id);
    } else {
      await removeOrder(id);
    }
  }


  return (
    <PageSection
      title={"ORDER"}
      subtitle={<span>
        {order.name} <FaveHeart isFavorite={isFavorite} id={order.id} size="sm" onChange={handleSetFavorite} className="ms-1 mb-1" />
      </span>}
      tools={
        <OrderActionLinks
          order={order}
          allowChangeLog={allowChangeLog}
          allowCloneOrders={allowCloneOrders}
          allowViewLeads={allowViewLeads}
          allowViewAuctions={allowViewAuctions}
          allowViewCalls={allowViewCalls}
          onClone={toggleClone}
          />
      }
      className="order-info-section mb-3"
    >
      <div >



        <div className="d-flex align-items-center">


          <div className="order-info-cell">
            <Label>Account</Label>
            <div className="d-flex no-wrap">
              {order.account.name}
              {accountOrders.length > 1 && (
                <UncontrolledDropdown  >
                  <DropdownToggle className="ms-1 inline" color="link" size="xs" caret>Orders</DropdownToggle>
                  <DropdownMenu >
                    {accountOrders.map((ord, i) =>(
                      <DropdownItem onClick={()=>gotoOrder(ord)} key={i}>
                        <StatusDot status={ord.status}  className="order-status" /> {ord.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </div>

          <div className="order-info-cell">
            <Label>Vertical</Label>
            <div>{order.vertical.display_name}</div>
          </div>

          <div className="order-info-cell">
            <Label>Product</Label>
            <div>{titleCaseWord(order.product)}</div>
          </div>

          <div className="order-info-cell">
            <Label>Status</Label>
            <div>
              <StatusToggler
                status={order.status}
                target={order.id}
                onChangeAsync={handleStatusChange}
                allowEdit={allowStatusToggle}
              />

            </div>
          </div>

          <div className="order-info-cell">
            <Label>Last Sold</Label>
            <div>
              {order.last_sold_at ? (
                <OrderLastSold
                  date={order.last_sold_at}
                  isStale={order.last_sold_alert}
                  status={order.status}
                  orderId={order.id}
                />
              ) : <span>&mdash;</span>}
            </div>
          </div>

        </div>




      </div>

      {allowCloneOrders && <CloneOrderModal
        isOpen={isCloneOpen}
        close={toggleClone}
        order={order}
        accountOrders={accountOrders}
      />}

    </PageSection>
  );
};


export default OrderInfoBar;


type OrderActionLinksProps = {
  order:Order,
  allowChangeLog:boolean,
  allowCloneOrders:boolean,
  allowViewLeads:boolean,
  allowViewAuctions:boolean,
  allowViewCalls:boolean,
  onClone:any,
}

const OrderActionLinks = ({
  order,
  allowChangeLog,
  allowCloneOrders,
  allowViewLeads,
  allowViewAuctions,
  allowViewCalls,
  onClone,
                          }:OrderActionLinksProps) => {

  return (
    <div className="d-flex justify-content-between mb-2">
      {/*<h5 className="p-0 m-0 ">
            <span className="card-title">ORDER: {order.name}</span>
            <FaveHeart isFavorite={isFavorite} id={order.id} size="sm" onChange={handleSetFavorite} className="ms-1 mb-1" />
          </h5>*/}
      {/* Links and actions */}
      <div>

        {allowChangeLog && (
          <span className="action-item">
              <Button
                tag={Link}
                color="link"
                target="_blank"
                to={{
                  pathname: '/changes',
                  search: `accounts=${order.account.id}&orders=${order.id}&page=1&sort_by=occurred_at&sort_dir=desc&limit=20`,
                }}
                title="Go to change log for this order"
                className="inline"
              >
               <FaIcon icon="list" size="sm"  /> Change Log
              </Button>
            </span>
        )}

        {allowCloneOrders && (
          <span className="action-item">
              <Button
                color="link"
                title="Clone Order"
                className="inline ms-2"
                onClick={onClone}
              >
              <FaIcon icon="copy" size="sm" className="me-1" />Clone
              </Button>
            </span>
        )}


        {order.product === 'leads' && allowViewLeads && (
          <span className="action-item">
              <Button
                tag={Link}
                color="link"
                target="_blank"
                to={{
                  pathname: '/reports/leads',
                  search: `accounts=${order.account.id}&orders=${order.id}&sort_by=received_time&sort_dir=desc`,
                }}
                title="Lead prospects for this order"
                className="inline ms-2"
              >
               <FaIcon icon="id-card" size="sm" className="me-1" />Lead prospects
              </Button>
            </span>
        )}

        {order.product === 'calls' && allowViewCalls && (
          <span className="action-item">

              <Button
                tag={Link}
                target="_blank"
                color="link"
                to={{
                  pathname: '/reports/calls',
                  search: `accounts=${order.account.id}&orders=${order.id}&sort_by=received_time&sort_dir=desc`,
                }}
                title="Call prospects for this order"
                className="inline ms-2"
              >
                <FaIcon icon="phone" size="sm" /> Call Prospects
              </Button>
            </span>
        )}

        {allowViewAuctions && (
          <>
                <span className="action-item">
                  <Button
                    tag={Link}
                    target="_blank"
                    color="link"
                    to={{
                      pathname: '/auctions',
                      search: `page=1&order=${order.id}`,
                    }}
                    title="Auctions"
                    className="inline ms-2"
                  >
                    <FaIcon icon="comment-dollar" size="sm" /> Auctions
                  </Button>
                </span>
            <span className="action-item">
                  <Button
                    tag={Link}
                    color="link"
                    target="_blank"
                    to={{
                      pathname: '/routing',
                      search: `account=${order.account.id}&order=${order.id}`,
                    }}
                    title="Routing Map"
                    className="inline ms-2"
                  >
                    <FaIcon icon="project-diagram" size="sm" /> Routing
                  </Button>
                </span>
          </>

        )}
      </div>
    </div>
  )
}
