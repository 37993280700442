import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { tokensToString } from '../../utils/string';
// import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

/*
 * Components for showing a summary display (non interactive) version of
 * rule targets
 */

export const TargetGroupsDescription = ({ groups, attributes, highlightAttribute, listLimit = 10 }) => {
  if (!Array.isArray(groups)) return (null);
  return (
    <>
      { groups.map((group, i) => {
        if (Array.isArray(group)) {
          return (
            <div key={`group-${i}`}>
              {i > 0 && <div className="target-summary-group-or">OR</div>}
              <ul className="fa-ul ms-0">
                {group.map((target, i) => (
                  <li key={i}>
                    <TargetDescription
                      attribute={attributes[target.attribute]}
                      highlight={target.attribute === highlightAttribute}
                      target={target}
                    />
                  </li>
                ))}
              </ul>
            </div>

          );
        } return (null);
      })}
    </>
  );
};

TargetGroupsDescription.propTypes = {
  attributes: PropTypes.object,
  groups: PropTypes.array,
  listLimit: PropTypes.number,
};

/*
 * Component for making a summary description out of a rule target object
 */

export const TargetDescription = ({ attribute, target, highlight, listLimit = 10 }) => {
  if (!attribute) return (<span>No attribute</span>);
  return (
    <div className="d-inline-block target-description">

      <span className={`target-summary-label ${highlight ? 'highlight' : ''} me-1`}>{attribute.label}</span>
      { (target.matches === true || target.matches === undefined) ? (<span className="target-match-bool target-is">is </span>)
        : (<span className="target-match-bool target-is-not">is not </span>)}
      <FormattedTargetValue attribute={attribute} value={target.value} listLimit={listLimit} />
      {attribute.alias !== 'expression' && target.accept_unknown === true && <span className="ms-1 target-summary-unknown"> (accept if unknown)</span> }
    </div>
  );
};

TargetDescription.propTypes = {
  attribute: PropTypes.object,
  target: PropTypes.object,
  listLimit: PropTypes.number,
};

const FormattedTargetValue = ({ value, attribute, listLimit = 10 }) => {
  switch (attribute.input_type) {
    case 'integer':
      return <SingleValueFormat value={value} attribute={attribute} />;
    case 'choice':
    case 'dynamic_choice':
    case 'text':
      value = AttributeChoiceDisplay(attribute, value);
      return <SingleValueFormat value={value} attribute={attribute} />;
    case 'expression':
      return <i><SingleValueFormat value={value} attribute={attribute} /></i>;
    case 'integer_range':
      return <IntegerRangeFormat value={value} />;
    case 'multiple_choice':
    case 'dynamic_multiple_choice':
    case 'state_choice':
      value = AttributeChoiceDisplay(attribute, value);
      return <MultipleChoiceFormat value={value} limit={listLimit} />;
    case 'boolean':
      return <BooleanFormat value={value} />;
    case 'zipcode_list':
    case 'county_list':
    case 'list':
      // For now we are assuming csv input
      return <TokenListFormat value={value} limit={listLimit} />;
    default:
      return (null);
  }
};

// Get the choice values from the attribute so we can display a nice value
const AttributeChoiceDisplay = (attr, values) => {
  if (Array.isArray(values)) {
    values = values.map((val) => (attr.options.choices.hasOwnProperty(val) ? attr.options.choices[val] : val));
  } else { // radio choice, one value
    if (attr.options.choices) {
      values = attr.options.choices[values];
    }
  }

  return values;
};

const BooleanFormat = ({ value }) => (
  <span className="target-summary-boolval">
    {value === true || value === 'true' ? 'Yes' : 'No'}
    {' '}
  </span>
);

const SingleValueFormat = ({ value, attribute }) => {
  const label = attribute.options.hasOwnProperty('label') ? attribute.options.label : '';
  return (
    <span>
      {value}
      {' '}
      {label}
    </span>
  );
};

// Expects array of strings or csv string
// (zip list is provided by API as array but is modified and returned as a comma or new line delimited string)
const TokenListFormat = ({ value, limit }) => {
  value = typeof value === 'string' ? value.replace('\n', ',').split(',') : value;
  const subset = LimitValues(value, limit);
  return (<ListPhrase {...subset} />);
};

const IntegerRangeFormat = ({ value }) => {
  if (value.from && value.to) {
    return value.from === value.to
      ? <span><NumberFormat value={value.from} displayType="text" thousandSeparator /></span>
      : (
        <span>
          <NumberFormat value={value.from} displayType="text" thousandSeparator />
          {' '}
          to
          {' '}
          <NumberFormat value={value.to} displayType="text" thousandSeparator />
        </span>
      );
  }

  if (!value.from && !value.to) {
    return <i>empty</i>;
  }

  if (!value.from) {
    return (
      <span>
        <NumberFormat value={value.to} displayType="text" thousandSeparator />
        {' '}
        or below
      </span>
    );
  }

  if (!value.to) {
    return (
      <span>
        <NumberFormat value={value.from} displayType="text" thousandSeparator />
        {' '}
        or above
      </span>
    );
  }
};

const MultipleChoiceFormat = ({ value, limit }) => {
  if (!Array.isArray(value)) return (<i>invalid value</i>);

  if (value.length) {
    // Sorting, assuming if first is number, all are numbers
    if (isNaN(value[0])) {
      value.sort();
    } else {
      value.sort((a, b) => a - b);
    }
  } else {
    return <i>empty</i>;
  }

  if (!Array.isArray(value)) return (<span>None selected</span>);
  const subset = LimitValues(value, limit);
  return (<ListPhrase {...subset} />);
};

// "This,that,whatever and 5 others"
const ListPhrase = ({ display, hiddenCount }) => (
  <span>
    {tokensToString(display)}
    { hiddenCount > 0 && (
    <span>
      {' '}
      and
      {' '}
      <strong>
        {hiddenCount}
        {' '}
        { hiddenCount > 1 ? 'others' : 'other'}
      </strong>
    </span>
    ) }
  </span>
);

const LimitValues = (values, limit = 5) => {
  if (!Array.isArray(values)) return [values];
  const count = values.length;
  const display = count > limit ? values.slice(0, limit) : values;
  const hiddenCount = count > limit ? count - limit : 0;
  return { display, hiddenCount };
};
