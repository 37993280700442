import React, {FC} from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Order from './index';
import {OrderModalPropertiesType} from "../advertiser";

/*
 * Modal for order edit form
 */
const OrderModal:FC<OrderModalPropertiesType> = ({
  order, account, isOpen, cancel,
}) => {

  if (!order || !account) return null;

  const handleToggle = () => cancel();

  const title = order.id ? 'Edit Order Settings' : 'Create Order';
  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
      <div className="modal-subheader">
        <span>
          {account.name}
          {' '}
          &gt;
          {' '}
          {order.id ? order.name : 'New Order' }
        </span>
      </div>
      <ModalBody>
        <Order
          order={order}
          account={account}
          cancel={cancel}
        />
      </ModalBody>
    </Modal>
  );
};

export default OrderModal;
