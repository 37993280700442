import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import usePermission from '../../../Hooks/usePermission';
import PagedTable from '../../../Components/Table/PagedTable';
import SeedDataCell from '../../../Components/Table/SeedDataCell';
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';
import EntityId from "../../../Components/Table/EntityId";

// Assumes all numbers are +1 with 10 digits
const NumberCell = ({ number }) => (
    <div>
        <span>
            <NumberFormat
                value={number}
                displayType='text'
                format='+# (###) ###-####'
            />
        </span>
    </div>
);

const SourceCell = ({ source }) => (
    <span>
        {source ? (
            <span className='status-toggler'>
                  <FaIcon
                    icon='circle'
                    size='xs'
                    className={classnames([
                      'current-status-icon',
                      source.status,
                      'me-2',
                    ])}
                  />
              <EntityId id={source.id} /> {source.name}
              </span>

        ) : (
            <i>available</i>
        )}
    </span>
);

const ActionCell = ({ number, allowEdit, onEditClick }) =>
    allowEdit ? (
        <div className='list-actions'>
            <Button
                color='link'
                className='action-item inline'
                onClick={() => {
                    onEditClick(number);
                }}
                title='Settings'
            >
                <FaIcon icon='cog' size='1x' />
            </Button>
        </div>
    ) : null;

const Table = (props) => {
    const allowEdit = usePermission('phone_numbers.edit');

    const columns = useMemo(
        () => [
            {
                Header: 'Number',
                className: 'number-cell',
                // id:'number', //used to id sort
                accessor: 'phone_number',
                defaultCanSort: true,
                Cell: (c) => <NumberCell number={c.value} />,
                minWidth: 150,
            },
            {
                Header: 'Source',
                className: 'source-cell',
                accessor: 'source',
                disableSortBy: true,
                Cell: (c) => <SourceCell source={c.value} />,
                minWidth: 250,
            },
            {
                Header: 'Vendor',
                accessor: 'vendor',
                disableSortBy: true,
                minWidth: 100,
              Cell: ({ value }) => (
                <span>
                        {value ? (
                          <span><EntityId id={value.id} />{value.name}</span>
                        ) : (
                          <span>&mdash;</span>
                        )}
                    </span>
              ),
            },
            {
                Header: 'Vertical',
                disableSortBy: true,
                id: 'vertical',
                Cell: ({ row }) => (
                    <span>
                        {row.original.source
                            ? row.original.source.vertical.display_name
                            : null}
                    </span>
                ),
                maxWidth: 180,
            },
            {
                Header: 'IVR Script',
                sortable: false,
                id: 'script',
                Cell: ({ row }) => (
                    <span>
                        {row.original.script ? (
                            row.original.script.name
                        ) : (
                            <span>&mdash;</span>
                        )}
                    </span>
                ),
                minWidth: 180,
            },
            {
                Header: 'Seed Data',
                disableSortBy: true,
                accessor: 'seed_data',
                Cell: (c) => <SeedDataCell seedData={c.value} />,
                minWidth: 100,
            },

            {
                Header: 'Created',
                accessor: 'inserted_at',
                defaultCanSort: true,
                width: 130,
                Cell: ({ value }) => <RelativeTime date={value} />,
            },
            {
                maxWidth: 60,
                sortable: false,
                id: 'actions',
                Cell: (c) => (
                    <ActionCell
                        number={c.row}
                        allowEdit={allowEdit}
                        onEditClick={() => props.openModal(c.row.original)}
                    />
                ),
            },
        ],
        []
    );

    const initialTableState = useMemo(
        () => ({
            sortBy: [
                {
                    id: props.sort_by,
                    desc:
                        typeof props.sort_dir !== 'undefined'
                            ? props.sort_dir === 'desc'
                            : true,
                },
            ],
        }),
        []
    );

    return (
        <PagedTable
            columns={columns}
            initialState={initialTableState}
            className='phone-numbers-table striped'
            {...props}
            noDataMessage='No phone numbers were found'
        />
    );
};

Table.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    totalRows: PropTypes.number,
    pageCount: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number,
    sort_by: PropTypes.string,
    sort_dir: PropTypes.string,
    attributes: PropTypes.object,
    setPage: PropTypes.func,
    setLimit: PropTypes.func,
    setSort: PropTypes.func,
    openModal: PropTypes.func,
    //  onStatusChange:PropTypes.func
};

export default Table;
