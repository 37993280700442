import React, { useContext, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  ModalFooter,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import { Required } from '../../../Components/Form/FormCommon';
import {notify, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import DynamicSingleSelect from '../../../Components/Form/DynamicSingleSelect';
import LoadingBar from '../../../Layout/LoadingBar';
import { titleCaseWord } from '../../../utils/string';
import usePermission from '../../../Hooks/usePermission';
import useFormState from '../../../Hooks/useFormState';
import useApi from '../../../Hooks/useApi';
import { AppContext } from '../../../Providers/AppProvider';
import {OrderPropertiesType} from "../advertiser";
import {Account} from "../../../types";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please enter an order name'),
  product: Yup.string().trim().required('Please select a product'),
  account_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select an account'),
  vertical_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select a vertical'),
});

/*
 * Component for editing settings or creating an order associated with an account
 */
const Order = ({ order = {}, account, cancel }:OrderPropertiesType) => {
  const { api } = useApi();
  const app = useContext(AppContext);
  const [accountList, setAccountList] = useState<Account[]>([]);
  // const[loader, setLoader] = useGlobal('loader');
  const isNew = order.id === 0;
  const allowEdit = usePermission('orders.edit');
  const allowEditStatus = usePermission('order_status.edit');

  // Ad data may be already set from clicking on a link in the list.
  // If not, it we are creating a new ad, or we will load the ad from the API
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      id: 0,
      account_id: account.id,
      vertical_id: isNew ? 0 : order.vertical?.id,
      vertical: {},
      status: 'active',
      name: '',
      product: '',
      transfer_number: '',
      ...order,
    },
    ValidationSchema,
  );

  useEffect(() => {
    loadAccountListOptions()
  }, []);

  const handleSave = () => {
    if (formApi.validate()) {
      save();
    }
  };

  const loadAccountListOptions = async () => {
    const accountList = await api.endpoints.accounts.list();
    setAccountList(accountList);
  };

  const save = async () => {
    app.showLoader('modal', 'Saving Order...');
    let result;

    const order = { ...formValues };

    // format number to +18885552222  (masking should give us +1 already)
    // no longer setting this here
    // if(order.transfer_number){
    //   order.transfer_number = order.transfer_number.replace(/[^\d\+]/g, '')
    // }

    if (order.id) {
      result = await api.endpoints.orders.update(order);
    } else {
      result = await api.endpoints.orders.create(order);
    }

    app.showLoader(false);

    if (!result) {
      notify(`Unable to save order: ${api.error.name}`, 'error');
    } else {
      cancel(true);
    }
  };

  // const {name, product, status, vertical, vertical_id, account_id} = this.state.order;
  if (!allowEdit) return null;

  return (
    <>
      <LoadingBar name="modal" />

      {!formIsValid
            && (
            <StandardAlert color="warning" className="alert-form-validation">
              Please correct the errors below before continuing
            </StandardAlert>
            )}

      <Row>
        <Col sm={12}>
          <FormGroup>
            <Label>
              Name
              {' '}
              <Required />
            </Label>

            <Input
              type="text"
              name="name"
              onChange={formApi.handleOnChange}
              value={formValues.name || ''}
              invalid={!!formErrors.name}
            />
            <FormFeedback>{formErrors.name}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label>
              Account
              {' '}
              { isNew && <Required />}
            </Label>

            {/* New order: Async fetch of account options */}
            {isNew && accountList.length > 0 && (
              <Input
                type="select"
                name="account_id"
                defaultValue={formValues.account_id}
                onChange={formApi.handleOnChange}
                invalid={!!formErrors.account_id}
              >
                <option value="0">Select...</option>
                {
                   accountList.map((acct, index) => (<option value={acct.id} key={index}>{acct.name}</option>))
                 }

              </Input>

            )}

            {/* New Order: Spin until we get Accounts */}
            { isNew && accountList.length === 0 && (
              <div className="inline-loading">
                <Spinner size="sm" />
                {' '}
                Loading...
              </div>
            )}

            {/* Read only text for existing order */}
            { !isNew && account && (
              <>
                <Input type="text" defaultValue={account.name} readOnly />
                <Input type="hidden" name="account_id" defaultValue={account.id} />
              </>
            )}
            <FormFeedback>{formErrors.account_id}</FormFeedback>

          </FormGroup>

          <FormGroup>

            <Label>
              Vertical
              {' '}
              { isNew && <Required />}
            </Label>
            { isNew && (
              <DynamicSingleSelect
                onChange={formApi.handleOnChange}
                defaultValue={formValues.vertical_id}
                name="vertical_id"
                endpoint="verticals"
                invalid={!!formErrors.vertical_id}
                labelProperty="display_name"
                params={{options:true}}
              />
            )}

            {/* Read only text for existing order */}
            { !isNew && formValues.vertical && (
              <>
                <Input type="text" defaultValue={formValues.vertical.display_name} readOnly />
                <Input type="hidden" name="vertical_id" defaultValue={formValues.vertical.id} />
              </>
            )}
            <FormFeedback>{formErrors.vertical_id}</FormFeedback>
          </FormGroup>

          <FormGroup>

            <Label>
              Product
              {' '}
              { isNew && <Required />}
            </Label>
            { isNew && (

              <InputGroup>
                <Input
                  type="select"
                  onChange={formApi.handleOnChange}
                  defaultValue={formValues.product}
                  name="product"
                  invalid={!!formErrors.product}
                >
                  <option value="">Select...</option>
                  <option value="clicks">Clicks</option>
                  <option value="calls">Calls</option>
                  <option value="leads">Leads</option>
                </Input>
              </InputGroup>

            )}

            {/* Read only text for existing order */}
            { !isNew && formValues.product && (
              <>
                <Input type="text" defaultValue={titleCaseWord(formValues.product)} readOnly />
                <Input type="hidden" name="product" defaultValue={formValues.product} />
              </>
            )}
            <FormFeedback>{formErrors.product}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label>
              Status
            </Label>
            {/* special permission where product is c2c (leads) or calls */}
            { (allowEditStatus || formValues.product === 'clicks') ? (
              <InputGroup>
                <Input type="select" onChange={formApi.handleOnChange} value={formValues.status} name="status">
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                  <option value="archived">Archived</option>
                </Input>
              </InputGroup>
            ) : (
              <span>
                :
                {isNew === true ? 'Paused' : titleCaseWord(formValues.status)}
              </span>
            )}
          </FormGroup>

        </Col>

      </Row>

      <ModalFooter>


          <Button
            onClick={()=>cancel(false)}
            color="link"
            size="sm"
          >
            Cancel
          </Button>

          <Button
            onClick={handleSave}
            color="primary"
            size="sm"
          >
            Save
          </Button>

      </ModalFooter>

    </>
  );
};


export default Order;
