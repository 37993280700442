import React, { useState, useEffect } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { endOfDay } from 'date-fns';
import FilterSummary from '../../Components/Table/FilterSummary';
import { StackedFilter, TextSearch } from '../../Components/Table/Filter';
import usePermission from '../../Hooks/usePermission';
import RightDateAndControls from '../../Components/Table/RightDateAndControls';

const StaticChoices = {
  // note: handle true/false as string
  billable: [
    { value: '', label: 'Any' },
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ],
  disposition: [
    { value: 'answered', label: 'Answered' },
    { value: 'completed', label: 'Completed' },
    { value: 'failed', label: 'Failed' },
    { value: 'canceled', label: 'Canceled' },
    { value: 'no-answer', label: 'No Answer' },
    { value: 'busy', label: 'Busy' },
    { value: 'ringing_inbound', label: 'Ringing Inbound' },
    { value: 'ringing_outbound', label: 'Ringing outbound' },
  ],
};

const CallsFilters = ({
  filters, api, userTimezone, reportLoading, onLoadFilters, onChange, onClear, onExecute, product,
}) => {
  const [filterChoices, setFilterChoices] = useState({});
  const allowViewSources = usePermission('sources.view');
  const allowViewAuctions = usePermission('auctions.view');
  // const allowViewVendors = usePermission('vendors.view');

  // load choices on mount
  useEffect(() => {
    const params = filters.accounts ? { account_ids: filters.accounts } : {};
    if (!Object.keys(filterChoices).length) loadChoices(params);
  }, []);

  const loadChoices = async (params = {}) => {
    const result = await api.getFilterOptions(params, 'calls');
    if (result) {
      const { choices, defaults } = result;

      setFilterChoices({ ...StaticChoices, ...choices });

      // set filters with any default choices
      if (defaults && !Array.isArray(defaults)) {
        const defaultFilters = {};
        Object.entries(defaults).map(([prop, val]) => {
          if (prop === 'accounts' && Array.isArray(val)) {
            defaultFilters[prop] = val.map(v=>v.toString())
          } else {
            defaultFilters[prop] = val.toString();
          }


        })
        onChange({...defaultFilters, ...filters})
      }
      onLoadFilters && onLoadFilters();
    }
  };

  const onFilterChange = (filterName, val) => {

    if (filterName === 'accounts') {
      if (val.length) {
        // fetch account order choices
        loadChoices({ account_ids: val });
      } else {
        // remove order choices and filter setting
        setFilterChoices((prev) => {
          prev.orders = [];
          return prev;
        });
        // mergeFilter({'orders': []})
        deleteFilter('orders');
      }
    }

    if (!val || (Array.isArray(val) && !val.length)) {
      deleteFilter(filterName);
    } else {
      mergeFilter({ [filterName]: val });
    }
  };

  const mergeFilter = (toMerge) => {
    onChange({ ...filters, ...toMerge });
  };

  const deleteFilter = (filterName) => {
    const f = { ...filters };
    delete f[filterName];
    onChange(f);
  };

  const onChangeDate = (selection) => {
    onChange({
      ...filters,
      startDate: selection.startDate,
      endDate: endOfDay(selection.endDate),
    });
  };

  const onTimezoneChange = (tz) => {
    mergeFilter({
      timezone: tz,
    });
  };

  const clearSearch = () => onFilterChange('origin_number', '');

  return (
    <div className="d-flex  justify-content-between">
      <div className="flex-fill">
        <div className="d-flex">

          {product === 'calls' && (
            <div className="boxed-filter search-filter me-3">
              <TextSearch
                value={filters.origin_number || ''}
                placeholder="Origin Phone Number (10 digit)"
                onUpdate={(val) => onFilterChange('origin_number', val)}
                onClear={clearSearch}
                delay={200}
              />
            </div>
          )}

          {/* Use of  inNavbar={true} disables popper and allows application of '.dropDown' animation class to menu */}
          <UncontrolledDropdown inNavbar id="report-group-menu" className="ms-3">
            {/* "nav" makes toggle <a> rather than <button> */}
            <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
              <FaIcon icon="filter" size="lg" className="fa-icons" />
              <span className="title d-block">Applied Filters</span>
              <small>
                {/* Expecting object, not array */}
                <FilterSummary choices={filterChoices} filters={filters} filterKeys={['accounts', 'orders', 'verticals', 'vendors', 'sources', 'disposition', 'billable']} />
              </small>

            </DropdownToggle>
            <DropdownMenu className="select-filters-dropdown animate dropDown">

              <div className="modal-body">

                <StackedFilter
                  label="Account"
                  placeholder="Any Account"
                  options={filterChoices.accounts || []}
                  param="accounts"
                  onChange={onFilterChange}
                  value={filters.accounts || []}
                  isMulti
                />

                <StackedFilter
                  label="Order"
                  placeholder={filters?.accounts?.length ? 'Any Order' : 'Select an account'}
                  options={filterChoices.orders || []}
                  param="orders"
                  onChange={onFilterChange}
                  value={filters.orders || []}
                  isMulti
                />

                <StackedFilter
                  placeholder="Any Vendor"
                  label="Vendor"
                  param="vendors"
                  options={filterChoices.vendors || []}
                  value={filters.vendors || null}
                  onChange={onFilterChange}
                  isMulti
                />

                {allowViewSources && allowViewAuctions
                  && (
                  <StackedFilter
                    label="Source"
                    placeholder="Any Source"
                    options={filterChoices.sources || []}
                    param="sources"
                    value={filters.sources || []}
                    onChange={onFilterChange}
                    isMulti
                  />
                  )}

                <StackedFilter
                  placeholder="Any Vertical"
                  label="Vertical"
                  param="verticals"
                  options={filterChoices.verticals || []}
                  value={filters.verticals || null}
                  onChange={onFilterChange}
                  isMulti
                />

                {product === 'calls' && (
                  <>
                    <StackedFilter
                      placeholder="Any Disposition"
                      label="Disposition"
                      param="disposition"
                      options={filterChoices.disposition || []}
                      value={filters.disposition || null}
                      onChange={onFilterChange}
                      isMulti
                    />

                    <StackedFilter
                      placeholder="Any Billable Status"
                      label="Billable"
                      param="billable"
                      options={filterChoices.billable || []}
                      value={filters.billable || null}
                      onChange={onFilterChange}
                    />
                  </>
                )}
              </div>

            </DropdownMenu>
          </UncontrolledDropdown>


        </div>
      </div>

      <RightDateAndControls
        startDate={filters.startDate}
        endDate={filters.endDate}
        onChangeDate={onChangeDate}
        onSelectTimezone={onTimezoneChange}
        timezone={filters.timezone}
        defaultTimezone={userTimezone}
        isLoading={reportLoading}
        onExecute={onExecute}
        executeLabel="View Report"
        onReset={onClear}
        className="reports"
      />

    </div>

  );
};

CallsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  onLoadFilters: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onExecute: PropTypes.func.isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userTimezone: PropTypes.string,
  reportLoading: PropTypes.bool,
  product: PropTypes.string,
};

export default CallsFilters;
