import React from 'react';
import Select from 'react-select';

// this file is adapted from https://github.com/jossmac/react-select-simple-value
// based on discussion: https://github.com/JedWatson/react-select/issues/2669
// it adapts react-select to accept simple value types (scalars or scalar arrays) instead of options objects
function flatten(arr) {
  return arr.reduce((acc, val) => (Array.isArray(val.options)
    ? acc.concat(flatten(val.options))
    : acc.concat(val)
  ), []);
}

function getValue(opts, val, getOptVal, isMulti) {
  if (val === undefined) return undefined;
  if (isMulti && !Array.isArray(val)) {
    val = [val];
  }
  const options = flatten(opts);
  let simpleVal = isMulti
    ? options.filter((o) => val.includes(getOptVal(o)))
    : options.find((o) => getOptVal(o) === val);

  // singleValue won't clear unless value is null
  if (simpleVal === undefined) simpleVal = null;

  return simpleVal;
}

const defaultGetOptionValue = (opt) => opt.value;

function wrapOnChange(onChange, value, isMulti, getOptVal) {
  if (onChange === undefined) return;

  const flatValue = isMulti
    ? value.map(getOptVal)
    : (value
      ? getOptVal(value)
      : null
    );

  onChange(flatValue);
}

function ReactSelectSimpleValue({
  children,
  defaultValue: simpleDefault,
  getOptionValue = defaultGetOptionValue,
  isMulti = false,
  options,
  value: simpleValue,
  onChange: originalOnChange,
}) {
  const value = getValue(options, simpleValue, getOptionValue, isMulti);
  const defaultValue = getValue(options, simpleDefault, getOptionValue, isMulti);
  const onChange = (v) => wrapOnChange(originalOnChange, v, isMulti, getOptionValue);

  return children({
    defaultValue, getOptionValue, isMulti, options, value, onChange,
  });
}

const SimpleSelect = ({ className = '', ...restProps }) => (
  <ReactSelectSimpleValue {...restProps}>
    {(simpleProps) => <Select className={`simple-select ${className}`} {...restProps} {...simpleProps} />}
  </ReactSelectSimpleValue>
);

export default SimpleSelect;
