import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormFeedback, FormText, FormGroup, Input, InputGroup, Label,
} from 'reactstrap';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Required, TimezoneOptions } from '../../../Components/Form/FormCommon';
import useFormState from '../../../Hooks/useFormState';
import {HelpPopper, StandardAlert}  from '@thedmsgroup/mastodon-ui-components';
import DynamicEnhancedSelect from "../../../Components/Form/DynamicEnhancedSelect";
import usePermission from "../../../Hooks/usePermission";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please enter an advertiser name'),
  // tenant_id: Yup.string().trim().required('Please select a tenant'),
  // timezone: Yup.string().trim().required('Please select a time zone')
});

/*
 * Form for basic account fields. Create/update account.
 * Fetch and save methods are in parent Settings component
 */
const AccountDetail = ({ account = {}, onSave }) => {
  const allowEdit = usePermission('accounts.edit');

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      name: account.name,
      description: account.description,
      dba_id: account.dba?.id ? String(account.dba.id) : '',
      buyer_name: account.buyer_name,
      buyer_group: account.buyer_group,
      timezone: account.timezone,
    },
    ValidationSchema,
  );

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      onSave(formValues);
    }
  };

  return (
    <div className="form-section">
      <form>

        {!formIsValid
        && (
        <StandardAlert color="warning" className="alert-form-validation">
          Please correct the errors below before continuing
        </StandardAlert>
        )}

        <FormGroup>
          <Label>
            Name
            {' '}
            <Required />
          </Label>
          <Input
            type="text"
            name="name"
            onChange={formApi.handleOnChange}
            value={formValues.name}
            invalid={!!formErrors.name}
            disabled={!allowEdit}
          />
          <FormFeedback>{formErrors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label>
            Description
          </Label>

          <Input
            type="textarea"
            name="description"
            onChange={formApi.handleOnChange}
            value={formValues.description}
            disabled={!allowEdit}
          />
        </FormGroup>

        <FormGroup>
          <Label>
            Time Zone
            <Required />
          </Label>
          <InputGroup>
            <Input
              type="select"
              onChange={formApi.handleOnChange}
              value={formValues.timezone}
              name="timezone"
              disabled={!allowEdit}
            >
              {TimezoneOptions.map((zone, i) => <option value={zone.value} key={i}>{zone.name}</option>)}
            </Input>

          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Label>DBA</Label>
          <DynamicEnhancedSelect
            onChange={(id) => formApi.handleOnChange('dba_id', id)}
            value={formValues.dba_id}
            name="dba"
            endpoint="dbas"
            params={{limit:1000}}
            labelProperty="name"
            className="w-100"
            isClearable
            isSearchable
          />
        </FormGroup>

        <FormGroup>
          <Label>
            Buyer Name
            {' '}
            <HelpPopper name="buyer-name" title="Buyer Name">
              <p>Target this buyer for auctions. If matches are provided from third party integrations,
                the buyer name will be used to de-duplicate them.</p>
              <StandardAlert icon="alert" color="light" inline>
                Buyer name will be deprecated.<br/>
                Use DBA instead.
              </StandardAlert>
            </HelpPopper>
          </Label>
          <Input
            type="text"
            name="buyer_name"
            onChange={formApi.handleOnChange}
            value={formValues.buyer_name}
            disabled={!allowEdit}
          />
          <FormText>
            <StandardAlert icon="alert" color="light" inline>
              Buyer name will be deprecated. Use DBA.
            </StandardAlert>
          </FormText>
        </FormGroup>

        <FormGroup>
          <Label>
            Buyer Group
            {' '}
            <HelpPopper name="buyer-group" title="Buyer Group">
              Used to prevent duplication of lead purchasing within a group of accounts.
              &nbsp;Accounts that share a buyer group will only buy a single lead once.

            </HelpPopper>
          </Label>
          <Input
            type="text"
            name="buyer_group"
            onChange={formApi.handleOnChange}
            value={formValues.buyer_group}
            disabled={!allowEdit}
          />
        </FormGroup>
      </form>

      {allowEdit && (
        <div className="mt-2 d-flex justify-content-end">
          <Button
            tag={Link}
            to="/accounts"
            color="link"
            size="sm"
            className="me-2"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            size="sm"
          >
            Save
          </Button>
        </div>
      )}

    </div>
  );
};

AccountDetail.propTypes = {
  account: PropTypes.object,
  onSave: PropTypes.func,
};

export default AccountDetail;
