import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import PixelForm from '../../../../Components/Form/PixelForm';
import useUniqueKeyArray from '../../../../Hooks/useUniqueKeyArray';

/*
 * Tracking: a page for entering tracking pixels associated with a rule.
 */
const Tracking = ({
  impressionUrls, clickUrls, onChange, errors,
}) => {

  const {
    items: keyedClickUrls,
    addItem: addClickUrl,
    removeItem: removeClickUrl,
    updateItem: updateClickUrl,
  } = useUniqueKeyArray(clickUrls, (items) => onChange('click_urls', items));

  const {
    items: keyedImpUrls,
    addItem: addImpUrl,
    removeItem: removeImpUrl,
    updateItem: updateImpUrl,
  } = useUniqueKeyArray(impressionUrls, (items) => onChange('impression_urls', items));


  return (
    <>

      {errors.impression_urls
        && (
        <StandardAlert color="warning">
          {errors.impression_urls}
        </StandardAlert>
        )}
      <div className="form-section">
        <div className="form-section-header">Impression Pixels</div>
        <p>Enter URLs or tags for tracking impressions.</p>

        {keyedImpUrls.length === 0
          && (
          <StandardAlert color="light">
            <span>
              No impression pixels are configured.
              <Button color="link" className="inline ms-1" onClick={addImpUrl}>
                <FaIcon icon="plus" size="sm" className="me-1" />
                <span>Add a pixel</span>
              </Button>
&nbsp;
            </span>
          </StandardAlert>
          )}

        <div>

          {keyedImpUrls.map((px, i) => (
            <PixelForm
              pixel={px.value}
              className="impression-pixel"
              handleRemove={() => removeImpUrl(i)}
              handleChange={(val) => updateImpUrl(i, val)}
              key={px.key}
            />
          ))}

          {keyedImpUrls.length > 0 && (
            <div className="text-right">
              <Button color="link" onClick={addImpUrl}>
                <FaIcon icon="plus" />
                {' '}
                <span>Add Another</span>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="form-section">
        <div className="form-section-header">Click Pixels</div>
        <p>Enter URLs or tags for tracking clicks.</p>

        {keyedClickUrls.length === 0
        && (
        <StandardAlert color="light">
          <span>
            No click pixels are configured.
            <Button color="link" className="inline ms-1" onClick={addClickUrl}>
              <FaIcon icon="plus" size="sm" className="me-1" />
              <span>Add a pixel</span>
            </Button>
&nbsp;
          </span>
        </StandardAlert>
        )}

        <div>

          {keyedClickUrls.map((px, i) => (
            <PixelForm
              pixel={px.value}
              className="click-pixel"
              handleRemove={() => removeClickUrl(i)}
              handleChange={(val) => updateClickUrl(i, val)}
              key={px.key}
            />
          ))}

          {clickUrls.length > 0 && (
          <div className="text-right">
            <Button color="link" onClick={addClickUrl}>
              <FaIcon icon="plus" />
              {' '}
              <span>Add Another</span>
            </Button>
          </div>
          )}
        </div>
      </div>

    </>
  );
};

Tracking.propTypes = {
  impressionUrls: PropTypes.array,
  clickUrls: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default Tracking;
