import React, { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import PasswordWithToggle from './PasswordWithToggle';

/*
 * Password input with strength check, and confirm password input.
 * Used in: ActivateUser, ResetPassword, MyPasswordModal
 */
const PasswordEntry = ({
  password, confirmPassword, onChange, errors, options, ...restProps
}) => {

  const { noLabel } = options;

  return (
    <>
      <FormGroup>
        {noLabel !== true && (
        <Label>
          New Password
        </Label>
        )}
        <PasswordWithToggle
          name="password"
          maxLength={60}
          placeholder={noLabel ? 'Password' : ''}
          autoComplete="new-password"
          onChange={onChange}
          value={password}
          invalid={!!errors.password}
          error={errors.password}
          {...restProps}
        />
        <FormFeedback>{errors.password}</FormFeedback>
      </FormGroup>

      <FormGroup>
        {noLabel !== true && (
        <Label>
          Confirm Password
        </Label>
        )}
        <PasswordWithToggle
          name="confirm_password"
          maxLength={60}
          placeholder={noLabel ? 'Confirm Password' : ''}
          onChange={onChange}
          value={confirmPassword}
          invalid={!!errors.confirm_password}
          error={errors.confirm_password}
          {...restProps}
        />
        <FormFeedback>{errors.confirm_password}</FormFeedback>
      </FormGroup>
    </>
  );
};

PasswordEntry.defaultProps = {
  password: '',
  confirmPassword: '',
  options: {},
};

export default PasswordEntry;
