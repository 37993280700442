import React, { useMemo } from 'react';
import classnames from "classnames";
import StatusDot from "../../../Components/Icons/StatusDot";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';


// TODO: use FC like export const MyComponent: React.FC<MyComponentProps> = React.forwardRef(( ...

const ChannelNode = React.forwardRef((
  {
    channel,
    sourceId=0,
    currentHover,
    currentSelection,
    onMouseEnter,
    onMouseLeave,
    onExamine,
    onGetOrders,
    onSelect,
    onOpenRules,
    reconnect
  }:any,
  ref:React.LegacyRef<HTMLDivElement>
) => {

  const handleClick = (e:any) => {
    onSelect(e);
    onGetOrders(channel);
  }

  const isRelated = useMemo(()=>{
    if (currentHover) {
      if (currentHover?.object === "channel") {
        return currentHover.id === channel.id.toString();
      } else if (currentHover.channels) {
        return currentHover.channels.includes(channel.id.toString());
      }
    }

    return false;
  }, [currentHover])


  const isSelected = useMemo(()=>{
    if (currentSelection) {
      if (currentSelection.object === "channel") {
        return currentSelection.id === channel.id.toString();
      } else if (currentSelection.channels ) {
        return currentSelection.channels.includes(channel.id.toString());
      }
    }
    return false;
  }, [currentSelection])

  // stop Propation so clicking buttons does not activate toggle
  const handleExamine = (e: any) => {
    e.stopPropagation();
    onExamine(channel);
  }
  const handleOpenRules = (e: any) => {
    e.stopPropagation();
    onOpenRules(channel);
  }

  return (
    <div
      className={classnames("node-row channel", {related: isRelated, selected: isSelected} )}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-id={channel.id}
      data-object="channel"
      onClick={handleClick}
    >
      <div
        className="node-pod"
      >
        <div className="node-pod-header">
          <div className="node-pod-title">
            <div>
              <StatusDot status={channel.status} className="me-1"/>
              <span className="id me-1" title="Channel ID">{channel.id}</span>
              {channel.name}

            </div>
            {/* TODO don't show button if currently selected channel */}
            <div className="actions">
              <Button color="link" size="sm" onClick={handleExamine} title="Examine channel routes">
                <FaIcon icon="eye" size="sm"/>
              </Button>
              <Button color="link" size="sm" onClick={handleOpenRules} title="View/Edit routing rule">
                <FaIcon icon="clipboard-list" size="sm"/>
              </Button>
            </div>
          </div>

        </div>


      </div>

      <div className="node-pod-footer d-flex">
        <div><label>Match:</label> <RelativeTime date={channel.last_matched_at} uniqueId={`ch-${channel.id}-match`} showTimezone={true}/></div>
        <div><label>Sale:</label> <RelativeTime date={channel.last_sold_at} uniqueId={`ch-${channel.id}-sale`} showTimezone={true}/></div>
      </div>


    </div>
  )
})

export default ChannelNode;
