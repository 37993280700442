import React, { useState, useContext } from 'react';
import { Row, Col, Form, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import PasswordEntry from '../Components/Form/PasswordEntry';
import PasswordRules from './UserManager/User/PasswordRules';
import useFormState from '../Hooks/useFormState';
import { AppContext } from '../Providers/AppProvider';
import DocTitle from '../Layout/DocTitle';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .test('passwordStrength', 'Please strengthen your password', function (pw) {
      return true;
      // return pw ? this.options.context.passwordScore >= 3 : true;
    })
    .min(15, 'Passwords must have 15 or more characters')
    .required('Please enter a new password'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match (please confirm)'),
});

// with length checks:  https://www.reactnativeschool.com/build-and-validate-forms-with-formik-and-yup/checking-field-equality-confirm-password

const ResetPassword = ({ match }) => {
  const app = useContext(AppContext);
  const {resetToken:token} = useParams();
  const initialFormValues = { password: '', confirm_password: '' };
  const { formApi, formValues, formErrors } = useFormState(initialFormValues, validationSchema);
  const [isPosting, setIsPosting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [tokenExpiredError, setTokenExpiredError] = useState(false);
  const [passwordRuleError, setPasswordRuleError] = useState('');

  const submitRequest = async (password) => {
    const result = await app.api.resetPassword(token, password);

    setIsPosting(false);
    if (result) {
      setSubmitSuccess(true);
    } else {
      const err = app.api.error;
      /* If expired
      {
      "errors": {
          "": [
              "Token is expired or already used"
          ]
      }
  }
       */
      if (err && err.form) {
        // The token expiration error is in an array and has blank for a key
        if (Array.isArray(err.form[""]) && err.form[""].length) {
          if (err.form[""][0].indexOf('expired') > 0) {
            setTokenExpiredError(true);
          } else {
            setSubmitError(`Unable to update Password: ${err.form[""][0]}`);
          }
        } else if (err.form.password) {
          setSubmitError('The new password does not meet security requirements.');
          setPasswordRuleError(err.form.password);
        }
      } else {
        setSubmitError(`Unable to updatePassword: ${err.name}`);
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (isPosting) return;
    setSubmitError(false);
    setTokenExpiredError(false);
    setPasswordRuleError(null);
    const isValid = formApi.validate();
    if (isValid) {
      setIsPosting(true);
      submitRequest(evt.target.password.value);
    }
  };

  // const formError = formErrors.password || formErrors.confirm_password;

  // We should not be here
  if (app.isAuthorized) {
    // or this.props.history.goBack() ?
    return <Navigate to="/accounts" replace={true}/>;
  }

  return (
    <>
      <DocTitle pageTitle="Reset Password" />
      {submitSuccess === true && <Navigate to="/login" />}
      <Row>
        <Col sm={12} md={{ size:6, offset:3 }} className="entry-form-panel">
          <h4>Reset Password</h4>

          { !token && (<StandardAlert color="warning">Reset token is not defined</StandardAlert>)}

          { submitError && (
            <StandardAlert color="warning">
              {submitError}
            </StandardAlert>
          )}

          { tokenExpiredError === true && (
            <StandardAlert color="warning">
              We were unable to reset your password. The reset link has expired.
              Please <Link to="/forgot-password">request another reset</Link>.
            </StandardAlert>
          )}

          <div className="d-flex">

            <div className="flex-grow-1">
              <Form onSubmit={handleSubmit}>

                <PasswordEntry
                  password={formValues.password}
                  confirmPassword={formValues.confirm_password}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                  options={{ noLabel: true }}
                  bsSize="lg"
                />

                <Button type="submit" color="primary" block disabled={isPosting} size="sm">
                  { isPosting === true ? (
                    <Spinner color="light" size="sm" />
                  ) : <span>Reset Password</span>}
                </Button>

                <Button size="sm" color="link" tag={Link} to="/login" block className="mt-2 no-case">
                  Return to sign-in
                </Button>


              </Form>
            </div>

            <div className="ms-4">
              <PasswordRules errors={passwordRuleError} />
            </div>
          </div>
        </Col>
      </Row>



    </>

  );
};

export default ResetPassword;
