import BaseAPI from './BaseAPI';

class ReportingAPI extends BaseAPI {
  constructor() {
    super(process.env.API_URL_LOOKER_REPORTING, []);
  }

  resolveAggregateReportFunc = (type) => {
    if (type === 'advertiser') {
      return this.getAdvertiserReport;
    } if (type === 'vendor') {
      return this.getVendorReport;
    }
    return null;
  };

  getFilterOptions = async (params, type = 'reports') => await this.makeRequest(
    'get',
    `${this.url}/options/${type}`,
    params || {},
  );

  getCalls = async (params, responseType = 'json') => await this.makeRequest(
    'get',
    `${this.url}/reports/looker/calls.${responseType}`,
    params || {},
    responseType === 'csv' ? { responseType: 'blob' } : {},
  );

  getLeads = async (params, responseType = 'json') => await this.makeRequest(
    'get',
    `${this.url}/reports/looker/leads.${responseType}`,
    params || {},
    responseType === 'csv' ? { responseType: 'blob' } : {},
  );

  // Get detail view for 'calls' or 'leads'
  getProspectDetail = async (id, type, params={}) => await this.makeRequest(
    'get',
    `${this.url}/reports/looker/${type}/${id}`,
     params
  );

  getAdvertiserReport = async (params, responseType = 'json') => await this.makeRequest(
    'get',
    `${this.url}/reports/looker/advertiser.${responseType}`,
    params || {},
    responseType === 'csv' ? { responseType: 'blob' } : {},
  );

  getVendorReport = async (params, responseType = 'json') => await this.makeRequest(
    'get',
    `${this.url}/reports/looker/vendor.${responseType}`,
    params || {},
    responseType === 'csv' ? { responseType: 'blob' } : {},
  );

}

export default ReportingAPI;
