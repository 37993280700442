import React from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PercentFormatter } from './Table/Table';
import { getDelta } from '../utils/math';

// Calculates and displays percentage change between 2 numbers with up or down arrow.
// Option for alerting of threshold overflow
const ValueDeltaDisplay = ({
  oldValue, newValue, classNames, threshold,
}) => {
  const delta = getDelta(oldValue, newValue);
  const overflow = threshold !== undefined && delta >= threshold;
  const increase = delta > 0;

  return (
    <span className={classnames(['value-delta', classNames, { overflow }])}>
      <FaIcon icon={increase ? 'arrow-up' : 'arrow-down'} size="sm" color={increase ? 'green' : 'red'} className="me-1" />
      {overflow && <FaIcon icon="exclamation-triangle" size="sm" color="red" className="me-1" />}
      <PercentFormatter value={Math.abs(delta)} decimals={1} />
    </span>
  );
};

ValueDeltaDisplay.propTypes = {
  classNames: PropTypes.string,
  threshold: PropTypes.number,
  oldValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  newValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ValueDeltaDisplay.defaultProps = {
  classNames: '',
};

export default ValueDeltaDisplay;
