import React, {useMemo} from 'react';
import Select from '../Form/SimpleSelect';
import {DefaultReactSelectProps} from "./const";
import classnames from 'classnames';
import './EnhancedSelect.scss';


type EnhancedSelectPropsType = {
  onChange:any;
  options:any[];
  value:number|string;
  label?:string;
  className?:string;
  disabled?:boolean;
  isInvalid?:boolean;
  invalidFeedback?:string;
  placeholder?:string|JSX.Element;
  // for react-select (this version not typed)
  controlShouldRenderValue?:boolean;
  hideSelectedOptions?:boolean;
  closeMenuOnSelect?:boolean;
  tabSelectsValue?:boolean;
  backspaceRemovesValue?:boolean;
  isMulti?:boolean;
  isSearchable?:boolean;
  isLoading?:boolean;
  loadingMessage?:string|JSX.Element;
  formatOptionLabel?:any;
}

/*
 * Dropdown input that uses react-select (by way of SimpleSelect)
 * for enhanced features like search and multi-select. It uses bootstrap classes for invalid feedback.
 *
 * SimpleSelect allows us to use scalar values for react-select instead of options objects.
 *
 */
const EnhancedSelect = ({
                          onChange,
                          options = [],
                          value,
                          label,
                          disabled = false,
                          className="",
                          isInvalid,
                          invalidFeedback,
                          placeholder,
                          isLoading,
                          loadingMessage="",
                          ...rest
                        }:EnhancedSelectPropsType) =>{

  const selectProps = useMemo(
    ()=>({ ...DefaultReactSelectProps, ...rest }),
    [isLoading, placeholder, loadingMessage]);

  return (
    <div  className={classnames('enhanced-select', className, {"is-invalid":isInvalid})}>
      <div>
        {label && <label className="form-label">{label}</label>}
        <Select
          classNamePrefix="enhanced-select"
          isClearable
          onChange={onChange}
          options={options}
          value={value}
          tabSelectsValue
          backspaceRemovesValue
          placeholder={placeholder}
          isDisabled={disabled}
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          {...selectProps}
        />
      </div>
      {invalidFeedback && <div className="invalid-feedback">{invalidFeedback}</div>}
    </div>
  );
}

export default EnhancedSelect
