import React from 'react';

const FilterLabelMap = {
  channels: 'CHANNEL',
  verticals: 'VERTICAL',
  sources: 'SOURCE',
  states: 'STATE',
  products: 'PRODUCT',
  devices: 'DEVICE',
  accounts: 'ACCOUNT',
  vendors: 'VENDOR',
  tags: 'TAG',
  currentlyInsured: 'INSURED',
};

const FilterSummary = ({ summaryData }) => {
  // TBD:Limit length of each filter. Limit total length.
  if (!summaryData || Object.keys(summaryData).length === 0) {
    return (<i>No filters applied. Click to add filters.</i>);
  }

  return (
    <span>
      {Object.entries(summaryData).map(([filterName, val], i) => {
        if (Array.isArray(val) && val.length > 0) {
          return (
            <span key={i}>
              <strong>
                {FilterLabelMap[filterName]}
                :
              </strong>
              {val.join(',')}
            </span>
          );
        }
      }).reduce((prev, curr) => [prev, ', ', curr])}
    </span>
  );
};

export default FilterSummary;
