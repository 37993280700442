import BaseAPI from './BaseAPI';

/* Used for Dynamic Integrations
*/
class MatchingAPI extends BaseAPI {
  constructor() {
    super(process.env.API_URL_MATCHING, []);
  }

  runAuction = async (params) => await this.makeRequest('post', `${this.url}/auctions`, params);
}

export default MatchingAPI;
