import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import filesize from 'filesize.js';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FormGroup, Label, Button, Spinner, FormText,
} from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import './dropzone.scss';

const RejectedFiles = ({ files }) => {
  /*
  0:
  errors: Array(1)
    0: {code: "file-invalid-type", message: "File type must be image/jpeg, image/png, image/gif"}

  file: File
      lastModified: 1596478800973
      lastModifiedDate: Mon Aug 03 2020 11:20:00 GMT-0700 (Pacific Daylight Time) {}
      name: "MastodonAdvertiserReport-2020-08-03 (1).csv"
      path: "MastodonAdvertiserReport-2020-08-03 (1).csv"
      size: 730
      type: "text/csv"
      webkitRelativePath: ""
   */
  if (!files.length) return (null);
  const { file } = files[0];
  return (
    <div className="rejected-file d-flex flex-column">
      <div className="text-center">
        <FaIcon icon="exclamation-triangle" size="sm" className="me-1" />
        {files.length > 1 ? (
          <span>Please select only one file</span>
        ) : <span>Please check file type and size:</span>}
      </div>
      {files.length === 1 && (
      <div className="dropped-file text-center">
        {file.name}
        {' '}
        &bull;
        {' '}
        {file.type}
        {' '}
        &bull;
        {' '}
        {filesize(file.size)}
        {' '}
      </div>
      )}
    </div>
  );
};

/*
 * Widget that uses Dropzone component for drag 'n drop file picking
 * Currently configured for single file entry.
 * see: https://react-dropzone.js.org/
 *
 * Note: In some places we might want to reset this component (say, after successful file upload).
 *    Recommended solution is set a key value on the component and update the key to reset.
 */
const FileDropzone = (props) => {
  const [myFiles, setMyFiles] = useState([]);
  const [badFiles, setBadFiles] = useState([]);

  const removeFiles = (e) => {
    e.stopPropagation();
    // setMyFiles([]);
    // setBadFiles([])
    onDropAccepted([]);
  };

  const onDropAccepted = (files) => {
    setMyFiles(files);
    if (props.onDropAccepted) {
      props.onDropAccepted(files);
    }
    setBadFiles([]);
  };
  const onDropRejected = (fileRejections) => {
    setBadFiles(fileRejections);
    setMyFiles([]);
    if (props.onDropRejected) {
      props.onDropRejected(fileRejections);
    }
  };
  /*
    Ad images
      "Upload" button that puts image in account images so you can select it
    Datasheets
      No upload button
   */
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noKeyboard: true,
    noClick: true, // allows having buttons inside dropzone
    maxSize: props.maxSize,
    multiple: false,
    onDropRejected,
    onDropAccepted,
    disabled: props.disabled,
    accept: props.fileTypes,
  });

  const files = myFiles.map((file) => (
    <div className="accepted-file text-center" key={file.name}>
      {file.name}
      {' '}
      &bull;
      {' '}
      {file.type}
      {' '}
      &bull;
      {' '}
      {filesize(file.size)}
      {' '}
    </div>
  ));

  const handleUploadClick = () => {
    if (props.upload && myFiles.length) {
      props.upload(myFiles[0]);
    }
  };

  return (
    <FormGroup className={props.className}>
      {props.label && <Label className="d-block">{props.label}</Label>}
      {props.hint && <FormText>{props.hint}</FormText>}
      <div {...getRootProps({
        className: classnames('d-flex dropzone', {
          'drag-active': isDragActive,
          'drag-accept': isDragAccept,
          'drag-reject': isDragReject,
        }),
      })}
      >
        <input {...getInputProps()} disabled={props.isUploading} />
        <div className="file-icon">
          <div className="file-border">
            <div className="m-auto">
              <FaIcon icon={isDragReject ? 'times' : 'file-alt'} size="2x" />
            </div>
          </div>
        </div>
        <div className="flex-fill d-flex flex-column justify-content-center">

          {isDragReject && (
          <div className="rejected-file">
            <FaIcon icon="exclamation-triangle" size="sm" className="me-1" />
            Files will be rejected
          </div>
          )}
          {!isDragReject && (
            <>
              {props.isUploading ? (
                <div className="uploading d-flex align-items-center">
                  <Spinner color="secondary" className="me-2" />
                  <div className="flex-fill">Uploading...</div>
                </div>
              ) : (
                <>
                  {files.length > 0 && files}
                  {files.length === 0 && badFiles.length === 0 && (
                  <div className="prompt text-center">
                    Drop a file here, or {' '}
                    <Button color="link" className="inline" onClick={open}>select a file</Button>
                  </div>
                  )}
                  {badFiles.length > 0 && <RejectedFiles files={badFiles} />}
                  <div className="d-flex justify-content-center">
                    { (files.length > 0 || badFiles.length > 0) && <Button size="sm" color="link" onClick={removeFiles}>Clear</Button>}
                    {props.upload && files.length > 0 && <Button size="sm" color="primary" onClick={handleUploadClick} className="me-1">Upload</Button>}

                  </div>

                </>
              )}

            </>
          )}

        </div>

      </div>

    </FormGroup>
  );
};

FileDropzone.propTypes = {
  fileTypes: PropTypes.string.isRequired, // comma delimted list of mime types
  maxSize: PropTypes.number.isRequired,
  onDropRejected: PropTypes.func,
  onDropAccepted: PropTypes.func,
  upload: PropTypes.func,
  isUploading: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
};

FileDropzone.defaultProps = {
  disabled: false,
  isUploading: false,
};

export default FileDropzone;
