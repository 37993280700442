import {useEffect, useState} from 'react';
import useApi from "./useApi";

// Translates integration alias to a display name.
// This is useful because some services, like the read service, only provide an alias
// Example usage:
// const {isLoaded: isIntegrationsLoaded, getIntegrationName} = useIntegrationNameLookup();
// if (isIntegrationsLoaded) getIntegrationName('alias_shmalias');
const useIntegrationNameLookup = (params:Record<string, any> = {}) => {
  const [lookup, setLookup] = useState<Record<string, string>>({})
  const [isLoaded, setIsLoaded] = useState(false);
  const apiIntegrations = useApi('integrations', 'list');

  useEffect(() => {

    const loadIntegrations = async() => {
      const result = await apiIntegrations.fetch({ ...params, limit: 1000 } );
      if (result) {
        setLookup(() => {
          const records = {} as Record<string, any>;
          result.data.map((intg:any) => {
            records[intg.name] = intg.label;
          })
          return records;
        })
      }
      setIsLoaded(true);
    }

    if (!Object.keys(lookup).length) {
      loadIntegrations()
    }

  }, [])

  const getIntegrationName = (alias:string) => {
    return lookup[alias] ?? alias;
  }

  return {lookup, isLoaded, getIntegrationName};

};

export default useIntegrationNameLookup;
