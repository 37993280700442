export function titleCaseWord(word) {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : word;
}

export function titleCase(str) {
  return str ? str.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : str;
}

export function tokensToString(arrTokens, delim = ', ') {
  return Array.isArray(arrTokens) ? arrTokens.join(delim) : arrTokens;
}

export function tokenToWords(token) {
  return titleCase(token.replace(/_/g, ' '));
}

const parseNumber = (str) => str ? +str : NaN;

export function idCompare(id1, id2) {
  const num1 = parseNumber(id1);
  const num2 = parseNumber(id2);
  if (isNaN(num1) || isNaN(num2)) {
    return false;
  }
  return num1 === num2;
}

export function singularOrPlural(amount, singular, plural) {
  return amount === 1 ? singular : plural;
}
