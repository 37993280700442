import {Button, Form, FormGroup, Label, Spinner} from "reactstrap";
import React, {useContext, useState} from "react";
import EnhancedSelect from "../../../../Components/Form/EnhancedSelect";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import useFormState from "../../../../Hooks/useFormState";
import * as Yup from "yup";
import {AppContext} from "../../../../Providers/AppProvider";
import {MaskedInputGroup} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";

const FundTransferValidationSchema = Yup.object().shape({
  receiver_account_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0,'Please select an Account to transfer to'),
  amount: Yup.number().min(1).positive().required('Please enter an amount to transfer'),
});

const TransfersInline = ({accountId, accountOptions, close}) => {
  const [isTransferring, setIsTransferring] = useState(false);
  const {
    formApi,formValues, formErrors,
  } = useFormState(
    {
      sender_account_id: accountId,
      receiver_account_id: '',
      amount: '',
    },
    FundTransferValidationSchema,
  );

  const app = useContext(AppContext);
  const Accounting = React.useMemo(() => app.getApi('accounting'), []);

  const transferFunds = async () => {
    setIsTransferring(true);
    const request = {...formValues};

    let result;

    try {
      result = await Accounting.transfer(request);
    } catch(err) {
      setIsTransferring(false);
      if (err.status === 403) {
        notify('You do not have permission to initiate transfers on this account.', 'error')
        return
      }
      if (err.data.error) {
        notify(err.data.error, 'error')
      }
      return
    }
    setIsTransferring(false);

    if (result.error) {
      notify('Failed to transfer funds: ' + result.error, 'error')
      return
    }

    notify(`Transaction successful.`, 'success')
    formApi.clearForm()
    close(true);
  }

  const handleTransferFunds = () => {
    if (formApi.validate()) {
      transferFunds();
    }
  }

  const handleCancel = () => {
    formApi.clearForm();
    close();
  }

  const DefaultMaskOptions = {
    mask: Number,
    unmask: "typed",
    scale:0,
    max:99999,
    signed: false,
    radix:".",
    thousandsSeparator:","
  }

  return (
    <>

        <Form>
            <FormGroup className="mt-2">
              <Label>Transfer Cash</Label>
            </FormGroup>

            <FormGroup>

              <EnhancedSelect
                isClearable
                isSearchable
                onChange={(val) => formApi.handleOnChange('receiver_account_id', val)}
                options={accountOptions}
                value={formValues.receiver_account_id ?? ''}
                tabSelectsValue
                backspaceRemovesValue
                isMulti={false}
                controlShouldRenderValue
                hideSelectedOptions
                closeMenuOnSelect
                placeholder="Receiving Account"
                isInvalid={!!formErrors.receiver_account_id}
                invalidFeedback={formErrors.receiver_account_id}
              />


              <div className="mt-2">
                <MaskedInputGroup
                  className=""
                  prefix="$"
                  placeholder="Transfer Amount"
                  {...DefaultMaskOptions}
                  value={formValues.amount ?? ''}
                  onAccept={(value) => formApi.handleOnChange('amount', value)}
                  invalid={!!formErrors.amount}
                  bsSize="sm"
                />
              </div>
            </FormGroup>

            <Button
              size="sm"
              color="link"
              onClick={handleCancel}
              disabled={isTransferring}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              color="primary"
              onClick={handleTransferFunds}
              className="me-2"
              disabled={isTransferring}
            >
              Transfer Cash{isTransferring && <Spinner size="sm" className="ms-1" /> }
            </Button>
        </Form>

    </>
  );
}

export default TransfersInline;
