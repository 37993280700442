import BaseAPI from './BaseAPI';

/* Used for Dynamic Integrations
*/
class IntegrationsAPI extends BaseAPI {
  constructor() {
    super(process.env.API_URL_INTEGRATIONS, []);
  }
}

export default IntegrationsAPI;
