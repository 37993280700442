import { useState, useMemo, useRef } from "react";
import { parse, stringify } from "query-string";
import type { ParseOptions, StringifyOptions } from 'query-string';
import { useNavigate, useLocation } from "react-router-dom";

const baseParseConfig: ParseOptions = {
  parseNumbers: true, // "foo=1" => {foo:1}
  parseBooleans: true, // "foo=true" => {foo:true}
};

const baseStringifyConfig: StringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
};


type useQueryStateOptionsType = {
  historyAction?: "push" | "replace";
  parseOptions?: ParseOptions;
  stringifyOptions?: StringifyOptions;
  disableQuery?:boolean;
}
export type useQueryStatePropsType = {
  initialState: any;
  options?: useQueryStateOptionsType;

};

const useQueryState = (
  initialState: any = {},
  options: useQueryStateOptionsType = {},
) => {
  const [, setFakeState] = useState({}); //forces re-render
  const initialStateRef = useRef({ ...initialState });
  const { historyAction = 'push', parseOptions, stringifyOptions } = options;
  // options like {arrayFormat: 'bracket'}, see https://github.com/sindresorhus/query-string
  const mergedParseOptions = { ...baseParseConfig, ...parseOptions };
  const mergedStringifyOptions = { ...baseStringifyConfig, ...stringifyOptions };
  const navigate = useNavigate();
  const location = useLocation();


  // The current URL query parsed into an object, updates when URL changes
  // From: https://github.com/alibaba/hooks/blob/master/packages/use-url-state/src/index.ts
  const queryFromUrl = useMemo(() => {
    return parse(location.search, mergedParseOptions);
  }, [location.search]);

  // The query state object, derived from initial state and current URL
  const query = useMemo(

    () => {
      return {
        ...initialStateRef.current,
        ...queryFromUrl,
      }

    },
    [queryFromUrl],
  );

  const setQuery = (params: any, action:string=historyAction) => {
    const newQuery = typeof params === 'function' ? params(query) : params;
    /* If search does not change after setState, update is required to trigger an update.
    For example, if demo1 directly clicks clear, update is required to trigger the update.
     The updates of update and history will be merged and will not cause multiple updates */
    setFakeState({});


    // @ts-ignore
    navigate({
      replace: action === 'replace',
      hash: location.hash,
      search: stringify(newQuery , mergedStringifyOptions) || '?',
    });
  };

  return [ query, setQuery ] as const;
}

export default useQueryState;
