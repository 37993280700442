import React, { FC, useState, useEffect } from 'react';
import {
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import classnames from 'classnames';


type FaveHeartPropertiesType = {
  isFavorite:boolean;
  id: number;
  size: SizeProp;
  onChange?:(id:any, favorite:boolean) => any;
  className?: string;
}

/*
 *
 *
 */
const FaveHeart:FC<FaveHeartPropertiesType> = ({
  isFavorite,
  id,
  size="xs",
  onChange,
  className
                                                       }) => {

  const [localState, setLocalState] = useState<boolean>(isFavorite);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = () => {
    console.log('FaveHeart.tsx: handleChagne');
    if (!isLoading) {
      updateFavorite(!isFavorite);
    }
  };

  const updateFavorite = async (newState:boolean) => {

    if (typeof onChange === 'function') {

      setIsLoading(true);
      setLocalState(newState);
      await onChange(id, newState);
      setIsLoading(false);
      // TODO: could have alert icon if result false
     // if (!updated) {
        setLocalState(newState);
     // }
    }

  };

  useEffect(() => {
    if (localState !== isFavorite) {
      setLocalState(isFavorite);
    }
  }, [isFavorite]);

  return (
    <span key={id} className={classnames(["fav-heart", className])}>

        <>
          {isLoading ? (
            <Spinner size={size} color="danger" type="grow" />
          ) : (
            <span onClick={handleChange} title="Toggle Favorite" style={{cursor:'pointer'}}>
            <FaIcon
              //@ts-ignore
              icon={isFavorite? 'heart' : ['far', 'heart']}
              size={size}
              className={classnames(['favorite-icon',  'me-2'],
                {favorite:isFavorite})}
              style={{color:isFavorite ? 'red' : 'inherit'}}
            />
            </span>
          )}
        </>
    </span>

  );
};

export default FaveHeart;
