import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RulesContext from '../../../../Providers/RulesContext';
import {
  ActivityScheduleSummary,
  AdsSummary,
  BidScheduleSummary,
  BiddingSummary,
  BudgetSummary,
  CallConfigSummary,
  ChannelSummary,
  DestinationSummary,
  EnrichmentSummary,
  SaleConfirmationSummary,
  TagSummary,
  TargetingSummary,
} from "../../../../Components/Rules/Summaries";
import usePermission from '../../../../Hooks/usePermission';


const Summary = ({
  rule, timezone, gotoTab, channels, product, accountAds, isRootRule,
}) => {
  const rulesContext = useContext(RulesContext);
  const allowEditIntegrations = usePermission('integrations.edit');

  if (!rule || !rulesContext.attributes) return (null);

  return (
    <div>
      <div className="rule-summary-section">

        <div className="rule-summary">

          <BiddingSummary
            {...rule.bidding}
            ceiling={rule.bid_ceiling}
            showBidCeiling={allowEditIntegrations}
            proxyBidding={rule.proxy_bidding}
            gotoTab={gotoTab}
          />

          <TargetingSummary
            attributes={rulesContext.attributes}
            targets={rule.match_conditions}
            matchIsRequired={rule.match_is_required}
            isStandalone={rule.is_standalone}
            expression={rule.match_expression}
            gotoTab={gotoTab}
          />


          <ChannelSummary modifiers={rule.channel_modifiers} channels={channels} isRootRule={isRootRule} gotoTab={gotoTab} />
          <BidScheduleSummary schedule={rule.schedule_modifiers} gotoTab={gotoTab} />
          <ActivityScheduleSummary schedule={rule.schedule} timezone={timezone} gotoTab={gotoTab} />

          <BudgetSummary budgets={rule.budgets} caps={rule.caps} schedule={rule.schedule_caps} product={product} gotoTab={gotoTab} />


          {product === 'calls' && (
            <CallConfigSummary
              concurrency={rule.call_concurrency}
              billableDuration={rule.billable_duration}
              gotoTab={gotoTab}
            />
          )}


          {/*<PrefillSummary  Now in destinations
            prefill={rule.prefill}
            gotoTab={gotoTab}
          />*/}
          <EnrichmentSummary
            attributes={rulesContext.attributes}
            enrichments={rule.enrichment_addons}
            gotoTab={gotoTab}
          />
          <SaleConfirmationSummary
            confirmation={rule.sale_confirmation}
            gotoTab={gotoTab}
          />
          <DestinationSummary
            product={product}
            destinationNumber={rule.destination_number}
            destinationSip={rule.destination_sip}
            prefill={rule.prefill}
            redirectUrl={rule.redirect_url}
            postSale={rule.delivery_destinations}
            callStarted={rule.call_started_webhooks}
            callEnded={rule.call_ended_webhooks}
            gotoTab={gotoTab}
          />
          <TagSummary tags={rule.tags} gotoTab={gotoTab} />
          {product === 'clicks' && <AdsSummary ads={rule.ads}  accountAds={accountAds} override={rule.override_parent_ads} gotoTab={gotoTab} />}
        </div>

      </div>

    </div>

  );
};

Summary.propTypes = {
  rule: PropTypes.object,
  gotoTab: PropTypes.func,
  timezone: PropTypes.string,
  channels: PropTypes.array,
  accountAds: PropTypes.array,
  isRootRule: PropTypes.bool,
  product: PropTypes.string,
};

Summary.defaultProps = {

};

export default Summary;
