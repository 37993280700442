import React, { useMemo } from 'react';
import { Button, Label } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {singularOrPlural} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";

/*
 * Static display of an integration configuration, can be switched to edit mode (ConfigEdit component)
 */
const ConfigDisplay = (props) => {


  const definition = useMemo(() => {
    if (props.config.name) {
      return props.definitions.find((conf) => conf.name === props.config.name);
    }
    return null;
  }, [props.config.name, props.definitions]);



  const getSettingValue = (setting) => {
    if (props.config?.settings) {
      return props.config.settings[setting] || '';
    }
    return '';
  };

  const getSettingDisplay = (setting) => {
    if (props.config?.settings) {
      const value = getSettingValue(setting);
      if (Array.isArray(value)) {
        // Assuming keyvalue type here, currently the only array type value possible
        return (
          <div className="key-value-display">
            {value.map( (item, i) => <div className="d-flex" key={i}>
              {item.key}: {item.value}
            </div>)}
          </div>)
      }

      const def =definition.supported_settings ? definition.supported_settings.find((def) => def.name === setting) : null;
      if (def) {
        if (def.input_type === "boolean") {
          return value ? 'Yes' : 'No';
        } else if (def.input_type === "choice") {
          const choice = def.choices.find((c)=>c.value === value);
          return choice ? choice.label : value;
        }
        return value || '';
      }

      return value;
    }
    return '';
  };

  return (
    <div>
      <div className="d-flex header justify-content-between align-items-center">
        <h5 className="">{props.label} {' '}
          {definition && props.allowEditIntegration && <Button
            tag={Link}
            color="link"
            to={{
              pathname: `/tools/integrations/${definition.id}`,
            }}
            title="Go to integration"
            className="inline"
            target="_blank"
          >
            <FaIcon icon="arrow-right" size="1x"  />
          </Button>
          }
        </h5>
        {props.allowControls === true && (
          <div className="actions">

            {props.willDelete === true && (
              <div className="delete-prompt ms-1">
                <i>Remove configuration?</i>
                <Button color="success" size="xs" onClick={() => { props.onDelete(props.index); }} className="ms-2 me-2">yes</Button>
                <Button color="danger" size="xs" onClick={() => { props.setWillDelete(false); }}>no</Button>
              </div>
            )}

            {props.willDelete === false && props.allowEdit && (
              <>
                <Button
                  color="link"
                  className="btn-edit inline me-2"
                  size="sm"
                  onClick={() => props.setEdit(props.index)}
                  title="Edit"
                >
                  <FaIcon icon="edit" size="1x" />
                </Button>

                <Button
                  color="link"
                  className="btn-trash inline"
                  size="sm"
                  onClick={() => props.setWillDelete(props.index)}
                  title="Delete"
                >
                  <FaIcon icon="trash" size="1x" />
                </Button>
              </>
            )}

          </div>
        )}

      </div>

      {definition && (
        <div className="detail-table ">
          <div className="detail-rows">
            {definition.supported_settings?.length > 0 && (
              <>
                {definition.supported_settings.map((setting, i) => (
                  <div className="detail-row d-flex" key={i}>
                    <Label>{setting.label}</Label>
                    <div className="value">
                      {getSettingDisplay(setting.name)}
                    </div>
                  </div>
                ))}
              </>
            )}

            {!definition.supported_settings?.length && (

              <small><i>This service has no settings</i></small>

            )}

          </div>
        </div>
      )}

      {props.config.timeout && (
        <>

          <div className="detail-table ">
            <div className="detail-rows">
              <div className="detail-row d-flex">
                <Label>Custom Timeout</Label>
                <div className="value">
                  {props.config.timeout} {singularOrPlural(props.config.timeout, 'second', 'seconds')}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <>
        <div className="detail-table ">
          <div className="detail-rows">
            <div className="detail-row d-flex">
              <Label>Exclude PII</Label>
              <div className="value">
                {props.config.exclude_pii ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        </div>
      </>

    </div>
  );
};

ConfigDisplay.propTypes = {
  label: PropTypes.string,
  index: PropTypes.number,
  type: PropTypes.string,
  allowControls: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowEditIntegration: PropTypes.bool,
  definitions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired, // definitions for all available configurations
  config: PropTypes.object, // current config setting values
  onDelete: PropTypes.func.isRequired,
  willDelete: PropTypes.bool,
  setWillDelete: PropTypes.func,
  setEdit: PropTypes.func,
};

export default ConfigDisplay;
