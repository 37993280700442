import React, { useEffect, useState } from 'react';
import {
  FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";
/*
 * Modal for display of goal tracking result
 */
const GoalTrackingResultModal = ({ isOpen, close, rowErrors }) => {
  const [rows, setRows] = useState('');

  useEffect(() => {
    if (isOpen && rowErrors) {
      const lines = [];
      Object.entries(rowErrors).map(([rowNum, val]) => {
        lines.push(`${rowNum}\t${val || 'unknown error'}`);
      });
      setRows(lines.join('\n'));
    }
  }, [rowErrors]);

  return (
    <Modal isOpen={isOpen} toggle={close} size="lg" id="goal-tracking-result-modal">
      <ModalHeader toggle={close}>
        Goal Tracking Errors
      </ModalHeader>
      <ModalBody>
        <p>These rows could not be processed, or did not result in a conversion.</p>

        <FormGroup>
          <CopyButton
            id="copy-error0rows"
            textToCopy={rows}
            buttonSize="sm"
          >
            Copy
          </CopyButton>
          <Input type="textarea" rows={12} value={rows} />
        </FormGroup>

      </ModalBody>

      <ModalFooter>
        <div className="d-flex justify-content-end">
          <Button size="sm" onClick={close}>Done</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

GoalTrackingResultModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  rowErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default GoalTrackingResultModal;
