import React, {FC} from 'react';

//Small inline display of ID value, usually shown with the entity name
type EntityIdProps = {
  id:number|string;
  title?:string;
  className?:string;
}

const EntityId:FC<EntityIdProps> = ({ id, title="ID value", className="" }) => (
  id ? <span className={`entity-id ${className}`} title={title}>{id}</span> : null
);

export default EntityId;
