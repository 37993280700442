import React, {
  FC, useCallback, useMemo,
} from 'react';
import PagedTable from '../../../Components/Table/PagedTable';
import OrdersSubtable from './OrdersSubtable';
import usePermission from '../../../Hooks/usePermission';
import AccountNameCell from './AccountNameCell';
import { NoDataComponent } from '../../../Components/Table/Table';

const AccountsTable:FC<any> = ({
  loading, accounts, onStatusChange, onFavorite, openOrderModal, favoriteOrderIds, view='all',
                       totalRows,
                       pageCount,
                       page,
                       sort_by,
                       sort_dir,
                       limit,
                       setPage,
                       setLimit,
                       setSort,
}) => {

  const allowCreateOrder = usePermission('orders.create');
  const allowEditOrder = usePermission('orders.edit');
  const allowEditOrderStatus = usePermission('order_status.edit');
  const allowViewLeads = usePermission('leads.view');
  const allowViewCalls = usePermission('calls.view');
  const allowViewAuctions = usePermission('auctions.view');


  const initialTableState = useMemo(
    () => ({
      sortBy: [{ id: 'name', desc: false }],
    }),
    []
  );

  const columns = useMemo(() => [
    {
      accessor: 'name',
      Header: () => null,
      disableSortBy: true,
      defaultCanSort: false,
      className: 'account-cell',
      Cell: ({ row }:any) => <AccountNameCell account={row.original} />,
    },

  ], [favoriteOrderIds]);


  const renderAccountOrderTable = useCallback((row:any) => (

      <OrdersSubtable
        view={view}
        account={row.original}
        orders={row.original.orders}
        openOrderModal={openOrderModal}
        onStatusChange={onStatusChange}
        onFavorite={onFavorite}
        favoriteOrderIds={favoriteOrderIds}
        allowEditOrder={allowEditOrder}
        allowEditOrderStatus={allowEditOrderStatus}
        allowCreateOrder={allowCreateOrder}
        allowViewLeads={allowViewLeads}
        allowViewCalls={allowViewCalls}
        allowViewAuctions={allowViewAuctions}
      />

    ), [favoriteOrderIds]);

  return (
    <div className="react-table-container mh-400">
      {!loading && accounts.length === 0 && <NoDataComponent>{view === 'favorites' ? 'No favorites!' : 'No accounts were found'}</NoDataComponent>}
      {/* React table has build in fade effect when loading. Hide only when done loading and no results */}
      { (loading || accounts.length > 0)
      && (
      <PagedTable
        columns={columns}
        noHeader={true}
        className="accounts-table"
        initialState={initialTableState}
        data={accounts}
        loading={loading}
        totalRows={totalRows}
        pageCount={pageCount}
        page={page}
        limit={limit}
        sort_by={sort_by}
        sort_dir={sort_dir}
        setPage={setPage}
        setLimit={setLimit}
        setSort={setSort}
        noDataMessage={view === 'favorites' ? "No favorites!" : "No results were found"}
        renderRowSubcomponent={renderAccountOrderTable}
        disableFilters

      />
      )}
    </div>
  );
};



export default AccountsTable;
