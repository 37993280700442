import React from 'react';
import {
  Input,
  FormGroup,
  Label,
  Col, FormFeedback, FormText,
} from 'reactstrap';
import copy from 'fast-copy';
import { useDebouncedCallback } from 'use-debounce';
import KeyValueBuilder from '../../Form/KeyValueBuilder';

const ConfigSettingsForm = (props) => {

  const getNewIntegration = (name, value) => {
    const intg = copy(props.config);
    if (!intg.settings || Array.isArray(intg.settings)) {
      intg.settings = {};
    }
    intg.settings[name] = value;
    return intg;
  };

  const handleChangeSettingDebounced = useDebouncedCallback((name, value) => {
    props.onUpdate(getNewIntegration(name, value), props.index);
  }, 400);

  // Update by form event
  const handleChangeSetting = ({ target: { value, name } }) => {
    props.onUpdate(getNewIntegration(name, value), props.index);
  };

  const handleChangeSettingByName = (name, value) => {
    props.onUpdate(getNewIntegration(name, value), props.index);
  };

  const getSettingValue = (setting) => {
    if (props.config && props.config.settings) {
      return props.config.settings[setting] || '';
    }
    return '';
  };

  return (
    <div className="settings-container">

      {props.definition.supported_settings.map((setting, i) => {

        switch (setting.input_type) {
          case 'text':
            // not debounced
            // value={props.integration ? props.integration.settings[setting.name] : ''}
            // onChange={handleChangeSetting}
            return (
              <TextSetting
                key={i}
                setting={setting}
                value={getSettingValue(setting.name)}
                onChange={(e) => handleChangeSettingDebounced(setting.name, e.target.value)}
                error={props.errors.integration}
              />
            );
          case 'choice':
            return (
              <ChoiceSetting
                key={i}
                setting={setting}
                value={getSettingValue(setting.name)}
                onChange={handleChangeSetting}
                error={props.errors.integration}
              />
            );
          case 'boolean':
            return (
              <BooleanSetting
                key={i}
                setting={setting}
                value={getSettingValue(setting.name)}
                onChange={(e) => handleChangeSettingDebounced(setting.name, e.target.checked)}
              />

            );
          case 'keyvalue':
            // Can't use debounced, but need to be able to pass (name, val) instead of event
            return (
              <FormGroup row key={i}>
                <Label sm={3} for="integration">{setting.label}</Label>
                <Col sm={9}>
                  <KeyValueBuilder
                    label={setting.name}
                    onChange={(val) => handleChangeSettingByName(setting.name, val)}
                    values={getSettingValue(setting.name)}
                  />
                </Col>
              </FormGroup>
            );
          default:
            return (null);
        }
      })}

    </div>
  );
};

const TextSetting = ({
                       setting, value, onChange, error,
                     }) => (
  <FormGroup row>
    <Label sm={3} for={setting.name}>{setting.label}</Label>
    <Col sm={9}>
      <Input
        name={setting.name}
        type="text"
        defaultValue={value || ''}
        onChange={onChange}
        invalid={!!error}
      />
      <FormFeedback>{error}</FormFeedback>
      {setting.help && <FormText>{setting.help}</FormText>}
    </Col>
  </FormGroup>
);

const BooleanSetting = ({
                          setting, value, onChange,
                        }) => (
  <FormGroup row className="align-items-center">
    <Label sm={3} for={setting.name}>{setting.label}</Label>
    <Col sm={9}>
      <Input
        name={setting.name}
        type="checkbox"
        onChange={onChange}
        defaultChecked={Boolean(value)}
        className="me-2"
      />

      {setting.help && <FormText>{setting.help}</FormText>}
    </Col>
  </FormGroup>
);

const ChoiceSetting = ({
                         setting, value, onChange, error,
                       }) =>
  // do we need multichoice?
  (
    <FormGroup row>
      <Label sm={3} for={setting.name}>{setting.label}</Label>
      <Col sm={9}>
        <Input
          type="select"
          name={setting.name}
          value={value || ''}
          onChange={onChange}
          invalid={!!error}
        >
          <option value="">Select...</option>
          {setting.choices.map((c, i) => <option value={c.value} key={i}>{c.label}</option>)}
        </Input>

        <FormFeedback>{error}</FormFeedback>
        {setting.help && <FormText>{setting.help}</FormText>}
      </Col>
    </FormGroup>
  );


export default ConfigSettingsForm;
