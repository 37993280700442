import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextSearch, Filter, ButtonToggleFilter } from '../../../Components/Table/Filter';
import { StatusChoices } from '../../../Components/Table/FilterChoices';
import useApiBatch from '../../../Hooks/useApiBatch';
import RightDateAndControls from '../../../Components/Table/RightDateAndControls';
import {useFilters} from "../../../Hooks/useFilters";

const Filters = ({
  filters, onChange, onRefresh, loading,
}) => {

  const batcher = useApiBatch();
  const [choices, setChoices] = useState({});
  const {onChangeFilter, clearSearch, reset} = useFilters(filters, onChange)
  // load choices
  useEffect(() => {

    const getChoices = async () => {
      const batches = await batcher.fetch([
        // { endpoint: 'vendors', action: 'list' },
        { endpoint: 'verticals', action: 'list' },
        { endpoint: 'scripts', action: 'list' },
      ]);
      if (batches && batches.length) {
        const [verticals, scripts] = batches;
        setChoices({
          status: StatusChoices,
          // product:ProductChoices,
         // vendors: vendors.map((item) => ({ value: item.id, label: item.name })),
          vertical: verticals.map((item) => ({ value: item.id, label: item.display_name })),
          script: scripts.map((item) => ({ value: item.id, label: item.name })),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);


  return (
    <div className="d-flex  justify-content-between position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />

      <div className="phone-number-filters d-flex">

        <div className="boxed-filter search-filter me-3 asdfasf">

          <TextSearch
            value={filters.search || ''}
            placeholder="Search number or source"
            onUpdate={(val) => onChangeFilter('search', val)}
            onClear={clearSearch}
            delay={500}
          />

        </div>

        <div className="min-250" style={{ width: '300px' }}>
          <Filter
            placeholder="Vertical"
            param="vertical"
            options={choices.vertical || []}
            value={filters.vertical || ''}
            onChange={onChangeFilter}
            isMulti={false}
          />
        </div>

        <div className="min-250">
          <Filter
            placeholder="Script"
            param="script"
            options={choices.script || []}
            value={filters.script || ''}
            onChange={onChangeFilter}
            isMulti={false}
          />
        </div>

        <div className="">
          <ButtonToggleFilter
            param="available"
            active={filters.available || false}
            onChange={onChangeFilter}
            labelOn="Available"
            labelOff="All"
          />
        </div>

        {/*  Use dropdown? Not yet sure how many filters we will have.

        <UncontrolledDropdown nav inNavbar={true} id="report-group-menu" className="ms-3" >
          <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
            <FaIcon icon="filter" size="lg" className="fa-icons" />
            <span className="title d-block">Applied Filters</span>
            <small>
              <FilterSummary choices={choices} filters={filters}/>
            </small>
          </DropdownToggle>
          <DropdownMenu  className="select-filters-dropdown animate dropDown">

            <div className="modal-body">
              <StackedFilter
                placeholder="Any Vertical"
                label={"Vertical"}
                param="vertical"
                options={choices.vertical}
                value={filters.vertical}
                onChange={onChangeFilter}
                isMulti={false}
              />

              <StackedFilter
                placeholder="Any product"
                label="Product"
                param="product"
                options={choices.product}
                value={filters.product}
                onChange={onChangeFilter}
                isMulti={true}
              />

              <StackedFilter
                placeholder="Any status"
                label="Status"
                param="status"
                options={choices.status}
                value={filters.status}
                onChange={onChangeFilter}
                isMulti={true}
              />

            </div>

          </DropdownMenu>
        </UncontrolledDropdown>

*/}

      </div>

      <RightDateAndControls
        onChangeDate={null}
        onRefresh={onRefresh}
        onReset={reset}
      />
    </div>

  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};

export default Filters;
