import React from 'react';
import classnames from 'classnames';
import { format } from 'date-fns';
import { VerticalMap, DateFormatter } from '../../Components/Table/Table';
import ReportHeader from './ReportHeader';
import Delta from '../../Components/Icons/Delta';

// Version 2 reports uses 'account_name' instead of 'account' (and other entities)
// Version 2 also uses 'match_tag' instead of 'tag'
const TextColumns = ['account', 'account_name', 'vertical', 'goal_name', 'buyer_name',
  'month', 'ad', 'ad_name', 'channel', 'channel_name', 'source', 'source_name', 'tag', 'match_tag',
  'full_name', 'city', 'order_name', 'channel_name', 'purchased_time', 'state', 'currentlyInsured', 'product', 'device',
  'disposition'];

const SmallColumns = ['cost', 'state', 'billable', 'currentlyInsured', 'product', 'device'];

const DateColumns = ['received_time', 'purchased_time'];

// Determine delta value positive, negative, zero/empty
function getDeltaDir(val) {
  let dir = 0;
  const zeroVals = ['0', '0.00', '0.00%', '$0.00'];
  if (val) {
    if (isNaN(val)) {
      if (!zeroVals.includes(val)) {
        if (val.indexOf('($') === 0 || val.indexOf('-') === 0) {
          dir = -1;
        } else {
          dir = 1;
        }
      }
    } else if (parseFloat(val) !== 0) {
      dir = val > 0 ? 1 : -1;
    }
  }
  return dir;
}

const DeltaCell = ({ value }) => {
  const dir = getDeltaDir(value);
  return (<div className={classnames(['delta-value', 'numeric', { negative: dir < 0, positive: dir > 0 }])}>{dir === 0 ? '-' : value}</div>);
};

const DeltaHeader = ({ value }) => {
  if (value) value = value.replace('Delta', '').trim();
  return (
    <span>
      <Delta width={16} fill="#0000cd" />
      {' '}
      {value}
    </span>
  );
};

function defineColumns(columns, data, totals, pivot, pivotIndex, displayResolver = {}) {
  const columnDefinitions = [];
  if (data.length > 0) {
    const keys = Object.keys(data[0]);
    let isFirstKey = true;

    for (const key of keys) {
      if (pivot && pivotIndex === 0 && key.indexOf('_delta') > 0) {
        // deltas for 1st pivot are all null, don't show columns
        continue;
      }

      const colDef = {
        dataField: key, // accessor
        accessor: pivot ? `${pivot}.${key}` : key,
        id: key,
        text: key, // header
        defaultCanSort: true,
        sortType: 'number',
        align: 'right',
        headerAlign: 'right',
        footerAlign: 'right',
        footer: '',
        minWidth: 130,
        show: true,
        className: '', // for cells
        headerClassName: '',
      };

      if (pivot && isFirstKey) {
        colDef.className = 'first-pivot-col'; // cell class
        colDef.headerClassName = 'first-pivot-col';
      }

      const cellClasses = [key];

      if (isFirstKey) isFirstKey = false;

      if (pivot) {
        // TODO: might use this for auto-scrolling to next/prev pivot
        colDef.id += `-${pivot}`;
      }

      const isDeltaField = key.indexOf('_delta') > 0;

      if (isDeltaField) {
        // columnDefinition.className = 'delta'; //cell class
        cellClasses.push('delta');
        // columnDefinition.Header = (row)=><DeltaHeader value={columns[key].label}/>;
        colDef.headerClassName = 'delta';
      }

      if (columns[key]) {
        if (columns[key].hidden) {
          colDef.show = false;
        }
        if (columns[key].label) {
          colDef.Header = isDeltaField ? <DeltaHeader value={columns[key].label} /> : <ReportHeader label={columns[key].label} col={key} />;
        }
        if (columns[key].sortable === false) {
          colDef.disablySortBy = true;
        }
        if (columns[key].description) {
          // Looker does not send this
          colDef.headerTitle = () => columns[key].description;
        }

        if (SmallColumns.includes(key)) {
          colDef.maxWidth = 130;
          colDef.width = 130;
        } else if (TextColumns.includes(key)) {
          colDef.minWidth = 200;
          colDef.width = 200;
          colDef.sortType = 'alphanumeric';
        }

        // Formatters
        // Looker does most formatting but we adjust some things here
        if (isDeltaField) {
          // added formatting for delta field (version 2 pivots)
          colDef.Cell = (row) => <DeltaCell value={row.value} />;
        } else {
          // if(key === 'duration'){
          //  columnDefinition.Cell = (row)=>DurationFormatter(row.value);
          if (key === 'vertical') {
            colDef.Cell = ({ value }) => VerticalMap[value] ?? value;
            colDef.sortType = 'alphanumeric';
          } else if (key === 'billable' && displayResolver) {
            const Cell = displayResolver(key);
            if (Cell) {
              colDef.Cell = ({ value }) => <Cell value={value} trueTitle="Billable" falseTitle="Not billable" />;
            }
          } else if (DateColumns.includes(key)) {
            // Dates from Looker are already adjusted from UTC to account timezone, no need for timezone adjustment
            colDef.Cell = ({ value }) => <DateFormatter value={value} format="MMM d, yyyy h:mm aaa" />;
            cellClasses.push('date');
            colDef.minWidth = 200;
            colDef.width = 200;
            colDef.sortType = 'datetime';
          }
        }
      }

      if (totals && Object.keys(totals).length && totals[key]) {
        if (isDeltaField) {
          // added formatting for delta field (version 2 pivots)
          colDef.Footer = (row) => <DeltaCell value={totals[key]} />;
        } else {
          colDef.Footer = totals[key];
        }
      }

      cellClasses.push(TextColumns.includes(key) ? 'text' : 'numeric');
      colDef.className = cellClasses.join(' ');
      colDef.headerClassName = colDef.className;
      columnDefinitions.push(colDef);
    }

    if (totals && Object.keys(totals).length) {
      columnDefinitions[0].Footer = 'Total';
      columnDefinitions[0].footerClassName = 'text';
    }

    // add unique row id for react key property
    for (let i = 0; i < data.length; i++) {
      data[i].row_id = i;
    }
  }

  return columnDefinitions;
}

const PivotHeader = ({ pivot, value, index }) => {
  let prefix = '';
  if (pivot.indexOf('week') > 0) {
    prefix = 'Week of: ';
  } else if (pivot.indexOf('month') > 0) {
    prefix = 'Month: ';
    value = <DateFormatter value={value} format="MMMM, y" />;
  }
  // Maybe don't need index, might use for scroll-to feature
  return (
    <div id={`pivot-${index}`}>
      {prefix}
      {value}
    </div>
  );
};

// Parse response for aggregate reports (advertiser/vendor) to produce formatted table column definitions.
// Handle pivoting.
// Column choices derived from the defined columns.
export function extractColumnsAndData(reportResponse) {
  let {
    columns, data, totals, pivot,
  } = reportResponse;

  let columnDefinitions = [];
  const columnChoices = [];

  if (pivot) {
    if (data.length) {
      const pivotCols = Object.keys(data[0]);

      if (pivotCols.length) {
        // Define each column of the pivot (for example, comparing week to week (time_week), each "column" is the data for a week
        // Even though columns are the same for each pivot, the accessor values must be different,
        // so we create definitions for each.
        columnDefinitions = pivotCols.map((pivotCol, i) => {
          const pivotData = data.map((row) => row[pivotCol]);
          const pivotTotals = totals[pivotCol];
          return {
            Header: (props) => <PivotHeader pivot={pivot} value={pivotCol} index={i} />,
            headerClassName: i === 0 || i % 2 === 0 ? 'pivot-header even numeric' : 'pivot-header odd numeric',
            id: `pivot-header-${i}`,
            sortable: false,
            columns: defineColumns(columns, pivotData, pivotTotals, pivotCol, i),
          };
        });

        if (columnDefinitions.length) {
          columnDefinitions[0].columns.map((col) => {
            if (col.dataField.indexOf('delta') === -1) {
              columnChoices.push({ value: col.dataField, label: col.Header.props.label });
            }
          });
        }

        data = data.map((row) => {
          const temp = {};
          Object.entries(row).map(([pCol, pData]) => {
            temp[pCol] = pData;
          });
          return temp;
        });
      }
    }
  } else {
    // No pivot
    columnDefinitions = defineColumns(columns, data, totals, null, null);

    if (columnDefinitions.length) {
      columnDefinitions.map((col) => {
        columnChoices.push({ value: col.dataField, label: col.Header.props.label });
      });
    }
  }

  return {
    data,
    columns: columnDefinitions,
    columnChoices,
  };
}

// Parse respone for Leads and calls reports to produce formatted table column definitions.
// No pivots. ColumnChoices are from hard coded list defined in report component
// (Since the API only gives us the columns requested, not all columns)
export function extractColumnsAndDataForLeads(reportResponse, displayResolver) {
  const { columns, data, totals } = reportResponse;

  const columnDefinitions = defineColumns(columns, data, totals, null, null, displayResolver);
  return {
    data,
    columns: columnDefinitions,
  };
}

// Set column show property of report based on selected viewColumns
// export function setViewColumns( reportColumns, filters) {
//  console.log('api.js:setViewColumns(reportColumns)', reportColumns);
//  //for pivot, object with {columns:[], reportColumns:[], data:[]
//   //non-pivot, is array of columns
//   if(filters.pivot) {
//     if(reportColumns?.length && typeof reportColumns[0].columns !== 'undefined'){
//       reportColumns.map( (pivotCol) => {
//         if(pivotCol.columns){
//           updateColumnShow(pivotCol.columns, filters.view_cols, filters.groupBy)
//         }
//       })
//     }
//   } else {
//     updateColumnShow(reportColumns, filters.view_cols, filters.groupBy)
//   }
//
//   return reportColumns;
// }

// Remove selected view columns that are not in updated column choices
// (columns can change between queries)
export function validateViewColumns(columnFilters, columnChoices) {
  columnFilters = typeof columnFilters === 'string' ? [columnFilters] : columnFilters;
  const choices = columnChoices.map((col) => col.value);
  return columnFilters ? columnFilters.filter((col) => choices.includes(col)) : [];
}

// Look for specific groupBy in selected groupBy
function hasGroupByFilter(target, groupByFilters) {
  if (!target) {
    return false;
  } if (Array.isArray(groupByFilters)) {
    return groupByFilters.includes(target);
  }
  return groupByFilters === target;
}

// Sets Moment from/to date params according to configured user time zone.
// The Datepicker uses browser time zone which could differ from user settings.
// Example:
// Browser (PDT):       from=2020-03-06T00:00:00-08:00
// User settings (MDT): from=2020-03-06T00:00:00-07:00
// Note: this is for the original version of reports (symfony core api), not the new version that uses Looker
// const dateToUserTimezone = (mDate, userTimezone ) => {
//     if(!moment.isMoment(mDate)){
//         mDate = moment(mDate);
//     }
//     return userTimezone ? moment.tz(mDate.format('YYYY-MM-DD HH:mm:ss'), userTimezone) : mDate;
// };

// For version 2 reports, do not provide timezone offsets or adjustments, eg "2020-07-08T23:59:59"
// const formatDateParamMOMENT = (mDate) => {
//   if(!moment.isMoment(mDate)){
//     mDate = moment(mDate);
//   }
//   return mDate.format('YYYY-MM-DDTHH:mm:ss');
//
//   //change to date-fns
//   // Represent 18 September 2019 in ISO 8601 format (UTC):
//     //  const result = formatISO(new Date(2019, 8, 18, 19, 0, 52))
//     //=> '2019-09-18T19:00:52Z'
//
//   // Represent 18 September 2019 in RFC 3339 format:
//   //const result = formatRFC3339(new Date(2019, 8, 18, 19, 0, 52))
// //=> '2019-09-18T19:00:52Z'
// }

const formatDateParam = (d) =>
  // convert to 2021-07-15T00:00:00  (like ISO but no timezone hour diff)
  // TODO: use toISOString ?
  format(d, "yyyy-MM-dd'T'HH:mm:ss");
  // return formatISO(d);

// change to date-fns
// Represent 18 September 2019 in ISO 8601 format (UTC):
//  const result = formatISO(new Date(2019, 8, 18, 19, 0, 52))
//= > '2019-09-18T19:00:52Z'

// Represent 18 September 2019 in RFC 3339 format:
// const result = formatRFC3339(new Date(2019, 8, 18, 19, 0, 52))
//= > '2019-09-18T19:00:52Z'

const FilterToApiMap = {
  accounts: 'account_ids',
  orders: 'order_ids',
  vendors: 'vendor_ids',
  channels: 'channel_ids',
  sources: 'source_ids',
  verticals: 'vertical_ids',
  currentlyInsured: 'currently_insured',
  billable: 'qualified',
  sortBy: 'sort_by',
  sortDir: 'sort_dir',
  groupBy: 'group_by',
  startDate: 'from',
  endDate: 'to',
};

// This function mediates between settings emitted from the filter widget
// and the params expected by the report generator.
export function convertFilterParams(filterSettings, userTimezone = '') {
  const apiParams = {};
  Object.entries(filterSettings).map(([key, val], i) => {
    if (key !== 'view_cols') {
      // View_cols, client-side only for controlling displayed columns in aggregate report
      const apiKey = FilterToApiMap[key] || key;
      apiParams[apiKey] = val;
    }
  });

  if (apiParams.from) {
    apiParams.from = formatDateParam(apiParams.from);
  }
  if (apiParams.to) {
    apiParams.to = formatDateParam(apiParams.to);
  }

  // converted
  // from: "2021-08-18T00:00:00"
  // to: "2021-08-18T23:59:59"

  if (apiParams.fields && Array.isArray(apiParams.fields)) {
    apiParams.fields = apiParams.fields.join(',');
  }

  // Phone search must be +1 10 digit
  if (apiParams.origin_number && !/^\+1/.test(apiParams.origin_number)) {
    // add +1 if not set
    if (apiParams.origin_number.toString().length === 10) {
      apiParams.origin_number = `+1${apiParams.origin_number}`;
    } else if (apiParams.origin_number.toString().length === 11) {
      apiParams.origin_number = `+${apiParams.origin_number}`;
    }
  }

  if (apiParams.pivot) {
    if (apiParams.pivot === 'time_week_v_last') {
      apiParams.pivot = 'time_week';
    } else if (apiParams.pivot === 'time_month_v_last') {
      apiParams.pivot = 'time_month';
    }
  }

  // Default sort was removed in version 2 api to enhance performance.
  // Enforce a default sort for groupBy so report does not return with random order dates, hours, etc
  if (apiParams.pivot) {
    // Pivot times, you want earliest first
    apiParams.sort_by = apiParams.pivot;
    apiParams.sort_dir = 'asc';
  } else if (apiParams.group_by && !apiParams.sort_by) {
    apiParams.sort_by = Array.isArray(apiParams.group_by) ? apiParams.group_by[0] : apiParams.group_by;
    apiParams.sort_dir = apiParams.group_by.indexOf('time') === 0 ? 'desc' : 'asc';
  }

  return apiParams;
}

const prepSortValue = (val) => {
  if (typeof val === 'string') {
    val = val.replace(/[\$%,]/g, '');
    return isNaN(val) ? val.toLowerCase() : parseFloat(val);
  }
  return val;
};

export function defaultSortMethod(a, b, desc) {
  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  // force any string values to lowercase
  a = prepSortValue(a);
  b = prepSortValue(b);
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
}
