import React, { useState, useEffect } from 'react';
import {
  NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';

/*
Shared items for forms
import { Required, TimezoneOptions} from "../../../Form/FormCommon";
 */

export const Required = React.memo(({ text = '(required)' }) => (
  <span className={classnames(['required', 'ms-1', { asterisk: text === '*' }])} aria-label="required" title="required">
    {text}
  </span>
));

export const GetValue = (e) => e.target.value;
export const GetChecked = (e) => e.target.checked;
export const ConvertBoolean = (val) => !(/^(false|0)$/i).test(val) && !!val;

export const TimezoneOptions = [

  {
    value: 'America/New_York',
    name: 'Eastern Standard Time',
  },
  {
    value: 'America/Chicago',
    name: 'Central Standard Time',
  },
  {
    value: 'America/Denver',
    name: 'Mountain Standard Time',
  },
  {
    value: 'America/Phoenix',
    name: 'Mountain Standard Time - No DST',
  },
  {
    value: 'America/Los_Angeles',
    name: 'Pacific Standard Time',
  },
  {
    value: 'America/Anchorage',
    name: 'Alaska Standard Time',
  },
  {
    value: 'Pacific/Honolulu',
    name: 'Hawaii-Aleutian Standard Time',
  },
  {
    value: 'UTC',
    name: 'UTC / GMT',
  },
];

// For tabbed forms, shows error state
export const FormTab = ({
  label, id, activeTab, setActive, isValid,
}) => (
  <NavItem>
    <NavLink
      className={classnames({ active: activeTab === id, error: !isValid })}
      onClick={() => { setActive(id); }}
    >
      {!isValid
                && <FaIcon icon="exclamation-triangle" size="1x" />}
      {label}
    </NavLink>
  </NavItem>
);

FormTab.defaultProps = {
  isValid: true,
};

// Tab style used in most places.
export const FormTabFlat = ({
  label, id, activeTab, setActive, isValid,
}) => {
  const isActive = activeTab === id;
  return (
    <NavItem className={classnames({ active: isActive })}>
      {!isValid && <FaIcon icon="exclamation-circle" size="1x" className="tab-icon tab-icon-error" />}

      <NavLink
        className={classnames({ active: isActive, error: !isValid })}
        onClick={() => { setActive(id); }}
      >
        <div className="tab-label">{label}</div>

      </NavLink>

    </NavItem>
  );
};

FormTabFlat.defaultProps = {
  isValid: true,
};

/*
 * Provides a way to "hide" tabs in a dropdown "..." menu.
 * Consists of one tab which shows the selected content,
 * and another tab with a "..." dropdown menu for selecting a tab.
 *
 * For use in rules manager (advertiser and publisher).
 */

export const FormTabMore = ({
  items, activeTab, setActive, errorTabs,
}) => {
  // set selectedItem to activeTab (if in items), or the first item
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isActive = items.filter((item) => activeTab === item.id).length > 0;
  const isInvalid = selectedItem ? errorTabs.includes(selectedItem.id) : false;
  const anyInvalid = items.some((item) => errorTabs.includes(item.id));

  useEffect(() => {
    // Switch the displayed navItem if activated and in the items collection
    const activeItem = items.find((item) => activeTab === item.id);
    if (activeItem && activeItem.id !== selectedItem.id) {
      setSelectedItem(activeItem);
    }
  }, [activeTab]);

  const handleClick = (item) => {
    setSelectedItem(item);
    setActive(item.id);
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <>

      {/* The currently selected member of "more" items */}
      <NavItem className={classnames({ active: isActive })}>
        {isInvalid && <FaIcon icon="exclamation-circle" size="1x" className="tab-icon tab-icon-error" />}

        <NavLink
          className={classnames({ active: isActive, error: isInvalid })}
          onClick={() => { setActive(selectedItem.id); }}
        >
          <div className="tab-label">{selectedItem.label}</div>

        </NavLink>

      </NavItem>

      {/* The "more" dropdown menu */}
      <NavItem>
        {/* Only show error icon over "more" ellipsis if the errored item is not selected */}
        { anyInvalid && !isInvalid && <FaIcon icon="exclamation-circle" size="1x" className="tab-icon tab-icon-error" />}
        <NavLink onClick={toggle} className={classnames({ error: anyInvalid && !isInvalid })}>
          <div className="tab-more">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
              <DropdownToggle color="link" title="More Settings" className="inline tab-label" caret>
                More
              </DropdownToggle>
              <DropdownMenu end>
                {items.map((item, i) => (
                  <DropdownItem
                      key={i}
                      onClick={() => handleClick(item)}
                    >
                      {item.label}
                    </DropdownItem>
                ))}

              </DropdownMenu>
            </Dropdown>
          </div>

        </NavLink>

      </NavItem>
    </>

  );
};

FormTabMore.defaultProps = {
  errorTabs: [],
  items: [],
};
