import React from 'react';
import {
  Row, Col, FormGroup, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

/*
 * For entering a rule tag
 * Note: we used to allow an array of tags per rule (see the Tagger component).
 * Now it's only one tag per rule, but the API still handles it as an array;
 */
const Tag = (props) => {
  const handleChangeDebounced = useDebouncedCallback((val) => {
    // must be array due to API requirements
    val = val.trim(val);
    props.onChange(val ? [val] : []);
  }, 400);

  return (
    <div className="form-section">
      <div className="form-section-header">Rule Tag</div>
      <p>
        A tag can be used to create special filters and groupings in reports.
        For example: &quot;Mobile Leads&quot;, &quot;Generic Search&quot;, or &quot;Premium Search&quot;
      </p>

      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <Input
              defaultValue={props.tags && props.tags.length > 0 ? props.tags[0] : ''}
              name="tags"
              onChange={(e) => handleChangeDebounced(e.target.value)}
              placeholder="Enter a word or phrase"
              maxLength={50}
            />
          </FormGroup>
        </Col>
      </Row>

    </div>
  );
};

Tag.propTypes = {
  tags: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,

};
export default Tag;
