import { useState, useEffect, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import {AnySchema, ValidationError} from "yup";

/*
 * This type allows errors to be matched to form sections, useful for complicated forms with tabs, etc
 * where error messages need to be placed or tabs activated.
 * Object with form of { sectionName: ['field1', 'field2'] }
 */
type FormSectionToFieldMapType = {
  [key:string]: string[];
}


/*
 * Hook that maintains the state of a form's values,
 * provides change and submit handler, and validation from YUP schema
 * Usage:
 * const{formApi, formIsValid, formValues, formErrors} = useFormState({}, validationSchema);
 *    - optionally provide initial values and validationSchema object
 *    - use handleChange for your form fields, and values for your form values
 *
 * Call validation, optionally provide context data needed for YUP validation
 * const isValid = validate({someExtraData});
 */
const useFormState = (
  initialValues: any = {},
  schema?: AnySchema,
  onChangeCallback?: (...args: any[]) => any,
  formSectionMap?: FormSectionToFieldMapType ,
  contextData?: any,
) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [values, setValues] = useState<any>(initialValues);
  const [startValues, setStartValues] = useState<any>(initialValues);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  // errors: field names and their error messages
  const [errors, setErrors] = useState<any>({});
  // errorSections: some forms have tabbed sections and we want to indicate when tab has errored fields
  // (relies on formSectionMap arg, an object mapping tab ids to arrays of field names)
  const [errorSections, setErrorSections] = useState<any>([]);
  // Yup allows 'context' info to be passed, for when field validation depends on external data
  const [validationContext, setValidationContext] = useState(contextData);

  useEffect(() => {
    const dirty = !isEqual(values, startValues);
    setIsDirty(dirty);
  }, [values]);

  const handleOnSubmit = (event: any) => {
    if (event) event.preventDefault();
    return validate();
  };

  /*
   * Typically we would get an event,  onChange={handleOnChange}
   * But some special purpose form widgets have different ideas,
   * so you can provide the field name and value instead.
   * onChange={()=>handleOnChange('field_name', val)}
   *
   * useCallback to keep reference as it is passed down to subcomponents
   */
  const handleOnChange = useCallback((eventOrName: any, value = null) => {
    let fieldName:string;
    let fieldValue: any;
    if (typeof eventOrName === 'string') {
      // handle as name & value
      // setValues(values => ({ ...values, [eventOrName]: value }));
      fieldName = eventOrName;
      fieldValue = value;
    } else {
      // handle as event
      eventOrName.persist();
      fieldName = eventOrName.target.name;
      // fieldValue = (eventOrName.target.type === 'checkbox') ? !!eventOrName.target.checked : eventOrName.target.value;
      if (eventOrName.target.type === 'checkbox') {
        fieldValue = !!eventOrName.target.checked
      } else if (eventOrName.target.type === 'number') {
        fieldValue = parseInt(eventOrName.target.value)
      } else {
        fieldValue = eventOrName.target.value
      }
      // setValues(values => ({ ...values, [eventOrName.target.name]: eventOrName.target.value }));
    }
    // console.log('useFormState.js: onChange fieldName, fieldValue', fieldName, fieldValue);

    if (fieldName) {
      setValues((values: any) => ({ ...values, [fieldName]: fieldValue }));

      // If field is previously errored just remove the field error. It will be validated later on submit.
      //
      // Note: error keys might not match field name, for example,
      // fieldName could be 'bidding' but errorField from YUP is 'bidding.absolute_bid'.
      // This is because in this case a group of fields are evaluated as an object.
      const currentErrors = {};
      Object.entries(errors).map(([errorField, err]) => {
        if (errorField.split('.')[0] !== fieldName) {
          // @ts-ignore
          currentErrors[errorField] = err;
        }
      });

      setErrors(currentErrors);
      setIsValid(Object.keys(currentErrors).length === 0);

      validateFormSections(Object.keys(currentErrors));
    }

    if (typeof onChangeCallback === 'function' && fieldName) {
      // @ts-ignore
      onChangeCallback(fieldName, fieldValue);
    }
  }, [errors]);

  // Alias for onChange, use for setting a value outside of form change events
  const setValue = (name: string, value: any) =>  handleOnChange(name, value);

  // Set back to original state (or create a new initial value state)
  const clearForm = (newValues?:any) => {
    setErrors({});
    setErrorSections([]);
    if (newValues) {
      setStartValues(newValues);
      setValues(newValues);
    } else {
      setValues(startValues);
    }

    setIsValid(true);
    setIsDirty(false);
  };


  // Useful for setting errors raised outside of Yup schema
  const setError = (field: string, message: string) => {
    setIsValid(false);
    setErrors({ ...errors, [field]: message });
  };

  // validates all form fields
  const validate = () => {
    if (!schema) return true;

    const errors = yupValidate();
    const isValid = Object.keys(errors).length === 0;
    setIsValid(isValid);
    setErrors(errors);
    validateFormSections(Object.keys(errors));

    return isValid;
  };

  // Finds the form sections (if defined) that have errored fields
  const validateFormSections = (errorFields: any) => {
    if (!formSectionMap) return;

    const ids:string[] = [];

    Object.entries(formSectionMap).map(([section, fields]) => {
      if (errorFields.some((err: string) => fields.includes(err))) {
        ids.push(section);
      }
    });

    setErrorSections(ids);
  };

  const isSectionValid = useCallback((section: string) => !errorSections.includes(section), [errorSections]);

  // Do YUP validation
  const yupValidate = () => {
    let errors = {};
    if (schema) {
      try {
        // await schema.validate(values, { abortEarly: false }) //ASYNC: add async to validate_yup if  using ths
        schema.validateSync(values, { abortEarly: false, stripUnknown: true, context: validationContext });
      } catch (err: any) {
        // console.log('useFormValidation.js:validate_yup, catch err.inner', err.inner);
        if (err?.inner) {
          errors = err.inner.reduce((formError:any, innerError: ValidationError) => ({
            ...formError,
            [innerError.path as string]: innerError.message,
          }), {});
        }
      }
    }


    return errors;
  };

  return {
    formApi: {
      handleOnSubmit,
      handleOnChange,
      setValidationContext,
      setValues,
      setValue,
      setError,
      validate,
      clearForm,
      isSectionValid,
    },
    validationContext,
    formIsDirty: isDirty,
    formIsValid: isValid,
    formValues: values,
    formErrors: errors,
    formErrorSections: errorSections,
  };
};

export default useFormState;
