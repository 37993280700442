import React, { useState, useEffect } from 'react';

// Used to prevent useEffect from making change on mount
// const didMount = useDidMount();
// useEffect(() => { if(didMount) ... do stuff...}, []);
const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => setDidMount(true), []);
  return didMount;
};

export default useDidMount;
