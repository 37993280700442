import React, {FC} from 'react'
import "./DataRow.scss";
/*
 * A two column row meant for listing key/value data
 */
type DataRowProps = {
  label:string;
  className?:string;
  children:React.ReactNode;
  compact?:boolean;
}

//TODO: level? sub-row? label width?
//TODO: separate css
// sub-row used only in auction detail Bid and Block
export const DataRow:FC<DataRowProps> = ({
  label,
  className="",
  children,
  compact
}) => {

  return (
    <div className={`mst-data-row ${className} ${compact ? 'compact' : ''}`}>
      <label>{label}</label>
      <div className="value">
        {children}
      </div>
    </div>
  )
}
