import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import JsonViewModal from "../JsonViewModal";

// Cell used in a few tables, has toggling display of seed data JSON
const SeedDataCell = ({ seedData }) => {
  const [showData, setShowData] = useState(false);
  const toggleData = () => setShowData(!showData);
  return seedData && !Array.isArray(seedData) ? (
    <div className="fw-bolder">
      <div className="">
        <Button
          color="link"
          className="inline"
          onClick={toggleData}
        >
          <FaIcon icon="eye" size="sm" />
          {' '}View
        </Button>
      </div>
      <JsonViewModal
        json={seedData}
        title="Seed Data"
        isOpen={showData}
        close={toggleData}
        />
    </div>
  ) : (<span>&mdash;</span>);
};

export default SeedDataCell;
