import React from 'react';
import classnames from 'classnames';

/*
 * Container for react-table subcomponent
 */
const TableSubcomponent = ({ className, children }) => (
  <div className={classnames(['subcomponent-container', className])}>

    <div className="pointer" />

    <div className="subcomponent-contents">
      {children}
    </div>

  </div>
);

export default TableSubcomponent;
