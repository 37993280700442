import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import PageSection from '../../../Layout/PageSection';
import ChannelsTable from './ChannelsTable';
import Channel from '../Channel';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import FilterBar from '../../../Components/Table/FilterBar';
import Filters from './Filters';
import DocTitle from '../../../Layout/DocTitle';
import usePermission from '../../../Hooks/usePermission';
import RoutingRulesModal from '../../Routing/RulesManager/Modal';
import useApi from '../../../Hooks/useApi';
import usePagedTableState from "../../../Hooks/usePagedTableState";
import RoutingMapModal from "../../Routing/Mapper/Modal";


const DefaultFilters = {
};

const DefaultControls = {
  limit: 50,
  sort_by: 'name',
  sort_dir: 'asc',
};

const ListHeaderTools = ({ allowCreate, buttonClick }) => {
  if (!allowCreate) return (null);
  return (
    <Button
      onClick={buttonClick}
      color="primary"
      size="sm"
    >
      New Channel
    </Button>
  );
};

const ChannelList = () => {
  const {
    filters, setFilters, controls, updateQuery,
  } = usePagedTableState(
    DefaultFilters,
    DefaultControls,
    { useQueryString: true, useTableControls: true },
  );
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [allSources, setAllSources] = useState([]);
  const [channelModalOpen, setChannelModalOpen] = useState(false);
  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [routingMapId, setRoutingMapId] = useState(null);
  const allowView = usePermission('channels.view');
  const allowCreate = usePermission('channels.create');
  // const allowEdit = usePermission('channels.edit');
  const { api } = useApi();

  if (!allowView) return (null);

  useEffect(() => {
    if (!loading) {
      load();
    }
    if (!allSources.length) {
      loadSources(); // for picker
    }
  }, [filters, controls.sort_by, controls.sort_dir, controls.page, controls.limit]);

  const handleCreateNewChannel = () => openChannelModal(null);

  const openRoutingModal = (channelId) => setRoutingMapId(channelId);
  const closeRoutingModal = () => setRoutingMapId(null);

  const openChannelModal = (channel) => {
    if (!channel) {
      channel = {
        id: 0,
        name: '',
        description: '',
        product: '',
        vertical: { id: 0, name: '' },
        account: { id: 0 },
        status: 'active',
      };
    }

    setSelectedChannel(channel);
    setChannelModalOpen(true);
  };

  const closeChannelModal = (refresh = false) => {
    setSelectedChannel(null);
    setChannelModalOpen(false);
    if (refresh === true) {
      load();
      // setChangeLogKey(changeLogKey + 1);
    }
  };

  const openRulesModal = (channel) => {
    setSelectedChannel(channel);
    setRulesModalOpen(true);
  };

  const closeRulesModal = () => setRulesModalOpen(false);


  const load = async (refresh = false) => {
    if (!refresh) {
      setLoading(true);
    }

    updateQuery();
    const {
      page, sort_by, sort_dir, limit,
    } = controls;
    const result = await api.endpoints.channels.list({
      ...filters,
      page,
      sort_by,
      sort_dir,
      limit,
    });

    if (result) {
      const { limit, total, data } = result; // row:0, page:1, limit:20, data:[]
      setChannels(data);
      setPageCount(total > limit ? Math.ceil(total / limit) : 1);
      setTotalRows(total);
    } else {
      notify(`Unable to load channels: ${api.error.name}`, 'error');
    }


    if (!refresh) {
      setLoading(false);
    }
  };

  const loadSources = async () => {
    const allSources = await api.endpoints.sources.list({ options: true });
    if (allSources) {
      setAllSources(allSources);
    }
  };

  const handleStatusChange = async (channelId, status) => {
    const result = await api.endpoints.channels.update({ id: channelId, status });
    return result;
  };

  /*  Currently API does not expose status
   handleStatusChange = async(channel, status) => {
      if(channel && this.hasPermission('channels.edit')){
        channel.vertical_id = channel.vertical.id;
        let result = await this.global.api.endpoints.channels.update({
          ...channel, status
        });

        if(result) this.load(true);
        return result ? true : false;
      }
      return false;
    }
  */

  const modalTitle = selectedChannel && selectedChannel.id === 0 ? 'Create' : 'Edit';

  return (

    <>
      <DocTitle pageTitle="Channels" />

        <FilterBar>
          <Filters filters={filters} loading={loading} onChange={setFilters}  onRefresh={() => load(true)} />
        </FilterBar>

        <PageSection
          title="Channels"
          className="overflow-section"
          tools={<ListHeaderTools allowCreate={allowCreate} buttonClick={handleCreateNewChannel} />}
        >


          <ChannelsTable
            loading={loading}
            data={channels}
            pageCount={pageCount}
            totalRows={totalRows}
            {...controls}
            openChannelModal={openChannelModal}
            openRulesModal={openRulesModal}
            openRoutingModal={openRoutingModal}
            onStatusChange={handleStatusChange}
            refreshChannels={()=>load(true)}
            allSources={allSources}
          />

          {selectedChannel && (
            <>
              <RoutingRulesModal
                entityType="channel"
                entity={selectedChannel}
                isOpen={rulesModalOpen}
                close={closeRulesModal}
              />

              <Modal isOpen={channelModalOpen} toggle={closeChannelModal} size="lg">
                <ModalHeader toggle={closeChannelModal}>
                  {modalTitle}
                  {' '}
                  Channel
                  {selectedChannel.name && (
                  <>
                    {' '}|{' '}
                    <span className="modal-subheader">{selectedChannel.name}</span>
                  </>
                  )}
                </ModalHeader>
                <ModalBody>
                  <Channel
                    channel={selectedChannel}
                    closeModal={closeChannelModal}
                  />
                </ModalBody>
              </Modal>
            </>

          )}

          <RoutingMapModal
            isOpen={Boolean(routingMapId)}
            toggle={closeRoutingModal}
            channelId={routingMapId}
          />

        </PageSection>

    </>
  );
};

export default ChannelList;
