import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import confirm from 'reactstrap-confirm';
import {useNavigate, useLocation} from "react-router-dom";
import PageSection from "../../../Layout/PageSection";
import RulesContext from '../../../Providers/RulesContext';
import { TreeProvider } from '../../../Components/Rules/TreeProvider';
import qs from 'query-string';
import TreeRule from './TreeRule';
import TreeBranch from '../../../Components/Rules/TreeBranch';
import RulesConfig from './RulesConfig';
import useRuleTree from '../../../Hooks/useRuleTree';
import usePermission from '../../../Hooks/usePermission';
import './styles.scss';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import LoaderDots from "../../../Layout/LoaderDots";
import { AppContext } from '../../../Providers/AppProvider';

const NEW_RULE = {
  id: 0,
  parent_id: 0,
  isNew: true,
  label: 'New Rule',
  disabled: false,
  bidding: {
    type: 'pass',
    absolute_bid: '',
    bid_modifier: '',
  },
  account_bid_floor: '',
  sale_caps: {},
  match_caps: {},
  ping_dedupe: [],
  match_conditions: [[]],
  vendor_bid: '',
  vendor_margin: '',
  billable_call_duration: '',
  rules: [],
  schedule: [],
  schedule_type: 'inactive',
  internal_data: '',
};

const RulesManager = ({
                        entityRuleId,
                        entityType,
                        verticalId,
                        product,
                        isModal,
                        onRefresh,
}) => {
  const app = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const qsData = qs.parse(location.search);
  const gotoRuleId = isModal ? null : qsData.rule;
  const allowEdit = usePermission('routing.edit');
  const allowCreate = usePermission('routing.create');
  const {
    tree,
    refreshKey,
    selectedRule,
    setTree,
    getRule,
    deleteRule,
    addTempRule,
    addNewRule,
    swapRule,
    selectRule,
    updateRuleTree,
  } = useRuleTree(null, null, NEW_RULE);

  const [attributes, setAttributes] = useState();
  const [integrations, setIntegrations] = useState();
  const [actionsOpen, setActionsOpen] = useState(false);
  const [attributeGroups, setAttributeGroups] = useState();
  const [activeTab, setActiveTab] = useState(qsData.tab || 'summary');
  const [isDirty, setIsDirty] = useState(false);
 // const [ready, setReady] = useState(false);

  // get data when entityRuleId has been resolved
  useEffect(() => {
    if (entityRuleId) {
        load();
    }

  }, [entityRuleId]);

  useEffect(() => {
    if (tree && !selectedRule ) {
      if (gotoRuleId) {
        const rule = getRule(parseInt(gotoRuleId));
        selectRule(rule || tree);
      } else {
        // no goto Rule, select top of tree
        selectRule(tree);
      }
    }
  }, [tree]);

  //Change URL on change rule, tab
  useEffect(() => {
    if (selectedRule && !isModal) {
      updateQueryString();
    }
  }, [selectedRule, activeTab]);

  // Close actions panel on switch rule
  useEffect(() => {
    setActionsOpen(false);
  }, [selectedRule?.id]);

  const load = async() => {
    await loadRulesTree();
    await loadAttributes();
    await loadIntegrations();
  }

  const loadRulesTree = async () => {
    // some entities might not have a root rule yet
    let rootRule;
    if (entityRuleId) {
      rootRule = await app.api.endpoints.routingRules.show(entityRuleId);
    }

    setTree(rootRule);
  };

  const loadAttributes = async () => {
    const attrByGroup = await app.api.endpoints.attributes.list({
      options: true, vertical_id: verticalId, product, scope: 'routing',
    });
    if (attrByGroup) {
      // Ungroup the attributeGroup data and store in attributes
      const attr = {};
      Object.entries(attrByGroup).map((group) => {
        const groupName = group[0];
        return group[1].map((a) => attr[a.alias] = { ...a, groupName });
      });
      setAttributes(attr);
      setAttributeGroups(attrByGroup);
    }
  };

  const toggleActionsPane = () => setActionsOpen(!actionsOpen);

  // called from useEffect after selectRule or tab
  const updateQueryString = () => {
    const qry = qs.parse(location.search);
    // console.log('index.js: updateQueryString', qs.stringify({...qry, tab:this.state.selectedTab, rule:this.state.selectedRule.id}) );
    navigate({replace:true, search: qs.stringify({ ...qry, tab: activeTab, rule: selectedRule.id }) });
  };

  // Tabs can be selected from inside RulesConfig (where the tabs are)
  // or from the rules tree (by clicking on icons).
  // In the tree you can effectively select a tab and a rule at the same time,
  // which is why we have the rule argument.
  //
  // Use a callback because it is passed to all tabs (keeps single reference to function)
  const handleSetActiveTab = useCallback((tab, rule) => {
    setActiveTab(tab);
    if (rule && rule.id !== selectedRule?.id) {
      selectRule(rule);
    }
  }, []);

  // Create a temporary rule to work on
  const addRule = (targetRule, placement) => addTempRule(targetRule, placement, { ...NEW_RULE });

  const disableRule = async (rule, isDisabled) => {
    const targetRule = { id: rule.id, label: rule.label, disabled: !!isDisabled };

    const result = await app.api.endpoints.routingRules.update(targetRule);

    if (result) {
      notify(`The rule has been ${isDisabled ? 'disabled' : 'enabled'}`, 'success');
      loadRulesTree(result);
      selectRule(result, null, true);
    }
  };

  const copyRule = async (rule, label = '') => {
    const copy = {
      parent_id: rule.parent_id,
      label: label || `${rule.label} (Copy)`,
      copy_from_id: rule.id,
      // Api sets priority one higher than source rule
    };
    app.showLoader(isModal ? 'modal' : 'main', 'Copying Rule...');

    const result = await app.api.endpoints.routingRules.create(copy);

    app.showLoader(false);

    if (!result) {
      notify(`Unable to copy rule: ${app.api.error.name}`, 'error');
    } else {
      notify('The rule has been copied', 'success');
      addNewRule(result);
    }
  };

  const moveRule = async (rule, targetRule, placement = 'below') => {
    app.showLoader(isModal ? 'modal' : 'main', 'Moving Rule...');

    let maxPriority = 0;
    const parentRule = getRule(rule.parent_id);
    if (parentRule && parentRule.rules.length) {
      parentRule.rules.map((r) => {
        if (r.priority > maxPriority) maxPriority = r.priority;
      });
    }

    const moveRule = { id: rule.id, label: rule.label };

    if (placement === 'below') {
      moveRule.priority = targetRule.priority + 1;
      moveRule.parent_id = targetRule.parent_id;
    } else if (placement === 'above') {
      if (maxPriority && targetRule.priority === maxPriority) {
        moveRule.priority = maxPriority - 1;
      } else {
        moveRule.priority = targetRule.priority;
      }
      moveRule.parent_id = targetRule.parent_id;
    } else if (placement === 'child') {
      moveRule.priority = 0;
      moveRule.parent_id = targetRule.id;
    }
    // console.log('index.js:MoveRule (target, moveRule):', targetRule, moveRule);
    const result = await app.api.endpoints.routingRules.update(moveRule);

    app.showLoader(false);

    if (result) {
      loadRulesTree();
      selectRule(result, true);
    }
  };

  const handleDeleteRule = async (rule) => {
    app.showLoader(isModal ? 'modal' : 'main', 'Deleting Rule...');

    const result = await app.api.endpoints.routingRules.delete(rule.id);
    app.showLoader(false);

    if (!result) {
      notify(`Unable to delete rule: ${app.api.error.name}`, 'error');
    } else {
      notify('The rule has been deleted', 'success');

      // Remove from tree
      deleteRule(rule);
    }
  };

  // Used for a few targeted async updates where we just want to update a specific prop
  // Bid value edited in the tree, or edit rule name.
  // NOTE: when we do bid edits in rule tree, see updateRule in advertiser rules
  const updateRule = async (data) => {
    // just the data and required fields
    const { id, parent_id, label } = selectedRule;
    const updatedRule = {
      id, parent_id, label, ...data,
    };
    const result = await app.api.endpoints.routingRules.update(updatedRule);

    if (result) {
      updateRuleTree(result);
      onRefresh && onRefresh();
    } else {
      notify(`Unable to update rule: ${app.api.error.name}`, 'error');
    }
    return result;
  };

  const saveRule = async (saveRule, isNew) => {
    app.showLoader(isModal ? 'modal' : 'main', 'Saving Rule...');
    let result;
    if (isNew) {
      // delete rule.isNew;
      result = await app.api.endpoints.routingRules.create(saveRule);
      if (result) {
        swapRule(result, saveRule);
      }
    } else {
      result = await app.api.endpoints.routingRules.update(saveRule);
      if (result) {
        updateRuleTree(result);
        selectRule(result, true);
      }
    }

    app.showLoader(false);

    if (!result) {
      notify(`Unable to save rule: ${app.api.error.name}`, 'error');
    } else {
      onRefresh && onRefresh();
      selectRule(result, true);
      notify('The rule has been saved', 'success');
    }
  };

  const handleSelectRule = async (rule, refresh) => {
    if (rule && rule.id !== selectedRule?.id) {
      if (isDirty) {
        // confirm leaving a dirty rule
        const stay = await dirtyRuleConfirm();
        if (stay) return;
      } else if (selectedRule?.isNew) {
        // confirm leaving a new unsaved rule
        const stay = await leaveNewRuleConfirm();
        if (stay) return;
        // discard the new rule and go to selected
        deleteRule(selectedRule, rule);
        return;
      }
    }

    selectRule(rule, refresh);
  };

  const dirtyRuleConfirm = async () => await confirm({
    title: 'Unsaved Rule',
    message: 'The current rule has unsaved changes',
    confirmText: 'Continue editing',
    cancelText: 'Discard changes',
  });

  const leaveNewRuleConfirm = async () => await confirm({
    title: 'Unsaved New Rule',
    message: 'Please save or cancel the current new rule',
    confirmText: 'Continue editing',
    cancelText: 'Discard new rule',
  });

  const loadIntegrations = async (type) => {
    const result = await app.api.endpoints.integrations.list({product, vertical_id: verticalId, type: type, limit:1000})

    if (result?.data) {
       // this is BC handling during api transition // todo remove after transition
        // Filter out integrations with null versions, won't save
        result.data = result.data.filter(int => Boolean(int.current_version));
        // Group by type
        result.data = result.data.reduce((acc, intg) => {
          acc[intg.type] = (acc[intg.type] || []).concat(intg);
          return acc;
        }, {});
        setIntegrations(result.data);

    } else {
      notify(`Unable to load integrations: ${app.api.error.name}`, 'error');
    }
  }

  const getIntegrationDefinitionsByType = (type) => {
    if (integrations) {
      return integrations[type] || [];
    }
    return [];
  };

  // An API that we can pass through context instead of
  // passing all these methods via prop
  const rulesAPI = {
    selectedRule,
    setTree,
    getRule,
    deleteRule: handleDeleteRule,
    addTempRule,
    addRule,
    updateRule,
    saveRule,
    copyRule,
    moveRule,
    setSelected: handleSelectRule,
    setActiveTab: handleSetActiveTab,
    setIsDirty,
    refreshRules: loadRulesTree, // used by bulk add
    toggleActionsPane,
    disableRule,
    getIntegrationDefinitionsByType,
  };

  const ready = attributes && integrations && tree && selectedRule;

  return (
    <div className="rules-manager-container d-flex position-relative">
      <LoaderDots active={!ready} width={200} />
      <RulesContext.Provider value={{
        tree,
        selectedRule,
        attributes,
        attributeGroups,
        allowCreate,
        allowEdit,
        entityType,
        api: rulesAPI,
      }}
      >

        {ready && (
          <>
            <ConditionalPageSection isPage={!isModal} title="Rule Tree" className="me-3">
              <div className="col-rule-tree thin-scrollbar">

                <div className="tree flex-fill">
                  <TreeProvider rules={tree} product={product} ruleType="routing">
                    <TreeBranch
                      rule={tree}
                      selectedRule={selectedRule}
                      ancestors={[]}
                      expandToLevel={10}
                      ruleComponent={TreeRule}
                      level={0}
                    />
                  </TreeProvider>
                </div>
              </div>
            </ConditionalPageSection>
            <ConditionalPageSection isPage={!isModal} className="flex-fill">
              <div className="col-rule-config flex-fill">
                {selectedRule && (
                  <RulesConfig
                    rule={selectedRule}
                    isRoot={selectedRule ? selectedRule.id === tree.id : false}
                    isDirty={false}
                    key={selectedRule.id + refreshKey}
                    product={product}
                    timezone={app.user.timezone}
                    actionsOpen={actionsOpen}
                    activeTab={activeTab}
                    onSelectTab={handleSetActiveTab}
                  />
                ) }
              </div>
            </ConditionalPageSection>
          </>

        )}

      </RulesContext.Provider>
    </div>
  );
};

RulesManager.propTypes = {
  entityRuleId: PropTypes.number,
  entityType: PropTypes.string,
  verticalId: PropTypes.number,
  product: PropTypes.string,
  isModal: PropTypes.bool,
  onRefresh: PropTypes.func
};

export default RulesManager;


const ConditionalPageSection = ({isPage, title, children, className=""}) => {
  return isPage ? (
    <PageSection title={title} className={className}>{children}</PageSection>
  ): children
}
