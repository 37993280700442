import React, {  useEffect, useState } from 'react';
import classnames from 'classnames';
import {StackedFilter, TextSearch} from "../../../Components/Table/Filter";
import { ProductChoices, StatusChoices } from '../../../Components/Table/FilterChoices';
import RightDateAndControls from '../../../Components/Table/RightDateAndControls';
import FilterSummary from "../../../Components/Table/FilterSummary";
import useApi from '../../../Hooks/useApi';
import {useFilters} from "../../../Hooks/useFilters";
import {DropdownToggle, UncontrolledDropdown, DropdownMenu} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

const Filters = ({ filters, onChange, loading, onRefresh }) => {
  const [choices, setChoices] = useState({});
  const { api } = useApi();
  const {onChangeFilter, reset, clearSearch} = useFilters(filters, onChange)


  // load choices
  useEffect(() => {
    const getChoices = async () => {
      const verticals = await api.endpoints.verticals.list({options:true});
      const sources = await api.endpoints.sources.list({options:true});
      if (verticals && sources) {
        setChoices({
          status: StatusChoices.filter((opt) => opt.value !== 'paused'),
          product: ProductChoices,
          vertical: verticals.map((item) => ({ value: item.id, label: item.display_name })),
          source: sources.map((item) => ({ value: item.id, label: item.name })),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);


  return (

    <div className="channel-filters d-flex justify-content-between position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />

      <div className="d-flex">
        <div className="boxed-filter search-filter me-3">

          <TextSearch
            value={filters.search || ''}
            placeholder="Search channels"
            onUpdate={(val) => onChangeFilter('search', val)}
            onClear={clearSearch}
          />

        </div>

        <UncontrolledDropdown id="report-group-menu" className="ms-3" inNavbar>
          {/* "nav" makes toggle <a> rather than <button> */}
          <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
            <FaIcon icon="filter" size="lg" className="fa-icons" />
            <span className="title d-block">Applied Filters</span>
            <small>
              <FilterSummary choices={choices} filters={filters} filterKeys={['status', 'product', 'source_id', 'vertical_id']} />
            </small>
          </DropdownToggle>
          <DropdownMenu className="select-filters-dropdown animate dropDown">
            <div className="modal-body">
              <StackedFilter
                placeholder="Any Vertical"
                label="Vertical"
                param="vertical_id"
                options={choices.vertical || []}
                value={filters.vertical_id || ''}
                onChange={onChangeFilter}
                isMulti={false}
              />

              <StackedFilter
                placeholder="Any product"
                label="Product"
                param="product"
                options={choices.product || []}
                value={filters.product || []}
                onChange={onChangeFilter}
                isMulti
              />

              <StackedFilter
                placeholder="Status"
                label="Status"
                param="status"
                options={choices.status ?? []}
                value={filters.status ?? ''}
                onChange={onChangeFilter}
                isMulti={false}
              />

              <StackedFilter
                label="Source"
                placeholder="Any Source"
                param="source_id"
                options={choices.source ?? []}
                value={filters.source_id || ''}
                onChange={onChangeFilter}
                isMulti={false}
              />

            </div>
          </DropdownMenu>
        </UncontrolledDropdown>


      </div>

      <RightDateAndControls onRefresh={onRefresh} onReset={reset} />

    </div>
  );
};

export default Filters;
