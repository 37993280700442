import React, {useMemo, useState} from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import usePermission from '../../../Hooks/usePermission';
import FlexTable from '../../../Components/Table/FlexTable';
import { DateFormatter } from '../../../Components/Table/Table';
import RoutingRuleIconTableCell from "../../../Components/Rules/RoutingRuleIconTableCell";
import {singularOrPlural} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import EntityId from "../../../Components/Table/EntityId";
import {faKey} from "@fortawesome/free-solid-svg-icons";

const ActionCell = ({
  vendor, onEditClick, onRulesClick, onSourceTokensClick, allowEditVendor, allowViewRules
}) => (
  <div className="list-actions">
    {allowEditVendor && (
      <>
        <Button color="link" className="action-item inline" title="Settings" onClick={() => { onEditClick(vendor); }}>
          <FaIcon icon="cog" size="1x" />
        </Button>
        <Button
          color='link'
          className='action-item inline'
          title='Source Tokens'
          onClick={() => {
            onSourceTokensClick(vendor);
          }}
        >
          <FaIcon icon={faKey} size='1x' />
        </Button>
      </>

    )}
    {allowViewRules && (
      <Button
        color='link'
        className='action-item inline'
        title='Vendor routing rules'
        onClick={() => {
          onRulesClick(vendor);
        }}
      >
        <FaIcon icon='clipboard-list' size='1x' />
      </Button>
    )}
  </div>
);

const TokensCell = ({ tokens }) => {
  const [showData, setShowData] = useState(false);
  const nonArchived = tokens.filter(t => t.status !== 'archived');
  return nonArchived?.length > 0 ? (
    <div className='source-tokens-cell'>
      <Button
        color='link'
        className='fw-bolder inline'
        onClick={() => {
          setShowData(!showData);
        }}
      >
        {nonArchived.length}{' '}
        {singularOrPlural(nonArchived.length, 'source', 'sources')}
        <FaIcon
          icon={showData ? 'chevron-down' : 'chevron-right'}
          size='sm'
          className='ms-1'
        />
      </Button>

      {showData &&
        nonArchived.map((st, i) => (
          <div className='source-tokens mt-1' key={i}>
            <EntityId id={st.source.id} title="Source ID" />
            {st.source.name}
          </div>
        ))}
    </div>
  ) : (
    <span>&mdash;</span>
  );
};

const VendorsTable = ({ vendors, loading, openEditModal, openRulesModal, openSourceTokensModal
}) => {
  const allowEditVendor = usePermission('vendors.edit');
  const allowViewRules = usePermission('routing.view');

  const initialTableState = useMemo(() => ({
    sortBy: [{ id: 'name', desc: false }],
    hiddenColumns: ['id'],
  }), []);

  const columns = useMemo(
    () => [
      {
        Header: 'Vendor',
        accessor: 'name',
        id: 'name', // used to id sort
        className: 'name-cell',
        defaultCanSort: true,
        maxWidth: 300,
      },
      {
        Header: 'Vendor ID',
        accessor: 'id',
        id: 'id',
      },
      {
        Header: 'Rule Summary',
        accessor: 'rule_summary',
        Cell:({value, row}) => <RoutingRuleIconTableCell summary={value} ruleId={row.original.rule_id} onOpenRules={()=>openRulesModal(row.original)} /> ,
      },
      {
        Header: 'Tokens',
        disableSortBy: true,
        accessor: 'source_tokens',
        Cell: ({ value }) => <TokensCell tokens={value}  />,
        width: 170,
      },
      {
        Header: 'Created',
        accessor: 'inserted_at',
        defaultCanSort: true,
        width: 130,
        disableGlobalFilter: true,
        Cell: ({ value }) => <DateFormatter value={value} format="MMM do, y" />,
      },
      {
        Header: 'Modified',
        accessor: 'modified_at',
        defaultCanSort: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => <DateFormatter value={value} format="MMM do, y" />,
      },
      {
        maxWidth: 100,
        disableSortBy: true,
        id: 'actions',
        disableGlobalFilter: true,
        Cell: (c) => (
          <ActionCell
            vendor={c.row.original}
            onEditClick={openEditModal}
            onRulesClick={openRulesModal}
            onSourceTokensClick={openSourceTokensModal}
            allowEditVendor={allowEditVendor}
            allowViewRules={allowViewRules}
          />
        ),
      },
    ],
    [vendors],
  );

  return (
    <FlexTable
      columns={columns}
      initialState={initialTableState}
      className="vendors-table striped"
      data={vendors}
      loading={loading}
      noDataMessage="No vendors were found"
    />

  );
};

VendorsTable.propTypes = {
  loading: PropTypes.bool,
  vendors: PropTypes.array,
  openEditModal: PropTypes.func,
  openRulesModal: PropTypes.func,
  openSourceTokensModal: PropTypes.func,
};

export default VendorsTable;
