import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import { DateFormatter, DurationFormatter } from '../../../Components/Table/Table';
import { titleCase } from '../../../utils/string';
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";
import {DispositionHelp} from "../../const";
import lazify from "../../../utils/Lazify";
import {RECORDING_URL_BASE} from "../../const";

const ReactAudioPlayer = lazify(() => import(/* webpackChunkName: "Media" */ 'react-audio-player'));



/* Display data from received_call   */
const Call = React.memo(({ call, ivrCall, ivrLog }) => (
  <>
    {call ? (
      <div className="form-section col-8">
        <div className="form-section-header">Received Call</div>
        <CallDetails call={call} />

        {/* MRK-8593 don't show IVR if the id is the same as the call */}
        {ivrCall && ivrCall.id !== call.id && <>
          <div className="form-section-header mt-4">IVR Call</div>
          <CallDetails call={ivrCall} />
        </>}

        {ivrLog && <>
          <div className="form-section-header mt-4">IVR Log</div>
          <table className="ivr-log table table-bordered">
            {ivrLog.map(el => {
              return <tr>
                <td>
                  <DateFormatter value={el.occurred_at} format="hh:mm:ss" />{}
                </td>
                <td>
                  {el.transcript.split("\n").map(el => {
                    return <>{el} <br/></>
                  })}
                </td>
                <td>
                  {el.data}
                </td>
              </tr>
            })}
          </table>
        </>}

      </div>
    ) : (
      <StandardAlert color="light">
        No call received
      </StandardAlert>
    )}
  </>

));

const CallDetails = ({call}) => {
  return <>
    <div className="data-row">
      <label>ID</label>
      <div>{call.id}</div>
    </div>

    <div className="data-row">
      <label>Disposition</label>
      <div>
        <span id="disposition">{titleCase(call.disposition)}</span>
        {call.disposition && DispositionHelp[call.disposition] && (
          <HelpPopper name="disposition" iconClass="ms-2">{DispositionHelp[call.disposition]}</HelpPopper>
        )}
      </div>
    </div>

    <div className="data-row">
      <label>Origin number</label>
      <div>{call.origin_number}</div>
    </div>

    <div className="data-row">
      <label>Transfer number</label>
      <div>{call.transfer_number}</div>
    </div>

    <div className="data-row">
      <label>Destination number</label>
      <div>{call.destination_number}</div>
    </div>

    <div className="data-row">
      <label>Received</label>
      <div><DateFormatter value={call.received_at} format="MMM do y hh:mm:ss aaa" /></div>
    </div>

    <div className="data-row">
      <label>Ended</label>
      <div><DateFormatter value={call.ended_at} format="MMM do y hh:mm:ss aaa" /></div>
    </div>

    <div className="data-row">
      <label>Duration</label>
      <div>
        {call.duration ? (
          <>
            <DurationFormatter seconds={call.duration} /> {parseInt(call.duration) > 60 && <small>({call.duration} seconds)</small>}
          </>
        ) : (
          <i>Unknown</i>
        )}
      </div>
    </div>


    <div className="data-row">
      <label>Recording</label>
      <div>
        {call.recording_url ? (
          <Suspense fallback={<div>Loading</div>}>
            <ReactAudioPlayer src={RECORDING_URL_BASE + encodeURIComponent(call.recording_url)} controls autoPlay={false} className="w-100" />
          </Suspense>
        ) : (
          <i>none</i>
        )}
      </div>
    </div>
  </>
}

Call.propTypes = {
  call: PropTypes.object,
  ivrCall: PropTypes.object,
  ivrLog: PropTypes.array,
}

export default Call;
