import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import {
  format, startOfDay, endOfDay, isSameDay, differenceInCalendarDays, addDays,
} from 'date-fns';
import PropTypes from 'prop-types';

const dateFormat = 'MMM d, yyyy';

const StartOfDay = startOfDay(new Date());
const EndOfToday = endOfDay(new Date());

// Inputs at bottom left where you can input "n days up to today"
// We aren't using the default input ranges because those allow future dates
const InputRanges = [{
  label: 'days up to today',
  range: function range(value) {
    return {
      startDate: addDays(StartOfDay, (Math.max(Number(value), 1) - 1) * -1),
      endDate: EndOfToday,
      widget: 'daysUpTo',
    };
  },
  getCurrentValue: function getCurrentValue(range) {
    if (!isSameDay(range.endDate, EndOfToday)) return '-';
    if (!range.startDate) return '∞';
    return differenceInCalendarDays(EndOfToday, range.startDate) + 1;
  },
}];

const DateRangeDropdown = ({
  startDate, endDate, onSelect, useEndOfDay = true, allowFuture = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMe = () => setIsOpen(!isOpen);

  // TODO: might need controlled menu so we can close on select
  const handleChange = (dr) => {
    // console.log('DateRangeDropdown.js:handleChange', dr);

    // Pre-defined ranges will set endDate to 23:59:59
    // but manually selected ranges will set endDate time to beginning of the day.

    if (useEndOfDay) {
      dr.selection.endDate = endOfDay(dr.selection.endDate);
    }

    // setState([dr.selection]);
    onSelect(dr.selection);

    // Close automatically if range is selected
    // Unfortunately this means if you intend to select just today (either in defined ranges or in calendar),
    // it will not automatically close.
    // (if you are just clicking one day, we don't know at this point if you just want that day or you intend to select a range)

    // dr.selection.widget: kludge to prevent menu from closing if you are filling out the 'days up to' field.
    // So if you mean to type '12' it won't close at '1'.
    if (!dr.selection.widget && !isSameDay(dr.selection.startDate, dr.selection.endDate)) {
      setIsOpen(false);
    }
  };

  if (!startDate || !endDate) return (null);

  return (
    <Dropdown isOpen={isOpen} toggle={toggleMe}>
      <DropdownToggle nav caret className="react-bootstrap-daterangepicker-container p-0">
        {`${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`}
      </DropdownToggle>
      <DropdownMenu
        end
        className="date-range-dropdown"
        modifiers={[
          {
            name: 'preventOverflow',
            options: { enabled: true },
          },
          {
            name: 'flip',
            options: {
              enabled: true,
              flipVariations: true,
              flipVariationsByContent: true,
              behavior: ['bottom', 'top', 'bottom'],
            },
          },
        ]}
      >
        <DateRangePicker
          onChange={handleChange}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          preventSnapRefocus={false}
          months={2}
          ranges={[{
            startDate,
            endDate,
            key: 'selection',
          }]}
          direction="horizontal"
          maxDate={allowFuture ? null : new Date()}
          inputRanges={InputRanges}
        />
      </DropdownMenu>

    </Dropdown>
  );
};

DateRangeDropdown.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onSelect: PropTypes.func,
  useEndOfDay: PropTypes.bool, // force end of date range to have end of day time
  allowFuture: PropTypes.bool,
};

export default DateRangeDropdown;
