import React, { useEffect } from 'react';
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
} from 'react-table';
import './ReactTableFlex.scss';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PaginationWidget from './PaginationWidget';
import { DefaultTableState } from './constants';
import { NoDataComponent } from './Table';

const defaultColumn = {};

/*
 * Generic Paged Table component using hook-based React-table-7
 * Uses Flex Layout
 * Filtering and sorting is executed server-side.
 * Filtering controls are outside of the table component.
 *
 */
const PagedTable = (props) => {
  const {
    // instance methods and props
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setFilter,
    // setGlobalFilter,
    state: { sortBy, expanded },
  } = useTable(
    // config
    {
      columns: props.columns,
      data: props.data,
      defaultColumn, // defaults to {}
      initialState: {
        ...DefaultTableState,
        ...props.initialState,
      },
      manualPagination: true, // tells pagination hook that we handle data fetching manually (must provide pageCount)
      manualSortBy: true, // letting server do the sorting,
      disableSortRemove: true, // disable 3rd click to turn off sort
      disableMultiSort: true,
      autoResetExpanded: true,
    },
    // hooks
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    useExpanded,
  );

  useEffect(() => {
    if (sortBy.length && props.setSortBy) {
      const { id, desc } = sortBy[0]; // just doing single sort
      props.setSortBy(id);
      props.setSortDir(desc ? 'desc' : 'asc');
    }
  }, [sortBy]);

  return (
    <div className={classnames('table-container', { loading: props.loading })}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {!props.loading && props.data.length === 0 && (
        <NoDataComponent>{props.noDataMessage}</NoDataComponent>
      )}

      {props.loading && (
        <div className="table-loader">
          <div>
            <Spinner color="secondary" />
          </div>
        </div>
      )}

      {props.data.length > 0 && (
        <>
          <div
            className={`table flex-table ${[props.className]}`}
            {...getTableProps()}
          >
            {props.noHeader !== true && (
              <div className="thead">
                {headerGroups.map((headerGroup, groupIndex) => (
                  <div
                    key={groupIndex}
                    {...headerGroup.getHeaderGroupProps()}
                    className="thr"
                  >
                    {headerGroup.headers.map((header, i) => {
                      // three new addition to column: isSorted, isSortedDesc, getSortByToggleProps

                      const {
                        render,
                        getHeaderProps,
                        isSorted,
                        isSortedDesc,
                        getSortByToggleProps,
                        // resizer
                        isResizing,
                        getResizerProps,
                        canSort,
                        headerClassName,
                      } = header;

                      const { onClick, ...rest } = getHeaderProps(
                        getSortByToggleProps(),
                      );

                      return (
                        <div
                          key={`th-${i}`}
                          className={classnames([
                            'th',
                            headerClassName,
                            {
                              sortable: canSort,
                              desc: isSorted && isSortedDesc,
                              asc: isSorted && !isSortedDesc,
                            },
                          ])}
                          {...rest}
                          // getHeaderProps now receives a function
                        >
                          <div onClick={onClick}>{render('Header')}</div>

                          {canSort && (
                            <div
                              className={classnames([
                                'sort-icon',
                                { 'sort-active': isSorted },
                              ])}
                            >
                              {isSorted && !isSortedDesc && (
                                <FaIcon icon="sort-up" size="sm" />
                              )}
                              {isSorted && isSortedDesc && (
                                <FaIcon icon="sort-down" size="sm" />
                              )}
                              {!isSorted && <FaIcon icon="sort" size="sm" />}
                            </div>
                          )}

                          {/* resizer div */}
                          <div
                            {...getResizerProps()}
                            className={`resizer ${
                              isResizing ? 'isResizing' : ''
                            }`}
                          />
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}


            <div className="tbody" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={`rt-tb-trs${i}`}>
                    <div
                      className={classnames(['tr', { odd: i % 2 !== 1 }])}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <div
                          key={i}
                          {...cell.getCellProps({
                            className: `td ${cell.column.className || ''}`,
                            style: cell.column.style,
                          })}
                        >
                          {cell.render('Cell')}
                        </div>
                      ))}
                    </div>
                    {row.isExpanded && props.renderRowSubcomponent ? (
                      <div className="tr subcomponent">

                          {props.renderRowSubcomponent(row, props.reload)}

                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <PaginationWidget
            page={props.page}
            pages={props.pageCount}
            pageSize={props.limit}
            defaultPageSize={25}
            totalRows={props.totalRows}
            onPageChange={props.setPage}
            onPageSizeChange={props.setLimit}
            showPageSize
          />
        </>
      )}
    </div>
  );
};

PagedTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  defaultColumn: PropTypes.object,
  initialState: PropTypes.object,
  totalRows: PropTypes.number,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort_by: PropTypes.string,
  sort_dir: PropTypes.string,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  setSort: PropTypes.func,
  noDataMessage: PropTypes.string,
  noHeader: PropTypes.bool,
  className: PropTypes.string,
  renderRowSubcomponent: PropTypes.func,
  disableFilters : PropTypes.bool,
};

PagedTable.defaultProps = {
  data: [],
  initialState: [],
  noDataMessage: 'No rows found',
  className: '',
  defaultColumn: { width: 'auto', minWidth: 100 },
};

export default PagedTable;
