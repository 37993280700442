import React, { useEffect, useState, useMemo } from 'react';
import classnames from "classnames";
import StatusDot from "../../../Components/Icons/StatusDot";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';

const OrderNode = React.forwardRef((
  {
    order,
    rules,
    channel,
    currentHover,
    currentSelection,
    isOrderView,
    onMouseEnter,
    onMouseLeave,
    onExamine,
    onSelect,
    reconnect
  }:any,
  ref:React.LegacyRef<HTMLDivElement>
) => {
  const[expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = (e:any) => {
    e.stopPropagation();
    setExpanded(!expanded)
  }

  useEffect(()=>{
    reconnect();
  }, [expanded])

  // stop Propation so clicking buttons does not activate toggle
  const handleExamine = (e: any) => {
    e.stopPropagation();
    onExamine(order);
  }

  const isRelated = useMemo(()=>{
    //channel prop is the channel that was selected (triggers loading of order list)
    if (currentHover) {
      if (isOrderView) {
        //all nodes end up here
        return true;
      } else if (currentHover.object === "order") {
        return currentHover.id === order.id.toString();
      } else if (currentHover.object === "source" && currentHover.channels && channel){
        return currentHover.channels.includes(channel.id.toString());
      } else if (currentHover.object === "channel" && channel) {
        return currentHover.id === channel.id.toString();
      }else if (currentHover.object === "eligibility" && channel) {
        //return channel.id.toString() === currentHover.id.split("-")[1];
      }
    }
    return false;
  }, [currentHover])

  const isSelected = useMemo(()=>{
    //channel prop is the channel that was selected (triggers loading of order list)
    if (currentSelection) {
      if (isOrderView) {
        return true;
      } else if (currentSelection.object === "order") {
        return currentSelection.id === order.id.toString();
      } else if (currentSelection.object === "source"  && currentSelection.channels && channel){
        return currentSelection.channels.includes(channel.id.toString());
      } else if (currentSelection.object === "channel") {
        return currentSelection.id === channel.id.toString();
      }else if (currentSelection.object === "eligibility" && channel) {
        return channel.id.toString() === currentSelection.id.split("-")[1];
      }
    }
    return false;
  }, [currentSelection])

  useEffect(()=>{
    reconnect();
  }, [expanded])



  return (
    <div
      className={classnames("node-row order", {related: isRelated, selected: isSelected })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
      data-id={order.id}
      data-object="order"
      ref={ref}
    >
      <div
        className="node-pod"
      >

        <div className="node-pod-header">
          <div className="node-pod-title">
            <div>
              <StatusDot status={order.status} className="me-1"/>
              <span className="id me-1" title="Order ID">{order.id}</span> {order.name}
            </div>
            <div className="actions">
              {onExamine && (
                <Button color="link" size="sm" onClick={handleExamine} title="Examine order routes">
                  <FaIcon icon="eye" size="sm"/>
                </Button>
              )}

            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center fw-normal">
            <div>{order.account.name}</div>
            <Button size="xs" className="inline" color="link" onClick={toggleExpanded}>Rules</Button>
          </div>
        </div>



        {expanded && (
          <div className="node-expand">
            <div className="rules">
              <div className="caption">Rules with channel modifier:</div>
              <div>
                {rules && rules.length ? (
                  <>
                    {rules.map((rule:any, i:number) => (
                      <div className="rule" key={i}>
                        <div className="caption">
                          <Link
                            target="_blank"
                            className="link"
                            to={{
                              pathname: `/accounts/${order.account.id}/order/${order.id}/rules`,
                              search: `rule=${rule.id}`,
                            }}
                            title="View rule in order rule tree"
                          >
                            {rule.label}
                          </Link>
                        </div>
                        {rule.channel_modifier && (
                          <div>Bid modifier: {rule.channel_modifier.bid_modifier}%

                          </div>
                        )}
                        <div>Last match: <RelativeTime date={rule.last_matched_at} uniqueId={`rule-${rule.id}-match`} showTimezone={true} /></div>
                        <div>Last sale: <RelativeTime date={rule.last_sold_at} uniqueId={`rle-${rule.id}-sale`} showTimezone={true} /></div>
                      </div>
                    ))}
                  </>
                ) : (
                  <i>No rules found</i>
                )}
              </div>
            </div>



          </div>
        )}

      </div>

      <div className="node-pod-footer d-flex">
        <div><label>Match:</label> <RelativeTime date={order.last_matched_at} uniqueId={`ord-${order.id}-match`} /></div>
        <div><label>Sale:</label> <RelativeTime date={order.last_sold_at} uniqueId={`ord-${order.id}-sale`} /></div>
      </div>


    </div>
  )
})

export default OrderNode;
