import React, { useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label, Button, Card, CardBody, CardTitle, FormFeedback,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import InputWithIcon from '../../../Components/Form/InputWithIcon';
import { Required } from '../../../Components/Form/FormCommon';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";
import InlineConfirmDelete from '../../../Components/Form/InlineConfirmDelete';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import useApi from '../../../Hooks/useApi';

// Mask dd.dd
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 9,
  // fixedDecimalScale:true
});

const Goal = ({
  goal, onRemove, onSaved, reloadList, allowEdit
}) => {
  const { api } = useApi();
  const [editMode, setEditMode] = useState(!goal.id);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [name, setName] = useState(goal.name);
  const [value, setValue] = useState(goal.value);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errors, setErrors] = useState({});
  const [originalGoal] = useState(goal);
  const isNew = !goal.id;
  const copyTag = `<img src="${goal.url}" width="1" height="1" border="0" alt="">`;

  const toggleDeleteConfirm = () => setDeleteConfirm(!deleteConfirm);

  const handleCancel = () => {
    setName(originalGoal.name);
    setValue(originalGoal.value);
    setEditMode(false);
    if (isNew) {
      // if cancel new, remove
      onRemove();
    }
  };

  const handleSave = () => {
    if (!isUpdating) {
      const valid = !!name;
      if (valid) {
        setErrors({});
        save();
      } else {
        setErrors({ name: 'Please enter a name' });
      }
    }
  };

  const save = async () => {
    // for new, will need to update list
    setIsUpdating(true);
    const newGoal = { ...goal, name, value };
    let result;
    if (isNew) {
      result = await api.endpoints.goals.create(newGoal);
    } else {
      result = await api.endpoints.goals.update(newGoal);
    }

    setIsUpdating(false);

    if (result) {
      setEditMode(false);
      onSaved();
      reloadList();
    } else {
      // Could get 'name already exists' error
      if (api.error.form && api.error.form['name.name']) {
        notify(`Unable to save goal: ${api.error.form['name.name']}`, 'error');
      } else {
        notify(`Unable to save goal: ${api.error.name}`, 'error');
      }
    }
  };

  const removeGoal = async () => {
    setIsUpdating(true);
    const result = await api.endpoints.goals.delete(goal.id);
    setIsUpdating(false);
    onRemove();
  };

  return (
    <Card className={classnames('goal mt-3 mb-3', { new: isNew })}>

      <CardBody>
        {editMode === false && (
        <CardTitle>
          {name}
          {' '}
          {value && (
          <span className="value ms-2">
            <NumberFormat value={value} displayType="text" thousandSeparator prefix="$" decimalScale={2} fixedDecimalScale />
          </span>
          ) }
        </CardTitle>
        )}
        <Row>

          {editMode === false && (
            <>
              <Col sm={9}>
                <div>
                  <code>
                    &lt;img src=&quot;
                    {goal.url}
                    &quot; width=&quot;1&quot; height=&quot;1&quot; border=&quot;0&quot; alt=&quot;&quot;&gt;
                  </code>
                </div>
                <div>
                  <CopyButton
                    id={`copy-goal-tag-${goal.id}`}
                    textToCopy={copyTag}
                    buttonSize="sm"
                  >
                    Copy Pixel Tag
                  </CopyButton>
                  &nbsp;|&nbsp;
                  <CopyButton
                    id={`copy-goal-id-${goal.id}`}
                    textToCopy={goal.uuid}
                    buttonSize="sm"
                  >
                    Copy Goal ID
                  </CopyButton>
                </div>
              </Col>
              {allowEdit && (
                <Col sm={3} className="text-end">
                  {deleteConfirm === true ? (
                    <InlineConfirmDelete prompt="Remove goal?" onDelete={removeGoal} onCancel={toggleDeleteConfirm} justify="end" />
                  ) : (
                    <>
                      <Button onClick={() => setEditMode(true)} color="link"><FaIcon icon="pencil-alt" /></Button>

                      <Button onClick={toggleDeleteConfirm} color="link"><FaIcon icon="times" color="darkred" /></Button>

                    </>
                  )}

                </Col>
              )}

            </>
          )}

          {editMode && allowEdit && (
            <>
              <Col sm={5}>
                <FormGroup>
                  <Label>
                    Name
                    <Required />
                  </Label>
                  <Input
                    name="name"
                    maxLength={100}
                    value={name}
                    invalid={errors.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <FormFeedback>{errors.name}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label>Value</Label>

                  <MaskedInput
                    mask={currencyMask}
                    value={value || ''}
                    onChange={(e) => setValue(e.target.value)}
                    render={(ref, props) => <InputWithIcon className="tiny" innerRef={ref} icon="dollar-sign" {...props} />}
                  />
                </FormGroup>
              </Col>
              <Col sm={4} className="text-right">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <div>
                    <Button onClick={handleSave} color="primary" size="sm" className="me-2">
                      <FaIcon icon="save" />
                      Save
                    </Button>
                    <Button onClick={handleCancel} color="secondary" size="sm" outline>Cancel</Button>
                  </div>
                </FormGroup>
              </Col>
            </>

          )}

        </Row>
      </CardBody>
    </Card>
  );
};

export default Goal;
