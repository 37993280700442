import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import EntityId from "../Table/EntityId";
import {Badge} from 'reactstrap';
import {titleCase} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import React from "react";

/*
 * A function that can be provided as react-select's formatOptionLabel function
 * on a source selector, to show more source info than just the name.
 *
 * Value is assumed to be the source id, and label == name
 * Status has to be programmatically added to the option values,
 * if you want to show it
 */
export const SourceOptionLabel = ({
                             value, label, status, selected,
                           }: {value:number|string, label:string, status?:string, selected:boolean}) => (
  <div className="d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <div>{selected && <FaIcon icon="check" size="sm" className="me-1" color="green" />}</div>
      <div><EntityId id={value} /> {label}</div>
    </div>

    <div>
      {/* Just indicating paused or archived (active assumed) */}
      {status && status !== 'active' && <Badge size="xs" color={status === 'archived'?'danger':'warning'}>{titleCase(status)}</Badge>}
    </div>

  </div>
);
