import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, FormText, Label, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useDebouncedCallback } from 'use-debounce';
import InputWithIcon from '../Form/InputWithIcon';
import ValueDeltaDisplay from '../ValueDeltaDisplay';
import { MoneyFormatter, PercentFormatter } from '../Table/Table';

// Mask dd.dd
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 3,
  // fixedDecimalScale:true
});

// Mask ddd
const modifierMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 3,
});

/*
 * Form elements for setting bid type and values (modifier or absolute bid).
 * Also for setting exclusivity option, which depends on the bid type
 * (exclusivity is only for advertiser rules)
 *
 * Note: When this component is used in routing rules, we use 'Margin' has a label
 * for bid_modifier, since that is technically what it is. But for consistency the API
 * uses the same properties for advertiser and routing rules.
 */
const BidWidget = ({
  type,
  absolute_bid,
  bid_modifier,
  ruleType,
  isActiveTab,
  showExclusivity,
  exclusivity,
  onChange,
  errors = {},
}) => {
  const [originalBidType] = useState(type);
  const [originalAbsoluteBid] = useState(absolute_bid);
  const [originalBidModifier] = useState(bid_modifier);

  // Focus on input when tab becomes active
  useEffect(() => {
    if (isActiveTab) {
      if (type === 'absolute') {
        document.getElementById('inp-absolute-bid').select();
      } else if (type === 'modifier') {
        document.getElementById('inp-modifier-bid').select();
      }
    }
  }, [isActiveTab]);

  const updateBidding = (newValue) => {
    const bidding = {
      type,
      absolute_bid,
      bid_modifier,
    };
    onChange('bidding', { ...bidding, ...newValue });
  };

  const handleChangeAbsBidDebounced = useDebouncedCallback((value) => {
    updateBidding({ absolute_bid: value });
  }, 400);

  const handleChangeModifierDebounced = useDebouncedCallback((value) => {
    value.replace(/[^0-9]/g, ''); // unmask
    value = (!value && value !== '0') ? null : Math.min(value, 500);
    updateBidding({ bid_modifier: value });
  }, 400);

  const handleChangeType = (e) => updateBidding({ type: e.target.value });

  const absBidError = errors['bidding.absolute_bid'];
  const modifierError = errors['bidding.bid_modifier'];

  return (
    <div className="bid-widget">

      <div className="d-flex flex-nowrap align-items-center">
        <div className="me-3" style={{ flexBasis: '225px' }}>
          <FormGroup>
            <Label for="bid_type">Type</Label>
            <Input type="select" value={type} onChange={handleChangeType}>
              <option value="pass">None specified</option>
              {/* Routing has no block (has the include/exclude flow_control field) */}
              {ruleType !== 'routing' && <option value="block">Blocked</option>}
              <option value="absolute">Dollar amount</option>
              <option value="modifier">{ruleType === 'routing' ? 'Margin' : 'Modifier'}</option>
            </Input>
          </FormGroup>
        </div>
        { (type === 'absolute' || type === 'modifier') && (
          < >

              {type === 'absolute' && (
                <FormGroup className="d-inline-block me-3">
                  <Label>Amount</Label>
                  <MaskedInput
                    id="inp-absolute-bid"
                    mask={currencyMask}
                    defaultValue={absolute_bid}
                    disabled={type !== 'absolute'}
                    onChange={(e) => handleChangeAbsBidDebounced(e.target.value)}
                    invalid={!!absBidError}
                    error={absBidError}
                    render={(ref, props) => <InputWithIcon className="tiny" innerRef={ref} icon="dollar-sign" {...props} />}
                  />
                </FormGroup>
              )}
              {type === 'modifier' && (
                <FormGroup className="d-inline-block  me-3">
                  <Label>{ruleType === 'routing' ? 'Margin' : 'Modifier'}</Label>
                  <MaskedInput
                    id="inp-modifier-bid"
                    mask={modifierMask}
                    defaultValue={bid_modifier}
                    disabled={type !== 'modifier'}
                    onChange={(e) => handleChangeModifierDebounced(e.target.value)}
                    invalid={!!modifierError}
                    error={modifierError}
                    render={(ref, props) => <InputWithIcon className="tiny" icon="percent" iconSide="right" innerRef={ref} {...props} />}
                  />
                </FormGroup>
              )}
            <div>
              {type === 'pass' && <BidAlert defaultMsg="Bidding is determined by parent or child rules" />}
              {type === 'block' && <BidAlert defaultMsg="Do not bid. This overrides bidding defaults." />}
              {type === 'absolute' && <BidAlert type={type} bid={absolute_bid} previousBid={originalAbsoluteBid} previousType={originalBidType} />}
              {type === 'modifier' && <BidAlert type={type} bid={bid_modifier} previousBid={originalBidModifier} previousType={originalBidType} />}
            </div>

          </>
        )}

      </div>

      {showExclusivity && (
        <Row>
          <Col sm={10} md={7}>
            <FormGroup className="d-inline-block">
              <Label>Exclusivity</Label>
              <Input
                name="exclusivity"
                type="select"
                value={exclusivity || ''}
                onChange={onChange}
              >
                <option value="">None</option>
                <option value="strict">Strict - bid only in exclusive auctions</option>
                <option value="optional" disabled={type !== 'modifier'}>
                  Optional -
                  {type === 'modifier' ? 'all auctions, or exclusive when permitted' : 'requires modifier bid type'}
                </option>
              </Input>
              <FormText>Controls bidding in exclusive auctions. Purchased leads cannot be sold again.</FormText>

            </FormGroup>
          </Col>
        </Row>
      )}

    </div>
  );
};

const BidAlert = ({
  type, bid, previousBid, previousType, defaultMsg = '',
}) => {
  let alert = '';
  if (type === 'modifier') {
    bid = parseInt(bid);

    if (bid === 0) {
      alert = 'Setting the modifier to zero will disable bidding. Instead, choose Block as a bid type.';
    } else if (bid === 100 && previousType === type) {
      alert = 'A modifier of 100% will not modify the bid.';
    } else if (bid && previousBid !== bid && previousType === type) {
      alert = (
        <span>
          Bid change:
          <ValueDeltaDisplay oldValue={previousBid} newValue={bid} threshold={1000} />
          {' '}
          from
          <PercentFormatter value={previousBid} />
        </span>
      );
    }
  } else if (type === 'absolute') {
    bid = parseFloat(bid);

    if (bid === 0) {
      alert = 'Setting the bid to zero will disable bidding. You can also choose Block as a bid type.';
    } else if (bid && parseFloat(previousBid) !== bid && previousType === type) {
      alert = (
        <span>
          Bid change:
          <ValueDeltaDisplay oldValue={previousBid} newValue={bid} threshold={1000} />
          {' '}
          from
          <MoneyFormatter value={previousBid} />
        </span>
      );
    }
  }
  //
  return (<FormText className="bid-alert">{alert || defaultMsg}</FormText>);
};

BidWidget.propTypes = {

  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  absolute_bid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bid_modifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ruleType: PropTypes.string,
  isActiveTab: PropTypes.bool,
  errors: PropTypes.object,
  showExclusivity: PropTypes.bool,
  exclusivity: PropTypes.string,

};

export default BidWidget;
