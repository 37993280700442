import React, { useEffect, useState, useMemo } from 'react';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap'
import {useNavigate} from "react-router-dom";
import filesize from 'filesize.js';
import PropTypes from 'prop-types';
import GoalTrackingUpload from './GoalTrackingUpload';
import PagedTable from '../../../Components/Table/PagedTable';
import { DateFormatter, NoDataComponent } from '../../../Components/Table/Table';
import EntityId from "../../../Components/Table/EntityId";
//import InlineConfirmDelete from '../../../Components/Form/InlineConfirmDelete';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import DownloadFileLink from '../../../Components/DownloadFileLink';
import GoalTrackingResultModal from './GoalTrackingResultModal';
import usePermission from "../../../Hooks/usePermission";
import useApi from '../../../Hooks/useApi';
import qs from "query-string";

const GoalTracking = ({ accountId }) => {
  const navigate = useNavigate();
  const allowEdit = usePermission('accounts.edit');
  const [tracking, setTracking] = useState([]);
  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [rowErrorModalOpen, setRowErrorModalOpen] = useState(false);
  const [rowErrors, setRowErrors] = useState();
  const { api } = useApi();

  const closeUploadModal = (refresh) => {
    setUploadModalOpen(false);
    if (refresh) {
      load();
    }
  };

  const showRowErrors = (errors) => {
    setRowErrors(errors);
    setRowErrorModalOpen(true);
  };

  const closeRowErrors = () => {
    setRowErrors(null);
    setRowErrorModalOpen(false);
  };

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setIsLoading(true);
    const files = await api.endpoints.goalsTracking.list({ account_id: accountId });
    if (files) {
      setTracking(files.data);
    } else {
      notify(`Unable to get tracking files: ${api.error?.name}`, 'error');
    }

    const goals = await api.endpoints.goals.list({ account_id: accountId });
    if (goals) {
      setGoals(goals);
    }

    setIsLoading(false);
  };


  const deleteTrackingFile = async (id) => {
    setIsLoading(true);
    const result = await api.endpoints.goalsTracking.delete(id);
    if (result) {
      load();
      notify('The file has been removed', 'success');
    } else {
      notify(`Unable to remove file: ${api.error?.name}`, 'error');
      setIsLoading(false);
    }
  };

  /*
  account: {id: 9, name: "Acme Insurance"}
file: {file_name: "mst_test_2_sheet.xlsx", size: 8879, created_at: "2021-09-27T18:56:25+00:00"}
created_at: "2021-09-27T18:56:25+00:00"
file_name: "mst_test_2_sheet.xlsx"
size: 8879
id: 35
note: "Excel with 2 sheets"
user: {id: 5, name: "Don Admin"}
   */

  const columns = useMemo(() => [
    {
      Header: 'File',
      accessor: 'file.file_name',
      Cell: ({ row, value }) => <FileNameCell name={value} size={row.original.file.size} />,
      minWidth: 180,
    },
    {
      Header: 'Goal',
      accessor: 'goal',
      Cell: ({value}) => <><EntityId id={value.id} /> {value.name}</>
    },
    {
      Header: 'Note',
      accessor: 'note',
      minWidth: 200,
    },
    {
      Header: 'User',
      accessor: 'user.name',
      maxWidth: 100,
    },
    {
      Header: 'Success',
      accessor: 'num_success',
      maxWidth: 80,
    },
    {
      Header: 'Errors',
      accessor: 'num_errors',
      Cell: ({ value, row }) => <RowErrorCell numErrors={value} errors={row.original.row_errors} openModal={() => showRowErrors(row.original.row_errors)} />,
      minWidth: 100,
    },

    {
      Header: 'Processed',
      accessor: 'processed_at',
      maxWidth: 120,
      Cell: ({ value }) => <DateCell date={value} />,
    },
    {
      Header: 'Created',
      accessor: 'file.created_at',
      maxWidth: 120,
      Cell: ({ value }) => <DateCell date={value} />,
    },
    {
      id: 'actions',
      disableSortBy: true,
      maxWidth: 60,
      Cell: ({ row }) => (
        <ActionCell
          id={row.original.id}
          filename={row.original.file.file_name}
          onDelete={() => deleteTrackingFile(row.original.id)}
        />
      ),
    },

  ]);

  const initialTableState = useMemo(() => ({
    sortBy: [{ id: 'created_at', desc: true }],
  }), []);

  const gotoGoals = () => {
    navigate({
      replace: true,
      search: 'tab=goals'
    });
  }

  return (
    <div className="form-section">
      <div className="form-section-header d-flex justify-content-between">

        <div>Goal Tracking Files</div>
        {tracking.length > 0 && allowEdit && <Button size="sm" color="primary" onClick={() => setUploadModalOpen(true)}>Add File</Button>}
      </div>

      <p>Associate conversion funnel events with goals for enhanced reporting.</p>

      {!isLoading && tracking.length === 0 && (

        <>
          {goals.length > 0 ? (
            <NoDataComponent>
              No tracking files.
              {allowEdit && <Button color="link" className="p-0 ms-2" onClick={() => setUploadModalOpen(true)}>Upload a file.</Button>}
            </NoDataComponent>
          ) : (
            <NoDataComponent>
              No goals have been defined.
              {allowEdit && <Button color="link" className="p-0 ms-2" onClick={gotoGoals}>Add a goal.</Button>}
            </NoDataComponent>
          )}

        </>

      )}

      {(isLoading || tracking.length > 0)
      && (
      <div className="react-table-container">

        <PagedTable
          columns={columns}
          initialState={initialTableState}
          className="goal-tracking-table"
          data={tracking}
          loading={isLoading}
          noDataMessage="No tracking files were found"
        />
      </div>
      )}

      {allowEdit && (
        <Modal isOpen={uploadModalOpen} toggle={closeUploadModal} size="lg" id="goal-tracking-modal">

          <ModalHeader toggle={closeUploadModal}>Upload Goal Tracking File</ModalHeader>
          <ModalBody>
            <GoalTrackingUpload close={closeUploadModal} accountId={accountId} />
          </ModalBody>

        </Modal>
      )}


      <GoalTrackingResultModal isOpen={rowErrorModalOpen} close={closeRowErrors} rowErrors={rowErrors} />
    </div>
  );
};

const DateCell = ({ date }) => {
  if (!date) return (null);
  return (
    <>
      <div>
        <DateFormatter value={date} format="MMM d, yyy" />
      </div>
      <div>
        <small><DateFormatter value={date} format="h:mm a" /></small>
      </div>
    </>

  );
};

const FileNameCell = ({ name, size, note }) => (
  <div>
    <div className="file-name">
      {name}
      {' '}
      <span className="file-size">
        (
        {filesize(size, 0)}
        )
      </span>
    </div>
    {note && <div className="file-note"><i>{note}</i></div>}
  </div>
);

const RowErrorCell = ({ numErrors, errors, openModal }) => (
  <span>
    {numErrors}
    {errors && !Array.isArray(errors) && <Button color="link" size="sm" className="p-0 m-0 inline" onClick={openModal}>view</Button>}
  </span>
);

//  We probably don't need the delete file action
// const ActionCell = React.memo( ({id, filename, onDelete}) => {
//   const[showPrompt, setShowPrompt] = useState(false);
//   const handleDelete = () => onDelete(id);
//   const togglePrompt = () => setShowPrompt(!showPrompt);
//
//   return (
//     <div className="list-actions">
//         <span className="action-item">
//           {showPrompt ? (
//             <InlineConfirmDelete prompt="Remove this file?" onDelete={handleDelete} onCancel={togglePrompt}/>
//           ) : (
//             <>
//               <DownloadFileLink identifier={id} downloadSource="goalsTracking" defaultFilename={filename}/>
//               <Button color="link" title="Remove File" onClick={togglePrompt}  >
//                 <FaIcon icon="times" color="darkred"/>
//               </Button>
//             </>
//
//           )}
//
//         </span>
//
//     </div>
//   )
// });

const ActionCell = React.memo(({ id, filename, onDelete }) => (
  <div className="list-actions">
    <span className="action-item">

      <DownloadFileLink identifier={id} downloadSource="goalsTracking" defaultFilename={filename} />
    </span>
  </div>
));

GoalTracking.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default GoalTracking;
