import React from 'react';
import { Outlet } from 'react-router-dom';
import NotAuthorized from '../Layout/NotAuthorized';
import usePermission from '../Hooks/usePermission';

/*
 * Wrapper for Route
 * Checks a permission, shows Component or NotAuthorized
 * ("Outlet" is react-router-6 term for an element associated with a route)
 */
const ProtectedRoute = ({permission}) => {
  const allowRoute = usePermission(permission);
  return allowRoute ? <Outlet /> : <NotAuthorized />
}

export default ProtectedRoute;
