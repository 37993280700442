import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { titleCaseWord } from '../../../utils/string';
import usePermission from '../../../Hooks/usePermission';
import ChannelSourceDetail from './ChannelSourceDetail';
import PagedTable from '../../../Components/Table/PagedTable';
import TableSubcomponent from '../../../Components/Table/TableSubcomponent';
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';
import EntityId from "../../../Components/Table/EntityId";
import StatusToggler from "../../../Components/Form/StatusToggler";

const ActionCell = ({
    channel,
    onEditClick,
    onRulesClick,
    onRoutingClick,
    allowEditChannel,
    allowViewRules,
    allowViewAuction,
}) => (
    <div className='list-actions'>
        {allowEditChannel && (
            <Button
                color='link'
                className='action-item inline'
                title='Settings'
                onClick={() => {
                    onEditClick(channel);
                }}
            >
                <FaIcon icon='cog' size='1x' />
            </Button>
        )}

        {/* {allowEditChannel && <span className="action-item">
      <FaIcon icon="external-link-alt" size="sm" className="me-1"/>
      <Button color="transparent" onClick={(e)=>{e.stopPropagation();onSourcesClick(channel)}}>Sources</Button>
      </span>} */}

        {allowViewRules && (
            <Button
                color='link'
                onClick={() => {
                    onRulesClick(channel);
                }}
                title='Channel routing rules'
                className='action-item inline'
            >
                <FaIcon icon='clipboard-list' size='1x' />
            </Button>
        )}

        {allowViewAuction && (
          <>
            <Link
              to={`/auctions?page=1&channel=${channel.id}`}
              target="_blank"
              title='Auction List'
              className='action-item'
            >
              <FaIcon icon='comment-dollar' size='1x' />
            </Link>
            <Button
              color='link'
              onClick={() => {
                onRoutingClick(channel.id);
              }}
              title='Routing Map'
              className='action-item inline'
            >
              <FaIcon icon='project-diagram' size='1x' />
            </Button>
          </>

        )}

    </div>
);

const SourceExpander = ({ sourceCount, isExpanded }) => {
    // "Fake" button. Actual onclick event is from row.getToggleRowExpandedProps()
    // in the column definition
    if (!sourceCount) {
        return (
            <Button color="link" size="sm" className='inline' onClick={null} >
                Add sources
            </Button>
        );
    }

    return (
        <div>
            {sourceCount} {sourceCount > 1 ? 'Sources' : 'Source'}{' '}
            {isExpanded ? (
                <FaIcon icon='chevron-circle-down' size='sm' />
            ) : (
                <FaIcon icon='chevron-circle-right' size='sm' />
            )}
        </div>
    );
};

// const StatusCell = ({status, channel, allowEdit, onChange }) =>{
//
//   return allowEdit === true ? (
//     <StatusToggler status={status} id={channel} onChangeAsync={onChange} allowEdit={allowEdit}/>
//   ) : (titleCaseWord(status))
// }
//
// const SourceStatusCell = ({status}) => (<span className="status-toggler">
//       <FaIcon icon="circle" size="xs" className={classnames(["current-status-icon", status, "me-2"])}/>{status}
//     </span>
// );

const ChannelsTable = ({
    data,
    loading,
    totalRows,
    pageCount,
    page,
    sort_by,
    sort_dir,
    limit,
    setPage,
    setLimit,
    setSortBy,
    setSortDir,
    openChannelModal,
    onStatusChange,
    openRulesModal,
    openRoutingModal,
    refreshChannels,
    allSources=[]
}) => {
    const allowEdit = usePermission('channels.edit');
    const allowViewRules = usePermission('routing.view');
    const allowViewAuction = usePermission('auctions.view');
    // controlled row expansion overrides default react-table behavior
    // const [expanded, setExpanded] = useState({})

    const columns = useMemo(
        () => [
          {
            Header: 'ID',
            className: 'name-cell',
            id: 'id',
            accessor: 'id',
            defaultCanSort: false,
            Cell: ({value}) => (
              <EntityId id={value} title="Channel ID" />
            ),
            maxWidth: 60
          },
            {
                Header: 'Channel',
                accessor: 'name',
                defaultCanSort: true,
                width: 275,
                sortType: 'string',
            },
            {
                Header: 'Sources',
                id: 'sources',
                disableSortBy: true,
                className: 'sources-link',
                Cell: ({ row }) => (
                    <div {...row.getToggleRowExpandedProps()}>
                        <SourceExpander
                            isExpanded={row.isExpanded}
                            sourceCount={row.original.sources.length}
                        />
                    </div>
                ),
                style: { cursor: 'pointer' },
            },
            {
                Header: 'Vertical',
                id: 'vertical.id',
                defaultCanSort: true,
                Cell: ({row}) => row.original.vertical.display_name,
                width: 140,
            },
            {
                Header: 'Product',
                accessor: 'product',

                Cell: (c) => titleCaseWord(c.value),
                defaultCanSort: true,
               // filter: 'includesSome',
                width: 90,
            },
            {
                Header: 'Status',
                accessor: 'status',
                defaultCanSort: true,
                width: 110,
                Cell: (c) => (
                  <StatusToggler
                    status={c.value}
                    target={c.row.original}
                    allowEdit={false}
                  />

              ),
            },
            {
                Header: 'Account',
                accessor: 'account.name',
                disableSortBy: true,
                width: 150,
            },
            {
                Header: 'Created',
                accessor: 'inserted_at',
                defaultCanSort: true,
                Cell: ({ value }) => <RelativeTime date={value} />,
            },
            {
                Header: 'Modified',
                accessor: 'modified_at',
                defaultCanSort: true,
                Cell: ({ value }) => <RelativeTime date={value} />,
            },
            {
              Header: 'Last Match',
              accessor: 'last_matched_at',
              defaultCanSort: true,
              Cell: ({ value }) => <RelativeTime date={value} />,
            },
            {
              Header: 'Last Sold',
              accessor: 'last_sold_at',
              defaultCanSort: true,
              Cell: ({ value }) => <RelativeTime date={value} />,
            },
            {
                Cell: ({ row }) => (
                    <ActionCell
                        channel={row.original}
                        onEditClick={openChannelModal}
                        onRulesClick={openRulesModal}
                        allowEditChannel={allowEdit}
                        allowViewRules={allowViewRules}
                        allowViewAuction={allowViewAuction}
                        onRoutingClick={openRoutingModal}
                    />
                ),
                id: 'actions',
                width: 132,
            },
        ],
        []
    );

    const initialTableState = useMemo(
        () => ({
            sortBy: [{ id: 'name', desc: false }],
        }),
        []
    );


    const renderDetailSubRow = useCallback(
        (row) => (
            <TableSubcomponent className='channel-sources'>
                <ChannelSourceDetail
                    channel={row.original}
                    sources={row.original.sources}
                    onCancel={row.toggleRowExpanded}
                    refreshChannels={refreshChannels}
                    allSources={allSources}
                />
            </TableSubcomponent>
        ),
        [allSources]
    );

    return (
        <PagedTable
            columns={columns}
            initialState={initialTableState}
            className='channels-table striped'
            data={data}
            loading={loading}
            totalRows={totalRows}
            pageCount={pageCount}
            page={page}
            limit={limit}
            sort_by={sort_by}
            sort_dir={sort_dir}
            setPage={setPage}
            setLimit={setLimit}
            setSortBy={setSortBy}
            setSortDir={setSortDir}
            noDataMessage='No channels were found'
            renderRowSubcomponent={renderDetailSubRow}
        />
    );
};

ChannelsTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  totalRows: PropTypes.number,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  sort_by: PropTypes.string,
  sort_dir: PropTypes.string,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  attributes: PropTypes.object,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  setSort: PropTypes.func,
    openChannelModal: PropTypes.func,
    onStatusChange: PropTypes.func,
    openRulesModal: PropTypes.func,
    openRoutingModal: PropTypes.func,
};

export default ChannelsTable;
