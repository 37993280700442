const LocalStorageService = {

  setLocalItem(key, val) {
    try {
      const serialized = JSON.stringify(val);
      localStorage.setItem(key, serialized);
    } catch (err) {
      // Ignore write errors.
    }
  },

  getLocalItem(key) {
    try {
      const val = localStorage.getItem(key);
      if (val === null) {
        return undefined;
      }
      return JSON.parse(val);
    } catch (err) {
      return undefined;
    }
  },

  setSessionItem(key, val) {
    try {
      const serialized = JSON.stringify(val);
      sessionStorage.setItem(key, serialized);
    } catch (err) {
      // Ignore write errors.
    }
  },

  getSessionItem(key) {
    try {
      const val = sessionStorage.getItem(key);
      if (val === null) {
        return undefined;
      }
      return JSON.parse(val);
    } catch (err) {
      return undefined;
    }
  },

};

export default LocalStorageService;
