import React, { useContext, useMemo } from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import classnames from 'classnames';
import { TreeContext } from './TreeProvider';

const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

/*
 * Formats bid for display according to type and amount, used in rules trees and summar
 *
 */
const BidDisplay = ({
  ruleId,
  ruleType,
  type,
  absolute_bid,
  bid_modifier,
  exclusivity,
  iconSize,
  passText,
  blockedText,
  clickHandler,
}) => {
  const treeContext = useContext(TreeContext);
  const isUpdating = (treeContext && ruleId === treeContext.currentBidUpdating);

  const [formattedBid, zeroBid, modifierUp] = useMemo(() => {
    let zeroBid = false;
    let modifierUp = false;
    let bid = '';
    if (type === 'absolute') {
      if (absolute_bid > 0) {
        bid = numberFormat.format(parseFloat(absolute_bid));
      } else {
        bid = 0;
        zeroBid = true;
      }
    } else if (type === 'modifier') {
      if (bid_modifier && !isNaN(bid_modifier) && parseInt(bid_modifier) !== 0) {
        bid = `${bid_modifier}%`;
        modifierUp = bid_modifier > 100;
      } else {
        bid = bid_modifier;
        zeroBid = true;
      }
    } else if (type === 'block') {
      bid = (
        <span>
          <FaIcon icon="ban" size={iconSize} />
          {' '}
          {blockedText}
        </span>
      );
    } else if (type === 'pass') {
      bid = passText ? (<span>{passText}</span>) : (<span>&nbsp;</span>);
    }

    return [bid, zeroBid, modifierUp];
  }, [type, absolute_bid, bid_modifier]);

  return (
    <div className="bid-display d-flex">
      {exclusivity === 'strict' && <FaIcon icon="star" size="sm" className="exclusive-star" title="Exclusive only" />}
      {exclusivity === 'optional' && <FaIcon icon="star" size="sm" className="exclusive-star optional" title="Exclusive optional" />}

      {type === 'modifier' && ruleType !== 'routing' && (<ModifierArrow bid={formattedBid} />)}

      <div className={classnames([`rule-bid bid-${type}`, { 'zero-bid': zeroBid, 'over-100': modifierUp }])} onClick={clickHandler}>
        {isUpdating ? <Spinner size="sm" color="light" /> : formattedBid}

      </div>

    </div>
  );
};

const ModifierArrow = React.memo(({ bid }) => {
  bid = parseInt(bid);
  //  {'bid-modifier-arrow ' + (bid < 100 ? 'sub-100':'')}
  return (
    <div className={classnames(['bid-modifier-arrow', { 'sub-100': bid < 100 }])}>
      {bid === 100 && <FaIcon icon="arrows-alt-h" size="sm" />}
      {bid > 100 && <FaIcon icon="arrow-up" size="sm" />}
      {bid < 100 && <FaIcon icon="arrow-down" size="sm" />}
    </div>
  );
});

BidDisplay.propTypes = {
  type: PropTypes.string.isRequired,
  ruleId: PropTypes.number,
  ruleType: PropTypes.string,
  absolute_bid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bid_modifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exclusivity: PropTypes.string,
  passText: PropTypes.string,
  blockedText: PropTypes.string,
  iconSize: PropTypes.string,
  clickHandler: PropTypes.func,
};

BidDisplay.defaultProps = {
  type: 'pass',
  absolute_bid: '',
  bid_modifier: '',
  passText: '',
  blockedText: '',
  iconSize: '1x',
};

export default BidDisplay;
