import React from 'react';
import {
  FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import {HelpPopper}  from '@thedmsgroup/mastodon-ui-components';
import OptionTarget from './OptionTarget';
import { StaticChoiceInputTypes, DynamicChoiceInputTypes } from './constants';
import IconCheckbox from '../../../../Components/Form/IconCheckbox';

const Options = ({
  inputType = '',
  unknown_data_choice = true,
  choices = '',
  target,
  min = '',
  max = '',
  label = '',
  errors = {},
  onChange,
}) => {
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    updateParentForm(name, value);
  };

  const handleOnCheck = (name, checked) => {
    updateParentForm(name, checked);
  };

  const handleOnChangeTarget = (e) => {
    const { name, value } = e.target;
    updateParentForm('target', { ...target, [name]: value });
  };

  const updateParentForm = (field, value) => {
    const opt = {
      unknown_data_choice,
      choices,
      target,
      label,
      min,
      max,
    };
    onChange('options', { ...opt, [field]: value });
  };

  return (
    <>
      <div className="form-section attribute-options">
        <div className="form-section-header">Options</div>

        {/*
          If unknown_data_choice is false, the field will not be shown  (assume rejected?)
          if true (or not set), the field will be shown.
          allows user to accept or reject unknown data.
          Controls the 'accept_unknown' matchable field.
          Example: call_center choices are [parrot, "double positive"] and you can accept or reject unknown data.

          */}
        <IconCheckbox
          name="unknown_data_choice"
          value={!!unknown_data_choice}
          onChange={handleOnCheck}
          className="mt-3 mb-3"
        >
          Unknown Data Choice
          <HelpPopper name="unknown_data_choice" title="Unknown Data Choice" iconClass="ms-2">
            If checked, the user will be able to configure targeting to allow or disallow matching against unknown data.
            If unchecked, this option will not be available when configuring targets, and unknown data will be accepted by default.
          </HelpPopper>
        </IconCheckbox>

        <FormGroup className="flex-shrink-1">
          <Label for="method">
            Label
            <HelpPopper name="label-option" title="Label" iconClass="ms-2">
              A hint for describing the required input. For example, with an integer input for number of accidents:
              &quot;
              <i>in the last 3 years</i>
              &quot;. With zip code input: &quot;
              <i>A comma-separated list of zip codes</i>
              &quot;.
            </HelpPopper>
          </Label>
          <Input type="text" bsSize="sm" name="label" value={label} placeholder="Brief help text (optional)" onChange={handleOnChange} />
        </FormGroup>

        {inputType === 'integer_range' && (
        <div>
          <div className="d-flex align-items-center">

            <FormGroup className="flex-shrink-1">
              <Label for="method">Min Value</Label>
              <Input type="number" bsSize="sm" className="teeny me-2" name="min" value={min || ''} onChange={handleOnChange} />
            </FormGroup>

            <FormGroup className="flex-shrink-1">
              <Label for="method">Max Value</Label>
              <Input type="number" bsSize="sm" className="teeny" name="max" value={max || ''} onChange={handleOnChange} />
            </FormGroup>

            <div className="ms-3">
              <FormText>Leave blank to allow any value</FormText>
            </div>

          </div>

        </div>
        )}

        {StaticChoiceInputTypes.includes(inputType) && (
          <FormGroup className="flex-shrink-1">
            <Label for="method">Choices</Label>
            <Input
              type="textarea"
              name="choices"
              rows={5}
              value={choices}
              onChange={handleOnChange}
              invalid={!!errors['options.choices']}
            />
            <FormFeedback>{errors['options.choices']}</FormFeedback>
            <FormText>
              Add 1 choice per line. Delimit values and labels with a colon (
              <i>value:label</i>
              )
            </FormText>
          </FormGroup>
        )}

        {DynamicChoiceInputTypes.includes(inputType) && (
          <FormGroup className="flex-shrink-1">
            <Label for="method">Dynamic Choices</Label>
            <FormText>Select from dynamic source at left. Values are from database tables.</FormText>
          </FormGroup>
        )}

      </div>

      {/* Probably don't show this if input_type is expression, maybe others */}
      <OptionTarget {...target} onChange={handleOnChangeTarget} />
    </>
  );
};

export default Options;
