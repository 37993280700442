import React, { useMemo } from 'react';
import classnames from "classnames";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';

const EligibilityNode = React.forwardRef((
  {
    eligibility,
    sourceId,
    channelId,
    currentHover,
    currentSelection,
    onMouseEnter,
    onMouseLeave,
    onOpenRules,
    onSelect,
    reconnect
  }:any,
  ref:React.LegacyRef<HTMLDivElement>
) => {

  if (!eligibility) return null;


  const handleOpenRules = (e: any) => {
    e.stopPropagation();
    onOpenRules(eligibility, sourceId, channelId);
  }


  const isRelated = useMemo(()=>{
    if (currentHover) {
      if (currentHover?.object === "eligibility") {
        return currentHover.id === `${sourceId}-${channelId}`;
      } else  if (currentHover?.object === "channel" && currentHover.channels) {
        return currentHover.channels.includes(channelId.toString());
      } else if (currentHover?.object === "source" && currentHover.sources ) {
        return currentHover.sources.includes(sourceId.toString());
      } else if (currentHover?.object === "order" && currentHover.channels) {
        return currentHover.channels.includes(channelId.toString());
      }
    }

    return false;
  }, [currentHover])

  const isSelected = useMemo(()=>{
    if (currentSelection) {
      if (currentSelection?.object === "eligibility") {
        return currentSelection.id === `${sourceId}-${channelId}`;
      } else if (currentSelection.object === "channel" && currentSelection.channels) {
        return currentSelection.channels.includes(channelId.toString());
      } else if (currentSelection?.object === "source" && currentSelection.sources ) {
        return currentSelection.sources.includes(sourceId.toString());
      } else if (currentSelection?.object === "order" && currentSelection.channels) {
        return currentSelection.channels.includes(channelId.toString());
      }
    }

    return false;
  }, [currentSelection])

  const compositeId = `${sourceId}-${channelId}`

  return (
    <div
      className={classnames("node-row eligibility", {related: isRelated, selected: isSelected} )}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
      data-id={compositeId}
      data-object="eligibility"
    >
      <div
        className="node-pod"
      >
        <div className="node-pod-header">
          <div className="node-pod-title">
            <div>
              Distribution: {eligibility.distribution ? `${eligibility.distribution}%`: "open"}
              {eligibility.fixed_distribution && <span> (fixed)</span>}
            </div>

            <div className="actions">
              <Button color="link" size="sm" onClick={handleOpenRules} title="View/Edit routing rule">
                <FaIcon icon="clipboard-list" size="sm"/>
              </Button>
            </div>
          </div>
        </div>

      </div>

      <div className="node-pod-footer d-flex">
        <div><label>Match:</label> <RelativeTime date={eligibility.last_matched_at} uniqueId={`eg-${compositeId}-match`} showTimezone={true} /></div>
        <div><label>Sale:</label> <RelativeTime date={eligibility.last_sold_at} uniqueId={`eg-${compositeId}-sale`} showTimezone={true} /></div>
      </div>


    </div>
  )
})

export default EligibilityNode;
