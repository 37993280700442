import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import Configuration from '../../../../Components/Rules/Configuration';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import usePermission from "../../../../Hooks/usePermission";


const SipConfig = ({sip={}, onChange, error}) => {
  const [editMode, setEditMode] = useState(false);
  const [willDelete, setWillDelete] = useState(false);
  const allowEdit = usePermission('orders.edit');
  // We are pretending this is an integration so we can use the
  // integration component
  const configDef = [
    {
      name: 'sip',
      label: 'Sip Configuration',
      type: 'sip',
      supported_settings: [
        { name: 'uri', label: 'URI', input_type: 'text' },
        { name: 'username', label: 'User Name', input_type: 'text' },
        { name: 'password', label: 'Password', input_type: 'text' },
        { name: 'headers', label: 'Headers', input_type: 'keyvalue' },
      ],
    }
  ];

  const handleUpdate = (sip) => onChange(sip);

  const handleAdd = () => {
    if (allowEdit) {
      onChange({ name: 'sip', settings: {} });
      setEditMode(true);
    }

  }

  const handleApply = () => setEditMode(false);

  const handleDelete = () => {
    if (allowEdit) {
      onChange({ });
      setEditMode(false);
      setWillDelete(false);
    }

  };

  const toggleEdit = () => setEditMode(!editMode);

  // "Will Delete" prompt
  // Configuration provides an index number if turning on the prompt
  const handleWillDelete = (val) => setWillDelete(val === 0);

  return (
    <div className="sip-configuration">

      {error
      && (
        <StandardAlert color="info" className="validation">
          {error}
        </StandardAlert>
      )}

      {/* Since this is not a collection of configurations, it's always "new" */}
      {Object.keys(sip).length > 0 || editMode ? (
        <Configuration
          index={0}
          config={sip}
          definitions={configDef}
          type="sip"
          editMode={editMode}
          isNew={!sip.settings || Object.keys(sip.settings).length === 0}
          errors={{}}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          onApply={handleApply}
          setEdit={toggleEdit}
          willDelete={willDelete}
          setWillDelete={handleWillDelete}
          allowControls
          allowEdit={allowEdit}
          showServiceChooser={false}
        />
      ) : (
        <Button color="link" onClick={handleAdd} disabled={!allowEdit}>
          <FaIcon icon="cog" className="me-1" />
          Configure SIP
        </Button>
      )}
    </div>
  );
};

SipConfig.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sip: PropTypes.object.isRequired
};

export default SipConfig;

