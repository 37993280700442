import React, {FC, useEffect, useState} from "react";
import AccountsTable from "./AccountsTable";
import useApi from "../../../Hooks/useApi";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import useFavoriteOrder from "../../../Hooks/useFavoriteOrder";


/*
 * Favorite Orders
 * Shows paged table listing all favorited orders (by account)
 */
const Favorites: FC<any> = ({onStatusChange, openOrderModal, favoriteOrderIds, onFavorite}) => {
  const { api } = useApi(null, null);
  const {addOrder, removeOrder} = useFavoriteOrder();
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [refreshKey, setRefreshKey] = useState(1);

  // load favorite orders on mount or if favorites change
  useEffect(() => {
    load();
  }, [favoriteOrderIds]);

  const load = async (refresh = false) => {
    // Get list of accounts , each with FULL orders collections
    if (!refresh) {
      setLoading(true);
    }


    // high limit to get everything
    const result = await api.endpoints.accounts.list({
      page: 1,
      limit:1000,
      favorite_orders:1
    });

    if (result) {
      const { data } = result;
      // sets subrow (order list)0 to expanded for all
      setList(data.map((a:any) => ({ ...a, expanded: true })));
      setRefreshKey(refreshKey+1)
    } else {
      notify(`Unable to load favorite orders: ${api.error.name}`, 'error');
    }


    if (!refresh) {
      setLoading(false);
    }
  };

  const doFavorite = async (id:number, setAsFavorite:boolean) => {

    let result;

    if (setAsFavorite){
     // result = await api.endpoints.favorites.addOrder(id);
      result = await addOrder(id)
    } else {
      //result = await api.endpoints.favorites.deleteOrder(id);
      result = await removeOrder(id);
    }

    if (result) {
      onFavorite(id);
    }

  }

  return (
    <AccountsTable
      accounts={list}
      view="favorites"
      onStatusChange={onStatusChange}
      onFavorite={doFavorite}
      favoriteOrderIds={favoriteOrderIds}
      openOrderModal={openOrderModal}
      loading={loading}
      key={`favorites-${refreshKey}`}
    />
  )
}

export default Favorites;
