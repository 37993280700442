import React, {ReactNode, useRef} from "react";

type SvgWrapperProps = {
  className?: string;
  children: ReactNode;
}


/**
 * Connect elements with svg paths
 */

const SvgWrapper = ({className="", children}: SvgWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

 // width: wrapperRef.current?.offsetParent?.scrollWidth || "100%",
    //height: wrapperRef.current?.offsetParent?.scrollHeight || "100%",
  return (
    <div
      ref={wrapperRef}
      style={{
        position: "absolute",
        top: 0,

      }}
      className={`svg-connector-wrapper ${className}`}
    >
      {children}
    </div>
  );
}

export default SvgWrapper;
