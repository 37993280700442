import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import useDidMount from './useDidMount';

/*
 * For managing lists where items can be removed or appended, and the items don't have a unique id
 * that can be used for a component key (for example, click urls).
 *
 * We use nanoid to create a unique key, so the array items end up like
 * [ {value: 'whatever', key: 'a32cr0vz' }, ...]
 *
 * Reason: when mapping over a collection and using the map function's index as the component key,
 *    items.map( (item, i) => <Component value={item} key={`item-${i}`} />)
 * unexpected things can happen when removing items. For example, if you have a list of 4 items,
 * and delete the item with the key item-2, the refreshed list will still have an item keyed with item-2
 * (because there are still 3 items) and react will not re-render item-2.
 *
 * If the values are objects you can use 'updateItemProperty' to update
 * a property within the object (will only work for simple objects)
 *
 * Usage:
 * const{
 *   items:keyedClickUrls, //optional: rename (if you are managing more than 1 list)
 *   addItem: addClickUrl,
 *   removeItem: removeClickUrl,
 *   updateItem: updateClickUrl
 * } = useUniqueKeyArray(clickUrls);
 *
 * items.map( ({key, value}, i) => <Component value={value} key={key} />)
 */
const useUniqueKeyArray = (arr = [], updateCallback) => {
  const [items, setItems] = useState(() => arr.map((item) => ({ value: item, key: nanoid(7) })));
  const didMount = useDidMount();


  // Get items without the keys
  const getValues = () => items.map((item) => item.value);

  // Fire optional callback when items change
  useEffect(() => {
    if (didMount && updateCallback) {
      updateCallback(getValues(items));
    }
  }, [items]);

  const addItem = (item = '') => {
    // if addItem function is used in onClick without arguments, event object will be passed. Throw it out.
    if (item && item.target) item = '';
    return setItems([...items, { value: item, key: nanoid(7) }]);
  };

  const addItemBelow = (item = '', index) => {
    const newItems = [...items];
    return setItems(newItems.splice(index, 0, { value: item, key: nanoid(7) }));
  };

  const removeItem = (index) => {
    setItems((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  const updateItem = (index, val) => {
    setItems((prev) => {
      const temp = [...prev];
      temp[index].value = val;
      return temp;
    });
  };

  const updateItemProperty = (index, val, property) => {
    setItems((prev) => {
      const temp = [...prev];
      temp[index].value = { ...temp[index].value, [property]: val };
      return temp;
    });
  };

  return {
    items,
    addItem,
    addItemBelow,
    removeItem,
    updateItem,
    updateItemProperty,
    getValues,
  };
};

export default useUniqueKeyArray;
