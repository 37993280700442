import React, {
  useRef, useEffect, useState, useContext,
} from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { TreeContext } from '../../../Components/Rules/TreeProvider';
import RulesContext from '../../../Providers/RulesContext';

const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const makeNumeric = (bid) => (bid ? Number(bid.toString().replace(/[^0-9\.]/, '')) : 0);

/*
 * Input for editing bid directly in the rule tree.
 * We only assign modifier, absolute, or block (not pass)
 */
const BidEdit = ({
  rule, type, absolute_bid, bid_modifier, exclusivity, setBidEditMode, onBidDelta, onBidWarning,
}) => {
  const rulesContext = useContext(RulesContext);
  const treeContext = useContext(TreeContext);

  // Save original values for comparison
  const [originalBidType] = useState(type);
  const [originalAbsoluteBid] = useState(makeNumeric(absolute_bid));
  const [originalBidModifier] = useState(makeNumeric(bid_modifier));

  // newBid: the string that the user enters, maybe contain % or $
  const [newBid, setNewBid] = useState(getFormattedBid(type, absolute_bid, bid_modifier));
  // derived value based on newBid or original bid type
  const [newType, setNewType] = useState(type);

  const inputEl = useRef(null);

  const updateBid = async () => {
    treeContext.setCurrentBidUpdating(rule.id);
    // const result = await rulesContext.api.updateBid(rule, makeNumeric(newBid), newType);

    const result = await rulesContext.api.updateRule(
      {
        bidding: {
          type: newType,
          bid_modifier: newType === 'modifier' ? makeNumeric(newBid) : '',
          absolute_bid: newType === 'absolute' ? makeNumeric(newBid) : '',
        },
      },
      rule,
      true,
    );
    treeContext.setCurrentBidUpdating(0);
    if (!result) {
      console.log('BidEdit.js:Could not update bid', newBid, result);
    }
  };

  const postChangeBid = () => {
    if (!isBidChanged()) {
      onBidDelta(null);
      onBidWarning(null);
    } else {
      let bid = makeNumeric(newBid);

      if (newType === 'modifier') {
        bid = parseInt(bid);

        if (bid === 100) {
          onBidWarning('Unmodified');
        } else if (bid) {
          // only delta if type has not changed
          if (originalBidType === 'modifier') {
            onBidDelta({ newValue: bid, oldValue: parseFloat(originalBidModifier) });
          } else {
            onBidDelta(null);
          }
          onBidWarning(null);
        }
      } else if (newType === 'absolute') {
        bid = parseFloat(bid);
        if (bid === 0) {
          onBidWarning('Bid disabled');
        } else {
          // only delta if type has not changed
          if (originalBidType === 'absolute') {
            onBidDelta({ newValue: bid, oldValue: parseFloat(originalAbsoluteBid) });
          } else {
            onBidDelta(null);
          }

          onBidWarning(null);
        }
      } else {
        onBidDelta(null);
        if (newType === 'block') {
          onBidWarning('Bidding blocked');
        } else if (newType === 'pass') {
          onBidWarning('No bid');
        }
      }
    }

    // onBidWarning(warning);
  };

  const isBidChanged = () => {
    // console.log('BidEdit.js:isBidChanged, types: from ', originalBidType, ' to:' + newType);
    if (newType === originalBidType) {
      const newBidNum = makeNumeric(newBid);
      // let oldBidNum;
      if (newType === 'modifier') {
        return (parseInt(newBidNum) !== parseInt(originalBidModifier));
      } if (newType === 'absolute') {
        return (parseFloat(newBidNum) !== parseFloat(originalAbsoluteBid));
      }
      // still blocked
      return false;
    }

    return true;
  };

  const handleChangeDebounced = useDebouncedCallback((bid) => {
    const type = deriveType(bid);
  console.log('BidEdit.js:inputEl', inputEl.current);
    if (type) {
      if (exclusivity === 'optional' && type === 'absolute') {
        // Optionally exclusive bid can only be modifier. Revert to modifier value.
        setNewType('modifier');
        setNewBid(bid_modifier);
        inputEl.current.value = getFormattedBid('modifier', absolute_bid, bid_modifier);
        return;
      }

      setNewType(type);
    }

    if (type === 'modifier') {
      if (bid && parseInt(bid) > 500) {
        bid = '500%';
        inputEl.current.value = bid;
      }
    } else if (type === 'absolute') {
      if (bid && parseFloat(bid.replace('$', '')) > 200) {
        bid = '$200';
        inputEl.current.value = bid;
      }
    } else if (isNaN(bid)) {
      bid = '';
      inputEl.current.value = bid;
    }

    // console.log('BidEdit.js:onChange setting new bid: ', bid, ' of type:'+type);
    setNewBid(bid);
  }, 400);

  const deriveType = (bid) => {
    if (bid === '') {
      return 'block';
    } if (bid.indexOf('%') >= 0) {
      return 'modifier';
    } if (parseFloat(bid) === 0) {
      // parseFloat, allow for testing 0.nn
      return 'pass';
    } if (bid.indexOf('$') >= 0 || /\d{0,}\.\d+/.test(bid) || bid === '.') {
      // has dollar sign or n.nn, 0.nn, .nn
      // Or has started bid of less than $ by typing '.'
      return 'absolute';
    } if (isNaN(bid)) {
      return '';
    }

    return originalBidType !== 'block' ? originalBidType : 'absolute';
  };

  const handleBlur = () => setBidEditMode(false);

  const keyHandler = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab' || (event.shiftKey && event.key === 'Tab')) {
      if (isBidChanged()) {
        updateBid();
      }

      setBidEditMode(false);
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      setBidEditMode(false);
    }
  };

  // Focus on mount
  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.select();
    }
  }, []);

  // Key listeners on mount
  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.addEventListener('keydown', keyHandler);
    }

    // unmount
    return () => {
      if (inputEl.current) {
        inputEl.current.removeEventListener('keydown', keyHandler);
      }

    };
  }); /* <- no dependency array, to enclose most recent state */

  // Report bid changes to parent
  useEffect(() => {
    postChangeBid();
  }, [newBid]);

  return (
    <div className="d-flex flex-column justify-content-end align-items-end">

      <div className="rule-bid-input">

        <Input
          type="text"
          innerRef={inputEl}
          size="sm"
          className="teeny"
          defaultValue={newBid}
          onChange={(e) => handleChangeDebounced(e.target.value)}
          onBlur={handleBlur}
          maxLength={6}
        />

        {/* Using MaskedInput. You need to refer to the input ref like this: inputEl.current.inputElement.
              Hard to make this work for both $1.00 and 100%
          */}
        {/* <MaskedInput
            mask={bidMask}
            defaultValue={bid}
            ref={inputEl}
            onBlur={handleBlur}
            render={(maskRef,props) => {
              return (
                  <Input
                    type="text"
                    innerRef={maskRef}
                    size="sm"
                    className="tiny"
                    {...props}
                  />
                )

            }}
            /> */}

      </div>

    </div>
  );
};

const getFormattedBid = (type, absolute_bid, bid_modifier) => {
  let bid = '';
  if (type === 'absolute') {
    if (absolute_bid && !isNaN(absolute_bid)) {
      bid = numberFormat.format(parseFloat(absolute_bid));
    } else {
      bid = absolute_bid;
    }
  } else if (type === 'modifier') {
    if (bid_modifier && !isNaN(bid_modifier) && parseInt(bid_modifier) !== 0) {
      bid = `${bid_modifier}%`;
    } else {
      bid = bid_modifier;
    }
  } else if (type === 'block') {
    bid = '';
  } else if (type === 'pass') {
    bid = '0';
  }

  return bid;
};

BidEdit.propTypes = {
  rule: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  absolute_bid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bid_modifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exclusivity: PropTypes.string,
  setBidEditMode: PropTypes.func,
  onBidDelta: PropTypes.func,
  onBidWarning: PropTypes.func,
};

BidEdit.defaultProps = {
  type: 'pass',
  absolute_bid: '',
  bid_modifier: '',
};

export default BidEdit;
