import React from 'react';
import {
 Button, Form, FormGroup, Label, Input, Row, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';


const MatchFilters = ({ buyers, brokers, filters, onChange, resultCount, total }) => {

  const handleFilterBroker = (e) => onChange({ ...filters, broker: e.target.value });

  const handleFilterBuyer = (e) => onChange({ ...filters, buyer: e.target.value });

  const clearFilters = () => onChange({});

  if (!buyers.length && !brokers.length) {
    return null;
  }

  return (
    <div className="match-filters mb-3">
      <Row className="d-flex align-items-center">
        <Col sm={12}>

          <Row className="align-items-center">
            {buyers.length > 0 && (
              <Col sm={5}>
                <FormGroup row className="form-group align-items-center">
                  <Label sm={2} className="me-2">Buyer</Label>
                  <Col>
                    <Input
                      type="select"
                      value={filters.buyer || ''}
                      name="buyer"
                      onChange={handleFilterBuyer}
                      bsSize="sm"
                    >
                      <option value="">any</option>
                      {buyers.map((buyer, i) => <option value={buyer} key={i}>{buyer}</option>)}
                    </Input>
                  </Col>

                </FormGroup>
              </Col>
            )}

            {brokers.length > 0 && (
              <Col sm={5}>
                <FormGroup row className="form-group ms-3">
                  <Label sm={2} className="me-2">Broker</Label>
                  <Input
                    type="select"
                    value={filters.broker || ''}
                    name="broker"
                    onChange={handleFilterBroker}
                    bsSize="sm"
                  >
                    <option value="">any</option>
                    {brokers.map((broker, i) => <option value={broker} key={i}>{broker}</option>)}
                  </Input>
                </FormGroup>
              </Col>

            )}

            <Col>
              <Button className="inline ms-3" color="link" size="xs" onClick={clearFilters}>Clear Filters</Button>
            </Col>
          </Row>



         </Col>
      </Row>

      {resultCount < total && (
        <div className="filter-count">
          FILTERED: showing {resultCount} of {total} matches
        </div>
      )}

    </div>

  );
};

export default MatchFilters;

MatchFilters.propTypes = {
  brokers: PropTypes.array.isRequired,
  buyers: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  resultCount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}
