import React, {ChangeEvent} from "react";
import {Input} from "reactstrap";
import {DeliveryRejectCategoryChoices} from "../../../Components/Table/FilterChoices";


const RejectionCategorySelect = ({
                                   selected='delivery_timeout',
                                   onChange
}:{selected:string, onChange:(e:ChangeEvent<HTMLInputElement>)=>void}) => {

  return (
    <Input
      type="select"
      bsSize="sm"
      value={selected}
      onChange={onChange}
    >
      {/*<option value="" >All Categories</option>)*/}
      {DeliveryRejectCategoryChoices.map(
        (c:Record<string, string>, i:number) => <option key={i} value={c.value}>{c.label}</option>
      )}

    </Input>
  )
}

export default RejectionCategorySelect;
