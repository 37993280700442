import React from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';

// Display an icon based on boolean status.
// Some of our "booleans" are yes/no
// The value could be null/undefined, for example a call that has not been sold yet,
// so we have the option of interpreting that as false or neither true nor false (showUndetermined)
const BooleanIconCell = ({ value, trueTitle, falseTitle, showUndetermined=true }) => (
    <div className="bool-icon-cell w-100" style={{textAlign: "center"}}>
      {(value && (value === true || value.toString().toLowerCase() === "yes"))
        ? <FaIcon icon="check-circle" size="lg" title={trueTitle} color="green"/>
        : (showUndetermined && (typeof value === 'undefined' || value === null)
          ? <span title="Undetermined">&mdash;</span>
          : <FaIcon icon="times" size="lg" title={falseTitle} color="darkred"/>)}
    </div>
  );

export default BooleanIconCell;
