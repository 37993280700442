import React from 'react';
import {
  Row, Col,
} from 'reactstrap';
import {CapManager} from "@thedmsgroup/mastodon-ui-components";
import PropTypes from 'prop-types';


const CapsFormOptions = {
  intervals: ["total","monthly", "weekly","daily"],
  max: 99999,
}

const BudgetOptions = {
  intervals: ["total","monthly", "weekly","daily"],
  max: 99999,
}

const CapScheduleConfig = {
    defaultValue: null,
    max: 300,
    valueSuffix: "",
    labelInterval: 25,
    lineInterval: 25,
    showBaseline: false,
    dragStep: 1,
    allowOpenValue: true,
    allowHourParting: true,
};

const Budgets = ({
  product,
  budgets,
  caps,
  saleAttemptCaps,
  onChange,
  onChangeSchedule,
  schedule,
  scheduleTimezone,
  scheduleSaleAttemptCaps,
  scheduleSaleAttemptCapsTimezone

}) => {

  const handleChangeCapSchedule = (sched, tz) => {
    onChangeSchedule('schedule_caps', sched, tz);
  };


  const handleChangeCaps = (caps) => {
    onChange('caps', caps);
  }

  // MRK-9151 TBD
  // const handleChangeSaleAttemptCapSchedule = (sched, tz) => {
  //   onChangeSchedule('schedule_sale_attempt_caps', sched, tz);
  // };

  // const handleChangeSaleAttemptCaps = (caps) => {
  //   onChange('sale_attempt_caps', caps);
  // }

  const handleChangeBudget = (caps) => {
    onChange('budgets', caps);
  }


  return (
    <div className="form-section">
      <p>
        Pause the order upon reaching the specified limits. If budget and cap values are both set for a specific timeframe, the order will pause temporarily when one of the
        limits is reached, whichever is reached first.
      </p>

      {product === 'calls' && (
        <p><i>Caps</i> will restrict the number of sold calls. </p>
      )}

      {/* Add to above when sale attempt caps are implemented MRK-9151: <i>Sale Attempt Caps</i> include both billable and unbillable calls.*/}

      <Row className="mt-3 gx-lg-4">
        <Col sm={12} lg={4} className="border-end">
          < CapManager
            caps={caps}
            schedule={schedule}
            scheduleTimezone={scheduleTimezone}
            scheduleConfig={CapScheduleConfig}
            onChange={handleChangeCaps}
            onChangeSchedule={handleChangeCapSchedule}
            label={"Caps"}
            gap="12px"
            options={CapsFormOptions}
          />
        </Col>

{/*  TBD MRK-9151
        {product === 'calls' && (
          <Col sm={12} lg={4} className="border-end">
            < CapManager
              caps={saleAttemptCaps}
              schedule={scheduleSaleAttemptCaps}
              scheduleTimezone={scheduleSaleAttemptCapsTimezone}
              scheduleConfig={CapScheduleConfig}
              onChange={handleChangeSaleAttemptCaps}
              onChangeSchedule={handleChangeSaleAttemptCapSchedule}
              label={"Sale Attempt Caps"}
              gap="12px"
              options={CapsFormOptions}
            />
          </Col>
        )}*/}

        <Col sm={12} lg={4} >
            <CapManager
              caps={budgets}
              onChange={handleChangeBudget}
              label={"Budget"}
              gap="12px"
              options={BudgetOptions}
              inputPrefix="$"
            />
        </Col>


      </Row>
    </div>
  )
};


Budgets.propTypes = {
  product: PropTypes.string,
  budgets: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  caps: PropTypes.object.isRequired,
  onChangeCap: PropTypes.func.isRequired,
  schedule: PropTypes.object,
  scheduleTimezone: PropTypes.string,
  onChangeSchedule: PropTypes.func,
  scheduleSaleAttemptCaps: PropTypes.object,
  scheduleSaleAttemptCapsTimezone:PropTypes.string,
  saleAttemptCaps: PropTypes.object,
};

export default React.memo(Budgets);
