import React from "react";
import {Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import {JsonEditorLoadable} from "@thedmsgroup/mastodon-ui-components";


/*
 * Modal for displaying a schema in read only mode
 */
const InputSchemaViewModal = ({
                           schema, isOpen, cancel
                         }:any) => {


  return (
    <Modal isOpen={isOpen} toggle={cancel} size="xlg" scrollable>
      <ModalHeader toggle={cancel}>View Schema</ModalHeader>
      <div className="modal-subheader">{schema && schema.name}</div>
      <ModalBody>
        {schema ? (
          <JsonEditorLoadable
            code={JSON.stringify(schema.content, null, 2)}
            onChange={()=>{}}
            linting={false}
            isDisabled={true}
          />

        ) : (
          <div className="text-center my-5"><Spinner /></div>
        )}

      </ModalBody>
    </Modal>
  );
};

export default InputSchemaViewModal;
