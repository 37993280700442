import React from 'react';
import {
  Row, Col, FormGroup, Input, Label, Card, CardBody, Button, ButtonGroup,
} from 'reactstrap';
// import {ConvertBoolean} from '../Form/FormCommon';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useDebouncedCallback } from 'use-debounce';
import SimpleSelect from '../Form/SimpleSelect';
import { tokensToString } from '../../utils/string';

const IntegerMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: false,
  integerLimit: 6,
});

const TargetValueForm = (props) => {
  // Debounce used for text input. Because the rule configuration object can be large,
  // updating it with each change can cause typing to lag.
  const onChangeDebounced = useDebouncedCallback((val) => {
    props.onChangeValue(val);
  }, 300);

  return (
    <div className="target-value-form flex-grow-1">
      <FormOfType {...props} onChangeDebounced={onChangeDebounced} />
    </div>
  );
};

const FormOfType = (props) => {
  if (props.attribute) {
    switch (props.attribute.input_type) {
      case 'integer':
        return (<IntegerInput {...props} />);
      case 'integer_range':
        return (<IntegerRange {...props} />);
      case 'text':
        return (<TextInput {...props} />);
      case 'multiple_choice':
      case 'dynamic_multiple_choice':
      case 'state_choice':
        return (Object.keys(props.attribute.options.choices).length >= 10)
          ? (<MultipleChoiceSelect {...props} />)
          : (<MultipleChoiceCheck {...props} />);
      case 'choice':
      case 'dynamic_choice':
        return (<Choice {...props} />);
      case 'boolean':
        return (<BooleanChoice {...props} />);
      case 'zipcode_list':
      case 'county_list':
      case 'list':
        return (<TextAreaInput {...props} />);
      case 'expression':
        return (<TextAreaInput {...props} rows={3} />);
    }
  }

  return (null);
};

/*
 * TargetValueForm
 * A set of components for different types of target attribute value inputs (multiple select, range, etc).
 * (Subcomponent of TargetForm)
 * Note: the onChangeValue function is debounced (TargetForm), so we use defaultValue attribute for inputs instead of 'value',
 * because it is then not directly tied to state.
 */

const IntegerInput = ({ attribute, value = '', onChangeDebounced }) => {
  const label = attribute.options.hasOwnProperty('label') ? attribute.options.label : '';
  return (
    <Row>
      <Col sm={3}>
        <MaskedInput
          mask={IntegerMask}
          min={attribute.options.min}
          max={attribute.options.max}
          defaultValue={value}
          name="value"
          onChange={(e) => onChangeDebounced(e.target)}
          maxLength={6}
          render={(ref, props) => <Input type="text" innerRef={ref} className="teeny inp-int" {...props} />}
        />
      </Col>
      <Col>
        <span>
          {' '}
          {label}
        </span>
      </Col>
    </Row>
  );
};

const IntegerRange = (props) => (
  <div className="form-inline integer-range d-flex align-items-center">
    <div className="mb-2 me-sm-2 mb-sm-0">
      <MaskedInput
        mask={IntegerMask}
        min={props.attribute.options.min}
        max={props.attribute.options.max}
        defaultValue={isNaN((props.value || {}).from) ? '' : props.value.from}
        name="from"
        onChange={(e) => props.onChangeDebounced(e.target)}
        render={(ref, props) => <Input type="text" innerRef={ref} className="teeny inp-int" {...props} />}
      />
    </div>
    <Label for="input-range-max" className="me-sm-2">to</Label>
    {/* defaultValue={(props.value || {}).to || ""} */}
    <div className="mb-2 me-sm-2 mb-sm-0">

      <MaskedInput
        mask={IntegerMask}
        min={props.attribute.options.min}
        max={props.attribute.options.max}
        defaultValue={isNaN((props.value || {}).to) ? '' : props.value.to}
        name="to"
        onChange={(e) => props.onChangeDebounced(e.target)}
        render={(ref, props) => <Input type="text" innerRef={ref} className="teeny inp-int" {...props} />}
      />
    </div>
  </div>
);

// This is the version that works with the IntegerRangeType dropdown
// It still needs work to cover some edge cases
// Example:   {"attribute":"drivers.num_accidents_at_fault","matches":false,"target":"primary","value":{"from":1,"to":null}},
//     This must mean "no accidents"  (not 1 to null)
//    But in new IntegerRange, resulting in '>= 1' because not taking into account the 'matches=false'
//
// or : {"attribute":"drivers.num_tickets","matches":false,"accept_unknown":true,"target":"primary","value":{"from":2,"to":null}},
// This must mean "tickets not greater than 2)   (not 2 to null)
//  BUt in new IntegerRange, resulting in '>= 2' because not taking into account the 'matches=false'
//
// const IntegerRange = ({attribute, value, onChangeValue, integerRangeType}) => (
//     <div className="form-inline">
//       {(integerRangeType === 'between' ||integerRangeType === 'not_between') && (
//         <React.Fragment>
//           <FormGroup  className="mb-2 me-sm-2 mb-sm-0">
//             <Input type="number"
//                    className="teeny"
//                    min={attribute.options.min}
//                    max={attribute.options.max}
//                    value={value.from || ""}
//                    name="from"
//                    onChange={onChangeValue}
//             />
//           </FormGroup>
//
//           <FormGroup  className="mb-2 me-sm-2 mb-sm-0" >
//             <Label for="to" className="me-sm-2">and</Label>
//             <Input type="number"
//                    className="teeny"
//                    min={attribute.options.min}
//                    max={attribute.options.max}
//                    value={value.to || ""}
//                    name="to"
//                    onChange={onChangeValue}
//             />
//           </FormGroup>
//           <FormText> (inclusive)</FormText>
//         </React.Fragment>
//       )}
//
//       {integerRangeType === 'greater_than'  && (
//         <FormGroup  className="mb-2 me-sm-2 mb-sm-0">
//           <Input type="number" id="input-range-min"
//                  className="teeny"
//                  min={attribute.options.min}
//                  max={attribute.options.max}
//                  value={value.from || ""}
//                  name="from"
//                  onChange={onChangeValue}
//           />
//         </FormGroup>
//       )}
//
//       {integerRangeType === 'less_than'  && (
//         <FormGroup  className="mb-2 me-sm-2 mb-sm-0">
//           <Input type="number" id="input-range-max"
//                  className="teeny"
//                  min={attribute.options.min}
//                  max={attribute.options.max}
//                  value={value.to || ""}
//                  name="to"
//                  onChange={onChangeValue}
//           />
//         </FormGroup>
//       )}
//
//       {integerRangeType === 'not_set'  && (null)}
//
//     </div>
// );

const TextAreaInput = React.memo(({ value, onChangeDebounced, rows = 5 }) => (
  <div>
    <Input
      type="textarea"
      defaultValue={tokensToString(value) || ''}
      name="value"
      rows={rows}
      onChange={(e) => onChangeDebounced(e.target)}
    />
  </div>
));

const TextInput = React.memo(({ value, onChangeDebounced }) => (
  <Row>
    <Col>
      <Input
        type="text"
        defaultValue={value || ''}
        name="value"
        onChange={(e) => onChangeDebounced(e.target)}
      />
    </Col>

  </Row>

));

const MultipleChoiceCheck = ({ attribute, value=[], onChangeValue }) => {
  if (!attribute.options.hasOwnProperty('choices')) return (<span>No choices have been defined!</span>);
  const arrChoices = Object.entries(attribute.options.choices);

  if (!arrChoices.length) return (<span>No choices have been defined!</span>);

  const checkAll = () => { onChangeValue(Object.keys(attribute.options.choices)); };
  const checkNone = () => { onChangeValue([]); };


  // Note: choice keys might be numeric, but selection is string (watch out when comparing)
  return (
    <Card className="target-value-options-card">
      <div className="batch-check">
        <Button color="link" onClick={checkAll} size="xs" className="me-2">
          All
        </Button>
        <Button color="link" onClick={checkNone} size="xs" className="me-2">
          None
        </Button>
      </div>

      <CardBody>
        <div>
          {arrChoices.map(([choiceVal, label], i) => (
            // Set key with attribute alias so checked status does not carry over
            // when switching between components of this type

              <div key={`${attribute.alias}-${i}`}>
                <Label>
                  <Input
                    type="checkbox"
                    name={`${attribute.alias}-value`}
                    value={choiceVal}
                    checked={Array.isArray(value) ? Boolean(value.find(v =>  v.toString() === choiceVal.toString())) : value === choiceVal}
                    onChange={onChangeValue}
                  />
                  {label}
                </Label>
              </div>
            )
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const MultipleChoiceSelect = ({ attribute, value, onChangeValue }) => {
  if (!attribute.options.hasOwnProperty('choices')) return (<span>No choices have been defined!</span>);
  const arrChoices = Object.entries(attribute.options.choices).map(([value, label]) => ({ value, label }));
  if (!arrChoices.length) return (<span>No choices have been defined!</span>);
  const selectedValues = Array.isArray(value) ? value.map((v) => v.toString()) : [];

  return (
    <div className="multi-choice-select">
      <SimpleSelect
        defaultValue={selectedValues}
        options={arrChoices}
        controlShouldRenderValue
        isMulti
        isSearchable
        onChange={onChangeValue}
        placeholder={`Select ${attribute.label}`}
      />
    </div>
  );
};

const Choice = React.memo(({ attribute, value, onChangeValue }) => {
  if (!attribute.options.hasOwnProperty('choices')) return (<span>No choices have been defined!</span>);
  const arrChoices = Object.entries(attribute.options.choices);
  if (!arrChoices.length) return (<span>No choices have been defined!</span>);

  // Note: value comparison not strict in default checked so we can handle 2 == "2" wewqer

  return (
    <Card className="target-value-options-card">
      {/* <CardHeader>{props.attribute.label} Options</CardHeader> */}
      <CardBody>
        {arrChoices.map((opt, i) => (
          <div key={i}>
            <Label>
              <Input
                type="radio"
                name="value"
                value={opt[0]}
                defaultChecked={value == opt[0]}
                onChange={onChangeValue}
                className="me-1"
              />
              {opt[1]}
            </Label>
          </div>
        )) }

      </CardBody>
    </Card>
  );
});

// Use reactstrap "switch" for boolean?
// <CustomInput type="switch" id="bool-target" defaultChecked={value} value={value} name="value" label={value ? 'Yes':'No'} onClick={onChangeValue} />
// TargetForm needs value = el.checked;
// This is small and can't be sized through props.
// Need custom styles on label :before and :after
// Also, might need unique id (if multiple on page)
const BooleanChoice = ({ value, onChangeValue }) => (
  <ButtonGroup className="yes-no">

    <Button
      color={value ? 'success' : 'secondary'}
      type="button"
      name="value"
      value
      onClick={onChangeValue}
      outline
      className="me-1"
    >
      Yes
    </Button>

    <Button
      color={!value ? 'danger' : 'secondary'}
      type="button"
      name="value"
      value={false}
      onClick={onChangeValue}
      outline
    >
      No
    </Button>

  </ButtonGroup>
);

TargetValueForm.propTypes = {
  attribute: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  // integerRangeType:PropTypes.string
};

export default TargetValueForm;
