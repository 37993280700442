import React, { useEffect, useState, useMemo } from 'react';
import {
  Card,  Button, Row, Col
} from 'reactstrap';
import {Link} from 'react-router-dom';
import PageSection from '../../../../Layout/PageSection';
import DocTitle from '../../../../Layout/DocTitle';
import useTableState from "../../../../Hooks/useTableState";
import useApi from '../../../../Hooks/useApi';
import RuleSearchApi from "../RuleSearchApi";
import {TargetGroupsDescription} from "../../../../Components/Rules/TargetDescription";
import FilterBar from "../../../../Components/Table/FilterBar";
import Filters from "./Filters";
import {titleCase, singularOrPlural} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import {Stack, RowDivider} from "@thedmsgroup/mastodon-ui-components";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import StatusDot from "../../../../Components/Icons/StatusDot";
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';
import LoaderDots from "../../../../Layout/LoaderDots";

const RuleSearch = new RuleSearchApi();

const DefaultFilters = {
}

const SearchByTarget = () => {
  const {api} = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([])
  const [attributes, setAttributes] = useState([])
  const {
    filters, setFilters,  query
  } = useTableState(
    DefaultFilters,
    {},
    { useQueryString: true, useTableControls: false },
  );

  useEffect(() => {

      if (query && !isLoading) {
        load();
      }
  }, [query]);

  const load = async() => {
    setIsLoading(true);

    //First load attributes needed for display
    if (!attributes.length) {
      const attrByGroup = await api.endpoints.attributes.list({
        options: true, scope: 'routing',
      });
      if (attrByGroup) {
        // Ungroup the attributeGroup data and store in attributes
        const attr = {};
        Object.entries(attrByGroup).map((group) => {
          const groupName = group[0];
          return group[1].map((a) => attr[a.alias] = { ...a, groupName });
        });
        setAttributes(attr);
      }
    }

    //Load search results
    const result = await RuleSearch.list(filters);
    if (result) {
      setList(result)
    }
    setIsLoading(false);
  }


  return (
    <>
      <DocTitle pageTitle="Rule Search" />

      <FilterBar>
        <Filters filters={filters} onChange={setFilters} onRefresh={() => load()} />
      </FilterBar>

      <PageSection
        title="Rule Search"
        subtitle="by Target Attribute"
        className="rule-search-page"
      >

      <div className="rule-search-container">



        {isLoading ? (
          <div className="mt-5">
            <LoaderDots active={isLoading} width={120} />
          </div>
        ) : (
          <>
            {filters.attribute && list.length === 0 && (
              <StandardAlert color="light" icon="alert" className="mb-0">No rules were found</StandardAlert>
            )}
            {!filters.attribute && (
              <StandardAlert color="light" icon="alert" className="mb-0">Choose a rule targeting attribute</StandardAlert>
            )}

            {list.length > 0 && (
              <Row>
                <Col sm={12} md={9} lg={8}>
                  {list.map((item, i) => (
                    <RuleSearchResult
                      data={item}
                      attributes={attributes}
                      filteredAttribute={filters.attribute}
                      key={i}
                    />
                  ))}
                </Col>

              </Row>
            )}

          </>
        )}

      </div>
      </PageSection>
    </>
  )
}

export default SearchByTarget

// const RuleTypeDisplayName = {
//   order: 'Order',
//   channel: 'Channel Routing',
//   source: 'Source Routing',
//   'vendor': 'Vendor Routing'
// };

const RuleSearchResult = ({data, attributes, filteredAttribute}) => {
  const [expanded, setExpanded] = useState (false);
  const {type, resource, account, rule_matches} = data;

  const toggleExpanded = () => setExpanded(!expanded);

  const entityLink = useMemo(() => {
    switch (type) {
      case 'order':
        return `/accounts/${account.id}/order/${resource.id}/rules`;
      case 'source':
        return {
          pathname: '/publishing/sources',
          search: `search=${resource.id}`
        }
      case 'channel':
        return {
          pathname: '/publishing/channels',
          search: `search=${resource.id}`
        }
      case 'vendor':
        return {
          pathname: '/publishing/vendors',
          search: `name=${resource.name}`
        }
    }
  }, [type])

  return (
    <Card className="rule-search-result mb-3 p-1">

        <div className="d-flex">
          <div className="type-col me-3">
            <div className={`type ${type}`}>
              {titleCase(type)}
            </div>
          </div>
          <div className="data-col flex-fill">
              <div className="title d-flex align-items-center justify-content-between">

                <Button
                  tag={Link}
                  color="link"
                  className="inline"
                  to={entityLink}
                  title={`View ${type}`}
                  target="_blank"
                >
                  {resource.name}
                </Button>
                {resource.status && (
                  <div>
                    {' '}<StatusDot status={resource.status} /> {titleCase(resource.status)}
                  </div>
                )}

              </div>
              {/* probably don't need subtitle for vendor */}
              <div className="subtitle">
                {type === 'order' && account && <span>{account.name} | </span>}
                {type !== 'vendor' && <span>{resource.vertical.display_name} | </span>}
                {type !== 'vendor' && <span>{titleCase(resource.product)}</span>}
              </div>
            </div>

        </div>

      <div className="d-flex mt-1">
          <div className="type-col me-3">
            &nbsp;
          </div>

          <div className="data-col flex-fill">
            <Button
              color="link"
              className="inline"
              onClick={toggleExpanded}
              >
              {rule_matches.length} {singularOrPlural(rule_matches.length, 'Rule', 'Rules')}
              <span className="ms-1">
                <FaIcon icon={expanded ? "chevron-circle-down" : "chevron-circle-right"} size='sm' style={{color:"#98a6ad"}} />
              </span>
            </Button>

            {expanded && (
              <div className="rule-matches mt-1">
                <Stack divider={<RowDivider/>} >
                  {rule_matches && rule_matches.map((match,i) => (
                    <div className="match" key={i}>
                      <div className="mb-1">
                        <RuleLink
                          type={type}
                          resource={resource}
                          account={account}
                          rule={match}
                        />
                      </div>

                      <TargetingSummary
                        attributes={attributes}
                        conditions={match.match_conditions}
                        expression={match.match_expression}
                        highlightAttribute={filteredAttribute}
                      />
                    </div>
                  ))}
                </Stack>

              </div>
            )}

          </div>
      </div>

    </Card>
  )
}

const TargetingSummary = ({conditions, expression, attributes, highlightAttribute}) => {
  const [expressionMode, setExpressionMode] = useState(false);
  return(
    <div className="targeting-summary ms-2">
      { expressionMode === false ? (
        <TargetGroupsDescription groups={conditions} attributes={attributes} highlightAttribute={highlightAttribute} />
      ) : (<div className="dont-break-out">{expression}</div>)}

      { expression && (
        <div id="expression-toggler">
          <Button color="link" className="inline" size="xs" onClick={() => setExpressionMode(!expressionMode)}>
            [
            {expressionMode === true ? 'Description' : 'Expression'}
            ]
          </Button>
        </div>
      )}
    </div>
  )
}
const RuleLink = ({type, resource, account, rule}) => {
  const ruleLink = useMemo(() => {
    switch (type) {
      case 'order':
        return {
          pathname: `/accounts/${account.id}/order/${resource.id}/rules`,
          search: `tab=targeting&rule=${rule.id}`
        };
      case 'source':
        return {
          pathname: `/routing/source/${resource.id}/rules`,
          search: `tab=targeting&rule=${rule.id}`
        }
      case 'channel':
        return {
          pathname: `/routing/channel/${resource.id}/rules`,
          search: `tab=targeting&rule=${rule.id}`
        }
      case 'vendor':
        return {
          pathname: `/routing/vendor/${resource.id}/rules`,
          search: `tab=targeting&rule=${rule.id}`
        }
    }
  }, [])

  return (
    <Link
      target="_blank"
      className="link"
      to={ruleLink}
      title="View rule"
    >
      {rule.label}
    </Link>
  )
}
