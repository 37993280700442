import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { StackedFilter } from '../../../../Components/Table/Filter';
import { ProductChoices, StatusChoices } from '../../../../Components/Table/FilterChoices';
import RightDateAndControls from '../../../../Components/Table/RightDateAndControls';
import useApiBatch from '../../../../Hooks/useApiBatch';
import TargetSelect from "../../../../Components/TargetingManager/TargetSelect";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import FilterSummaryTags from "../../../../Components/Table/FilterSummaryTags";
import {useFilters} from "../../../../Hooks/useFilters";

const Filters = ({ filters, onChange, loading, onRefresh }) => {
  const {onChangeFilter, clearFilter, reset} = useFilters(filters, onChange);
  const [choices, setChoices] = useState({});


  const batcher = useApiBatch();

  // load choices
  useEffect(() => {
    const getChoices = async () => {
      const batches = await batcher.fetch([
        { endpoint: 'verticals', action: 'list' },
        { endpoint: 'attributes', action: 'list', params:{options:true, scope:'routing'} },
      ]);
      if (batches && batches.length) {
        const [verticals, attributes] = batches;
        setChoices({
          type: [
            {value:'order', label:'Order Targeting'},
            {value:'source', label:'Source Routing'},
            {value:'channel', label:'Channel Routing'},
            {value:'vendor', label:'Vendor Routing'},
          ],
          vertical: verticals.map((item) => ({ value: item.id.toString(), label: item.display_name })),
          attribute: attributes,
          status: StatusChoices,
          product: ProductChoices,
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);

  const getLabelForTag = {
    attribute:(value, choices) => {
            let label = '';
              if (value && choices) {
                Object.entries(choices).forEach( ([groupName, group]) => {
                  const attr = group.find(attr => attr.alias === value);
                  if (attr) {
                    label = attr.label;
                  }
                })
              }
              return label;
            }
  }


  return (
  <>
    <div className="attribute-filters d-flex justify-content-between position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />

      <div className="d-flex">
        {/* Not sure if we are going to do text search
        <div className="boxed-filter search-filter me-3">
          <TextSearch
            value={filters.search || ''}
            placeholder="Search"
            onUpdate={(val) => onChangeFilter('search', val)}
            onClear={clearSearch}
            delay={500}
          />
        </div>*/}

        <div className="new-boxed-filter min-250 me-3">

            <TargetSelect
              value={filters.attribute}
              onChange={(val) => onChangeFilter('attribute', val)}
              attributeGroups={choices.attribute}
            />


        </div>

        <UncontrolledDropdown  className="simple-filters-dropdown me-2">

          <DropdownToggle  caret className="boxed-filter new-boxed-filter filter-group-dropdown">
            <FaIcon icon="filter" size="1x" className="fa-icons" />
          </DropdownToggle>

          <DropdownMenu className="select-filters-dropdown animate dropDown">
            <div className="modal-body">
              <StackedFilter
                placeholder="Any Type"
                label="Rule Type"
                param="type"
                options={choices.type || []}
                value={filters.type || ""}
                onChange={onChangeFilter}
                isMulti={false}
              />

              <StackedFilter
                placeholder="Any Vertical"
                label="Vertical"
                param="vertical"
                options={choices.vertical || []}
                value={filters.vertical || ""}
                onChange={onChangeFilter}
                isMulti={false}
              />
              <StackedFilter
                placeholder="Any Product"
                label="Product"
                param="product"
                options={choices.product || []}
                value={filters.product || ""}
                onChange={onChangeFilter}
                isMulti={false}
              />
              <StackedFilter
                placeholder="Any Status"
                label="Status"
                param="status"
                options={choices.status || []}
                value={filters.status || []}
                onChange={onChangeFilter}
                isMulti={true}
              />
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>

      </div>

      <RightDateAndControls onRefresh={onRefresh} onReset={reset} />

    </div>

    <FilterSummaryTags
      choices={choices}
      filters={filters}
      filterKeys={['attribute', 'type', 'product', 'status', 'vertical']}
      onRemoveFilter={clearFilter}
      onClear={reset}
      isLoading={loading}
      isPendingApply={false}
      fnGetLabel={getLabelForTag}
    />
  </>
  );
};

export default Filters;
