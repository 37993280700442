import React, { useRef, useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import RulesContext from '../../../../Providers/RulesContext';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import Configuration from '../../../../Components/Rules/Configuration';
import useCollectionTracker from '../../../../Hooks/useCollectionTracker';
// import {useDebouncedCallback} from "use-debounce";
import usePermission from '../../../../Hooks/usePermission';

const Prefill = ({ integration, errors = {}, onChange }) => {
  const allowEdit = usePermission('integrations.edit');
  const rulesContext = useContext(RulesContext);
  const definitionsRef = useRef(rulesContext.api.getIntegrationDefinitionsByType('prefill'));

  const {
    api: collection,
    willDeleteIndex,
    editIndex,
    addMode,
  } = useCollectionTracker();

  const handleUpdateIntegration = (intg) => onChange({ integration: intg });

  const handleDelete = () => {
    collection.onDelete();
    onChange({});
  };

  const handleAdd = () => {
    collection.add(0);
    onChange({
      integration: { name: '', settings: {} },
    });
  };

  // const handleChangeTemplateDebounced = useDebouncedCallback( (template) =>{
  //   onChange({integration, template})
  // }, 700);

  // MST-979 if user has no permission to edit integrations: hide if none exist, show read-only if integration exists
  const hasIntegration = integration && Object.keys(integration).length > 0;
  if (!allowEdit && !hasIntegration) {
    return (null);
  }

  return (
    <div className="form-section">
      <div className="form-section-header">Prefill Integrations</div>
      <p>Configure integration with external systems such as CRMs</p>

      {definitionsRef.current.length === 0 && <StandardAlert color="light" icon="alert">No integration definitions found for product and vertical</StandardAlert>}

      <Row>
        <Col lg={12} xl={9}>

          {hasIntegration ? (
            <Row>
              <Col>
                {!allowEdit && <StandardAlert color="light" className="mb-0">Managing this setting requires additional permissions.</StandardAlert>}

                <Configuration
                  config={integration}
                  definitions={definitionsRef.current}
                  type="prefill"
                  editMode={editIndex === 0}
                  isNew={addMode}
                  errors={errors}
                  onUpdate={handleUpdateIntegration}
                  onDelete={handleDelete}
                  onApply={collection.apply}
                  setEdit={collection.edit}
                  allowEdit={allowEdit}
                  willDelete={willDeleteIndex === 0}
                  setWillDelete={(i) => collection.setWillDeleteIndex(i)}
                  allowControls={allowEdit}
                />
              </Col>
            </Row>
          ) : (
            <>
              {allowEdit && (
                <Button color="link" onClick={handleAdd} disabled={editIndex !== null}>
                  <FaIcon icon="plus" />
                  {' '}
                  Add Integration
                </Button>
              )}
            </>
          )}

        </Col>
      </Row>

    </div>

  );
};

Prefill.propTypes = {
  onChange: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired,
  errors: PropTypes.object,
};
Prefill.defaultProps = {
  integration: {},
};
export default Prefill;
