import React, { useContext } from 'react';
import {
  Collapse, Navbar, NavbarBrand, Nav, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem,
} from 'reactstrap';
import {
  useLocation, Link,
} from 'react-router-dom';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import AccountMegaMenu from '../Components/AccountMegaMenu';
import usePermission from '../Hooks/usePermission';
import { AppContext } from '../Providers/AppProvider';
import logoImage from '../images/DMS_logo_mastodon_white.svg';
// import AppSelect from './AppSelect';


const Header = () => {
  const location = useLocation();
  const appContext = useContext(AppContext);
  // const[settingsModalOpen, setSettingsModalOpen] = useGlobal('mySettingsModal');
  const allowAccountsView = usePermission('accounts.view');
  const allowViewAdvReports = usePermission('advertiser_reports.view');
  const allowViewPubReports = false; //usePermission('vendor_reports.view'); //MRK-8107 disable
  const allowViewCalls = usePermission('calls.view');
  const allowViewLeads = usePermission('leads.view');
  const allowPartners = usePermission('partners.view');
  const allowChannels = usePermission('channels.view');
  const allowSources = usePermission('sources.view');
  const allowChanges = usePermission('changes.view');
  const allowRuleSearch = usePermission('rules.search.view');
  const allowPhoneNumbers = usePermission('phone_numbers.view');
  const allowDBA = usePermission('dbas.edit');
  const allowDNC = usePermission('dnc.edit');
  const allowIntegrations = usePermission('integrations.edit');
  const allowInputSchema = usePermission('input_schema.edit');
  const allowAttributes = usePermission('attributes.edit');
  const allowAuctions = usePermission('auctions.view');
  const allowVendors = usePermission('vendors.view');
  const allowVerticals = usePermission('verticals.edit');
  const allowPublisherPortal = appContext.vendor_id !== null;

  const allowTools = allowPartners || allowDNC || allowIntegrations || allowInputSchema || allowVerticals || allowChanges || allowAttributes || allowAuctions;

  const openProfileModal = () => appContext.dispatch({ type: 'global_modal', value: 'profile' });
  const openPasswordModal = () => appContext.dispatch({ type: 'global_modal', value: 'password' });

  return (
    <header className="header-main">
      <Navbar className="main-navbar" expand="md" fixed="top">

        { allowAccountsView === true ? (
          <NavbarBrand tag={Link} to="/accounts">
            <img src={logoImage} width="160" alt="Mastodon: powered by DMS" />
          </NavbarBrand>
        ) : (
          <NavbarBrand>
            <img src={logoImage} width="160" alt="Mastodon: powered by DMS" />
          </NavbarBrand>
        )}


          <Collapse navbar className="navbar-light">

            <Nav className="ms-auto" navbar>

              { (allowAuctions) && (
                <UncontrolledDropdown nav>
                  <DropdownToggle nav caret className={classnames({ active: location.pathname.indexOf('dashboard') > 0 })}>Dashboard</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to="/dashboard/calls">Calls</DropdownItem>
                    {allowPublisherPortal &&
                      <DropdownItem tag={Link} to="/dashboard/publishers">Publishers</DropdownItem>}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              <AccountMegaMenu />

              { (allowChannels || allowSources || allowVendors || allowPhoneNumbers) && (
                <UncontrolledDropdown nav>
                  <DropdownToggle nav caret className={classnames({ active: location.pathname.indexOf('publishing') > 0 })}>Publishing</DropdownToggle>
                  <DropdownMenu end>
                    {allowChannels && <DropdownItem tag={Link} to="/routing">Routing Map</DropdownItem>}
                    {allowChannels && <DropdownItem tag={Link} to="/publishing/channels">Channels</DropdownItem>}
                    {allowSources && <DropdownItem tag={Link} to="/publishing/sources">Sources</DropdownItem>}
                    {allowPhoneNumbers && <DropdownItem tag={Link} to="/publishing/sources/phone-numbers">Phone Numbers</DropdownItem>}
                    {allowVendors && <>
                      <DropdownItem tag={Link} to="/publishing/vendors">Vendors</DropdownItem>
                      <DropdownItem tag={Link} to="/publishing/source-tokens">Source Tokens</DropdownItem>
                    </>}
                    {/* When we do routes: <DropdownItem tag={Link} to="/publishing/routes" >Routes</DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              { (allowViewPubReports || allowViewAdvReports || allowViewCalls || allowViewLeads) && (
                <UncontrolledDropdown nav>
                  <DropdownToggle nav caret className={classnames({ active: location.pathname.indexOf('reports') > 0 })}>Reports</DropdownToggle>
                  <DropdownMenu>
                    {allowViewAdvReports && <DropdownItem tag={Link} to="/reports/advertiser">Advertiser Report</DropdownItem>}
                    {allowViewPubReports && <DropdownItem tag={Link} to="/reports/publisher">Publisher Report</DropdownItem>}
                    {allowViewCalls && <DropdownItem tag={Link} to="/reports/calls">Calls</DropdownItem>}
                    {allowViewLeads && <DropdownItem tag={Link} to="/reports/leads">Leads</DropdownItem>}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {allowTools && (
                <UncontrolledDropdown nav>
                  <DropdownToggle nav caret className={classnames({ active: (location.pathname.indexOf('tools') > 0 || location.pathname.indexOf('changes') > 0) })}>Tools</DropdownToggle>
                  <DropdownMenu end>
                    {allowAuctions && <DropdownItem tag={Link} to="/auctions">Auctions</DropdownItem> }
                    {allowChanges && <DropdownItem tag={Link} to="/changes">Change Log</DropdownItem> }
                    {allowAuctions && <DropdownItem tag={Link} to="/deliveries">Deliveries</DropdownItem> }
                    {allowAuctions && <DropdownItem tag={Link} to="/deliveries/queue">Delivery Queue</DropdownItem> }
                    {allowDBA && <DropdownItem tag={Link} to="/tools/dba">DBAs</DropdownItem> }
                    {allowDNC && <DropdownItem tag={Link} to="/tools/dnc">DNC/Opt-out</DropdownItem> }
                    {allowPartners && <DropdownItem tag={Link} to="/tools/partners">DMS Partners</DropdownItem> }
                    {allowInputSchema && <DropdownItem tag={Link} to="/tools/inputschema">Input Schema</DropdownItem> }
                    {allowIntegrations && <DropdownItem tag={Link} to="/tools/integrations">Integrations</DropdownItem> }
                    {allowIntegrations && <DropdownItem tag={Link} to="/tools/matching">Match Tool</DropdownItem>}
                    {allowRuleSearch && <DropdownItem tag={Link} to="/tools/rules">Rule Search</DropdownItem>}
                    {allowAttributes && <DropdownItem tag={Link} to="/tools/attributes">Targeting Attributes</DropdownItem> }
                    {allowVerticals && <DropdownItem tag={Link} to="/verticals">Verticals</DropdownItem> }
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}


              {appContext.user && (
                <UncontrolledDropdown nav id="user-control">
                  <DropdownToggle nav>
                    <FaIcon icon="user-circle" size="1x" className="me-1"/>
                    {appContext.user.first_name}
                  </DropdownToggle>
                  <DropdownMenu end>

                    <DropdownItem onClick={() => appContext.logout()}>Log Out</DropdownItem>
                    {/* <DropdownItem tag={Link} to="/settings/me">My Settings</DropdownItem> */}
                    {/* <DropdownItem tag={Button}  color="link" onClick={()=>setSettingsModalOpen('profile')} >My Profile</DropdownItem>
                                          <DropdownItem tag={Button}  color="link" onClick={()=>setSettingsModalOpen('password')} >Change Password</DropdownItem> */}
                    <DropdownItem  onClick={openProfileModal}>My Profile</DropdownItem>
                    <DropdownItem  onClick={openPasswordModal}>Change Password</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

             {/* Removing until we get single sign-on across platforms
             <NavItem>
                <AppSelect />
              </NavItem>*/}



            </Nav>
          </Collapse>

      </Navbar>

    </header>

  );
};

export default Header;
