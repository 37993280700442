import React from 'react';
import {
  FormFeedback, FormGroup, Input, FormText, InputGroup, Label, Row, Col,
} from 'reactstrap';
import {HelpPopper}  from '@thedmsgroup/mastodon-ui-components';

const OptionTarget = ({
  choices,
  label,
  errors,
  onChange,
}) =>
  // Target has choices and label  (separate component?)
  (
    <div className="form-section">
      <div className="form-section-header">
        Target
        <HelpPopper name="option-target" title="Target" iconClass="ms-2">
          An optional value that targets who or what the attribute applies to. For example, an attribute
          {' '}
          <i>drivers.license_status</i>
          {' '}
          might have a target &quot;Driver&quot;
          with choices for &quot;Primary&quot;, &quot;All&quot;, or &quot;Any&quot;
        </HelpPopper>
      </div>
      <p>Choices for refining the targeting. </p>
      <Row className="option-target">
        <Col sm={4}>
          <FormGroup className="flex-shrink-1">
            <Label for="method">Target Label</Label>
            <Input type="text" bsSize="sm" name="label" value={label} onChange={onChange} />
            <FormText>A short label to describe the target (example:&quot;Driver&quot;)</FormText>
          </FormGroup>
        </Col>

        <Col sm={8}>
          <FormGroup className="flex-shrink-1">
            <Label for="method">Target Choices</Label>
            <Input type="textarea" name="choices" rows={4} value={choices} onChange={onChange} />
            <FormText>
              Add 1 choice per line. Delimit values and labels with a colon (
              <i>value:label</i>
              )
            </FormText>
          </FormGroup>
        </Col>

      </Row>
    </div>

  );

export default OptionTarget;
