import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import { FormTabFlat } from '../../../Components/Form/FormCommon';

/*
 * Navigation for RulesConfig form
 */

const NavTabs = ({
  activeTab, onSelectTab, isSectionValid, allowAdvanced, product,
}) => (
  <Nav className="mastodon-tabs">
    <FormTabFlat
      id="summary"
      label="Summary"
      activeTab={activeTab}
      setActive={onSelectTab}
      isValid
    />
    {/* <FormTabFlat id="bidding"
                       label="Bidding"
                       activeTab={activeTab}
                       isValid={isSectionValid('bidding')}
                       setActive={onSelectTab}
          /> */}
    <FormTabFlat
      id="bidding"
      label="Settings"
      activeTab={activeTab}
      setActive={onSelectTab}
      isValid={isSectionValid('bidding')}
    />
    <FormTabFlat
      id="targeting"
      label="Targeting"
      activeTab={activeTab}
      setActive={onSelectTab}
      isValid={isSectionValid('targeting')}
    />
    <FormTabFlat
      id="caps"
      label="Caps & Dupes"
      activeTab={activeTab}
      setActive={onSelectTab}
      isValid={isSectionValid('caps')}
    />
    <FormTabFlat
      id="integrations"
      label="Integrations"
      activeTab={activeTab}
      setActive={onSelectTab}
      isValid={isSectionValid('integrations')}
    />

    {/* MST-1970 hide schedule tab, sows confusion and chaos
            <FormTabFlat id="activity"
                         label="Schedule"
                         activeTab={activeTab}
                         setActive={onSelectTab}
                         isValid={isSectionValid('activity')}
            />
            */}

    {product === 'clicks'
            && (
            <FormTabFlat
              id="tracking"
              label="Tracking"
              activeTab={activeTab}
              setActive={onSelectTab}
              isValid={isSectionValid('tracking')}
            />
            )}

    {allowAdvanced && (
    <FormTabFlat
      id="advanced"
      label="Advanced"
      activeTab={activeTab}
      setActive={onSelectTab}
      isValid={isSectionValid('advanced')}
    />
    )}

  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.string,
  onSelectTab: PropTypes.func,
  isSectionValid: PropTypes.func,
  allowAdvanced: PropTypes.bool,
  product: PropTypes.string,
};

NavTabs.defaultProps = {
  activeTab: '1',
  onSelectTab: () => {},
  isSectionValid: true,
};

export default NavTabs;
